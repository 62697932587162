import { FormInstance } from "antd/lib/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface ITFirmaTuru_Form_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface ITFirmaTuru_Form_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GoBack: any;
  GoBack_dummy: any;
  FirmaTuruSelectById: any[];
  FirmaTuruSelectById_dummy: any[];
  FirmaTuruSave: number;
  FirmaTuruSave_dummy: number;
  isComp6510107Visible: "visible" | "hidden";
  isComp59482600Visible: "visible" | "hidden";
}

export class TFirmaTuru_Form_ScreenBase extends React.PureComponent<ITFirmaTuru_Form_ScreenProps, any> {
  ml = [
    {
      Id: "16c151eb-42cf-0b2b-7e4a-e8b04fc43d88",
      Name: "tr_TR",
      ShortName: "Turkish",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "fbdbce9f-3fdb-30c5-c4fe-f7e443068251", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    { Id: "8468043e-f025-01e1-93b8-c71e3215778a", Name: "de_DE", ShortName: "German", LanguagePhrases: [] },
    {
      Id: "ac6201ea-f318-46c6-8ec3-4a799154d744",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 747402, PropertyName: "value", Value: "Firma Türü" },
        { Id: 7472962, PropertyName: "value", Value: "Tanim" },
        { Id: 59304470, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 615082, PropertyName: "value", Value: "Tanim EN" },
        { Id: 1825, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 6510107, PropertyName: "value", Value: "Sira" },
        { Id: 3910372, PropertyName: "value", Value: "Aktif" },
        { Id: 9544, PropertyName: "label", Value: "Save" }
      ]
    }
  ];
  defaultML = "tr_TR";
  screenHash = "";

  constructor(props) {
    super(props);

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GoBack: "",
      FirmaTuruSelectById: [],
      FirmaTuruSave: 0,
      isComp6510107Visible: "hidden",
      isComp59482600Visible: "hidden"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("tfirmaturu_form", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.TFirmaTuru_FormPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("tfirmaturu_form", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("tfirmaturu_form", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.TFirmaTuru_FormPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      tfirmaturu_form_59304470_value: this.state.FirmaTuruSelectById?.at?.(0)?.tanim ?? undefined,
      tfirmaturu_form_1825_value: this.state.FirmaTuruSelectById?.at?.(0)?.tanimEN ?? undefined,
      tfirmaturu_form_59482600_value: this.state.FirmaTuruSelectById?.at?.(0)?.sira ?? undefined,
      tfirmaturu_form_53423950_value: this.state.FirmaTuruSelectById?.at?.(0)?.aktif ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  TFirmaTuru_FormPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.Id ?? this.props.screenInputs.id, "Guid")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "TFirmaTuru_Form/TFirmaTuru_FormPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.FirmaTuruSelectById = result?.data.firmaTuruSelectById;
    formVars.tfirmaturu_form_59304470_value = ReactSystemFunctions.toString(
      this,
      stateVars.FirmaTuruSelectById?.length > 0 ? stateVars.FirmaTuruSelectById[0]?.tanim : null
    );
    formVars.tfirmaturu_form_1825_value = ReactSystemFunctions.toString(
      this,
      stateVars.FirmaTuruSelectById?.length > 0 ? stateVars.FirmaTuruSelectById[0]?.tanimEN : null
    );
    formVars.tfirmaturu_form_59482600_value = ReactSystemFunctions.toString(
      this,
      stateVars.FirmaTuruSelectById?.length > 0 ? stateVars.FirmaTuruSelectById[0]?.sira : null
    );
    formVars.tfirmaturu_form_53423950_value = ReactSystemFunctions.toString(
      this,
      stateVars.FirmaTuruSelectById?.length > 0 ? stateVars.FirmaTuruSelectById[0]?.aktif : null
    );
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TFirmaTuru_FormPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TFirmaTuru_FormPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.tfirmaturu_form_59304470_value = ReactSystemFunctions.toString(
      this,
      this.state.FirmaTuruSelectById?.length > 0 ? this.state.FirmaTuruSelectById[0]?.tanim : null
    );

    formVars.tfirmaturu_form_1825_value = ReactSystemFunctions.toString(
      this,
      this.state.FirmaTuruSelectById?.length > 0 ? this.state.FirmaTuruSelectById[0]?.tanimEN : null
    );

    formVars.tfirmaturu_form_59482600_value = ReactSystemFunctions.toString(
      this,
      this.state.FirmaTuruSelectById?.length > 0 ? this.state.FirmaTuruSelectById[0]?.sira : null
    );

    formVars.tfirmaturu_form_53423950_value = ReactSystemFunctions.toString(
      this,
      this.state.FirmaTuruSelectById?.length > 0 ? this.state.FirmaTuruSelectById[0]?.aktif : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  TFirmaTuru_FormComponent_931995_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TFirmaTuru_FormComponent_9544_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Aktif_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tfirmaturu_form_53423950_value", "value", "", "", "")
        ),
        "boolean"
      ),
      Sira_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tfirmaturu_form_59482600_value", "value", "", "", "")
        ),
        "number"
      ),
      Tanim_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "tfirmaturu_form_59304470_value", "value", "", "", "")
        ),
        "string"
      ),
      TanimEN_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "tfirmaturu_form_1825_value", "value", "", "", "")
        ),
        "string"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.Id ?? this.props.screenInputs.id, "Guid")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "TFirmaTuru_Form/TFirmaTuru_FormComponent_9544_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.FirmaTuruSave = result?.data.firmaTuruSave;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TFirmaTuru_FormComponent_9544_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TFirmaTuru_FormComponent_9544_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
