import { FormInstance } from "antd/lib/form";
import { Guid } from "guid-typescript";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IKatalogKart_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IKatalogKart_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  GoBack: any;
  GoBack_dummy: any;
  sbEserTipiList: any[];
  sbEserTipiList_dummy: any[];
  getStokKartbyId: any[];
  getStokKartbyId_dummy: any[];
  getEserPayDagilimListByStokId: any[];
  getEserPayDagilimListByStokId_dummy: any[];
  TriggerEvent: string;
  TriggerEvent_dummy: string;
  SaveStokM: number;
  SaveStokM_dummy: number;
  isComp370656Visible: "visible" | "hidden";
  isComp884751Visible: "visible" | "hidden";
  isComp887975Visible: "visible" | "hidden";
  isComp105717Visible: "visible" | "hidden";
  isComp82454Visible: "visible" | "hidden";
}

export class KatalogKart_ScreenBase extends React.PureComponent<IKatalogKart_ScreenProps, any> {
  katalogkart_868633_value_kuikaSelectBoxRef: React.RefObject<any>;
  katalogkart_520044_value_kuikaDatePickerRef: React.RefObject<any>;
  katalogkart_360462_value_kuikaDatePickerRef: React.RefObject<any>;
  katalogkart_159707_value_kuikaDatePickerRef: React.RefObject<any>;
  katalogkart_183921_value_kuikaSelectBoxRef: React.RefObject<any>;
  katalogkart_981337_value_kuikaTableRef: React.RefObject<any>;
  ml = [
    {
      Id: "16c151eb-42cf-0b2b-7e4a-e8b04fc43d88",
      Name: "tr_TR",
      ShortName: "Turkish",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "fbdbce9f-3fdb-30c5-c4fe-f7e443068251", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    { Id: "8468043e-f025-01e1-93b8-c71e3215778a", Name: "de_DE", ShortName: "German", LanguagePhrases: [] },
    {
      Id: "546b1100-428a-43b9-a8f0-69751c6d1835",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 554046, PropertyName: "value", Value: "Eser  Bilgileri" },
        { Id: 12763, PropertyName: "value", Value: "Eser Tipi" },
        { Id: 868633, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 168968, PropertyName: "value", Value: "Eser Adı" },
        { Id: 59872, PropertyName: "value", Value: "Alternatif Eser Adı" },
        { Id: 8015, PropertyName: "value", Value: "Adepte Eser ise Orjinal Eser Adı" },
        { Id: 199175, PropertyName: "value", Value: "Albüm Numarası" },
        { Id: 207512, PropertyName: "value", Value: "Albüm Adı" },
        { Id: 482170, PropertyName: "value", Value: "Albüm Track No" },
        { Id: 93994, PropertyName: "value", Value: "Versiyon" },
        { Id: 864844, PropertyName: "value", Value: "Süre" },
        { Id: 370656, PropertyName: "value", Value: "Track Ad" },
        { Id: 254531, PropertyName: "value", Value: "Eser Yayım Sözleşme Tarihi" },
        { Id: 520044, PropertyName: "placeholder", Value: "Select Date..." },
        { Id: 560288, PropertyName: "value", Value: "ISWC (Uluslararası Standar Müzik Eser Kodu)" },
        { Id: 778437, PropertyName: "value", Value: "Eser Yayın (Release) Tarihi" },
        { Id: 360462, PropertyName: "placeholder", Value: "Select Date..." },
        { Id: 997552, PropertyName: "value", Value: "Orjinal Yapım / Kayıt Sahibi" },
        { Id: 665254, PropertyName: "value", Value: "ISRC (Uluslararası Standart Yapım/Kayıt Kodu)" },
        { Id: 385167, PropertyName: "value", Value: "Eser Ticari Olarak Yayınlandıysa;" },
        { Id: 256214, PropertyName: "value", Value: "Ticari Albümü Çıkaran" },
        { Id: 654716, PropertyName: "value", Value: "Ticari Albüm Adı" },
        { Id: 752850, PropertyName: "value", Value: "Eserin Ticari Adı" },
        { Id: 119094, PropertyName: "value", Value: "Ticari Yayın (Release) Tarihi" },
        { Id: 159707, PropertyName: "placeholder", Value: "Select Date..." },
        { Id: 12977, PropertyName: "value", Value: "Eser Tipi" },
        { Id: 183921, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 909280, PropertyName: "value", Value: "Stok Kodu" },
        { Id: 39238, PropertyName: "value", Value: "Mh.Kodu" },
        { Id: 265648, PropertyName: "value", Value: "Katalog Kodu" },
        { Id: 426290, PropertyName: "value", Value: "Katalog Adı" },
        { Id: 917390, PropertyName: "value", Value: "Besteciler" },
        { Id: 707570, PropertyName: "value", Value: "Eser Sahipleri / Temsilcilik / Dağıtım" },
        { Id: 981337, PropertyName: "nodatafoundmessage", Value: "Veri Bulunamadı" },
        { Id: 242926, PropertyName: "title", Value: "Eser Sahibi" },
        { Id: 930735, PropertyName: "value", Value: "[datafield:adsoyad]" },
        { Id: 833397, PropertyName: "title", Value: "Görevi" },
        { Id: 870872, PropertyName: "value", Value: "[datafield:gorevi]" },
        { Id: 944008, PropertyName: "title", Value: "Eser Payı" },
        { Id: 238471, PropertyName: "value", Value: "[datafield:hakedisoran]" },
        { Id: 3319, PropertyName: "title", Value: "Yayımcı" },
        { Id: 461838, PropertyName: "value", Value: "[datafield:yayimcitanim]" },
        { Id: 855521, PropertyName: "title", Value: "M.Birliği" },
        { Id: 961374, PropertyName: "value", Value: "[datafield:mbirligitanim]" },
        { Id: 537689, PropertyName: "title", Value: "MUZ (Mekanik Payı)" },
        { Id: 878313, PropertyName: "value", Value: "[datafield:muzoran]" },
        { Id: 525158, PropertyName: "title", Value: "MUZ (Temsili Payı)" },
        { Id: 289488, PropertyName: "value", Value: "[datafield:muzorantemsili]" },
        { Id: 398542, PropertyName: "label", Value: "Vazgeç" },
        { Id: 336812, PropertyName: "label", Value: "Kaydet" }
      ]
    }
  ];
  defaultML = "tr_TR";
  screenHash = "";

  constructor(props) {
    super(props);
    this.katalogkart_868633_value_kuikaSelectBoxRef = React.createRef();
    this.katalogkart_520044_value_kuikaDatePickerRef = React.createRef();
    this.katalogkart_360462_value_kuikaDatePickerRef = React.createRef();
    this.katalogkart_159707_value_kuikaDatePickerRef = React.createRef();
    this.katalogkart_183921_value_kuikaSelectBoxRef = React.createRef();
    this.katalogkart_981337_value_kuikaTableRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      NAVIGATE: "",
      GoBack: "",
      sbEserTipiList: [],
      getStokKartbyId: [],
      getEserPayDagilimListByStokId: [],
      TriggerEvent: "",
      SaveStokM: 0,
      isComp370656Visible: "hidden",
      isComp884751Visible: "hidden",
      isComp887975Visible: "hidden",
      isComp105717Visible: "hidden",
      isComp82454Visible: "hidden"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("katalogkart", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.KatalogKartPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("katalogkart", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("katalogkart", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.KatalogKartPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      katalogkart_868633_value: this.state.getStokKartbyId?.at?.(0)?.eserTipiID ?? undefined,
      katalogkart_6285_value: this.state.getStokKartbyId?.at?.(0)?.stokAd ?? undefined,
      katalogkart_370468_value: this.state.getStokKartbyId?.at?.(0)?.stokAdAlternatif ?? undefined,
      katalogkart_563580_value: this.state.getStokKartbyId?.at?.(0)?.stokAdAdapte ?? undefined,
      katalogkart_986980_value: this.state.getStokKartbyId?.at?.(0)?.albumKodu ?? undefined,
      katalogkart_519113_value: this.state.getStokKartbyId?.at?.(0)?.albumAd ?? undefined,
      katalogkart_256393_value: this.state.getStokKartbyId?.at?.(-1)?.trackNo ?? undefined,
      katalogkart_607171_value: this.state.getStokKartbyId?.at?.(0)?.trackVersiyon ?? undefined,
      katalogkart_769389_value: this.state.getStokKartbyId?.at?.(0)?.trackSure ?? undefined,
      katalogkart_884751_value: this.state.getStokKartbyId?.at?.(0)?.trackAd ?? undefined,
      katalogkart_520044_value: this.state.getStokKartbyId?.at?.(0)?.sozlesmeTarihi ?? undefined,
      katalogkart_972982_value: this.state.getStokKartbyId?.at?.(0)?.iswc ?? undefined,
      katalogkart_360462_value: this.state.getStokKartbyId?.at?.(0)?.releaseTarihi ?? undefined,
      katalogkart_872732_value: this.state.getStokKartbyId?.at?.(0)?.yapimSahibi ?? undefined,
      katalogkart_776878_value: this.state.getStokKartbyId?.at?.(0)?.isrc ?? undefined,
      katalogkart_869356_value: this.state.getStokKartbyId?.at?.(0)?.ticariAlbumCikaran ?? undefined,
      katalogkart_723070_value: this.state.getStokKartbyId?.at?.(0)?.ticariAlbumAd ?? undefined,
      katalogkart_586230_value: this.state.getStokKartbyId?.at?.(0)?.ticariAd ?? undefined,
      katalogkart_159707_value: this.state.getStokKartbyId?.at?.(0)?.ticariReleaseTarihi ?? undefined,
      katalogkart_183921_value: this.state.getStokKartbyId?.at?.(0)?.eserTipiID ?? undefined,
      katalogkart_782466_value: this.state.getStokKartbyId?.at?.(0)?.stokKod ?? undefined,
      katalogkart_664882_value: this.state.getStokKartbyId?.at?.(0)?.stokKod ?? undefined,
      katalogkart_937565_value: this.state.getStokKartbyId?.at?.(0)?.katalogKodu ?? undefined,
      katalogkart_941344_value: this.state.getStokKartbyId?.at?.(0)?.katalogAd ?? undefined,
      katalogkart_650045_value: this.state.getStokKartbyId?.at?.(0)?.besteciler ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  KatalogKartPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      Id_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      ),
      UserName_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      StokId_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "KatalogKart/KatalogKartPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.sbEserTipiList = result?.data.sbEserTipiList;

    formVars.katalogkart_868633_value =
      stateVars.getStokKartbyId?.length > 0 ? stateVars.getStokKartbyId[0]?.eserTipiID : null;
    formVars.katalogkart_868633_options = stateVars.sbEserTipiList;

    formVars.katalogkart_183921_value =
      stateVars.getStokKartbyId?.length > 0 ? stateVars.getStokKartbyId[0]?.eserTipiID : null;
    formVars.katalogkart_183921_options = stateVars.sbEserTipiList;
    stateVars.getStokKartbyId = result?.data.getStokKartbyId;
    formVars.katalogkart_868633_value =
      stateVars.getStokKartbyId?.length > 0 ? stateVars.getStokKartbyId[0]?.eserTipiID : null;
    formVars.katalogkart_868633_options = stateVars.sbEserTipiList;
    formVars.katalogkart_6285_value = ReactSystemFunctions.toString(
      this,
      stateVars.getStokKartbyId?.length > 0 ? stateVars.getStokKartbyId[0]?.stokAd : null
    );
    formVars.katalogkart_370468_value = ReactSystemFunctions.toString(
      this,
      stateVars.getStokKartbyId?.length > 0 ? stateVars.getStokKartbyId[0]?.stokAdAlternatif : null
    );
    formVars.katalogkart_563580_value = ReactSystemFunctions.toString(
      this,
      stateVars.getStokKartbyId?.length > 0 ? stateVars.getStokKartbyId[0]?.stokAdAdapte : null
    );
    formVars.katalogkart_986980_value = ReactSystemFunctions.toString(
      this,
      stateVars.getStokKartbyId?.length > 0 ? stateVars.getStokKartbyId[0]?.albumKodu : null
    );
    formVars.katalogkart_519113_value = ReactSystemFunctions.toString(
      this,
      stateVars.getStokKartbyId?.length > 0 ? stateVars.getStokKartbyId[0]?.albumAd : null
    );
    formVars.katalogkart_256393_value = ReactSystemFunctions.toInteger(
      this,
      stateVars.getStokKartbyId?.length > 0
        ? stateVars.getStokKartbyId[stateVars.getStokKartbyId.length - 1].trackNo
        : null
    );
    formVars.katalogkart_607171_value = ReactSystemFunctions.toString(
      this,
      stateVars.getStokKartbyId?.length > 0 ? stateVars.getStokKartbyId[0]?.trackVersiyon : null
    );
    formVars.katalogkart_769389_value = ReactSystemFunctions.toInteger(
      this,
      stateVars.getStokKartbyId?.length > 0 ? stateVars.getStokKartbyId[0]?.trackSure : null
    );
    formVars.katalogkart_884751_value = ReactSystemFunctions.toString(
      this,
      stateVars.getStokKartbyId?.length > 0 ? stateVars.getStokKartbyId[0]?.trackAd : null
    );
    formVars.katalogkart_520044_value = ReactSystemFunctions.value(
      this,
      stateVars.getStokKartbyId?.length > 0 ? stateVars.getStokKartbyId[0]?.sozlesmeTarihi : null
    );
    formVars.katalogkart_972982_value = ReactSystemFunctions.toString(
      this,
      stateVars.getStokKartbyId?.length > 0 ? stateVars.getStokKartbyId[0]?.iswc : null
    );
    formVars.katalogkart_360462_value = ReactSystemFunctions.value(
      this,
      stateVars.getStokKartbyId?.length > 0 ? stateVars.getStokKartbyId[0]?.releaseTarihi : null
    );
    formVars.katalogkart_872732_value = ReactSystemFunctions.toString(
      this,
      stateVars.getStokKartbyId?.length > 0 ? stateVars.getStokKartbyId[0]?.yapimSahibi : null
    );
    formVars.katalogkart_776878_value = ReactSystemFunctions.toString(
      this,
      stateVars.getStokKartbyId?.length > 0 ? stateVars.getStokKartbyId[0]?.isrc : null
    );
    formVars.katalogkart_869356_value = ReactSystemFunctions.toString(
      this,
      stateVars.getStokKartbyId?.length > 0 ? stateVars.getStokKartbyId[0]?.ticariAlbumCikaran : null
    );
    formVars.katalogkart_723070_value = ReactSystemFunctions.toString(
      this,
      stateVars.getStokKartbyId?.length > 0 ? stateVars.getStokKartbyId[0]?.ticariAlbumAd : null
    );
    formVars.katalogkart_586230_value = ReactSystemFunctions.toString(
      this,
      stateVars.getStokKartbyId?.length > 0 ? stateVars.getStokKartbyId[0]?.ticariAd : null
    );
    formVars.katalogkart_159707_value = ReactSystemFunctions.value(
      this,
      stateVars.getStokKartbyId?.length > 0 ? stateVars.getStokKartbyId[0]?.ticariReleaseTarihi : null
    );
    formVars.katalogkart_183921_value =
      stateVars.getStokKartbyId?.length > 0 ? stateVars.getStokKartbyId[0]?.eserTipiID : null;
    formVars.katalogkart_183921_options = stateVars.sbEserTipiList;
    formVars.katalogkart_782466_value = ReactSystemFunctions.toString(
      this,
      stateVars.getStokKartbyId?.length > 0 ? stateVars.getStokKartbyId[0]?.stokKod : null
    );
    formVars.katalogkart_664882_value = ReactSystemFunctions.toString(
      this,
      stateVars.getStokKartbyId?.length > 0 ? stateVars.getStokKartbyId[0]?.stokKod : null
    );
    formVars.katalogkart_937565_value = ReactSystemFunctions.toString(
      this,
      stateVars.getStokKartbyId?.length > 0 ? stateVars.getStokKartbyId[0]?.katalogKodu : null
    );
    formVars.katalogkart_941344_value = ReactSystemFunctions.toString(
      this,
      stateVars.getStokKartbyId?.length > 0 ? stateVars.getStokKartbyId[0]?.katalogAd : null
    );
    formVars.katalogkart_650045_value = ReactSystemFunctions.toString(
      this,
      stateVars.getStokKartbyId?.length > 0 ? stateVars.getStokKartbyId[0]?.besteciler : null
    );
    stateVars.getEserPayDagilimListByStokId = result?.data.getEserPayDagilimListByStokId;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.KatalogKartPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  KatalogKartPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.katalogkart_868633_value = ReactSystemFunctions.value(
      this,
      this.state.getStokKartbyId?.length > 0 ? this.state.getStokKartbyId[0]?.eserTipiID : null
    );

    stateVars.dataSource_868633 = this.state.sbEserTipiList;
    stateVars.dataSource_868633 = this.state.sbEserTipiList;
    formVars.katalogkart_6285_value = ReactSystemFunctions.toString(
      this,
      this.state.getStokKartbyId?.length > 0 ? this.state.getStokKartbyId[0]?.stokAd : null
    );

    formVars.katalogkart_370468_value = ReactSystemFunctions.toString(
      this,
      this.state.getStokKartbyId?.length > 0 ? this.state.getStokKartbyId[0]?.stokAdAlternatif : null
    );

    formVars.katalogkart_563580_value = ReactSystemFunctions.toString(
      this,
      this.state.getStokKartbyId?.length > 0 ? this.state.getStokKartbyId[0]?.stokAdAdapte : null
    );

    formVars.katalogkart_986980_value = ReactSystemFunctions.toString(
      this,
      this.state.getStokKartbyId?.length > 0 ? this.state.getStokKartbyId[0]?.albumKodu : null
    );

    formVars.katalogkart_519113_value = ReactSystemFunctions.toString(
      this,
      this.state.getStokKartbyId?.length > 0 ? this.state.getStokKartbyId[0]?.albumAd : null
    );

    formVars.katalogkart_256393_value = ReactSystemFunctions.toInteger(
      this,
      this.state.getStokKartbyId?.length > 0
        ? this.state.getStokKartbyId[this.state.getStokKartbyId.length - 1].trackNo
        : null
    );

    formVars.katalogkart_607171_value = ReactSystemFunctions.toString(
      this,
      this.state.getStokKartbyId?.length > 0 ? this.state.getStokKartbyId[0]?.trackVersiyon : null
    );

    formVars.katalogkart_769389_value = ReactSystemFunctions.toInteger(
      this,
      this.state.getStokKartbyId?.length > 0 ? this.state.getStokKartbyId[0]?.trackSure : null
    );

    formVars.katalogkart_884751_value = ReactSystemFunctions.toString(
      this,
      this.state.getStokKartbyId?.length > 0 ? this.state.getStokKartbyId[0]?.trackAd : null
    );

    formVars.katalogkart_520044_value = ReactSystemFunctions.value(
      this,
      this.state.getStokKartbyId?.length > 0 ? this.state.getStokKartbyId[0]?.sozlesmeTarihi : null
    );

    formVars.katalogkart_972982_value = ReactSystemFunctions.toString(
      this,
      this.state.getStokKartbyId?.length > 0 ? this.state.getStokKartbyId[0]?.iswc : null
    );

    formVars.katalogkart_360462_value = ReactSystemFunctions.value(
      this,
      this.state.getStokKartbyId?.length > 0 ? this.state.getStokKartbyId[0]?.releaseTarihi : null
    );

    formVars.katalogkart_872732_value = ReactSystemFunctions.toString(
      this,
      this.state.getStokKartbyId?.length > 0 ? this.state.getStokKartbyId[0]?.yapimSahibi : null
    );

    formVars.katalogkart_776878_value = ReactSystemFunctions.toString(
      this,
      this.state.getStokKartbyId?.length > 0 ? this.state.getStokKartbyId[0]?.isrc : null
    );

    formVars.katalogkart_869356_value = ReactSystemFunctions.toString(
      this,
      this.state.getStokKartbyId?.length > 0 ? this.state.getStokKartbyId[0]?.ticariAlbumCikaran : null
    );

    formVars.katalogkart_723070_value = ReactSystemFunctions.toString(
      this,
      this.state.getStokKartbyId?.length > 0 ? this.state.getStokKartbyId[0]?.ticariAlbumAd : null
    );

    formVars.katalogkart_586230_value = ReactSystemFunctions.toString(
      this,
      this.state.getStokKartbyId?.length > 0 ? this.state.getStokKartbyId[0]?.ticariAd : null
    );

    formVars.katalogkart_159707_value = ReactSystemFunctions.value(
      this,
      this.state.getStokKartbyId?.length > 0 ? this.state.getStokKartbyId[0]?.ticariReleaseTarihi : null
    );

    formVars.katalogkart_183921_value = ReactSystemFunctions.value(
      this,
      this.state.getStokKartbyId?.length > 0 ? this.state.getStokKartbyId[0]?.eserTipiID : null
    );

    stateVars.dataSource_183921 = this.state.sbEserTipiList;
    stateVars.dataSource_183921 = this.state.sbEserTipiList;
    formVars.katalogkart_782466_value = ReactSystemFunctions.toString(
      this,
      this.state.getStokKartbyId?.length > 0 ? this.state.getStokKartbyId[0]?.stokKod : null
    );

    formVars.katalogkart_664882_value = ReactSystemFunctions.toString(
      this,
      this.state.getStokKartbyId?.length > 0 ? this.state.getStokKartbyId[0]?.stokKod : null
    );

    formVars.katalogkart_937565_value = ReactSystemFunctions.toString(
      this,
      this.state.getStokKartbyId?.length > 0 ? this.state.getStokKartbyId[0]?.katalogKodu : null
    );

    formVars.katalogkart_941344_value = ReactSystemFunctions.toString(
      this,
      this.state.getStokKartbyId?.length > 0 ? this.state.getStokKartbyId[0]?.katalogAd : null
    );

    formVars.katalogkart_650045_value = ReactSystemFunctions.toString(
      this,
      this.state.getStokKartbyId?.length > 0 ? this.state.getStokKartbyId[0]?.besteciler : null
    );

    stateVars.dataSource_981337 = this.state.getEserPayDagilimListByStokId;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  KatalogKartComponent_515750_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "KatalogKart",
      "LeftMenu",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "304331",
      "",
      "left",
      null,
      "",
      "",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  KatalogKartComponent_622923_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  KatalogKartComponent_516358_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    if (await this.KatalogKartComponent_336812_onClick()) return true;

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "UrunPayDagilimKart",
      "prmStokID",
      this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid
    );
    KuikaAppManager.addToPageInputVariables("UrunPayDagilimKart", "prmGuid", Guid.create().toString());

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "KatalogKart",
      "UrunPayDagilimKart",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "591512",
      null,
      "right",
      null,
      "400",
      "",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  KatalogKartComponent_553764_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "UrunPayDagilimKart",
      "prmStokID",
      this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid
    );
    KuikaAppManager.addToPageInputVariables(
      "UrunPayDagilimKart",
      "prmGuid",
      ReactSystemFunctions.value(this, "katalogkart_981337_value", "id")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "KatalogKart",
      "UrunPayDagilimKart",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "374430",
      null,
      "right",
      null,
      "400",
      "",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  KatalogKartComponent_398542_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  KatalogKartComponent_336812_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "katalogkart_519113_value", "value", "", "", "")
        ),
        null
      ),
      message: "!",
      formName: "katalogkart_519113_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "katalogkart_986980_value", "value", "", "", "")
        ),
        null
      ),
      message: "!",
      formName: "katalogkart_986980_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "katalogkart_183921_value", "value", "sbEserTipiList", "id", "")
        ),
        null
      ),
      message: "!",
      formName: "katalogkart_183921_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "katalogkart_6285_value", "value", "", "", "")
        ),
        null
      ),
      message: "!",
      formName: "katalogkart_6285_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "katalogkart_884751_value", "value", "", "", "")
        ),
        null
      ),
      message: "!",
      formName: "katalogkart_884751_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "katalogkart_607171_value", "value", "", "", "")
        ),
        null
      ),
      message: "!",
      formName: "katalogkart_607171_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "katalogkart_6285_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "katalogkart_370468_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "katalogkart_6285_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "katalogkart_563580_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "katalogkart_519113_value", "value", "", "", "")
        ),
        null
      ),
      message: "!",
      formName: "katalogkart_972982_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "katalogkart_986980_value", "value", "", "", "")
        ),
        null
      ),
      message: "!",
      formName: "katalogkart_872732_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "katalogkart_519113_value", "value", "", "", "")
        ),
        null
      ),
      message: "!",
      formName: "katalogkart_776878_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "katalogkart_986980_value", "value", "", "", "")
        ),
        null
      ),
      message: "!",
      formName: "katalogkart_869356_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "katalogkart_986980_value", "value", "", "", "")
        ),
        null
      ),
      message: "!",
      formName: "katalogkart_723070_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "katalogkart_519113_value", "value", "", "", "")
        ),
        null
      ),
      message: "!",
      formName: "katalogkart_586230_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      AlbumAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "katalogkart_519113_value", "value", "", "", "")
        ),
        "string"
      ),
      AlbumKodu_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "katalogkart_986980_value", "value", "", "", "")
        ),
        "string"
      ),
      EserTipiID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "katalogkart_183921_value", "value", "sbEserTipiList", "id", "id")
        ),
        "Guid"
      ),
      StokAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "katalogkart_6285_value", "value", "", "", "")
        ),
        "string"
      ),
      TrackAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "katalogkart_884751_value", "value", "", "", "")
        ),
        "string"
      ),
      TrackNo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "katalogkart_256393_value", "value", "", "", "")
        ),
        "string"
      ),
      TrackSure_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "katalogkart_769389_value", "value", "", "", "")
        ),
        "string"
      ),
      TrackVersiyon_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "katalogkart_607171_value", "value", "", "", "")
        ),
        "string"
      ),
      StokAdAlternatif_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "katalogkart_370468_value", "value", "", "", "")
        ),
        "string"
      ),
      StokAdAdapte_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "katalogkart_563580_value", "value", "", "", "")
        ),
        "string"
      ),
      SozlesmeTarihi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "katalogkart_520044_value", "value", "", "", "")
        ),
        "Date"
      ),
      ISWC_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "katalogkart_972982_value", "value", "", "", "")
        ),
        "string"
      ),
      ReleaseTarihi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "katalogkart_360462_value", "value", "", "", "")
        ),
        "Date"
      ),
      YapimSahibi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "katalogkart_872732_value", "value", "", "", "")
        ),
        "string"
      ),
      ISRC_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "katalogkart_776878_value", "value", "", "", "")
        ),
        "string"
      ),
      TicariAlbumCikaran_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "katalogkart_869356_value", "value", "", "", "")
        ),
        "string"
      ),
      TicariAlbumAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "katalogkart_723070_value", "value", "", "", "")
        ),
        "string"
      ),
      TicariAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "katalogkart_586230_value", "value", "", "", "")
        ),
        "string"
      ),
      TicariReleaseTarihi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "katalogkart_159707_value", "value", "", "", "")
        ),
        "Date"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "KatalogKart/KatalogKartComponent_336812_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveStokM = result?.data.saveStokM;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.KatalogKartComponent_336812_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  KatalogKartComponent_336812_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [304331, 591512, 374430] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.KatalogKartPageInit();
    }
  }
}
