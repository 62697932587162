const screenInputData = {
  MusteriKart: [{"name":"prmGuid","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	BaglantiliMeslekBirligiKart: [{"name":"prmGuid","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"prmEserSahibiID","type":"Guid","cardinality":1,"isExpandedPanel":"false"}],
	IKartYapim: [{"name":"prmGuid","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	TProjeTuru_Form: [{"name":"Id","type":"Guid","cardinality":1,"isExpandedPanel":"false"}],
	TAksiyonTur_Form: [{"name":"Id","type":"Guid","cardinality":1,"isExpandedPanel":"false"}],
	TParaBirim_Form: [{"name":"Id","type":"Guid","cardinality":1,"isExpandedPanel":"false"}],
	ParcaEkle: [{"name":"prm_AlbumID","type":"Guid","cardinality":1,"isExpandedPanel":"true"},{"name":"prm_EserID","type":"Guid","cardinality":1,"isExpandedPanel":"true"}],
	KullaniciKart: [{"name":"prmGuid","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"prmNewEdit","type":"String","cardinality":1,"isExpandedPanel":null}],
	UrunPayDagilimKart: [{"name":"prmStokID","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"prmGuid","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	FiyatlandirmaNotlarKart: [{"name":"prmGuid","type":"Guid","cardinality":1,"isExpandedPanel":"false"},{"name":"NewEdit","type":"String","cardinality":1,"isExpandedPanel":"false"}],
	KatalogPayDagilimKart: [{"name":"prmGuid","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"prmAltKatalogID","type":"Guid","cardinality":1,"isExpandedPanel":"false"}],
	TStopaj_Form: [{"name":"Id","type":"Guid","cardinality":1,"isExpandedPanel":"false"}],
	Sticker: [{"name":"prmIId","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"prmCId","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	CariIlgiliKisiler: [{"name":"prmGuid","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"prmCariID","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	ZarfEtiket: [{"name":"prmIId","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"prmCId","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	TSozlesmeTipi_Form: [{"name":"Id","type":"Guid","cardinality":1,"isExpandedPanel":"false"}],
	UrunPayDagilimKartelifsiz: [{"name":"prmStokID","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"prmGuid","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	FiyatArttir: [{"name":"FiyatListeId","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"FiyatListeAd","type":"String","cardinality":1,"isExpandedPanel":null}],
	IKartAltYayimci: [{"name":"prmGuid","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	TSureAralik_Form: [{"name":"Id","type":"Guid","cardinality":1,"isExpandedPanel":"false"}],
	CariAdresler: [{"name":"prmGuid","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"prmCariID","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	QuarterChange: [{"name":"prmGuid","type":"Guid","cardinality":1,"isExpandedPanel":"false"},{"name":"nereden","type":"String","cardinality":1,"isExpandedPanel":null}],
	CariVarisler: [{"name":"prmGuid","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"prmCariID","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	Ulke_Form: [{"name":"Id","type":"Guid","cardinality":1,"isExpandedPanel":"false"}],
	TSozlesmeKonu_Form: [{"name":"Id","type":"Guid","cardinality":1,"isExpandedPanel":"false"}],
	IsTakipKart: [{"name":"prmGuid","type":"Guid","cardinality":1,"isExpandedPanel":"false"},{"name":"prmNerdenGeldi","type":"String","cardinality":1,"isExpandedPanel":null}],
	CariSozlesmeler: [{"name":"prmGuid","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"prmCariID","type":"Guid","cardinality":1,"isExpandedPanel":"false"},{"name":"prmNerdenGeldi","type":"String","cardinality":1,"isExpandedPanel":null},{"name":"prmIsKartTuruID","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"prmMusteriID","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	DagitimDetailList: [{"name":"prmKategoriKodu","type":"String","cardinality":1,"isExpandedPanel":"false"},{"name":"prmSimge","type":"String","cardinality":1,"isExpandedPanel":"false"},{"name":"prmQuarterKod","type":"String","cardinality":1,"isExpandedPanel":"false"},{"name":"prmQuarterYear","type":"String","cardinality":1,"isExpandedPanel":"false"}],
	PopupMuzikBankasiSecimi: [{"name":"prmGuid","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	EserSahibiKart: [{"name":"prmGuid","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	AlbumEkle: [{"name":"prm_id","type":"Guid","cardinality":1,"isExpandedPanel":"false"}],
	UrunKart: [{"name":"prmGuid","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"NewEdit","type":"String","cardinality":1,"isExpandedPanel":null}],
	IKartDigital: [{"name":"prmGuid","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	TMeslekBirligiAd_Form: [{"name":"Id","type":"Guid","cardinality":1,"isExpandedPanel":"false"}],
	CariIBankaBilgileri: [{"name":"prmGuid","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"prmCariID","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	IKartMeslekBirligiY: [{"name":"prmGuid","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	HataKontrol: [{"name":"KontrolParametresi","type":"String","cardinality":1,"isExpandedPanel":null}],
	IKartMeslekBirligiTR: [{"name":"prmGuid","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	TSozlesmeKanal_Form: [{"name":"Id","type":"Guid","cardinality":1,"isExpandedPanel":"false"}],
	AksiyonKart: [{"name":"prmGuid","type":"Guid","cardinality":1,"isExpandedPanel":"false"},{"name":"NewDisplay","type":"String","cardinality":1,"isExpandedPanel":null},{"name":"BasvuruFormuID","type":"Guid","cardinality":1,"isExpandedPanel":"false"}],
	FiyatlandirmaOzelKart: [{"name":"prmGuid","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"NewEdit","type":"String","cardinality":1,"isExpandedPanel":"false"},{"name":"BTuru","type":"String","cardinality":1,"isExpandedPanel":"false"},{"name":"PTuru","type":"String","cardinality":1,"isExpandedPanel":"false"},{"name":"BFId","type":"Guid","cardinality":1,"isExpandedPanel":"false"},{"name":"FiyatListeAdID","type":"Guid","cardinality":1,"isExpandedPanel":"false"}],
	BFormuBankaV2: [{"name":"prmGuid","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"prmBavuruTuruID","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"NewDisplay","type":"String","cardinality":1,"isExpandedPanel":null},{"name":"prmNerdenGeldi","type":"String","cardinality":1,"isExpandedPanel":"false"}],
	IKartPaketAnlasma: [{"name":"prmGuid","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	EserKayitKart: [{"name":"prmStokID","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"prmGuid","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	IsTakipListNot: [{"name":"BasvuruFormuID","type":"Guid","cardinality":1,"isExpandedPanel":"false"}],
	KatalogKart: [{"name":"prmGuid","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	BFormuTicari: [{"name":"prmGuid","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"prmBavuruTuruID","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"NewDisplay","type":"String","cardinality":1,"isExpandedPanel":null},{"name":"prmProjeTuruID","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"prmNerdenGeldi","type":"String","cardinality":1,"isExpandedPanel":"false"}],
	EserSahibiPayDagilimKart: [{"name":"prmGuid","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"prmEserSahibiID","type":"Guid","cardinality":1,"isExpandedPanel":"false"}],
	ilce_Form: [{"name":"Id","type":"Guid","cardinality":1,"isExpandedPanel":"false"}],
	TYayimci_Form: [{"name":"Id","type":"Guid","cardinality":1,"isExpandedPanel":"false"}],
	TProjeFormTuru_Form: [{"name":"Id","type":"Guid","cardinality":1,"isExpandedPanel":"false"}],
	TMecra_Form: [{"name":"Id","type":"Guid","cardinality":1,"isExpandedPanel":"false"}],
	AksiyonTur_Form: [{"name":"Id","type":"Guid","cardinality":1,"isExpandedPanel":"true"}],
	FiyatKart: [{"name":"prmGuid","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	TMusteriTuru_Form: [{"name":"Id","type":"Guid","cardinality":1,"isExpandedPanel":"false"}],
	AltKatalogKart: [{"name":"prmGuid","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"prmKatalogId","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	TTelifTur_Form: [{"name":"Id","type":"Guid","cardinality":1,"isExpandedPanel":"false"}],
	AnaKatalogKart: [{"name":"prmGuid","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	BFormuTicariPrint: [{"name":"prmGuid","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"prmBavuruTuruID","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"NewDisplay","type":"String","cardinality":1,"isExpandedPanel":null},{"name":"prmProjeTuruID","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"prmNerdenGeldi","type":"String","cardinality":1,"isExpandedPanel":"false"}],
	TTemsilBolgesi_Form: [{"name":"Id","type":"Guid","cardinality":1,"isExpandedPanel":"false"}],
	TIsAlani_Form: [{"name":"Id","type":"Guid","cardinality":1,"isExpandedPanel":"false"}],
	AltKatalogBaglantiliMeslekBirligiKart: [{"name":"prmGuid","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"prmAltKatalogiID","type":"Guid","cardinality":1,"isExpandedPanel":"false"}],
	TMBirligi_Form: [{"name":"Id","type":"Guid","cardinality":1,"isExpandedPanel":"false"}],
	il_Form: [{"name":"Id","type":"Guid","cardinality":1,"isExpandedPanel":"false"}],
	TFirmaTuru_Form: [{"name":"Id","type":"Guid","cardinality":1,"isExpandedPanel":"false"}],
	DagitimListKUMULE: [{"name":"QYear","type":"String","cardinality":1,"isExpandedPanel":null},{"name":"QKod","type":"String","cardinality":1,"isExpandedPanel":null},{"name":"DKodu","type":"String","cardinality":1,"isExpandedPanel":null}]
};

export class ScreenInputHelper {
  public static getScreenInputValue(screenName: string, inputName: string, value: string): any {
    switch (this.getScreenInputType(screenName, inputName)) {
      case "String":
        return value;
      case "Boolean":
        return value.toLowerCase() === "true";
      // TODO
    }

    return value;
  }

  private static getScreenInputType(screenName: string, inputName: string): string {
    const data = screenInputData[screenName]?.find((x) => x.Name === inputName);
    return data?.TypeName ? data.TypeName : "";
  }
}
