import { FormInstance } from "antd/lib/form";
import React from "react";
import Swal from "sweetalert2";
import { IUserInfoDto } from "../../models/dto/user-info.dto";
import { IValidationData } from "../../models/dto/validation-data";
import { UserService } from "../../services/user-service";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface ITicketList_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface ITicketList_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  spTicketList: any[];
  spTicketList_dummy: any[];
  getUserInfo: any[];
  getUserInfo_dummy: any[];
  getFilterParametersByUserName: any[];
  getFilterParametersByUserName_dummy: any[];
  sbSureAralikList: any[];
  sbSureAralikList_dummy: any[];
  SetValueOf: any;
  SetValueOf_dummy: any;
  spTicketListCount: any[];
  spTicketListCount_dummy: any[];
  FilterParametersSaveTicketList: number;
  FilterParametersSaveTicketList_dummy: number;
  getFormNo: any[];
  getFormNo_dummy: any[];
  setIsTakipTrue: number;
  setIsTakipTrue_dummy: number;
  Notify: boolean;
  Notify_dummy: boolean;
  spTicketCopy: any[];
  spTicketCopy_dummy: any[];
  setBasvuruFormuDelete: number;
  setBasvuruFormuDelete_dummy: number;
  isComp494391Visible: "visible" | "hidden";
  isComp127465Visible: "visible" | "hidden";
  isComp352211Visible: "visible" | "hidden";
  isComp945777Visible: "visible" | "hidden";
  isComp427814Visible: "visible" | "hidden";
  isComp791514Visible: "visible" | "hidden";
  isCompticketlist_665093AuthorizationVisible: "visible" | "hidden";
}

export class TicketList_ScreenBase extends React.PureComponent<ITicketList_ScreenProps, any> {
  ticketlist_557151_value_kuikaSelectBoxRef: React.RefObject<any>;
  ticketlist_660947_value_kuikaTableRef: React.RefObject<any>;
  ticketlist_655210_value_kuikaPaginationRef: React.RefObject<any>;
  ml = [
    {
      Id: "16c151eb-42cf-0b2b-7e4a-e8b04fc43d88",
      Name: "tr_TR",
      ShortName: "Turkish",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "fbdbce9f-3fdb-30c5-c4fe-f7e443068251", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    { Id: "8468043e-f025-01e1-93b8-c71e3215778a", Name: "de_DE", ShortName: "German", LanguagePhrases: [] },
    {
      Id: "ac9db222-1214-4229-9f11-97961e11271e",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 625521, PropertyName: "value", Value: "YENİ BAŞVURULAR" },
        { Id: 557151, PropertyName: "placeholder", Value: "..." },
        { Id: 283911, PropertyName: "placeholder", Value: "Aranacak kelimeyi yazınız..." },
        { Id: 494391, PropertyName: "label", Value: "Benim İş Takip Listem" },
        { Id: 810173, PropertyName: "title", Value: "İş No" },
        { Id: 768729, PropertyName: "value", Value: "[datafield:ticketno]" },
        { Id: 812977, PropertyName: "title", Value: "Başvuru Tarihi" },
        { Id: 679677, PropertyName: "value", Value: "[datafield:basvurutarihi]" },
        { Id: 207170, PropertyName: "title", Value: "Başvuru Sahibi" },
        { Id: 952042, PropertyName: "value", Value: "[datafield:adsoyad]" },
        { Id: 468136, PropertyName: "title", Value: "Deadline" },
        { Id: 408011, PropertyName: "value", Value: "[datafield:termintarihi]" },
        { Id: 774118, PropertyName: "title", Value: "Başvuru Türü" },
        { Id: 883020, PropertyName: "value", Value: "[datafield:basvuruturu]" },
        { Id: 352211, PropertyName: "title", Value: "Şirket Adı" },
        { Id: 726097, PropertyName: "value", Value: "[datafield:sirketad]" },
        { Id: 885016, PropertyName: "title", Value: "Proje Türü" },
        { Id: 352743, PropertyName: "value", Value: "[datafield:projeturu]" },
        { Id: 73491, PropertyName: "title", Value: "Eser" },
        { Id: 442120, PropertyName: "value", Value: "[datafield:eserad]" },
        { Id: 866726, PropertyName: "title", Value: "Eser Sahibi" },
        { Id: 494484, PropertyName: "value", Value: "[datafield:esersahibi]" },
        { Id: 13180, PropertyName: "title", Value: "Proje Adı" },
        { Id: 596434, PropertyName: "value", Value: "[datafield:projead]" },
        { Id: 945777, PropertyName: "title", Value: "Durum" },
        { Id: 598539, PropertyName: "value", Value: "[datafield:formstatu]" }
      ]
    }
  ];
  defaultML = "tr_TR";
  screenHash = "";

  constructor(props) {
    super(props);
    this.ticketlist_557151_value_kuikaSelectBoxRef = React.createRef();
    this.ticketlist_660947_value_kuikaTableRef = React.createRef();
    this.ticketlist_655210_value_kuikaPaginationRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      NAVIGATE: "",
      spTicketList: [],
      getUserInfo: [],
      getFilterParametersByUserName: [],
      sbSureAralikList: [],
      SetValueOf: "",
      spTicketListCount: [],
      FilterParametersSaveTicketList: 0,
      getFormNo: [],
      setIsTakipTrue: 0,
      Notify: false,
      spTicketCopy: [],
      setBasvuruFormuDelete: "",
      isComp494391Visible: "hidden",
      isComp127465Visible: "hidden",
      isComp352211Visible: "hidden",
      isComp945777Visible: "hidden",
      isComp427814Visible: "hidden",
      isComp791514Visible: "hidden",
      isCompticketlist_665093AuthorizationVisible: "visible"
    };

    this.CheckPageAuthorization(this.state);
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("ticketlist", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.TicketListPageInit();
    }
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompticketlist_665093AuthorizationVisible", [
      "89f0bd8c-62b8-cf33-3763-edf7319ca78f",
      "5702a90f-4edb-07c0-5096-8ee6a5c813e6",
      "Admin",
      "Muz Ekip A"
    ]);

    KuikaAppManager.calculateAndSetBodyHeight("ticketlist", "");
  }

  componentWillUnmount() {}

  CheckPageAuthorization = async (state) => {
    KuikaAppManager.showSpinner(this);
    if (_.isEmpty(window.userInfo)) {
      await UserService.GetUserInfoForce();
    }
    const permittedRoleNames: string[] = ["Muhasebe", "Admin", "Muz Ekip A", "Muz Ekip B"];
    const userInfo = window.userInfo as IUserInfoDto;
    const userRoleNames = userInfo.roles.map((role) => role.name);
    if (!permittedRoleNames.some((roleName) => userRoleNames.includes(roleName))) {
      await ReactSystemFunctions.goBack(this);
      KMainFunctions.displayErrorNotification("You do not have permission to access this page.");
      state.isPageVisible = false;
      KuikaAppManager.hideSpinner(this);
      return;
    }
    state.isPageVisible = true;
    KuikaAppManager.hideSpinner(this);
  };

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("ticketlist", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.TicketListPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      ticketlist_557151_value: this.state.getFilterParametersByUserName?.at?.(0)?.ticketListSBox ?? undefined,
      ticketlist_283911_value: this.state.getFilterParametersByUserName?.at?.(0)?.ticketListSearch ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  TicketListPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {};

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "TicketList/TicketListPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.getUserInfo = result?.data.getUserInfo;
    stateVars.isComp494391Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.getUserInfo?.length > 0 ? stateVars.getUserInfo[0]?.rolName : null
        ),
        "Bayi"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.getFilterParametersByUserName = result?.data.getFilterParametersByUserName;
    formVars.ticketlist_557151_value =
      stateVars.getFilterParametersByUserName?.length > 0
        ? stateVars.getFilterParametersByUserName[0]?.ticketListSBox
        : null;
    formVars.ticketlist_557151_options = stateVars.sbSureAralikList;
    formVars.ticketlist_283911_value = ReactSystemFunctions.toString(
      this,
      stateVars.getFilterParametersByUserName?.length > 0
        ? stateVars.getFilterParametersByUserName[0]?.ticketListSearch
        : null
    );
    stateVars.sbSureAralikList = result?.data.sbSureAralikList;

    formVars.ticketlist_557151_value =
      stateVars.getFilterParametersByUserName?.length > 0
        ? stateVars.getFilterParametersByUserName[0]?.ticketListSBox
        : null;
    formVars.ticketlist_557151_options = stateVars.sbSureAralikList;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TicketListPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TicketListPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "ticketlist_283911_value",
      ReactSystemFunctions.toString(
        this,
        this.state.getFilterParametersByUserName?.length > 0
          ? this.state.getFilterParametersByUserName[0]?.ticketListSearch
          : null
      ),
      null
    );
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "ticketlist_557151_value",
      ReactSystemFunctions.toString(
        this,
        this.state.getFilterParametersByUserName?.length > 0
          ? this.state.getFilterParametersByUserName[0]?.ticketListSBox
          : null
      ),
      null,
      "Id"
    );

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TicketListPageInit2_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  TicketListPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      Rol_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.getUserInfo?.length > 0 ? this.state.getUserInfo[0]?.rolName : null
        ),
        "string"
      ),
      Search_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "ticketlist_283911_value", "value", "", "", "")
        ),
        "string"
      ),
      PageNumber_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ticketlist_655210_value", "current", "", "", "")
        ),
        "number"
      ),
      RowsOfPage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ticketlist_655210_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      GuncellemeGunuSon_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ticketlist_557151_value", "value", "sbSureAralikList", "id", "maxGun")
        ),
        "number"
      ),
      Rol_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.getUserInfo?.length > 0 ? this.state.getUserInfo[0]?.rolName : null
        ),
        "string"
      ),
      Search_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "ticketlist_283911_value", "value", "", "", "")
        ),
        "string"
      ),
      GuncellemeGunuSon_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ticketlist_557151_value", "value", "sbSureAralikList", "id", "maxGun")
        ),
        "number"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "TicketList/TicketListPageInit2_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.spTicketList = result?.data.spTicketList;

    stateVars.spTicketListCount = result?.data.spTicketListCount;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TicketListPageInit3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TicketListPageInit3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.ticketlist_557151_value = ReactSystemFunctions.toString(
      this,
      this.state.getFilterParametersByUserName?.length > 0
        ? this.state.getFilterParametersByUserName[0]?.ticketListSBox
        : null
    );

    stateVars.dataSource_557151 = this.state.sbSureAralikList;
    stateVars.dataSource_557151 = this.state.sbSureAralikList;
    formVars.ticketlist_283911_value = ReactSystemFunctions.toString(
      this,
      this.state.getFilterParametersByUserName?.length > 0
        ? this.state.getFilterParametersByUserName[0]?.ticketListSearch
        : null
    );

    stateVars.isComp494391Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.toString(
          this,
          this.state.getUserInfo?.length > 0 ? this.state.getUserInfo[0]?.rolName : null
        ),
        "Bayi"
      ) === true
        ? "visible"
        : "hidden";

    stateVars.dataSource_660947 = this.state.spTicketList;
    stateVars.isComp127465Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, "ticketlist_660947_value", "acil"), true) === true
        ? "visible"
        : "hidden";
    stateVars.isComp427814Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "ticketlist_660947_value", "editVisible"),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp791514Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "ticketlist_660947_value", "isataicovisible"),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.ticketlist_655210_total = ReactSystemFunctions.value(
      this,
      this.state.spTicketListCount?.length > 0 ? this.state.spTicketListCount[0]?.sumCount : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  TicketListComponent_653137_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "TicketList",
      "LeftMenu",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "464411",
      "",
      "left",
      null,
      "",
      "",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TicketListComponent_45091_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      this.TicketListComponent_45091_onClick1_,
      "TicketList",
      "BFormuAnaSayfa",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };
  TicketListComponent_45091_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Rol_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.getUserInfo?.length > 0 ? this.state.getUserInfo[0]?.rolName : null
        ),
        "string"
      ),
      Search_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      PageNumber_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ticketlist_655210_value", "current", "", "", "")
        ),
        "number"
      ),
      RowsOfPage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ticketlist_655210_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      GuncellemeGunuSon_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ticketlist_557151_value", "value", "sbSureAralikList", "id", "maxGun")
        ),
        "number"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "TicketList/TicketListComponent_45091_onClick1_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.spTicketList = result?.data.spTicketList;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TicketListComponent_45091_onClick2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TicketListComponent_45091_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_660947 = this.state.spTicketList;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TicketListComponent_557151_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      TicketListSBox_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "ticketlist_557151_value", "value", "sbSureAralikList", "id", "id")
        ),
        "string"
      ),
      TicketListSearch_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "ticketlist_283911_value", "value", "", "", "")
        ),
        "string"
      ),
      TicketListPagination_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ticketlist_655210_value", "current", "", "", "")
        ),
        "number"
      ),
      Rol_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.getUserInfo?.length > 0 ? this.state.getUserInfo[0]?.rolName : null
        ),
        "string"
      ),
      Search_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "ticketlist_283911_value", "value", "", "", "")
        ),
        "string"
      ),
      PageNumber_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ticketlist_655210_value", "current", "", "", "")
        ),
        "number"
      ),
      RowsOfPage_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ticketlist_655210_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      GuncellemeGunuSon_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ticketlist_557151_value", "value", "sbSureAralikList", "id", "maxGun")
        ),
        "number"
      ),
      Rol_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.getUserInfo?.length > 0 ? this.state.getUserInfo[0]?.rolName : null
        ),
        "string"
      ),
      Search_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "ticketlist_283911_value", "value", "", "", "")
        ),
        "string"
      ),
      GuncellemeGunuSon_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ticketlist_557151_value", "value", "sbSureAralikList", "id", "maxGun")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "TicketList/TicketListComponent_557151_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.FilterParametersSaveTicketList = result?.data.filterParametersSaveTicketList;
    stateVars.spTicketList = result?.data.spTicketList;

    stateVars.spTicketListCount = result?.data.spTicketListCount;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TicketListComponent_557151_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TicketListComponent_557151_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_660947 = this.state.spTicketList;
    formVars.ticketlist_655210_total = ReactSystemFunctions.value(
      this,
      this.state.spTicketListCount?.length > 0 ? this.state.spTicketListCount[0]?.sumCount : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TicketListComponent_283911_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      TicketListSBox_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "ticketlist_557151_value", "value", "sbSureAralikList", "id", "id")
        ),
        "string"
      ),
      TicketListSearch_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "ticketlist_283911_value", "value", "", "", "")
        ),
        "string"
      ),
      TicketListPagination_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ticketlist_655210_value", "current", "", "", "")
        ),
        "number"
      ),
      Rol_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.getUserInfo?.length > 0 ? this.state.getUserInfo[0]?.rolName : null
        ),
        "string"
      ),
      Search_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "ticketlist_283911_value", "value", "", "", "")
        ),
        "string"
      ),
      PageNumber_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ticketlist_655210_value", "current", "", "", "")
        ),
        "number"
      ),
      RowsOfPage_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ticketlist_655210_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      GuncellemeGunuSon_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ticketlist_557151_value", "value", "sbSureAralikList", "id", "maxGun")
        ),
        "number"
      ),
      Rol_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.getUserInfo?.length > 0 ? this.state.getUserInfo[0]?.rolName : null
        ),
        "string"
      ),
      Search_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "ticketlist_283911_value", "value", "", "", "")
        ),
        "string"
      ),
      GuncellemeGunuSon_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ticketlist_557151_value", "value", "sbSureAralikList", "id", "maxGun")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "TicketList/TicketListComponent_283911_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.FilterParametersSaveTicketList = result?.data.filterParametersSaveTicketList;
    stateVars.spTicketList = result?.data.spTicketList;

    stateVars.spTicketListCount = result?.data.spTicketListCount;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TicketListComponent_283911_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TicketListComponent_283911_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_660947 = this.state.spTicketList;
    formVars.ticketlist_655210_total = ReactSystemFunctions.value(
      this,
      this.state.spTicketListCount?.length > 0 ? this.state.spTicketListCount[0]?.sumCount : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TicketListComponent_494391_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "TicketList",
      "IsTakipList",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TicketListComponent_427814_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      BTuru_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "ticketlist_660947_value", "btKod"),
        "number"
      ),
      PTuru_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "ticketlist_660947_value", "pftKod"),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "TicketList/TicketListComponent_427814_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.getFormNo = result?.data.getFormNo;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TicketListComponent_427814_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TicketListComponent_427814_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, this.state.getFormNo?.length > 0 ? this.state.getFormNo[0]?.formNo : null),
        "0"
      )
    ) {
      KuikaAppManager.prepareForNavigation();
      KuikaAppManager.addToPageInputVariables(
        "BFormuBankaV2",
        "prmGuid",
        ReactSystemFunctions.value(this, "ticketlist_660947_value", "id")
      );
      KuikaAppManager.addToPageInputVariables(
        "BFormuBankaV2",
        "prmBavuruTuruID",
        ReactSystemFunctions.value(this, "ticketlist_660947_value", "basvuruTuruID")
      );
      KuikaAppManager.addToPageInputVariables("BFormuBankaV2", "NewDisplay", "D");
      KuikaAppManager.addToPageInputVariables("BFormuBankaV2", "prmNerdenGeldi", "istakip");

      stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
        this,
        this.TicketListComponent_427814_onClick2_,
        "TicketList",
        "BFormuBankaV2",
        "",
        "currentPage",
        null,
        null,
        null,
        null,
        null,
        null,
        true
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TicketListComponent_427814_onClick2_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  TicketListComponent_427814_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(this, this.state.getFormNo?.length > 0 ? this.state.getFormNo[0]?.formNo : null),
        "0"
      )
    ) {
      KuikaAppManager.prepareForNavigation();
      KuikaAppManager.addToPageInputVariables(
        "BFormuTicari",
        "prmGuid",
        ReactSystemFunctions.value(this, "ticketlist_660947_value", "id")
      );
      KuikaAppManager.addToPageInputVariables(
        "BFormuTicari",
        "prmBavuruTuruID",
        ReactSystemFunctions.value(this, "ticketlist_660947_value", "basvuruTuruID")
      );
      KuikaAppManager.addToPageInputVariables("BFormuTicari", "NewDisplay", "D");
      KuikaAppManager.addToPageInputVariables(
        "BFormuTicari",
        "prmProjeTuruID",
        ReactSystemFunctions.value(this, "ticketlist_660947_value", "projeTuruID")
      );
      KuikaAppManager.addToPageInputVariables("BFormuTicari", "prmNerdenGeldi", "istakip");

      stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
        this,
        undefined,
        "TicketList",
        "BFormuTicari",
        "",
        "currentPage",
        null,
        null,
        null,
        null,
        null,
        null,
        true
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  TicketListComponent_751353_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      BTuru_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "ticketlist_660947_value", "btKod"),
        "number"
      ),
      PTuru_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "ticketlist_660947_value", "pftKod"),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "TicketList/TicketListComponent_751353_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.getFormNo = result?.data.getFormNo;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TicketListComponent_751353_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TicketListComponent_751353_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, this.state.getFormNo?.length > 0 ? this.state.getFormNo[0]?.formNo : null),
        "0"
      )
    ) {
      KuikaAppManager.prepareForNavigation();
      KuikaAppManager.addToPageInputVariables(
        "BFormuBankaV2",
        "prmGuid",
        ReactSystemFunctions.value(this, "ticketlist_660947_value", "id")
      );
      KuikaAppManager.addToPageInputVariables(
        "BFormuBankaV2",
        "prmBavuruTuruID",
        ReactSystemFunctions.value(this, "ticketlist_660947_value", "basvuruTuruID")
      );
      KuikaAppManager.addToPageInputVariables("BFormuBankaV2", "NewDisplay", "D");
      KuikaAppManager.addToPageInputVariables("BFormuBankaV2", "prmNerdenGeldi", "info");

      stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
        this,
        this.TicketListComponent_751353_onClick2_,
        "TicketList",
        "BFormuBankaV2",
        "",
        "7d969ddf-5801-4eb6-b92b-12be35bdd565",
        "936817",
        null,
        "right",
        null,
        "680px",
        "100vh",
        true
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TicketListComponent_751353_onClick2_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  TicketListComponent_751353_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(this, this.state.getFormNo?.length > 0 ? this.state.getFormNo[0]?.formNo : null),
        "0"
      )
    ) {
      KuikaAppManager.prepareForNavigation();
      KuikaAppManager.addToPageInputVariables(
        "BFormuTicari",
        "prmGuid",
        ReactSystemFunctions.value(this, "ticketlist_660947_value", "id")
      );
      KuikaAppManager.addToPageInputVariables(
        "BFormuTicari",
        "prmBavuruTuruID",
        ReactSystemFunctions.value(this, "ticketlist_660947_value", "basvuruTuruID")
      );
      KuikaAppManager.addToPageInputVariables("BFormuTicari", "NewDisplay", "D");
      KuikaAppManager.addToPageInputVariables(
        "BFormuTicari",
        "prmProjeTuruID",
        ReactSystemFunctions.value(this, "ticketlist_660947_value", "projeTuruID")
      );
      KuikaAppManager.addToPageInputVariables("BFormuTicari", "prmNerdenGeldi", "info");

      stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
        this,
        undefined,
        "TicketList",
        "BFormuTicari",
        "",
        "7d969ddf-5801-4eb6-b92b-12be35bdd565",
        "422920",
        null,
        "right",
        null,
        "680px",
        "100vh",
        true
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  TicketListComponent_791514_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      AksiyonIlgiliKisi_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "ticketlist_660947_value", "id"),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "TicketList/TicketListComponent_791514_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.setIsTakipTrue = result?.data.setIsTakipTrue;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TicketListComponent_791514_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TicketListComponent_791514_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "d4a1b57f_598d_f8d1_45c5_234cb4d67011_notify",
        this.defaultML,
        "İlgili Ticket İş Listene Eklendi..!"
      ),
      "success",
      "bottom-right",
      5,
      null,
      null,
      null
    );

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TicketListComponent_791514_onClick2_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  TicketListComponent_791514_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Rol_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.getUserInfo?.length > 0 ? this.state.getUserInfo[0]?.rolName : null
        ),
        "string"
      ),
      Search_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      PageNumber_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ticketlist_655210_value", "current", "", "", "")
        ),
        "number"
      ),
      RowsOfPage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ticketlist_655210_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      GuncellemeGunuSon_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ticketlist_557151_value", "value", "sbSureAralikList", "id", "maxGun")
        ),
        "number"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "TicketList/TicketListComponent_791514_onClick2_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.spTicketList = result?.data.spTicketList;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TicketListComponent_791514_onClick3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TicketListComponent_791514_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_660947 = this.state.spTicketList;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TicketListComponent_420272_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "ticketlist_660947_value", "id"),
        "Guid"
      ),
      Rol_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.getUserInfo?.length > 0 ? this.state.getUserInfo[0]?.rolName : null
        ),
        "string"
      ),
      Search_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      PageNumber_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ticketlist_655210_value", "current", "", "", "")
        ),
        "number"
      ),
      RowsOfPage_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ticketlist_655210_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      GuncellemeGunuSon_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ticketlist_557151_value", "value", "sbSureAralikList", "id", "maxGun")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    stateVars.Confirm = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "e3e3cf9c_9098_ad18_ab83_a1223a045f58_confirmation",
        this.defaultML,
        "Seçilen Ticket Kaydı Çoğaltılacak. Devam Edilsin mi?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel",
      customClass: {
        title: "swal2-font-poppins"
      }
    });

    if (stateVars.Confirm.isConfirmed) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(this, "TicketList/TicketListComponent_420272_onClick", localVar);
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.spTicketCopy = result?.data.spTicketCopy;
      stateVars.spTicketList = result?.data.spTicketList;

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TicketListComponent_420272_onClick1_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TicketListComponent_420272_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "b9b61bf9_d9af_28b9_4978_ec70ea1afece_notify",
        this.defaultML,
        "Ticket Kopyalandı.Yeni Ticket üzerinden devam edebilirsiniz!"
      ),
      "success",
      "bottom-right",
      5,
      null,
      null,
      null
    );

    stateVars.dataSource_660947 = this.state.spTicketList;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TicketListComponent_665093_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "ticketlist_660947_value", "id"),
        "Guid"
      ),
      Rol_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.getUserInfo?.length > 0 ? this.state.getUserInfo[0]?.rolName : null
        ),
        "string"
      ),
      Search_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      PageNumber_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ticketlist_655210_value", "current", "", "", "")
        ),
        "number"
      ),
      RowsOfPage_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ticketlist_655210_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      GuncellemeGunuSon_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ticketlist_557151_value", "value", "sbSureAralikList", "id", "maxGun")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    stateVars.Confirm = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "494c7d8a_223b_73bf_fa63_5c768d73f470_confirmation",
        this.defaultML,
        "Seçilen Kayıt Silinecek; Devam Edilsin mi?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel",
      customClass: {
        title: "swal2-font-poppins"
      }
    });

    if (stateVars.Confirm.isConfirmed) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(this, "TicketList/TicketListComponent_665093_onClick", localVar);
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.setBasvuruFormuDelete = result?.data.setBasvuruFormuDelete;
      stateVars.spTicketList = result?.data.spTicketList;

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TicketListComponent_665093_onClick1_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TicketListComponent_665093_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_660947 = this.state.spTicketList;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TicketListComponent_655210_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      TicketListSBox_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "ticketlist_557151_value", "value", "sbSureAralikList", "id", "id")
        ),
        "string"
      ),
      TicketListSearch_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "ticketlist_283911_value", "value", "", "", "")
        ),
        "string"
      ),
      TicketListPagination_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ticketlist_655210_value", "current", "", "", "")
        ),
        "number"
      ),
      Rol_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.getUserInfo?.length > 0 ? this.state.getUserInfo[0]?.rolName : null
        ),
        "string"
      ),
      Search_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "ticketlist_283911_value", "value", "", "", "")
        ),
        "string"
      ),
      PageNumber_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ticketlist_655210_value", "current", "", "", "")
        ),
        "number"
      ),
      RowsOfPage_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ticketlist_655210_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      GuncellemeGunuSon_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ticketlist_557151_value", "value", "sbSureAralikList", "id", "maxGun")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "TicketList/TicketListComponent_655210_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.FilterParametersSaveTicketList = result?.data.filterParametersSaveTicketList;
    stateVars.spTicketList = result?.data.spTicketList;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TicketListComponent_655210_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TicketListComponent_655210_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_660947 = this.state.spTicketList;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [464411, 936817, 422920] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.TicketListPageInit();
    }
    if (diId == 936817) {
      isErrorOccurred = await this.TicketListComponent_751353_onClick2_();
      if (isErrorOccurred) return true;
    }
  }
}
