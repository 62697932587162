import { Form, Spin, Typography } from "antd";
import { connect } from "react-redux";
import {
  Header,
  HorizontalStack,
  Icon,
  Image,
  KCol,
  KDrawer,
  KPagination,
  KRow,
  KTable,
  KTableColumn,
  KTableHeader,
  KTableRow,
  Label,
  TextInput
} from "../../components/web-components";
import { KSelectBox, Spacer } from "../../kuika";
import {
  hideIyzicoDrawer,
  hidePhotoTake,
  hideStripeDrawer,
  showIyzicoDrawer,
  showPhotoTake,
  showStripeDrawer
} from "../../redux/photo-take/actions";
import { AppState } from "../../redux/root-reducer";
import { withContext } from "../../shared/hoc/with-context";
import { withForm } from "../../shared/hoc/with-form";
import { withGoogleLogin } from "../../shared/hoc/with-google-login";
import { withHistory } from "../../shared/hoc/with-history";
import { withScreenInput } from "../../shared/hoc/with-screen-input";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";
import { UrunList_ScreenBase } from "./urunlist-base";

import { LeftMenu_Screen } from "../leftmenu/leftmenu";

declare let window: any;
const { Title, Paragraph, Text, Link } = Typography;
const menuMap = [];

class UrunList_Screen extends UrunList_ScreenBase {
  render() {
    if (this.state.isPageVisible === false) {
      return <></>;
    }
    return (
      <>
        <Spin spinning={this.state.spinnerCount > 0}>
          <Form
            form={this.props.form}
            name="basic"
            initialValues={{
              urunlist_703976_value: "283ac3c9-74b0-40c1-7273-08dbdfa0a8af",
              urunlist_741996_value: "c0aa9184-625a-4539-c510-08da52e50202",
              urunlist_341027_value: this.state.getFilterParametersByUserName?.at?.(0)?.urunListSBox ?? undefined,
              urunlist_606520_value: this.state.getFilterParametersByUserName?.at?.(0)?.urunListSearch ?? undefined
            }}
          >
            <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
              <Header
                id="urunlist_header"
                style={
                  {
                    borderStyle: "solid",
                    borderTopWidth: 0,
                    borderRightWidth: 0,
                    borderBottomWidth: 0,
                    borderLeftWidth: 0,
                    backgroundColor: "rgba(255, 255, 255, 1)",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                    backgroundPosition: "left"
                  } as any
                }
              >
                <KRow
                  id="726727"
                  align="top"
                  justify="start"
                  horizontalGutter={0}
                  verticalGutter={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "contain",
                      backgroundPosition: "left",
                      paddingTop: 16,
                      paddingBottom: 16,
                      alignItems: "center",
                      fontWeight: 500,
                      fontSize: "14px",
                      letterSpacing: "0.5px"
                    } as any
                  }
                >
                  <KCol
                    id="952559"
                    xs={8}
                    sm={8}
                    md={8}
                    lg={8}
                    xl={8}
                    xxl={8}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingRight: 16,
                        paddingLeft: 16,
                        fontWeight: 500,
                        fontSize: "14px",
                        letterSpacing: "0.5px"
                      } as any
                    }
                  >
                    <HorizontalStack
                      id="828744"
                      direction="horizontal"
                      size={2}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 0,
                          paddingRight: 0,
                          paddingBottom: 0,
                          paddingLeft: 0,
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "14px",
                          color: "rgba(68, 68, 68, 1)",
                          letterSpacing: "0.5px"
                        } as any
                      }
                    >
                      <Icon
                        id="798391"
                        onClick={(e?: any) => {
                          if (e && e.stopPropagation) e.stopPropagation();
                          this.UrunListComponent_798391_onClick();
                        }}
                        showCursorPointer
                        iconName="menu"
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "left",
                            fontSize: "24px",
                            color: "rgba(172, 38, 42, 1)"
                          } as any
                        }
                      ></Icon>

                      <Form.Item className="kFormItem" name="urunlist_703976_value">
                        <Image
                          id="703976"
                          placeHolderImage="data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAASABIAAD/7QA4UGhvdG9zaG9wIDMuMAA4QklNBAQAAAAAAAA4QklNBCUAAAAAABDUHYzZjwCyBOmACZjs+EJ+/9sAQwABAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEB/8AACwgANgBgAQERAP/EABkAAQADAQEAAAAAAAAAAAAAAAAEBQYHCv/EACoQAAEEAgMBAAADCQAAAAAAAAIAAwQFAQYREhMUByEiJDEzNjd1drS1/9oACAEBAAA/APWAiIiIiIiIiIiIiIiIiIqTZrU6HXNgvGmQkO01Ja2rbDhZAHzroD8wGTMeSAHCawBEOM5HBFnHOccKmbpdyyAZe3Zv2yI+vhrVeDHpxjv4g9JfdBrtz5i6+84IcYN1wsZMsq9c25FeRqnf4064oIdhNODN1IW4EvNSWG5zDcttyCE9qLKy3XWjlRPecqpb4MTMsysgyfUq6X99fAnefl9sOLL8u3fz+lgHvPv1Ht079e3Ue3HPGOeFMRERERY78RP6f71/h2z/APEnKlnWG4VVfI2+xkw40SL3lS9Od+BtqPTYHH6BvCHDh7QP8XqUrNG88WKdoBzlu7Wcfk1dY1d3kzU5Ott7TWXLNbbS7CU/jD9jg5BRbKnex8unzdkl4ZneMbsNrPFlu5dZvMx4jvVdc/l6h/s1X/osK5RERERV1xVx7uotKWWTgxbeunVcomSEXhjz4zsR4miITEXMNullssgY4LjORLGM4WAk6hu8+XVS7LctcsBpwA40OTo9h8Dtk3x53UuNG3mOMiyaxgvm7fsMNwykQ4LEkQfCxs9e3O6rp1RZbLqxV9nFfgzcRdHsG5XzSWyadzGcm7xZQ25AgWSYckwJjLbuANyM8I5AtvEjNQosaGx28IkdmMz2z2LyYbFpvsXGOxdAxznGMc5/PjH7lIREREREREREREREREREX//Z"
                          zoomOnClick={false}
                          imageFit="fit"
                          style={
                            {
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingLeft: 16,
                              height: "24px"
                            } as any
                          }
                        ></Image>
                      </Form.Item>
                    </HorizontalStack>

                    <Label
                      id="524817"
                      value={ReactSystemFunctions.getMyUsername()}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "contain",
                          backgroundPosition: "left",
                          paddingTop: 8,
                          paddingLeft: 42,
                          display: "block",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "12px",
                          color: "rgba(155, 155, 155, 1)",
                          letterSpacing: "0.5px"
                        } as any
                      }
                    ></Label>
                  </KCol>

                  <KCol
                    id="798104"
                    xs={8}
                    sm={8}
                    md={8}
                    lg={8}
                    xl={8}
                    xxl={8}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "contain",
                        backgroundPosition: "left",
                        paddingRight: 16,
                        paddingLeft: 16,
                        alignItems: "center",
                        textAlign: "-webkit-center",
                        fontWeight: 500,
                        fontSize: "14px",
                        letterSpacing: "0.5px"
                      } as any
                    }
                  >
                    <Label
                      id="513636"
                      value={ReactSystemFunctions.translate(this.ml, 513636, "value", this.defaultML)}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "contain",
                          backgroundPosition: "left",
                          fontFamily: "Poppins",
                          fontWeight: 600,
                          fontSize: "18px",
                          color: "rgba(183, 14, 14, 1)"
                        } as any
                      }
                    ></Label>
                  </KCol>

                  <KCol
                    id="876439"
                    xs={8}
                    sm={8}
                    md={8}
                    lg={8}
                    xl={8}
                    xxl={8}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "contain",
                        backgroundPosition: "left",
                        paddingRight: 16,
                        paddingLeft: 16,
                        textAlign: "-webkit-right",
                        fontWeight: 500,
                        fontSize: "14px",
                        letterSpacing: "0.5px"
                      } as any
                    }
                  >
                    <Spacer id="126743" direction="horizontal" size={12} style={{} as any}>
                      <Icon
                        id="594883"
                        onClick={(e?: any) => {
                          if (e && e.stopPropagation) e.stopPropagation();
                          this.UrunListComponent_594883_onClick();
                        }}
                        showCursorPointer
                        visibility={this.state.isCompurunlist_594883AuthorizationVisible}
                        visibilityByAuthorization={this.state.isCompurunlist_594883AuthorizationVisible}
                        iconName="add_circle"
                        hoverText="Yeni Ekle"
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            fontSize: "24px",
                            color: "rgba(172, 38, 42, 1)"
                          } as any
                        }
                      ></Icon>

                      <Form.Item className="kFormItem" name="urunlist_741996_value">
                        <Image
                          id="741996"
                          onClick={(e?: any) => {
                            if (e && e.stopPropagation) e.stopPropagation();
                            this.UrunListComponent_741996_onClick();
                          }}
                          showCursorPointer
                          visibility={this.state.isComp741996Visible}
                          placeHolderImage="data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAASABIAAD/7QA4UGhvdG9zaG9wIDMuMAA4QklNBAQAAAAAAAA4QklNBCUAAAAAABDUHYzZjwCyBOmACZjs+EJ+/9sAQwABAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEB/8AACwgANgBgAQERAP/EABkAAQADAQEAAAAAAAAAAAAAAAAEBQYHCv/EACoQAAEEAgMBAAADCQAAAAAAAAIAAwQFAQYREhMUByEiJDEzNjd1drS1/9oACAEBAAA/APWAiIiIiIiIiIiIiIiIiIqTZrU6HXNgvGmQkO01Ja2rbDhZAHzroD8wGTMeSAHCawBEOM5HBFnHOccKmbpdyyAZe3Zv2yI+vhrVeDHpxjv4g9JfdBrtz5i6+84IcYN1wsZMsq9c25FeRqnf4064oIdhNODN1IW4EvNSWG5zDcttyCE9qLKy3XWjlRPecqpb4MTMsysgyfUq6X99fAnefl9sOLL8u3fz+lgHvPv1Ht079e3Ue3HPGOeFMRERERY78RP6f71/h2z/APEnKlnWG4VVfI2+xkw40SL3lS9Od+BtqPTYHH6BvCHDh7QP8XqUrNG88WKdoBzlu7Wcfk1dY1d3kzU5Ott7TWXLNbbS7CU/jD9jg5BRbKnex8unzdkl4ZneMbsNrPFlu5dZvMx4jvVdc/l6h/s1X/osK5RERERV1xVx7uotKWWTgxbeunVcomSEXhjz4zsR4miITEXMNullssgY4LjORLGM4WAk6hu8+XVS7LctcsBpwA40OTo9h8Dtk3x53UuNG3mOMiyaxgvm7fsMNwykQ4LEkQfCxs9e3O6rp1RZbLqxV9nFfgzcRdHsG5XzSWyadzGcm7xZQ25AgWSYckwJjLbuANyM8I5AtvEjNQosaGx28IkdmMz2z2LyYbFpvsXGOxdAxznGMc5/PjH7lIREREREREREREREREREX//Z"
                          zoomOnClick={false}
                          imageFit="fit"
                          style={
                            {
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              height: "35px"
                            } as any
                          }
                        ></Image>
                      </Form.Item>
                    </Spacer>
                  </KCol>
                </KRow>
              </Header>

              <div
                id="urunlist_body"
                style={
                  {
                    borderStyle: "solid",
                    borderTopWidth: 0,
                    borderRightWidth: 0,
                    borderBottomWidth: 0,
                    borderLeftWidth: 0,
                    backgroundColor: "rgba(245, 245, 245, 1)",
                    backgroundImage:
                      "url(" +
                      KuikaAppManager.GetBackendUrl() +
                      "/resource/runtime/byid?resourceId=31886a70-856c-4afc-8e82-08da211d182d)",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    paddingTop: 32,
                    paddingRight: 48,
                    paddingBottom: 0,
                    paddingLeft: 48,
                    display: "block"
                  } as any
                }
              >
                <KRow
                  id="288651"
                  align="top"
                  justify="start"
                  horizontalGutter={0}
                  verticalGutter={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "contain",
                      backgroundPosition: "left",
                      paddingTop: 16,
                      paddingRight: null,
                      paddingBottom: 16,
                      fontWeight: 500,
                      fontSize: "14px",
                      letterSpacing: "0.5px"
                    } as any
                  }
                >
                  <KCol
                    id="387389"
                    xs={8}
                    sm={8}
                    md={8}
                    lg={8}
                    xl={8}
                    xxl={8}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "contain",
                        backgroundPosition: "left",
                        paddingRight: 16,
                        paddingLeft: 0,
                        fontWeight: 500,
                        fontSize: "14px",
                        letterSpacing: "0.5px"
                      } as any
                    }
                  >
                    <Form.Item className="kFormItem" name="urunlist_341027_value">
                      <KSelectBox
                        id="341027"
                        onChange={(e?: any) => {
                          if (e && e.stopPropagation) e.stopPropagation();
                          this.UrunListComponent_341027_onChange();
                        }}
                        kuikaRef={this.urunlist_341027_value_kuikaSelectBoxRef}
                        options={this.state.sbEserTipiList}
                        placeholder={ReactSystemFunctions.translate(this.ml, 341027, "placeholder", this.defaultML)}
                        allowClear={true}
                        autoClearSearchValue={true}
                        showSearch={false}
                        widthType="fill"
                        containsNullItem={false}
                        sortBy="none"
                        datavaluefield="id"
                        datatextfield="tanim"
                        style={
                          {
                            borderBottomWidth: 1,
                            borderColor: "rgba(155, 155, 155, 1)",
                            borderStyle: "dotted",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderLeftWidth: 0,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "left",
                            height: "36px",
                            display: "block",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "12px",
                            color: "rgba(47, 47, 47, 1)"
                          } as any
                        }
                      ></KSelectBox>
                    </Form.Item>
                  </KCol>

                  <KCol
                    id="470777"
                    xs={16}
                    sm={16}
                    md={16}
                    lg={16}
                    xl={16}
                    xxl={16}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "14px",
                        color: "rgba(68, 68, 68, 1)",
                        letterSpacing: "0.5px"
                      } as any
                    }
                  >
                    <Form.Item className="kFormItem" name="urunlist_606520_value">
                      <TextInput
                        id="606520"
                        onChange={(e?: any) => {
                          if (e && e.stopPropagation) e.stopPropagation();
                          this.UrunListComponent_606520_onChange();
                        }}
                        placeholder={ReactSystemFunctions.translate(this.ml, 606520, "placeholder", this.defaultML)}
                        allowClear={true}
                        bordered={true}
                        disabled={false}
                        type="text"
                        iconColor="rgba(0, 0, 0, 1)"
                        formatter="None"
                        isCharOnly={false}
                        autoComplete={true}
                        style={
                          {
                            borderBottomWidth: 1,
                            borderColor: "rgba(155, 155, 155, 1)",
                            borderStyle: "dotted",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderLeftWidth: 0,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "left",
                            paddingRight: null,
                            paddingBottom: null,
                            paddingLeft: null,
                            height: "36px",
                            display: "block",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "12px",
                            color: "rgba(47, 47, 47, 1)",
                            letterSpacing: "0.5px"
                          } as any
                        }
                      ></TextInput>
                    </Form.Item>
                  </KCol>
                </KRow>

                <KRow
                  id="946976"
                  align="top"
                  justify="start"
                  horizontalGutter={0}
                  verticalGutter={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "contain",
                      backgroundPosition: "left",
                      paddingTop: 16,
                      paddingBottom: 16,
                      fontWeight: 500,
                      fontSize: "14px",
                      letterSpacing: "0.5px"
                    } as any
                  }
                >
                  <KCol
                    id="608138"
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={24}
                    xxl={24}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderTopLeftRadius: 12,
                        borderTopRightRadius: 12,
                        borderBottomRightRadius: 12,
                        borderBottomLeftRadius: 12,
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        backgroundColor: "rgba(255, 255, 255, 1)",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "contain",
                        backgroundPosition: "left",
                        paddingTop: 16,
                        paddingRight: 16,
                        paddingBottom: 16,
                        paddingLeft: 16,
                        fontWeight: 500,
                        fontSize: "14px",
                        letterSpacing: "0.5px"
                      } as any
                    }
                  >
                    <KTable
                      id="480485"
                      kuikaRef={this.urunlist_480485_value_kuikaTableRef}
                      form={this.props.form}
                      dataSource={this.state.spGetListStok}
                      size="middle"
                      bordered={true}
                      showHeader={true}
                      loading={false}
                      nodatafoundmessage={ReactSystemFunctions.translate(
                        this.ml,
                        480485,
                        "nodatafoundmessage",
                        this.defaultML
                      )}
                      sorter={false}
                      pagination={false}
                      striped={false}
                      stripedColor="#F5F7FA"
                      insertRowActive={false}
                      transformedOnMobileResolution={false}
                      fixedHeader={false}
                      editable={false}
                      tableWidthMode="fit"
                      globalSearch={false}
                      searchable={false}
                      filtering={false}
                      pageSize={10}
                      showSizeChanger={false}
                      columnChooser={false}
                      resizableColumns={false}
                      insertRowPosition="top"
                      showNoDataFound={true}
                      multiSelect={false}
                      export={false}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          fontWeight: 500,
                          fontSize: "14px",
                          color: "rgba(155, 155, 155, 1)",
                          letterSpacing: "0.5px"
                        } as any
                      }
                    >
                      <KTableHeader
                        id="511530"
                        hideOnMobileResolution={false}
                        textDirection="Default"
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "left",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "12px",
                            color: "rgba(155, 155, 155, 1)",
                            letterSpacing: "0.5px",
                            textTransform: "uppercase"
                          } as any
                        }
                      ></KTableHeader>

                      <KTableRow
                        id="814579"
                        hoverFontColor="red"
                        hoverBgColor="rgba(172, 38, 42, 0.08)"
                        style={
                          {
                            borderTopLeftRadius: 0,
                            borderTopRightRadius: 0,
                            borderBottomRightRadius: 0,
                            borderBottomLeftRadius: 0,
                            borderTopWidth: 1,
                            borderColor: "rgba(204, 204, 204, 1)",
                            borderStyle: "solid",
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "left",
                            fontWeight: 500,
                            fontSize: "14px",
                            letterSpacing: "0.5px"
                          } as any
                        }
                      >
                        <KTableColumn
                          id="425331"
                          title={ReactSystemFunctions.translate(this.ml, 425331, "title", this.defaultML)}
                          alwaysVisibleOnMobileResolution={false}
                          hideFiltering={false}
                          hideSorting={false}
                          hideSearch={false}
                          footerOptions="none"
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <Label
                            id="312985"
                            value="[datafield:eserTipi]"
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                paddingTop: 8,
                                paddingBottom: 8,
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "12px",
                                color: "rgba(47, 47, 47, 1)"
                              } as any
                            }
                          ></Label>
                        </KTableColumn>

                        <KTableColumn
                          id="458076"
                          title={ReactSystemFunctions.translate(this.ml, 458076, "title", this.defaultML)}
                          alwaysVisibleOnMobileResolution={false}
                          hideFiltering={false}
                          hideSorting={false}
                          hideSearch={false}
                          footerOptions="none"
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <Label
                            id="964832"
                            value="[datafield:stokAd]"
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                paddingTop: 8,
                                paddingBottom: 8,
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "12px",
                                color: "rgba(47, 47, 47, 1)"
                              } as any
                            }
                          ></Label>
                        </KTableColumn>

                        <KTableColumn
                          id="67631"
                          visibility={this.state.isComp67631Visible}
                          condition={(rowData) =>
                            ReactSystemFunctions.isNotEqualTo(this.state.sbEserTipiList[0]?.kod?.toString(), "B")
                          }
                          title={ReactSystemFunctions.translate(this.ml, 67631, "title", this.defaultML)}
                          alwaysVisibleOnMobileResolution={false}
                          hideFiltering={false}
                          hideSorting={false}
                          hideSearch={false}
                          footerOptions="none"
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <Label
                            id="589780"
                            value="[datafield:eserSahibis]"
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                paddingTop: 8,
                                paddingBottom: 8,
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "12px",
                                color: "rgba(47, 47, 47, 1)"
                              } as any
                            }
                          ></Label>
                        </KTableColumn>

                        <KTableColumn
                          id="34996"
                          visibility={this.state.isComp34996Visible}
                          condition={(rowData) =>
                            ReactSystemFunctions.isEqualTo(this.state.sbEserTipiList[0]?.kod?.toString(), "R")
                          }
                          title={ReactSystemFunctions.translate(this.ml, 34996, "title", this.defaultML)}
                          alwaysVisibleOnMobileResolution={false}
                          hideFiltering={false}
                          hideSorting={false}
                          hideSearch={false}
                          footerOptions="none"
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <Label
                            id="581437"
                            value="[datafield:sanatciAdi]"
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                paddingTop: 8,
                                paddingBottom: 8,
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "12px",
                                color: "rgba(47, 47, 47, 1)"
                              } as any
                            }
                          ></Label>
                        </KTableColumn>

                        <KTableColumn
                          id="271413"
                          visibility={this.state.isComp271413Visible}
                          condition={(rowData) =>
                            ReactSystemFunctions.isEqualTo(this.state.sbEserTipiList[0]?.kod?.toString(), "B")
                          }
                          title={ReactSystemFunctions.translate(this.ml, 271413, "title", this.defaultML)}
                          alwaysVisibleOnMobileResolution={false}
                          hideFiltering={false}
                          hideSorting={false}
                          hideSearch={false}
                          footerOptions="none"
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <Label
                            id="754316"
                            value="[datafield:anaKatalogAd]"
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                paddingTop: 8,
                                paddingBottom: 8,
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "12px",
                                color: "rgba(47, 47, 47, 1)"
                              } as any
                            }
                          ></Label>
                        </KTableColumn>

                        <KTableColumn
                          id="191378"
                          visibility={this.state.isComp191378Visible}
                          condition={(rowData) =>
                            ReactSystemFunctions.isEqualTo(this.state.sbEserTipiList[0]?.kod?.toString(), "B")
                          }
                          title={ReactSystemFunctions.translate(this.ml, 191378, "title", this.defaultML)}
                          alwaysVisibleOnMobileResolution={false}
                          hideFiltering={false}
                          hideSorting={false}
                          hideSearch={false}
                          footerOptions="none"
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <Label
                            id="605949"
                            value="[datafield:altKatalogAd]"
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                paddingTop: 8,
                                paddingBottom: 8,
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "12px",
                                color: "rgba(47, 47, 47, 1)"
                              } as any
                            }
                          ></Label>
                        </KTableColumn>

                        <KTableColumn
                          id="196686"
                          visibility={this.state.isComp196686Visible}
                          condition={(rowData) =>
                            ReactSystemFunctions.isNotEqualTo(this.state.sbEserTipiList[0]?.kod?.toString(), "T")
                          }
                          title={ReactSystemFunctions.translate(this.ml, 196686, "title", this.defaultML)}
                          alwaysVisibleOnMobileResolution={false}
                          hideFiltering={false}
                          hideSorting={false}
                          hideSearch={false}
                          footerOptions="none"
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <Label
                            id="712096"
                            value="[datafield:albumKodu]"
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                paddingTop: 8,
                                paddingBottom: 8,
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "12px",
                                color: "rgba(47, 47, 47, 1)"
                              } as any
                            }
                          ></Label>
                        </KTableColumn>

                        <KTableColumn
                          id="523126"
                          visibility={this.state.isComp523126Visible}
                          condition={(rowData) =>
                            ReactSystemFunctions.isNotEqualTo(this.state.sbEserTipiList[0]?.kod?.toString(), "T")
                          }
                          title={ReactSystemFunctions.translate(this.ml, 523126, "title", this.defaultML)}
                          alwaysVisibleOnMobileResolution={false}
                          hideFiltering={false}
                          hideSorting={false}
                          hideSearch={false}
                          footerOptions="none"
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <Label
                            id="404367"
                            value="[datafield:albumAd]"
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                paddingTop: 8,
                                paddingBottom: 8,
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "12px",
                                color: "rgba(47, 47, 47, 1)"
                              } as any
                            }
                          ></Label>
                        </KTableColumn>

                        <KTableColumn
                          id="477372"
                          visibility={this.state.isComp477372Visible}
                          condition={(rowData) =>
                            ReactSystemFunctions.isNotEqualTo(this.state.sbEserTipiList[0]?.kod?.toString(), "T")
                          }
                          title={ReactSystemFunctions.translate(this.ml, 477372, "title", this.defaultML)}
                          alwaysVisibleOnMobileResolution={false}
                          hideFiltering={false}
                          hideSorting={false}
                          hideSearch={false}
                          footerOptions="none"
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <Label
                            id="838727"
                            value="[datafield:trackNo]"
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                paddingTop: 8,
                                paddingBottom: 8,
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "12px",
                                color: "rgba(47, 47, 47, 1)"
                              } as any
                            }
                          ></Label>
                        </KTableColumn>

                        <KTableColumn
                          id="452085"
                          visibility={this.state.isComp452085Visible}
                          condition={(rowData) =>
                            ReactSystemFunctions.isEqualTo(this.state.sbEserTipiList[0]?.kod?.toString(), "B")
                          }
                          title={ReactSystemFunctions.translate(this.ml, 452085, "title", this.defaultML)}
                          alwaysVisibleOnMobileResolution={false}
                          hideFiltering={false}
                          hideSorting={false}
                          hideSearch={false}
                          footerOptions="none"
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <Label
                            id="284137"
                            value="[datafield:temsilcilikDurumu]"
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                paddingTop: 8,
                                paddingBottom: 8,
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "12px",
                                color: "rgba(47, 47, 47, 1)"
                              } as any
                            }
                          ></Label>
                        </KTableColumn>

                        <KTableColumn
                          id="816597"
                          visibility={this.state.isComp816597Visible}
                          title={ReactSystemFunctions.translate(this.ml, 816597, "title", this.defaultML)}
                          alwaysVisibleOnMobileResolution={false}
                          hideFiltering={false}
                          hideSorting={false}
                          hideSearch={false}
                          footerOptions="none"
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <Label
                            id="699845"
                            value="[datafield:logoAltKatalogKodu]"
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                paddingTop: 8,
                                paddingBottom: 8,
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "12px",
                                color: "rgba(47, 47, 47, 1)"
                              } as any
                            }
                          ></Label>
                        </KTableColumn>

                        <KTableColumn
                          id="638642"
                          visibility={this.state.isComp638642Visible}
                          title={ReactSystemFunctions.translate(this.ml, 638642, "title", this.defaultML)}
                          alwaysVisibleOnMobileResolution={false}
                          hideFiltering={false}
                          hideSorting={false}
                          hideSearch={false}
                          footerOptions="none"
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <Label
                            id="373023"
                            value="[datafield:logoStokKodu]"
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                paddingTop: 8,
                                paddingBottom: 8,
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "12px",
                                color: "rgba(47, 47, 47, 1)"
                              } as any
                            }
                          ></Label>
                        </KTableColumn>

                        <KTableColumn
                          id="470854"
                          title={ReactSystemFunctions.translate(this.ml, 470854, "title", this.defaultML)}
                          width="100"
                          alwaysVisibleOnMobileResolution={false}
                          hideFiltering={false}
                          hideSorting={false}
                          hideSearch={false}
                          footerOptions="none"
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <Spacer id="257846" direction="horizontal" size={12} style={{} as any}>
                            <Icon
                              id="167541"
                              onClick={(e?: any) => {
                                if (e && e.stopPropagation) e.stopPropagation();
                                this.UrunListComponent_167541_onClick();
                              }}
                              showCursorPointer
                              visibility={this.state.isCompurunlist_167541AuthorizationVisible}
                              visibilityByAuthorization={this.state.isCompurunlist_167541AuthorizationVisible}
                              iconName="delete"
                              hoverText="Sil"
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 0,
                                  paddingRight: 0,
                                  paddingBottom: 0,
                                  paddingLeft: 4,
                                  fontSize: "24px",
                                  color: "rgba(172, 38, 42, 1)"
                                } as any
                              }
                            ></Icon>

                            <Icon
                              id="211653"
                              onClick={(e?: any) => {
                                if (e && e.stopPropagation) e.stopPropagation();
                                this.UrunListComponent_211653_onClick();
                              }}
                              showCursorPointer
                              visibility={this.state.isCompurunlist_211653AuthorizationVisible}
                              visibilityByAuthorization={this.state.isCompurunlist_211653AuthorizationVisible}
                              iconName="visibility"
                              hoverText="İncele"
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  paddingRight: 4,
                                  fontSize: "24px",
                                  color: "rgba(172, 38, 42, 1)"
                                } as any
                              }
                            ></Icon>
                          </Spacer>
                        </KTableColumn>
                      </KTableRow>
                    </KTable>

                    <KPagination
                      id="39854"
                      onChange={(e?: any) => {
                        if (e && e.stopPropagation) e.stopPropagation();
                        this.UrunListComponent_39854_onChange();
                      }}
                      kuikaRef={this.urunlist_39854_value_kuikaPaginationRef}
                      total={this.state.spGetListStokCount?.at?.(0)?.sumCount ?? undefined}
                      pageSize={20}
                      defaultCurrent={1}
                      current={1}
                      defaultPageSize={10}
                      disabled={false}
                      hideOnSinglePage={true}
                      showLessItems={false}
                      showQuickJumper={false}
                      showSizeChanger={true}
                      showFirstPageButton={false}
                      showLastPageButton={false}
                      showNumbers={true}
                      showText={false}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 8,
                          textAlign: "-webkit-right"
                        } as any
                      }
                    ></KPagination>
                  </KCol>
                </KRow>

                <KDrawer
                  id="294787"
                  placement="left"
                  onClose={() => {
                    this.setState({ NavVisible294787: false }, () => {
                      this.callCallbackFunction(294787);
                    });
                  }}
                  visible={this.state.NavVisible294787}
                  push={true}
                  children={
                    this.state.NavVisible294787 && (
                      <LeftMenu_Screen
                        onClose={() => {
                          this.setState({ NavVisible294787: false }, () => {
                            this.callCallbackFunction(294787);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                      />
                    )
                  }
                  style={{} as any}
                ></KDrawer>
              </div>
            </div>
          </Form>
        </Spin>
      </>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  return {
    spinnerCount: state.spinnerReducer.spinnerCount,
    photoTakeVisible: state.photoTakeReducer.visible
  };
};
const mapDispatchToProps = (dispatch) => ({
  showPhotoTake: () => dispatch(showPhotoTake()),
  hidePhotoTake: () => dispatch(hidePhotoTake()),
  showStripeDrawer: (data, callback, that, future) => dispatch(showStripeDrawer(data, callback, that, future)),
  hideStripeDrawer: (data, callback, that) => dispatch(hideStripeDrawer(data, callback, that)),
  showIyzicoDrawer: (data, callback, that) => dispatch(showIyzicoDrawer(data, callback, that)),
  hideIyzicoDrawer: (data, callback, that) => dispatch(hideIyzicoDrawer(data, callback, that))
});
const tmp = withGoogleLogin(
  withContext(withScreenInput(withForm(withHistory(connect(mapStateToProps, mapDispatchToProps)(UrunList_Screen)))))
);
export { tmp as UrunList_Screen };
//export default tmp;
//export { tmp as UrunList_Screen };
