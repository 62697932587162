import { FormInstance } from "antd/lib/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface ITMecra_Form_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface ITMecra_Form_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GoBack: any;
  GoBack_dummy: any;
  MecraSelectById: any[];
  MecraSelectById_dummy: any[];
  ProjeTuruSelectAll: any[];
  ProjeTuruSelectAll_dummy: any[];
  MecraGrupSelectAll: any[];
  MecraGrupSelectAll_dummy: any[];
  MecraSave: number;
  MecraSave_dummy: number;
}

export class TMecra_Form_ScreenBase extends React.PureComponent<ITMecra_Form_ScreenProps, any> {
  tmecra_form_12149960_value_kuikaSelectBoxRef: React.RefObject<any>;
  tmecra_form_29262240_value_kuikaSelectBoxRef: React.RefObject<any>;
  ml = [
    {
      Id: "16c151eb-42cf-0b2b-7e4a-e8b04fc43d88",
      Name: "tr_TR",
      ShortName: "Turkish",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "fbdbce9f-3fdb-30c5-c4fe-f7e443068251", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    { Id: "8468043e-f025-01e1-93b8-c71e3215778a", Name: "de_DE", ShortName: "German", LanguagePhrases: [] },
    {
      Id: "9b44c52e-3137-4412-8413-787163893d6e",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 348135, PropertyName: "value", Value: "T Mecra form" },
        { Id: 5312418, PropertyName: "value", Value: "Tanim" },
        { Id: 92550510, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 8217972, PropertyName: "value", Value: "Kod" },
        { Id: 9397085, PropertyName: "value", Value: "Order by" },
        { Id: 4216868, PropertyName: "value", Value: "Proje turu iD" },
        { Id: 12149960, PropertyName: "placeholder", Value: "Choose one..." },
        { Id: 5351460, PropertyName: "value", Value: "Mecra grup iD" },
        { Id: 29262240, PropertyName: "placeholder", Value: "Choose one..." },
        { Id: 9544, PropertyName: "label", Value: "Save" }
      ]
    }
  ];
  defaultML = "tr_TR";
  screenHash = "";

  constructor(props) {
    super(props);
    this.tmecra_form_12149960_value_kuikaSelectBoxRef = React.createRef();
    this.tmecra_form_29262240_value_kuikaSelectBoxRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GoBack: "",
      MecraSelectById: [],
      ProjeTuruSelectAll: [],
      MecraGrupSelectAll: [],
      MecraSave: ""
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("tmecra_form", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.TMecra_FormPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("tmecra_form", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("tmecra_form", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.TMecra_FormPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      tmecra_form_92550510_value: this.state.MecraSelectById?.at?.(0)?.tanim ?? undefined,
      tmecra_form_26764500_value: this.state.MecraSelectById?.at?.(0)?.kod ?? undefined,
      tmecra_form_14049720_value: this.state.MecraSelectById?.at?.(0)?.orderBy ?? undefined,
      tmecra_form_12149960_value: this.state.MecraSelectById?.at?.(0)?.projeTuruID ?? undefined,
      tmecra_form_29262240_value: this.state.MecraSelectById?.at?.(0)?.mecraGrupID ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  TMecra_FormPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.Id ?? this.props.screenInputs.id, "Guid")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "TMecra_Form/TMecra_FormPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MecraSelectById = result?.data.mecraSelectById;
    formVars.tmecra_form_92550510_value = ReactSystemFunctions.toString(
      this,
      stateVars.MecraSelectById?.length > 0 ? stateVars.MecraSelectById[0]?.tanim : null
    );
    formVars.tmecra_form_26764500_value = ReactSystemFunctions.toString(
      this,
      stateVars.MecraSelectById?.length > 0 ? stateVars.MecraSelectById[0]?.kod : null
    );
    formVars.tmecra_form_14049720_value = ReactSystemFunctions.toString(
      this,
      stateVars.MecraSelectById?.length > 0 ? stateVars.MecraSelectById[0]?.orderBy : null
    );
    formVars.tmecra_form_12149960_value =
      stateVars.MecraSelectById?.length > 0 ? stateVars.MecraSelectById[0]?.projeTuruID : null;
    formVars.tmecra_form_12149960_options = stateVars.ProjeTuruSelectAll;
    formVars.tmecra_form_29262240_value =
      stateVars.MecraSelectById?.length > 0 ? stateVars.MecraSelectById[0]?.mecraGrupID : null;
    formVars.tmecra_form_29262240_options = stateVars.MecraGrupSelectAll;
    stateVars.ProjeTuruSelectAll = result?.data.projeTuruSelectAll;

    formVars.tmecra_form_12149960_value =
      stateVars.MecraSelectById?.length > 0 ? stateVars.MecraSelectById[0]?.projeTuruID : null;
    formVars.tmecra_form_12149960_options = stateVars.ProjeTuruSelectAll;
    stateVars.MecraGrupSelectAll = result?.data.mecraGrupSelectAll;

    formVars.tmecra_form_29262240_value =
      stateVars.MecraSelectById?.length > 0 ? stateVars.MecraSelectById[0]?.mecraGrupID : null;
    formVars.tmecra_form_29262240_options = stateVars.MecraGrupSelectAll;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TMecra_FormPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TMecra_FormPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.tmecra_form_92550510_value = ReactSystemFunctions.toString(
      this,
      this.state.MecraSelectById?.length > 0 ? this.state.MecraSelectById[0]?.tanim : null
    );

    formVars.tmecra_form_26764500_value = ReactSystemFunctions.toString(
      this,
      this.state.MecraSelectById?.length > 0 ? this.state.MecraSelectById[0]?.kod : null
    );

    formVars.tmecra_form_14049720_value = ReactSystemFunctions.toString(
      this,
      this.state.MecraSelectById?.length > 0 ? this.state.MecraSelectById[0]?.orderBy : null
    );

    formVars.tmecra_form_12149960_value = ReactSystemFunctions.toString(
      this,
      this.state.MecraSelectById?.length > 0 ? this.state.MecraSelectById[0]?.projeTuruID : null
    );

    stateVars.dataSource_12149960 = this.state.ProjeTuruSelectAll;
    stateVars.dataSource_12149960 = this.state.ProjeTuruSelectAll;
    formVars.tmecra_form_29262240_value = ReactSystemFunctions.toString(
      this,
      this.state.MecraSelectById?.length > 0 ? this.state.MecraSelectById[0]?.mecraGrupID : null
    );

    stateVars.dataSource_29262240 = this.state.MecraGrupSelectAll;
    stateVars.dataSource_29262240 = this.state.MecraGrupSelectAll;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  TMecra_FormComponent_733835_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TMecra_FormComponent_9544_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.Id ?? this.props.screenInputs.id, "Guid"),
      Kod_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tmecra_form_26764500_value", "value", "", "", "")
        ),
        "number"
      ),
      MecraGrupID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tmecra_form_29262240_value", "value", "MecraGrupSelectAll", "id", "")
        ),
        "Guid"
      ),
      OrderBy_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tmecra_form_14049720_value", "value", "", "", "")
        ),
        "number"
      ),
      ProjeTuruID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tmecra_form_12149960_value", "value", "ProjeTuruSelectAll", "id", "")
        ),
        "Guid"
      ),
      Tanim_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "tmecra_form_92550510_value", "value", "", "", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "TMecra_Form/TMecra_FormComponent_9544_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MecraSave = result?.data.mecraSave;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TMecra_FormComponent_9544_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TMecra_FormComponent_9544_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
