import { FormInstance } from "antd/lib/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IDagitimList_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IDagitimList_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  getUserInfo: any[];
  getUserInfo_dummy: any[];
  spDagitimListEXCEL: any[];
  spDagitimListEXCEL_dummy: any[];
  ExportExcel: any;
  ExportExcel_dummy: any;
  sbQuarterYearList: any[];
  sbQuarterYearList_dummy: any[];
  sbQuarterList: any[];
  sbQuarterList_dummy: any[];
  sbDagitimYeriList: any[];
  sbDagitimYeriList_dummy: any[];
  getFilterParametersByUserName: any[];
  getFilterParametersByUserName_dummy: any[];
  SetValueOf: any;
  SetValueOf_dummy: any;
  FilterParametersSaveDagitimList: number;
  FilterParametersSaveDagitimList_dummy: number;
  spDagitimList: any[];
  spDagitimList_dummy: any[];
  spDagitimListCount: any[];
  spDagitimListCount_dummy: any[];
  spTempDagitimSubListINSERT: number;
  spTempDagitimSubListINSERT_dummy: number;
  Notify: boolean;
  Notify_dummy: boolean;
  isComp822531Visible: "visible" | "hidden";
  isComp578618Visible: "visible" | "hidden";
  isComp187186Visible: "visible" | "hidden";
}

export class DagitimList_ScreenBase extends React.PureComponent<IDagitimList_ScreenProps, any> {
  dagitimlist_660947_value_kuikaTableRef: React.RefObject<any>;
  dagitimlist_187186_value_kuikaPaginationRef: React.RefObject<any>;
  ml = [
    {
      Id: "16c151eb-42cf-0b2b-7e4a-e8b04fc43d88",
      Name: "tr_TR",
      ShortName: "Turkish",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "fbdbce9f-3fdb-30c5-c4fe-f7e443068251", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    { Id: "8468043e-f025-01e1-93b8-c71e3215778a", Name: "de_DE", ShortName: "German", LanguagePhrases: [] },
    {
      Id: "70294770-517f-47e5-b948-d59e0c52f0e4",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 625521, PropertyName: "value", Value: "DAĞITIM (Eser Sahibi)" },
        { Id: 719815, PropertyName: "title", Value: "Sayfa Verilerini İndir" },
        { Id: 743778, PropertyName: "label", Value: "Veri Güncelle" },
        { Id: 357647, PropertyName: "label", Value: "Dağıtım (Kendisi/Temsilci/Varis)" },
        { Id: 660947, PropertyName: "nodatafoundmessage", Value: "Veri Bulunamadı" },
        { Id: 133595, PropertyName: "title", Value: "Yıl" },
        { Id: 431410, PropertyName: "value", Value: "[datafield:quarteryear]" },
        { Id: 555819, PropertyName: "title", Value: "Q" },
        { Id: 502152, PropertyName: "value", Value: "[datafield:quarterkod]" },
        { Id: 822531, PropertyName: "title", Value: "Dağıtım Yeri Kodu" },
        { Id: 166617, PropertyName: "value", Value: "[datafield:dagitimyerikodu]" },
        { Id: 468136, PropertyName: "title", Value: "Dağıtım Yeri" },
        { Id: 408011, PropertyName: "value", Value: "[datafield:dagitimyeri]" },
        { Id: 885016, PropertyName: "title", Value: "Toplam Tutar" },
        { Id: 352743, PropertyName: "value", Value: "[datafield:ttutar]" },
        { Id: 450855, PropertyName: "value", Value: "[datafield:_simge]" },
        { Id: 812977, PropertyName: "title", Value: "Eser Sahibi Tutar" },
        { Id: 679677, PropertyName: "value", Value: "[datafield:thakedistutar]" },
        { Id: 910514, PropertyName: "value", Value: "[datafield:_simge]" },
        { Id: 352211, PropertyName: "title", Value: "MUZ Pay" },
        { Id: 726097, PropertyName: "value", Value: "[datafield:tmuztutar]" },
        { Id: 607161, PropertyName: "value", Value: "[datafield:_simge]" },
        { Id: 608287, PropertyName: "title", Value: "Eser Sahibi Pay" },
        { Id: 602093, PropertyName: "value", Value: "[datafield:tstopajdahiltutar]" },
        { Id: 352398, PropertyName: "value", Value: "[datafield:_simge]" }
      ]
    }
  ];
  defaultML = "tr_TR";
  screenHash = "";

  constructor(props) {
    super(props);
    this.dagitimlist_660947_value_kuikaTableRef = React.createRef();
    this.dagitimlist_187186_value_kuikaPaginationRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      NAVIGATE: "",
      getUserInfo: [],
      spDagitimListEXCEL: [],
      ExportExcel: "",
      sbQuarterYearList: [],
      sbQuarterList: [],
      sbDagitimYeriList: [],
      getFilterParametersByUserName: [],
      SetValueOf: "",
      FilterParametersSaveDagitimList: "",
      spDagitimList: [],
      spDagitimListCount: [],
      spTempDagitimSubListINSERT: 0,
      Notify: false,
      isComp822531Visible: "hidden",
      isComp578618Visible: "hidden",
      isComp187186Visible: "hidden"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("dagitimlist", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.DagitimListPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("dagitimlist", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("dagitimlist", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.DagitimListPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      dagitimlist_347399_value: this.state.getFilterParametersByUserName?.at?.(0)?.dagitimListYil ?? undefined,
      dagitimlist_58407_value: this.state.getFilterParametersByUserName?.at?.(0)?.dagitimListQ ?? undefined,
      dagitimlist_197241_value: this.state.getFilterParametersByUserName?.at?.(0)?.dagitimListSearch ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  DagitimListPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {};

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "DagitimList/DagitimListPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.getUserInfo = result?.data.getUserInfo;
    stateVars.sbQuarterYearList = result?.data.sbQuarterYearList;

    formVars.dagitimlist_347399_options = stateVars.sbQuarterYearList;
    formVars.dagitimlist_347399_value =
      stateVars.getFilterParametersByUserName?.length > 0
        ? stateVars.getFilterParametersByUserName[0]?.dagitimListYil
        : null;
    stateVars.sbQuarterList = result?.data.sbQuarterList;

    formVars.dagitimlist_58407_options = stateVars.sbQuarterList;
    formVars.dagitimlist_58407_value =
      stateVars.getFilterParametersByUserName?.length > 0
        ? stateVars.getFilterParametersByUserName[0]?.dagitimListQ
        : null;
    stateVars.sbDagitimYeriList = result?.data.sbDagitimYeriList;

    formVars.dagitimlist_197241_options = stateVars.sbDagitimYeriList;
    formVars.dagitimlist_197241_value =
      stateVars.getFilterParametersByUserName?.length > 0
        ? stateVars.getFilterParametersByUserName[0]?.dagitimListSearch
        : null;
    stateVars.getFilterParametersByUserName = result?.data.getFilterParametersByUserName;
    formVars.dagitimlist_347399_options = stateVars.sbQuarterYearList;
    formVars.dagitimlist_347399_value =
      stateVars.getFilterParametersByUserName?.length > 0
        ? stateVars.getFilterParametersByUserName[0]?.dagitimListYil
        : null;
    formVars.dagitimlist_58407_options = stateVars.sbQuarterList;
    formVars.dagitimlist_58407_value =
      stateVars.getFilterParametersByUserName?.length > 0
        ? stateVars.getFilterParametersByUserName[0]?.dagitimListQ
        : null;
    formVars.dagitimlist_197241_options = stateVars.sbDagitimYeriList;
    formVars.dagitimlist_197241_value =
      stateVars.getFilterParametersByUserName?.length > 0
        ? stateVars.getFilterParametersByUserName[0]?.dagitimListSearch
        : null;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.DagitimListPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  DagitimListPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "dagitimlist_347399_value",
      ReactSystemFunctions.toString(
        this,
        this.state.getFilterParametersByUserName?.length > 0
          ? this.state.getFilterParametersByUserName[0]?.dagitimListYil
          : null
      ),
      null
    );
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "dagitimlist_58407_value",
      ReactSystemFunctions.toString(
        this,
        this.state.getFilterParametersByUserName?.length > 0
          ? this.state.getFilterParametersByUserName[0]?.dagitimListQ
          : null
      ),
      null
    );
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "dagitimlist_197241_value",
      ReactSystemFunctions.toString(
        this,
        this.state.getFilterParametersByUserName?.length > 0
          ? this.state.getFilterParametersByUserName[0]?.dagitimListSearch
          : null
      ),
      null
    );

    formVars.dagitimlist_347399_value = ReactSystemFunctions.toString(
      this,
      this.state.getFilterParametersByUserName?.length > 0
        ? this.state.getFilterParametersByUserName[0]?.dagitimListYil
        : null
    );

    stateVars.dataSource_347399 = this.state.sbQuarterYearList;
    stateVars.dataSource_347399 = this.state.sbQuarterYearList;

    formVars.dagitimlist_58407_value = ReactSystemFunctions.toString(
      this,
      this.state.getFilterParametersByUserName?.length > 0
        ? this.state.getFilterParametersByUserName[0]?.dagitimListQ
        : null
    );

    stateVars.dataSource_58407 = this.state.sbQuarterList;
    stateVars.dataSource_58407 = this.state.sbQuarterList;

    formVars.dagitimlist_197241_value = ReactSystemFunctions.toString(
      this,
      this.state.getFilterParametersByUserName?.length > 0
        ? this.state.getFilterParametersByUserName[0]?.dagitimListSearch
        : null
    );

    stateVars.dataSource_197241 = this.state.sbDagitimYeriList;
    stateVars.dataSource_197241 = this.state.sbDagitimYeriList;
    stateVars.isComp187186Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          this.state.spDagitimListCount?.length > 0 ? this.state.spDagitimListCount[0]?.dagitimListCount : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  DagitimListComponent_653137_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "DagitimList",
      "LeftMenu",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "464411",
      "",
      "left",
      null,
      "",
      "",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DagitimListComponent_522959_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "DagitimListKUMULE",
      "QYear",
      ReactSystemFunctions.toString(
        this,
        ReactSystemFunctions.value(
          this,
          "dagitimlist_347399_value",
          "value",
          "sbQuarterYearList",
          "quarterYear",
          "quarterYear"
        )
      )
    );
    KuikaAppManager.addToPageInputVariables(
      "DagitimListKUMULE",
      "QKod",
      ReactSystemFunctions.toString(
        this,
        ReactSystemFunctions.value(
          this,
          "dagitimlist_58407_value",
          "value",
          "sbQuarterList",
          "quarterKod",
          "quarterKod"
        )
      )
    );
    KuikaAppManager.addToPageInputVariables(
      "DagitimListKUMULE",
      "DKodu",
      ReactSystemFunctions.toString(
        this,
        ReactSystemFunctions.value(
          this,
          "dagitimlist_197241_value",
          "value",
          "sbDagitimYeriList",
          "dagitimYapilacakKodu",
          "dagitimYapilacakKodu"
        )
      )
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "DagitimList",
      "DagitimListKUMULE",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "402117",
      null,
      "left",
      null,
      "80%",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DagitimListComponent_489070_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Search_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      QuarterYear_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "dagitimlist_347399_value",
            "value",
            "sbQuarterYearList",
            "quarterYear",
            "quarterYear"
          )
        ),
        "string"
      ),
      QuarterKod_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "dagitimlist_58407_value",
            "value",
            "sbQuarterList",
            "quarterKod",
            "quarterKod"
          )
        ),
        "string"
      ),
      DagitimYapilacakKodu_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "dagitimlist_197241_value",
            "value",
            "sbDagitimYeriList",
            "dagitimYapilacakKodu",
            "dagitimYapilacakKodu"
          )
        ),
        "string"
      ),
      header_2: ReactSystemFunctions.convertToTypeByName("DAĞITIM LİSTESİ", "string"),
      footer_2: ReactSystemFunctions.convertToTypeByName("", "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "DagitimList/DagitimListComponent_489070_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.getUserInfo = result?.data.getUserInfo;
    stateVars.spDagitimListEXCEL = result?.data.spDagitimListEXCEL;
    stateVars.ExportExcel = result?.data.exportExcel;
    ReactSystemFunctions.downloadFile(result?.data?.exportExcel);
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  DagitimListComponent_217723_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      DagitimListPagination_0: ReactSystemFunctions.convertToTypeByName(1, "number"),
      DagitimListQ_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "dagitimlist_58407_value",
            "value",
            "sbQuarterList",
            "quarterKod",
            "quarterKod"
          )
        ),
        "string"
      ),
      DagitimListSearch_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "dagitimlist_197241_value",
            "value",
            "sbDagitimYeriList",
            "dagitimYapilacakKodu",
            "dagitimYapilacakKodu"
          )
        ),
        "string"
      ),
      DagitimListYil_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "dagitimlist_347399_value",
            "value",
            "sbQuarterYearList",
            "quarterYear",
            "quarterYear"
          )
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "DagitimList/DagitimListComponent_217723_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.FilterParametersSaveDagitimList = result?.data.filterParametersSaveDagitimList;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.DagitimListComponent_217723_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  DagitimListComponent_217723_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "dagitimlist_187186_value", 1, null);

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.DagitimListComponent_217723_onClick2_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  DagitimListComponent_217723_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Rol_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.getUserInfo?.length > 0 ? this.state.getUserInfo[0]?.rolName : null
        ),
        "string"
      ),
      Search_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      QuarterYear_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "dagitimlist_347399_value",
            "value",
            "sbQuarterYearList",
            "quarterYear",
            "quarterYear"
          )
        ),
        "string"
      ),
      QuarterKod_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "dagitimlist_58407_value",
            "value",
            "sbQuarterList",
            "quarterKod",
            "quarterKod"
          )
        ),
        "string"
      ),
      DagitimYapilacakKodu_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "dagitimlist_197241_value",
            "value",
            "sbDagitimYeriList",
            "dagitimYapilacakKodu",
            "dagitimYapilacakKodu"
          )
        ),
        "string"
      ),
      PageNumber_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dagitimlist_187186_value", "current", "", "", "")
        ),
        "number"
      ),
      RowsOfPage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dagitimlist_187186_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      Rol_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.getUserInfo?.length > 0 ? this.state.getUserInfo[0]?.rolName : null
        ),
        "string"
      ),
      Search_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      QuarterYear_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "dagitimlist_347399_value",
            "value",
            "sbQuarterYearList",
            "quarterYear",
            "quarterYear"
          )
        ),
        "string"
      ),
      QuarterKod_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "dagitimlist_58407_value",
            "value",
            "sbQuarterList",
            "quarterKod",
            "quarterKod"
          )
        ),
        "string"
      ),
      DagitimYapilacakKodu_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "dagitimlist_197241_value",
            "value",
            "sbDagitimYeriList",
            "dagitimYapilacakKodu",
            "dagitimYapilacakKodu"
          )
        ),
        "string"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "DagitimList/DagitimListComponent_217723_onClick2_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.spDagitimList = result?.data.spDagitimList;

    stateVars.spDagitimListCount = result?.data.spDagitimListCount;
    stateVars.isComp187186Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.spDagitimListCount?.length > 0 ? stateVars.spDagitimListCount[0]?.dagitimListCount : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.DagitimListComponent_217723_onClick3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  DagitimListComponent_217723_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_660947 = this.state.spDagitimList;
    formVars.dagitimlist_187186_total = ReactSystemFunctions.value(
      this,
      this.state.spDagitimListCount?.length > 0 ? this.state.spDagitimListCount[0]?.dagitimListCount : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DagitimListComponent_743778_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      QuarterYear_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "dagitimlist_347399_value",
            "value",
            "sbQuarterYearList",
            "quarterYear",
            "quarterYear"
          )
        ),
        "string"
      ),
      QuarterKod_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "dagitimlist_58407_value",
            "value",
            "sbQuarterList",
            "quarterKod",
            "quarterKod"
          )
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "DagitimList/DagitimListComponent_743778_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.spTempDagitimSubListINSERT = result?.data.spTempDagitimSubListINSERT;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.DagitimListComponent_743778_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  DagitimListComponent_743778_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "5880db4b_7385_c743_834e_4af49aee89a9_notify",
        this.defaultML,
        "Veriler Güncellendi!"
      ),
      "success",
      "bottom-right",
      2,
      null,
      null,
      null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DagitimListComponent_357647_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "DagitimList",
      "DagitimAltList",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DagitimListComponent_871646_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "DagitimDetailList",
      "prmKategoriKodu",
      ReactSystemFunctions.value(this, "dagitimlist_660947_value", "dagitimYeriKodu")
    );
    KuikaAppManager.addToPageInputVariables(
      "DagitimDetailList",
      "prmSimge",
      ReactSystemFunctions.value(this, "dagitimlist_660947_value", "_simge")
    );
    KuikaAppManager.addToPageInputVariables(
      "DagitimDetailList",
      "prmQuarterKod",
      ReactSystemFunctions.value(this, "dagitimlist_660947_value", "quarterKod")
    );
    KuikaAppManager.addToPageInputVariables(
      "DagitimDetailList",
      "prmQuarterYear",
      ReactSystemFunctions.value(this, "dagitimlist_660947_value", "quarterYear")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "DagitimList",
      "DagitimDetailList",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DagitimListComponent_187186_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Rol_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.getUserInfo?.length > 0 ? this.state.getUserInfo[0]?.rolName : null
        ),
        "string"
      ),
      Search_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      QuarterYear_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "dagitimlist_347399_value",
            "value",
            "sbQuarterYearList",
            "quarterYear",
            "quarterYear"
          )
        ),
        "string"
      ),
      QuarterKod_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "dagitimlist_58407_value",
            "value",
            "sbQuarterList",
            "quarterKod",
            "quarterKod"
          )
        ),
        "string"
      ),
      DagitimYapilacakKodu_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "dagitimlist_197241_value",
            "value",
            "sbDagitimYeriList",
            "dagitimYapilacakKodu",
            "dagitimYapilacakKodu"
          )
        ),
        "string"
      ),
      PageNumber_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dagitimlist_187186_value", "current", "", "", "")
        ),
        "number"
      ),
      RowsOfPage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dagitimlist_187186_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "DagitimList/DagitimListComponent_187186_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.spDagitimList = result?.data.spDagitimList;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.DagitimListComponent_187186_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  DagitimListComponent_187186_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_660947 = this.state.spDagitimList;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [464411, 402117] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.DagitimListPageInit();
    }
  }
}
