import { FormInstance } from "antd/lib/form";
import { Guid } from "guid-typescript";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IBFormuAnaSayfa_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IBFormuAnaSayfa_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  sbBasvuruTuruList: any[];
  sbBasvuruTuruList_dummy: any[];
  sbProjeFormTuruList: any[];
  sbProjeFormTuruList_dummy: any[];
  spProjeFormTuruSelectedColor: any[];
  spProjeFormTuruSelectedColor_dummy: any[];
  spBasvuruTuruSelectedColor: any[];
  spBasvuruTuruSelectedColor_dummy: any[];
  ChangeVisibilityOf: string;
  ChangeVisibilityOf_dummy: string;
  getFormNo: any[];
  getFormNo_dummy: any[];
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  Notify: boolean;
  Notify_dummy: boolean;
  isComp961346Visible: "visible" | "hidden";
  isComp122947Visible: "visible" | "hidden";
  isComp828842Visible: "visible" | "hidden";
}

export class BFormuAnaSayfa_ScreenBase extends React.PureComponent<IBFormuAnaSayfa_ScreenProps, any> {
  bformuanasayfa_122947_value_kuikaSelectBoxRef: React.RefObject<any>;
  bformuanasayfa_212965_value_kuikaTableRef: React.RefObject<any>;
  bformuanasayfa_828842_value_kuikaSelectBoxRef: React.RefObject<any>;
  bformuanasayfa_933855_value_kuikaFlexGridRef: React.RefObject<any>;
  ml = [
    {
      Id: "16c151eb-42cf-0b2b-7e4a-e8b04fc43d88",
      Name: "tr_TR",
      ShortName: "Turkish",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "fbdbce9f-3fdb-30c5-c4fe-f7e443068251", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    { Id: "8468043e-f025-01e1-93b8-c71e3215778a", Name: "de_DE", ShortName: "German", LanguagePhrases: [] },
    {
      Id: "7f79d2be-8fe9-4b35-9862-c09d6d1c6e4d",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 465757, PropertyName: "value", Value: "MUZIKOTEK’in Lisans Başvuru Alanına Hoş Geldiniz" },
        { Id: 953731, PropertyName: "value", Value: "Welcome to MUZIKOTEK’s Music License Request Area" },
        { Id: 61270, PropertyName: "value", Value: "Başvurunuzla ilgili departmanı aşağıdaki seçimlerden yapın." },
        { Id: 238861, PropertyName: "value", Value: "Please select your department of choice below." },
        {
          Id: 338351,
          PropertyName: "value",
          Value: "Aradığınızı bulamıyor veya emin değilseniz, lütfen DİĞER’i seçin."
        },
        { Id: 792328, PropertyName: "value", Value: "Please select OTHER if you’re not sure." },
        { Id: 212965, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 985516, PropertyName: "value", Value: "[datafield:tanim]" },
        { Id: 390245, PropertyName: "value", Value: "[datafield:tanimen]" },
        { Id: 517574, PropertyName: "value", Value: "Lütfen başvuru türünü aşağıdan seçin." },
        { Id: 608742, PropertyName: "value", Value: "Please select production type" },
        { Id: 933855, PropertyName: "nodatafoundmessage", Value: "Veri bulunamadı." },
        { Id: 317370, PropertyName: "value", Value: "[datafield:tanim]" },
        { Id: 138444, PropertyName: "value", Value: "[datafield:tanimen]" },
        { Id: 402503, PropertyName: "label", Value: "İlerle / Next" }
      ]
    }
  ];
  defaultML = "tr_TR";
  screenHash = "";

  constructor(props) {
    super(props);
    this.bformuanasayfa_122947_value_kuikaSelectBoxRef = React.createRef();
    this.bformuanasayfa_212965_value_kuikaTableRef = React.createRef();
    this.bformuanasayfa_828842_value_kuikaSelectBoxRef = React.createRef();
    this.bformuanasayfa_933855_value_kuikaFlexGridRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      sbBasvuruTuruList: [],
      sbProjeFormTuruList: [],
      spProjeFormTuruSelectedColor: [],
      spBasvuruTuruSelectedColor: [],
      ChangeVisibilityOf: "",
      getFormNo: [],
      NAVIGATE: "",
      Notify: false,
      isComp961346Visible: "hidden",
      isComp122947Visible: "hidden",
      isComp828842Visible: "hidden"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("bformuanasayfa", "");
      return;
    }

    if (true) {
      await this.BFormuAnaSayfaPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("bformuanasayfa", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("bformuanasayfa", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.BFormuAnaSayfaPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  BFormuAnaSayfaPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      PKod_2: ReactSystemFunctions.convertToTypeByName(0, "number"),
      PKod_3: ReactSystemFunctions.convertToTypeByName(0, "number")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "BFormuAnaSayfa/BFormuAnaSayfaPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.sbBasvuruTuruList = result?.data.sbBasvuruTuruList;

    formVars.bformuanasayfa_122947_options = stateVars.sbBasvuruTuruList;
    stateVars.sbProjeFormTuruList = result?.data.sbProjeFormTuruList;

    formVars.bformuanasayfa_828842_options = stateVars.sbProjeFormTuruList;
    stateVars.spProjeFormTuruSelectedColor = result?.data.spProjeFormTuruSelectedColor;

    stateVars.spBasvuruTuruSelectedColor = result?.data.spBasvuruTuruSelectedColor;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.BFormuAnaSayfaPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  BFormuAnaSayfaPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_122947 = this.state.sbBasvuruTuruList;
    stateVars.dataSource_122947 = this.state.sbBasvuruTuruList;

    stateVars.dataSource_212965 = this.state.spBasvuruTuruSelectedColor;

    stateVars.dataSource_828842 = this.state.sbProjeFormTuruList;
    stateVars.dataSource_828842 = this.state.sbProjeFormTuruList;

    stateVars.dataSource_933855 = this.state.spProjeFormTuruSelectedColor;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  BFormuAnaSayfaComponent_122947_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, "isComp961346Visible", "hidden");

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.BFormuAnaSayfaComponent_122947_onChange1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  BFormuAnaSayfaComponent_122947_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformuanasayfa_122947_value", "value", "sbBasvuruTuruList", "id", "kod")
        ),
        "2"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp961346Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  BFormuAnaSayfaComponent_705220_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      PKod_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "bformuanasayfa_212965_value", "kod"),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "BFormuAnaSayfa/BFormuAnaSayfaComponent_705220_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.spBasvuruTuruSelectedColor = result?.data.spBasvuruTuruSelectedColor;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.BFormuAnaSayfaComponent_705220_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  BFormuAnaSayfaComponent_705220_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, "isComp961346Visible", "hidden");

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.BFormuAnaSayfaComponent_705220_onClick2_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  BFormuAnaSayfaComponent_705220_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, "bformuanasayfa_212965_value", "kod"), "2")) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp961346Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuAnaSayfaComponent_705220_onClick3_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuAnaSayfaComponent_705220_onClick3_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BFormuAnaSayfaComponent_705220_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_212965 = this.state.spBasvuruTuruSelectedColor;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  BFormuAnaSayfaComponent_985516_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      PKod_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "bformuanasayfa_212965_value", "kod"),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "BFormuAnaSayfa/BFormuAnaSayfaComponent_985516_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.spBasvuruTuruSelectedColor = result?.data.spBasvuruTuruSelectedColor;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.BFormuAnaSayfaComponent_985516_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  BFormuAnaSayfaComponent_985516_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, "isComp961346Visible", "hidden");

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.BFormuAnaSayfaComponent_985516_onClick2_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  BFormuAnaSayfaComponent_985516_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, "bformuanasayfa_212965_value", ".kod"), "2")) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp961346Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuAnaSayfaComponent_985516_onClick3_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuAnaSayfaComponent_985516_onClick3_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BFormuAnaSayfaComponent_985516_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_212965 = this.state.spBasvuruTuruSelectedColor;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  BFormuAnaSayfaComponent_179599_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      PKod_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "bformuanasayfa_933855_value", ".kod"),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "BFormuAnaSayfa/BFormuAnaSayfaComponent_179599_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.spProjeFormTuruSelectedColor = result?.data.spProjeFormTuruSelectedColor;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.BFormuAnaSayfaComponent_179599_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  BFormuAnaSayfaComponent_179599_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_933855 = this.state.spProjeFormTuruSelectedColor;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  BFormuAnaSayfaComponent_317370_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      PKod_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "bformuanasayfa_933855_value", ".kod"),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "BFormuAnaSayfa/BFormuAnaSayfaComponent_317370_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.spProjeFormTuruSelectedColor = result?.data.spProjeFormTuruSelectedColor;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.BFormuAnaSayfaComponent_317370_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  BFormuAnaSayfaComponent_317370_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_933855 = this.state.spProjeFormTuruSelectedColor;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  BFormuAnaSayfaComponent_402503_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      BTuru_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "bformuanasayfa_212965_value", ".kod"),
        "number"
      ),
      PTuru_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "bformuanasayfa_933855_value", ".kod"),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "BFormuAnaSayfa/BFormuAnaSayfaComponent_402503_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.getFormNo = result?.data.getFormNo;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.BFormuAnaSayfaComponent_402503_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  BFormuAnaSayfaComponent_402503_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, this.state.getFormNo?.length > 0 ? this.state.getFormNo[0]?.formNo : null),
        "0"
      )
    ) {
      KuikaAppManager.prepareForNavigation();
      KuikaAppManager.addToPageInputVariables(
        "BFormuBankaV2",
        "prmBavuruTuruID",
        ReactSystemFunctions.value(this, "bformuanasayfa_212965_value", ".id")
      );
      KuikaAppManager.addToPageInputVariables("BFormuBankaV2", "prmGuid", Guid.create().toString());
      KuikaAppManager.addToPageInputVariables("BFormuBankaV2", "NewDisplay", "N");
      KuikaAppManager.addToPageInputVariables("BFormuBankaV2", "prmNerdenGeldi", "ticket");

      stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
        this,
        this.BFormuAnaSayfaComponent_402503_onClick2_,
        "BFormuAnaSayfa",
        "BFormuBankaV2",
        "",
        "currentPage",
        null,
        null,
        null,
        null,
        null,
        null,
        true
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuAnaSayfaComponent_402503_onClick2_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BFormuAnaSayfaComponent_402503_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(this, this.state.getFormNo?.length > 0 ? this.state.getFormNo[0]?.formNo : null),
        null
      )
    ) {
      KuikaAppManager.prepareForNavigation();
      KuikaAppManager.addToPageInputVariables("BFormuTicari", "prmGuid", Guid.create().toString());
      KuikaAppManager.addToPageInputVariables(
        "BFormuTicari",
        "prmBavuruTuruID",
        ReactSystemFunctions.value(this, "bformuanasayfa_212965_value", ".id")
      );
      KuikaAppManager.addToPageInputVariables("BFormuTicari", "NewDisplay", "N");
      KuikaAppManager.addToPageInputVariables(
        "BFormuTicari",
        "prmProjeTuruID",
        ReactSystemFunctions.value(this, "bformuanasayfa_933855_value", ".id")
      );
      KuikaAppManager.addToPageInputVariables("BFormuTicari", "prmNerdenGeldi", "ticket");

      stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
        this,
        this.BFormuAnaSayfaComponent_402503_onClick3_,
        "BFormuAnaSayfa",
        "BFormuTicari",
        "",
        "currentPage",
        null,
        null,
        null,
        null,
        null,
        null,
        true
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuAnaSayfaComponent_402503_onClick3_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BFormuAnaSayfaComponent_402503_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.value(this, this.state.getFormNo?.length > 0 ? this.state.getFormNo[0]?.formNo : null),
        null
      )
    ) {
      stateVars.Notify = await ReactSystemFunctions.notify(
        this,
        ReactSystemFunctions.translateCustomActions(
          this.ml,
          "d5dd7b8f_0293_85be_3d30_379c27a7a625_notify",
          this.defaultML,
          "Seçim yapmadan ilerleyemezsiniz! Lütfen seçim yapın."
        ),
        "danger",
        "bottom-right",
        3,
        null,
        null,
        null
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
