import { FormInstance } from "antd/lib/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface ICariAdresler_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface ICariAdresler_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  setKartCariAdresBilgileriSave: number;
  setKartCariAdresBilgileriSave_dummy: number;
  GoBack: any;
  GoBack_dummy: any;
  getKartCariAdresBilgi: any[];
  getKartCariAdresBilgi_dummy: any[];
}

export class CariAdresler_ScreenBase extends React.PureComponent<ICariAdresler_ScreenProps, any> {
  ml = [
    {
      Id: "16c151eb-42cf-0b2b-7e4a-e8b04fc43d88",
      Name: "tr_TR",
      ShortName: "Turkish",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "fbdbce9f-3fdb-30c5-c4fe-f7e443068251", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    { Id: "8468043e-f025-01e1-93b8-c71e3215778a", Name: "de_DE", ShortName: "German", LanguagePhrases: [] },
    {
      Id: "9e381579-a485-45f4-ad3d-ccb4863030ed",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 608676, PropertyName: "label", Value: "Kaydet" },
        { Id: 838135, PropertyName: "value", Value: "Adres Tanımı" },
        { Id: 821142, PropertyName: "placeholder", Value: "..." },
        { Id: 856150, PropertyName: "value", Value: "Adres" },
        { Id: 953737, PropertyName: "placeholder", Value: "..." }
      ]
    }
  ];
  defaultML = "tr_TR";
  screenHash = "";

  constructor(props) {
    super(props);

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      setKartCariAdresBilgileriSave: "",
      GoBack: "",
      getKartCariAdresBilgi: []
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("cariadresler", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.CariAdreslerPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("cariadresler", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("cariadresler", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.CariAdreslerPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      cariadresler_821142_value: this.state.getKartCariAdresBilgi?.at?.(0)?.adresTanim ?? undefined,
      cariadresler_953737_value: this.state.getKartCariAdresBilgi?.at?.(0)?.adres ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  CariAdreslerPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "CariAdresler/CariAdreslerPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.getKartCariAdresBilgi = result?.data.getKartCariAdresBilgi;
    formVars.cariadresler_821142_value = ReactSystemFunctions.toString(
      this,
      stateVars.getKartCariAdresBilgi?.length > 0 ? stateVars.getKartCariAdresBilgi[0]?.adresTanim : null
    );
    formVars.cariadresler_953737_value = ReactSystemFunctions.toString(
      this,
      stateVars.getKartCariAdresBilgi?.length > 0 ? stateVars.getKartCariAdresBilgi[0]?.adres : null
    );
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.CariAdreslerPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  CariAdreslerPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.cariadresler_821142_value = ReactSystemFunctions.toString(
      this,
      this.state.getKartCariAdresBilgi?.length > 0 ? this.state.getKartCariAdresBilgi[0]?.adresTanim : null
    );

    formVars.cariadresler_953737_value = ReactSystemFunctions.toString(
      this,
      this.state.getKartCariAdresBilgi?.length > 0 ? this.state.getKartCariAdresBilgi[0]?.adres : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  CariAdreslerComponent_608676_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Adres_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "cariadresler_953737_value", "value", "", "", "")
        ),
        "string"
      ),
      AdresTanim_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "cariadresler_821142_value", "value", "", "", "")
        ),
        "string"
      ),
      CariID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmCariID ?? this.props.screenInputs.prmcariid,
        "Guid"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "CariAdresler/CariAdreslerComponent_608676_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.setKartCariAdresBilgileriSave = result?.data.setKartCariAdresBilgileriSave;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.CariAdreslerComponent_608676_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  CariAdreslerComponent_608676_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CariAdreslerComponent_851358_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
