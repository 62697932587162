import { Form, Spin, Typography } from "antd";
import { connect } from "react-redux";
import {
  Button,
  Header,
  HorizontalStack,
  Icon,
  Image,
  KCol,
  KDrawer,
  KRow,
  KTable,
  KTableColumn,
  KTableHeader,
  KTableRow,
  Label,
  MultiSelect,
  NumberInput,
  TextArea,
  TextInput
} from "../../components/web-components";
import { KSelectBox, Spacer } from "../../kuika";
import {
  hideIyzicoDrawer,
  hidePhotoTake,
  hideStripeDrawer,
  showIyzicoDrawer,
  showPhotoTake,
  showStripeDrawer
} from "../../redux/photo-take/actions";
import { AppState } from "../../redux/root-reducer";
import { withContext } from "../../shared/hoc/with-context";
import { withForm } from "../../shared/hoc/with-form";
import { withGoogleLogin } from "../../shared/hoc/with-google-login";
import { withHistory } from "../../shared/hoc/with-history";
import { withScreenInput } from "../../shared/hoc/with-screen-input";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";
import { EserSahibiKart_ScreenBase } from "./esersahibikart-base";

import { BaglantiliMeslekBirligiKart_Screen } from "../baglantilimeslekbirligikart/baglantilimeslekbirligikart";
import { CariSozlesmeler_Screen } from "../carisozlesmeler/carisozlesmeler";
import { EserSahibiPayDagilimKart_Screen } from "../esersahibipaydagilimkart/esersahibipaydagilimkart";
import { LeftMenu_Screen } from "../leftmenu/leftmenu";

declare let window: any;
const { Title, Paragraph, Text, Link } = Typography;
const menuMap = [];

class EserSahibiKart_Screen extends EserSahibiKart_ScreenBase {
  render() {
    if (this.state.isPageVisible === false) {
      return <></>;
    }
    return (
      <>
        <Spin spinning={this.state.spinnerCount > 0}>
          <Form
            form={this.props.form}
            name="basic"
            initialValues={{
              esersahibikart_889558_value: "283ac3c9-74b0-40c1-7273-08dbdfa0a8af",
              esersahibikart_887861_value: this.state.getEserSahibiKartById?.at?.(0)?.cariID ?? undefined,
              esersahibikart_802808_value: this.state.getEserSahibiKartById?.at?.(0)?.ad ?? undefined,
              esersahibikart_173681_value: this.state.getEserSahibiKartById?.at?.(0)?.soyad ?? undefined,
              esersahibikart_7163_value: this.state.getEserSahibiKartById?.at?.(0)?.ozelKod1 ?? undefined,
              esersahibikart_1186_value: this.state.getEserSahibiKartById?.at?.(0)?.ozelKod2 ?? undefined,
              esersahibikart_874267_value: this.state.getEserSahibiKartById?.at?.(0)?.takmaAd ?? undefined,
              esersahibikart_146536_value: this.state.getEserSahibiKartById?.at?.(0)?.baglantiliBolumIDs ?? undefined,
              esersahibikart_316562_value: this.state.getEserSahibiKartById?.at?.(0)?.dogumYil ?? undefined,
              esersahibikart_756752_value: this.state.getEserSahibiKartById?.at?.(0)?.olumYil ?? undefined,
              esersahibikart_708965_value: this.state.getEserSahibiKartById?.at?.(0)?.uyruk ?? undefined,
              esersahibikart_331991_value: this.state.getEserSahibiKartById?.at?.(0)?.diller ?? undefined,
              esersahibikart_846368_value: this.state.getEserSahibiKartById?.at?.(0)?.onemliNot ?? undefined,
              esersahibikart_419041_value: this.state.getEserSahibiKartById?.at?.(0)?.muzMPayOran ?? undefined,
              esersahibikart_979658_value: this.state.getEserSahibiKartById?.at?.(0)?.muzTPayOran ?? undefined
            }}
          >
            <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
              <Header
                id="esersahibikart_header"
                style={
                  {
                    borderStyle: "solid",
                    borderTopWidth: 0,
                    borderRightWidth: 0,
                    borderBottomWidth: 0,
                    borderLeftWidth: 0,
                    backgroundColor: "rgba(255, 255, 255, 1)",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                    backgroundPosition: "left"
                  } as any
                }
              >
                <KRow
                  id="658116"
                  align="top"
                  justify="start"
                  horizontalGutter={0}
                  verticalGutter={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "contain",
                      backgroundPosition: "left",
                      paddingTop: 16,
                      paddingBottom: 16,
                      alignItems: "center",
                      fontWeight: 500,
                      fontSize: "14px",
                      letterSpacing: "0.5px"
                    } as any
                  }
                >
                  <KCol
                    id="572685"
                    xs={11}
                    sm={11}
                    md={11}
                    lg={11}
                    xl={11}
                    xxl={11}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingRight: 16,
                        paddingLeft: 16,
                        fontWeight: 500,
                        fontSize: "14px",
                        letterSpacing: "0.5px"
                      } as any
                    }
                  >
                    <HorizontalStack
                      id="764508"
                      direction="horizontal"
                      size={2}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 0,
                          paddingRight: 0,
                          paddingBottom: 0,
                          paddingLeft: 0,
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "14px",
                          color: "rgba(68, 68, 68, 1)",
                          letterSpacing: "0.5px"
                        } as any
                      }
                    >
                      <Icon
                        id="83067"
                        onClick={(e?: any) => {
                          if (e && e.stopPropagation) e.stopPropagation();
                          this.EserSahibiKartComponent_83067_onClick();
                        }}
                        showCursorPointer
                        iconName="menu"
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "left",
                            fontSize: "24px",
                            color: "rgba(172, 38, 42, 1)"
                          } as any
                        }
                      ></Icon>

                      <Form.Item className="kFormItem" name="esersahibikart_889558_value">
                        <Image
                          id="889558"
                          placeHolderImage="data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAASABIAAD/7QA4UGhvdG9zaG9wIDMuMAA4QklNBAQAAAAAAAA4QklNBCUAAAAAABDUHYzZjwCyBOmACZjs+EJ+/9sAQwABAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEB/8AACwgANgBgAQERAP/EABkAAQADAQEAAAAAAAAAAAAAAAAEBQYHCv/EACoQAAEEAgMBAAADCQAAAAAAAAIAAwQFAQYREhMUByEiJDEzNjd1drS1/9oACAEBAAA/APWAiIiIiIiIiIiIiIiIiIqTZrU6HXNgvGmQkO01Ja2rbDhZAHzroD8wGTMeSAHCawBEOM5HBFnHOccKmbpdyyAZe3Zv2yI+vhrVeDHpxjv4g9JfdBrtz5i6+84IcYN1wsZMsq9c25FeRqnf4064oIdhNODN1IW4EvNSWG5zDcttyCE9qLKy3XWjlRPecqpb4MTMsysgyfUq6X99fAnefl9sOLL8u3fz+lgHvPv1Ht079e3Ue3HPGOeFMRERERY78RP6f71/h2z/APEnKlnWG4VVfI2+xkw40SL3lS9Od+BtqPTYHH6BvCHDh7QP8XqUrNG88WKdoBzlu7Wcfk1dY1d3kzU5Ott7TWXLNbbS7CU/jD9jg5BRbKnex8unzdkl4ZneMbsNrPFlu5dZvMx4jvVdc/l6h/s1X/osK5RERERV1xVx7uotKWWTgxbeunVcomSEXhjz4zsR4miITEXMNullssgY4LjORLGM4WAk6hu8+XVS7LctcsBpwA40OTo9h8Dtk3x53UuNG3mOMiyaxgvm7fsMNwykQ4LEkQfCxs9e3O6rp1RZbLqxV9nFfgzcRdHsG5XzSWyadzGcm7xZQ25AgWSYckwJjLbuANyM8I5AtvEjNQosaGx28IkdmMz2z2LyYbFpvsXGOxdAxznGMc5/PjH7lIREREREREREREREREREX//Z"
                          zoomOnClick={false}
                          imageFit="fit"
                          style={
                            {
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingLeft: 16,
                              height: "24px"
                            } as any
                          }
                        ></Image>
                      </Form.Item>
                    </HorizontalStack>
                  </KCol>

                  <KCol
                    id="55603"
                    xs={11}
                    sm={11}
                    md={11}
                    lg={11}
                    xl={11}
                    xxl={11}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "contain",
                        backgroundPosition: "left",
                        paddingRight: 16,
                        paddingLeft: 16,
                        alignItems: "center",
                        textAlign: "-webkit-left",
                        fontWeight: 500,
                        fontSize: "14px",
                        letterSpacing: "0.5px"
                      } as any
                    }
                  >
                    <Label
                      id="785916"
                      value={ReactSystemFunctions.translate(this.ml, 785916, "value", this.defaultML)}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "contain",
                          backgroundPosition: "left",
                          fontFamily: "Poppins",
                          fontWeight: 600,
                          fontSize: "18px",
                          color: "rgba(183, 14, 14, 1)"
                        } as any
                      }
                    ></Label>
                  </KCol>

                  <KCol
                    id="659324"
                    xs={2}
                    sm={2}
                    md={2}
                    lg={2}
                    xl={2}
                    xxl={2}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "contain",
                        backgroundPosition: "left",
                        paddingRight: 16,
                        paddingLeft: 16,
                        textAlign: "-webkit-right",
                        fontWeight: 500,
                        fontSize: "14px",
                        letterSpacing: "0.5px"
                      } as any
                    }
                  >
                    <Icon
                      id="996082"
                      onClick={(e?: any) => {
                        if (e && e.stopPropagation) e.stopPropagation();
                        this.EserSahibiKartComponent_996082_onClick();
                      }}
                      showCursorPointer
                      iconName="close"
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "contain",
                          backgroundPosition: "left",
                          fontSize: "24px",
                          color: "rgba(172, 38, 42, 1)"
                        } as any
                      }
                    ></Icon>
                  </KCol>
                </KRow>
              </Header>

              <div
                id="esersahibikart_body"
                style={
                  {
                    borderStyle: "solid",
                    borderTopWidth: 0,
                    borderRightWidth: 0,
                    borderBottomWidth: 0,
                    borderLeftWidth: 0,
                    backgroundImage:
                      "url(" +
                      KuikaAppManager.GetBackendUrl() +
                      "/resource/runtime/byid?resourceId=31886a70-856c-4afc-8e82-08da211d182d)",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    display: "block"
                  } as any
                }
              >
                <KRow
                  id="595200"
                  align="top"
                  justify="start"
                  horizontalGutter={0}
                  verticalGutter={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "contain",
                      backgroundPosition: "left",
                      paddingTop: 48,
                      paddingBottom: 48,
                      fontWeight: 500,
                      fontSize: "14px",
                      letterSpacing: "0.5px"
                    } as any
                  }
                >
                  <KCol
                    id="967480"
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={24}
                    xxl={24}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "contain",
                        backgroundPosition: "left",
                        paddingRight: 16,
                        paddingLeft: 16,
                        alignItems: "center",
                        textAlign: "-webkit-center",
                        fontWeight: 500,
                        fontSize: "14px",
                        letterSpacing: "0.5px"
                      } as any
                    }
                  >
                    <KRow
                      id="243207"
                      align="top"
                      justify="start"
                      horizontalGutter={0}
                      verticalGutter={0}
                      style={
                        {
                          borderTopLeftRadius: 12,
                          borderTopRightRadius: 12,
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          backgroundColor: "rgba(255, 255, 255, 1)",
                          paddingTop: 16,
                          paddingRight: 18,
                          paddingBottom: 16,
                          paddingLeft: 18,
                          alignItems: "flex-start",
                          textAlign: "-webkit-left",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "14px",
                          color: "rgba(68, 68, 68, 1)",
                          letterSpacing: "0.5px"
                        } as any
                      }
                    >
                      <KCol
                        id="376827"
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        xxl={12}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderRightWidth: 1,
                            borderColor: "rgba(155, 155, 155, 1)",
                            borderStyle: "dotted",
                            borderTopWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "left",
                            paddingRight: 16,
                            paddingLeft: 16,
                            alignItems: "flex-start",
                            fontWeight: 500,
                            fontSize: "14px",
                            letterSpacing: "0.5px"
                          } as any
                        }
                      >
                        <KRow
                          id="644853"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(68, 68, 68, 1)",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="18003"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={24}
                            xxl={24}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingRight: 16,
                                paddingLeft: 16,
                                textAlign: "-webkit-left",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(68, 68, 68, 1)",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <KRow
                              id="202488"
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            >
                              <KCol
                                id="53449"
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                xxl={12}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Label
                                  id="965590"
                                  value={ReactSystemFunctions.translate(this.ml, 965590, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "12px",
                                      color: "rgba(172, 38, 42, 1)"
                                    } as any
                                  }
                                ></Label>
                              </KCol>

                              <KCol
                                id="388420"
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                xxl={12}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <KRow
                                  id="730142"
                                  onClick={(e?: any) => {
                                    if (e && e.stopPropagation) e.stopPropagation();
                                    this.EserSahibiKartComponent_730142_onClick();
                                  }}
                                  showCursorPointer
                                  align="top"
                                  justify="start"
                                  horizontalGutter={0}
                                  verticalGutter={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                >
                                  <KCol
                                    id="45052"
                                    xs={22}
                                    sm={22}
                                    md={22}
                                    lg={22}
                                    xl={22}
                                    xxl={22}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-right",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  >
                                    <Label
                                      id="894883"
                                      onClick={(e?: any) => {
                                        if (e && e.stopPropagation) e.stopPropagation();
                                        this.EserSahibiKartComponent_894883_onClick();
                                      }}
                                      showCursorPointer
                                      value={ReactSystemFunctions.translate(this.ml, 894883, "value", this.defaultML)}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "12px",
                                          color: "rgba(47, 47, 47, 1)"
                                        } as any
                                      }
                                    ></Label>
                                  </KCol>

                                  <KCol
                                    id="578866"
                                    xs={2}
                                    sm={2}
                                    md={2}
                                    lg={2}
                                    xl={2}
                                    xxl={2}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        textAlign: "-webkit-right",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  >
                                    <Icon
                                      id="809132"
                                      iconName="navigate_next"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          color: "rgba(172, 38, 42, 1)"
                                        } as any
                                      }
                                    ></Icon>
                                  </KCol>
                                </KRow>
                              </KCol>
                            </KRow>

                            <Form.Item className="kFormItem" name="esersahibikart_887861_value">
                              <KSelectBox
                                id="887861"
                                kuikaRef={this.esersahibikart_887861_value_kuikaSelectBoxRef}
                                options={this.state.sbCariList}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  887861,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={false}
                                autoClearSearchValue={true}
                                showSearch={true}
                                onSearch={{}}
                                widthType="fill"
                                containsNullItem={false}
                                sortBy="none"
                                datavaluefield="id"
                                datatextfield="cariAd"
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(155, 155, 155, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "36px",
                                    display: "block",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)"
                                  } as any
                                }
                              ></KSelectBox>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="329794"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 16,
                              paddingBottom: 16,
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="405389"
                            xs={9}
                            sm={9}
                            md={9}
                            lg={9}
                            xl={9}
                            xxl={9}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                paddingRight: 16,
                                paddingLeft: 16,
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="40473"
                              value={ReactSystemFunctions.translate(this.ml, 40473, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(172, 38, 42, 1)"
                                } as any
                              }
                            ></Label>

                            <Form.Item className="kFormItem" name="esersahibikart_802808_value">
                              <TextInput
                                id="802808"
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  802808,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={false}
                                bordered={true}
                                disabled={false}
                                type="text"
                                iconColor="rgba(0, 0, 0, 1)"
                                formatter="None"
                                isCharOnly={false}
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(155, 155, 155, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "36px",
                                    display: "block",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)"
                                  } as any
                                }
                              ></TextInput>
                            </Form.Item>
                          </KCol>

                          <KCol
                            id="438659"
                            xs={9}
                            sm={9}
                            md={9}
                            lg={9}
                            xl={9}
                            xxl={9}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                textAlign: "-webkit-left",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(68, 68, 68, 1)",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="973778"
                              value={ReactSystemFunctions.translate(this.ml, 973778, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(172, 38, 42, 1)"
                                } as any
                              }
                            ></Label>

                            <Form.Item className="kFormItem" name="esersahibikart_173681_value">
                              <TextInput
                                id="173681"
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  173681,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={false}
                                bordered={true}
                                disabled={false}
                                type="text"
                                iconColor="rgba(0, 0, 0, 1)"
                                formatter="None"
                                isCharOnly={false}
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(155, 155, 155, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "36px",
                                    display: "block",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)"
                                  } as any
                                }
                              ></TextInput>
                            </Form.Item>
                          </KCol>

                          <KCol
                            id="135386"
                            xs={3}
                            sm={3}
                            md={3}
                            lg={3}
                            xl={3}
                            xxl={3}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(68, 68, 68, 1)",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="118332"
                              value={ReactSystemFunctions.translate(this.ml, 118332, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(172, 38, 42, 1)"
                                } as any
                              }
                            ></Label>

                            <Form.Item className="kFormItem" name="esersahibikart_7163_value">
                              <TextInput
                                id="7163"
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  7163,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={false}
                                bordered={true}
                                disabled={false}
                                type="text"
                                iconColor="rgba(0, 0, 0, 1)"
                                formatter="None"
                                isCharOnly={false}
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(155, 155, 155, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "36px",
                                    display: "block",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)"
                                  } as any
                                }
                              ></TextInput>
                            </Form.Item>
                          </KCol>

                          <KCol
                            id="697229"
                            xs={3}
                            sm={3}
                            md={3}
                            lg={3}
                            xl={3}
                            xxl={3}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(68, 68, 68, 1)",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="973128"
                              value={ReactSystemFunctions.translate(this.ml, 973128, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(172, 38, 42, 1)"
                                } as any
                              }
                            ></Label>

                            <Form.Item className="kFormItem" name="esersahibikart_1186_value">
                              <TextInput
                                id="1186"
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  1186,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={false}
                                bordered={true}
                                disabled={false}
                                type="text"
                                iconColor="rgba(0, 0, 0, 1)"
                                formatter="None"
                                isCharOnly={false}
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(155, 155, 155, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "36px",
                                    display: "block",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)"
                                  } as any
                                }
                              ></TextInput>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="658573"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(68, 68, 68, 1)",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="745914"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={24}
                            xxl={24}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(68, 68, 68, 1)",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <KRow
                              id="851266"
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 16,
                                  paddingBottom: 16,
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            >
                              <KCol
                                id="846846"
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                xxl={12}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    paddingRight: 16,
                                    paddingLeft: 16,
                                    textAlign: "-webkit-left",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Label
                                  id="628958"
                                  value={ReactSystemFunctions.translate(this.ml, 628958, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "12px",
                                      color: "rgba(172, 38, 42, 1)"
                                    } as any
                                  }
                                ></Label>

                                <Form.Item className="kFormItem" name="esersahibikart_874267_value">
                                  <TextInput
                                    id="874267"
                                    placeholder={ReactSystemFunctions.translate(
                                      this.ml,
                                      874267,
                                      "placeholder",
                                      this.defaultML
                                    )}
                                    allowClear={false}
                                    bordered={true}
                                    disabled={false}
                                    type="text"
                                    iconColor="rgba(0, 0, 0, 1)"
                                    formatter="None"
                                    isCharOnly={false}
                                    style={
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: "rgba(155, 155, 155, 1)",
                                        borderStyle: "dotted",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        height: "36px",
                                        display: "block",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(47, 47, 47, 1)",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  ></TextInput>
                                </Form.Item>
                              </KCol>

                              <KCol
                                id="897271"
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                xxl={12}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    paddingRight: 16,
                                    paddingLeft: 16,
                                    textAlign: "-webkit-left",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Label
                                  id="820510"
                                  value={ReactSystemFunctions.translate(this.ml, 820510, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "12px",
                                      color: "rgba(172, 38, 42, 1)"
                                    } as any
                                  }
                                ></Label>

                                <Form.Item className="kFormItem" name="esersahibikart_146536_value">
                                  <MultiSelect
                                    id="146536"
                                    options={this.state.sbBolumList}
                                    allowClear={false}
                                    autoClearSearchValue={true}
                                    mode="tags"
                                    showSearch={true}
                                    autoRefresh={true}
                                    datavaluefield="id"
                                    datatextfield="tanim"
                                    style={
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: "rgba(155, 155, 155, 1)",
                                        borderStyle: "dotted",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderLeftWidth: 0,
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(47, 47, 47, 1)",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  ></MultiSelect>
                                </Form.Item>
                              </KCol>
                            </KRow>

                            <KRow
                              id="920238"
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 16,
                                  paddingBottom: 16,
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            >
                              <KCol
                                id="987719"
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                xxl={12}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    paddingRight: 16,
                                    paddingLeft: 16,
                                    textAlign: "-webkit-left",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Label
                                  id="128583"
                                  value={ReactSystemFunctions.translate(this.ml, 128583, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "12px",
                                      color: "rgba(172, 38, 42, 1)"
                                    } as any
                                  }
                                ></Label>

                                <Form.Item className="kFormItem" name="esersahibikart_316562_value">
                                  <TextInput
                                    id="316562"
                                    placeholder={ReactSystemFunctions.translate(
                                      this.ml,
                                      316562,
                                      "placeholder",
                                      this.defaultML
                                    )}
                                    allowClear={false}
                                    bordered={true}
                                    disabled={false}
                                    type="text"
                                    iconColor="rgba(0, 0, 0, 1)"
                                    formatter="None"
                                    isCharOnly={false}
                                    style={
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: "rgba(155, 155, 155, 1)",
                                        borderStyle: "dotted",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        height: "36px",
                                        display: "block",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(47, 47, 47, 1)",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  ></TextInput>
                                </Form.Item>
                              </KCol>

                              <KCol
                                id="156033"
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                xxl={12}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    paddingRight: 16,
                                    paddingLeft: 16,
                                    textAlign: "-webkit-left",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Label
                                  id="465804"
                                  value={ReactSystemFunctions.translate(this.ml, 465804, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "12px",
                                      color: "rgba(172, 38, 42, 1)"
                                    } as any
                                  }
                                ></Label>

                                <Form.Item className="kFormItem" name="esersahibikart_756752_value">
                                  <TextInput
                                    id="756752"
                                    placeholder={ReactSystemFunctions.translate(
                                      this.ml,
                                      756752,
                                      "placeholder",
                                      this.defaultML
                                    )}
                                    allowClear={false}
                                    bordered={true}
                                    disabled={false}
                                    type="text"
                                    iconColor="rgba(0, 0, 0, 1)"
                                    formatter="None"
                                    isCharOnly={false}
                                    style={
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: "rgba(155, 155, 155, 1)",
                                        borderStyle: "dotted",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        height: "36px",
                                        display: "block",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(47, 47, 47, 1)"
                                      } as any
                                    }
                                  ></TextInput>
                                </Form.Item>
                              </KCol>
                            </KRow>

                            <KRow
                              id="739876"
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 16,
                                  paddingBottom: 16,
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            >
                              <KCol
                                id="935975"
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                xxl={12}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    paddingRight: 16,
                                    paddingLeft: 16,
                                    textAlign: "-webkit-left",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Label
                                  id="674664"
                                  value={ReactSystemFunctions.translate(this.ml, 674664, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "12px",
                                      color: "rgba(172, 38, 42, 1)"
                                    } as any
                                  }
                                ></Label>

                                <Form.Item className="kFormItem" name="esersahibikart_708965_value">
                                  <TextInput
                                    id="708965"
                                    placeholder={ReactSystemFunctions.translate(
                                      this.ml,
                                      708965,
                                      "placeholder",
                                      this.defaultML
                                    )}
                                    allowClear={false}
                                    bordered={true}
                                    disabled={false}
                                    type="text"
                                    iconColor="rgba(0, 0, 0, 1)"
                                    formatter="None"
                                    isCharOnly={false}
                                    style={
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: "rgba(155, 155, 155, 1)",
                                        borderStyle: "dotted",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        height: "36px",
                                        display: "block",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(47, 47, 47, 1)"
                                      } as any
                                    }
                                  ></TextInput>
                                </Form.Item>
                              </KCol>

                              <KCol
                                id="975406"
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                xxl={12}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    paddingRight: 16,
                                    paddingLeft: 16,
                                    textAlign: "-webkit-left",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Label
                                  id="904704"
                                  value={ReactSystemFunctions.translate(this.ml, 904704, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "12px",
                                      color: "rgba(172, 38, 42, 1)"
                                    } as any
                                  }
                                ></Label>

                                <Form.Item className="kFormItem" name="esersahibikart_331991_value">
                                  <TextInput
                                    id="331991"
                                    placeholder={ReactSystemFunctions.translate(
                                      this.ml,
                                      331991,
                                      "placeholder",
                                      this.defaultML
                                    )}
                                    allowClear={false}
                                    bordered={true}
                                    disabled={false}
                                    type="text"
                                    iconColor="rgba(0, 0, 0, 1)"
                                    formatter="None"
                                    isCharOnly={false}
                                    style={
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: "rgba(155, 155, 155, 1)",
                                        borderStyle: "dotted",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        height: "36px",
                                        display: "block",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(47, 47, 47, 1)"
                                      } as any
                                    }
                                  ></TextInput>
                                </Form.Item>
                              </KCol>
                            </KRow>
                          </KCol>
                        </KRow>

                        <KRow
                          id="378693"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 16,
                              paddingBottom: 16,
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="21079"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={24}
                            xxl={24}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                paddingRight: 16,
                                paddingLeft: 16,
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="987639"
                              value={ReactSystemFunctions.translate(this.ml, 987639, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(172, 38, 42, 1)"
                                } as any
                              }
                            ></Label>

                            <Form.Item className="kFormItem" name="esersahibikart_846368_value">
                              <TextArea
                                id="846368"
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  846368,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={false}
                                autoSize={false}
                                showCount={false}
                                formatter="None"
                                readOnly={false}
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(155, 155, 155, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    display: "block",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)"
                                  } as any
                                }
                              ></TextArea>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="666778"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 8,
                              paddingRight: 8,
                              paddingBottom: 8,
                              paddingLeft: 8,
                              alignItems: "flex-start",
                              textAlign: "-webkit-left",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(68, 68, 68, 1)",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="153642"
                            xs={8}
                            sm={8}
                            md={8}
                            lg={8}
                            xl={8}
                            xxl={8}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                alignItems: "flex-start",
                                textAlign: "-webkit-left",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(68, 68, 68, 1)",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="67847"
                              value={ReactSystemFunctions.translate(this.ml, 67847, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(172, 38, 42, 1)"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="758966"
                            xs={8}
                            sm={8}
                            md={8}
                            lg={8}
                            xl={8}
                            xxl={8}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                alignItems: "flex-start",
                                textAlign: "-webkit-left",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(68, 68, 68, 1)",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="250412"
                              value={ReactSystemFunctions.translate(this.ml, 250412, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(172, 38, 42, 1)"
                                } as any
                              }
                            ></Label>

                            <Form.Item className="kFormItem" name="esersahibikart_419041_value">
                              <NumberInput
                                id="419041"
                                decimalSeparator="."
                                decimalScale={2}
                                disabled={false}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  419041,
                                  "placeholder",
                                  this.defaultML
                                )}
                                fixedDecimalScale={false}
                                allowNegative={true}
                                allowEmptyFormatting={false}
                                isThousandSeperatorOn={true}
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(221, 219, 219, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundColor: "rgba(255, 255, 255, 1)",
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "36px",
                                    textAlign: "-webkit-center",
                                    display: "block",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)"
                                  } as any
                                }
                              ></NumberInput>
                            </Form.Item>
                          </KCol>

                          <KCol
                            id="498513"
                            xs={8}
                            sm={8}
                            md={8}
                            lg={8}
                            xl={8}
                            xxl={8}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                alignItems: "flex-start",
                                textAlign: "-webkit-left",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(68, 68, 68, 1)",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="212873"
                              value={ReactSystemFunctions.translate(this.ml, 212873, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(172, 38, 42, 1)"
                                } as any
                              }
                            ></Label>

                            <Form.Item className="kFormItem" name="esersahibikart_979658_value">
                              <NumberInput
                                id="979658"
                                decimalSeparator="."
                                decimalScale={2}
                                disabled={false}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  979658,
                                  "placeholder",
                                  this.defaultML
                                )}
                                fixedDecimalScale={false}
                                allowNegative={true}
                                allowEmptyFormatting={false}
                                isThousandSeperatorOn={true}
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(221, 219, 219, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundColor: "rgba(255, 255, 255, 1)",
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "36px",
                                    textAlign: "-webkit-center",
                                    display: "block",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)"
                                  } as any
                                }
                              ></NumberInput>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="162363"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 48,
                              paddingBottom: 48,
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="650956"
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                paddingRight: 16,
                                paddingLeft: 16,
                                alignItems: "center",
                                textAlign: "-webkit-center",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Button
                              id="861691"
                              onClick={(e?: any) => {
                                if (e && e.stopPropagation) e.stopPropagation();
                                this.EserSahibiKartComponent_861691_onClick();
                              }}
                              showCursorPointer
                              label={ReactSystemFunctions.translate(this.ml, 861691, "label", this.defaultML)}
                              size="middle"
                              loading={false}
                              ghost={false}
                              block={false}
                              htmlType="button"
                              iconPosition="left"
                              danger={false}
                              style={
                                {
                                  borderTopLeftRadius: 32,
                                  borderTopRightRadius: 32,
                                  borderBottomRightRadius: 32,
                                  borderBottomLeftRadius: 32,
                                  borderTopWidth: 1,
                                  borderRightWidth: 1,
                                  borderBottomWidth: 1,
                                  borderLeftWidth: 1,
                                  borderColor: "rgba(172, 38, 42, 1)",
                                  borderStyle: "solid",
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  paddingTop: 8,
                                  paddingBottom: 8,
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(172, 38, 42, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Button>
                          </KCol>

                          <KCol
                            id="589969"
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingRight: 16,
                                paddingLeft: 16,
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Button
                              id="739020"
                              onClick={(e?: any) => {
                                if (e && e.stopPropagation) e.stopPropagation();
                                this.EserSahibiKartComponent_739020_onClick();
                              }}
                              showCursorPointer
                              label={ReactSystemFunctions.translate(this.ml, 739020, "label", this.defaultML)}
                              size="middle"
                              loading={false}
                              ghost={false}
                              block={false}
                              htmlType="button"
                              iconPosition="right"
                              danger={false}
                              style={
                                {
                                  borderTopLeftRadius: 32,
                                  borderTopRightRadius: 32,
                                  borderBottomRightRadius: 32,
                                  borderBottomLeftRadius: 32,
                                  borderTopWidth: 1,
                                  borderRightWidth: 1,
                                  borderBottomWidth: 1,
                                  borderLeftWidth: 1,
                                  borderColor: "rgba(172, 38, 42, 1)",
                                  borderStyle: "solid",
                                  backgroundColor: "rgba(172, 38, 42, 1)",
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  paddingTop: 8,
                                  paddingBottom: 8,
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(255, 255, 255, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Button>

                            <Button
                              id="356553"
                              onClick={(e?: any) => {
                                if (e && e.stopPropagation) e.stopPropagation();
                                this.EserSahibiKartComponent_356553_onClick();
                              }}
                              showCursorPointer
                              visibility={this.state.isComp356553Visible}
                              label={ReactSystemFunctions.translate(this.ml, 356553, "label", this.defaultML)}
                              size="middle"
                              loading={false}
                              ghost={false}
                              block={false}
                              htmlType="button"
                              iconPosition="right"
                              danger={false}
                              style={
                                {
                                  borderTopLeftRadius: 32,
                                  borderTopRightRadius: 32,
                                  borderBottomRightRadius: 32,
                                  borderBottomLeftRadius: 32,
                                  borderTopWidth: 1,
                                  borderRightWidth: 1,
                                  borderBottomWidth: 1,
                                  borderLeftWidth: 1,
                                  borderColor: "rgba(172, 38, 42, 1)",
                                  borderStyle: "solid",
                                  backgroundColor: "rgba(172, 38, 42, 1)",
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  paddingTop: 8,
                                  paddingBottom: 8,
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(255, 255, 255, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Button>
                          </KCol>
                        </KRow>
                      </KCol>

                      <KCol
                        id="943517"
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        xxl={12}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "left",
                            paddingRight: 16,
                            paddingLeft: 16,
                            alignItems: "flex-start",
                            fontWeight: 500,
                            fontSize: "14px",
                            letterSpacing: "0.5px"
                          } as any
                        }
                      >
                        <KRow
                          id="476082"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(68, 68, 68, 1)",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="11807"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={24}
                            xxl={24}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(68, 68, 68, 1)",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <KRow
                              id="69557"
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            >
                              <KCol
                                id="198904"
                                xs={20}
                                sm={20}
                                md={20}
                                lg={20}
                                xl={20}
                                xxl={20}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Label
                                  id="823422"
                                  value={ReactSystemFunctions.translate(this.ml, 823422, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "12px",
                                      color: "rgba(172, 38, 42, 1)"
                                    } as any
                                  }
                                ></Label>
                              </KCol>

                              <KCol
                                id="122516"
                                xs={4}
                                sm={4}
                                md={4}
                                lg={4}
                                xl={4}
                                xxl={4}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "flex-start",
                                    textAlign: "-webkit-right",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Icon
                                  id="174347"
                                  onClick={(e?: any) => {
                                    if (e && e.stopPropagation) e.stopPropagation();
                                    this.EserSahibiKartComponent_174347_onClick();
                                  }}
                                  showCursorPointer
                                  iconName="add_circle"
                                  hoverText="Yetkili Temsilcisi / Varis"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 16,
                                      fontSize: "24px",
                                      color: "rgba(172, 38, 42, 1)"
                                    } as any
                                  }
                                ></Icon>
                              </KCol>
                            </KRow>

                            <KRow
                              id="352598"
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            >
                              <KCol
                                id="801124"
                                xs={24}
                                sm={24}
                                md={24}
                                lg={24}
                                xl={24}
                                xxl={24}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <KTable
                                  id="456888"
                                  kuikaRef={this.esersahibikart_456888_value_kuikaTableRef}
                                  form={this.props.form}
                                  dataSource={this.state.getEserSahibiPayDagilimListByESId}
                                  size="middle"
                                  bordered={true}
                                  showHeader={true}
                                  loading={false}
                                  nodatafoundmessage={ReactSystemFunctions.translate(
                                    this.ml,
                                    456888,
                                    "nodatafoundmessage",
                                    this.defaultML
                                  )}
                                  sorter={false}
                                  pagination={false}
                                  hideTableOnEmpty={true}
                                  striped={false}
                                  stripedColor="#F5F7FA"
                                  insertRowActive={false}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(155, 155, 155, 1)",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                >
                                  <KTableHeader
                                    id="65525"
                                    hideOnMobileResolution={false}
                                    textDirection="Default"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        fontFamily: "Poppins",
                                        fontWeight: 400,
                                        fontSize: "11px",
                                        color: "rgba(172, 38, 42, 1)",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  ></KTableHeader>

                                  <KTableRow
                                    id="534160"
                                    hoverFontColor="red"
                                    hoverBgColor="yellow"
                                    style={
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: "rgba(155, 155, 155, 1)",
                                        borderStyle: "dotted",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  >
                                    <KTableColumn
                                      id="195018"
                                      title={ReactSystemFunctions.translate(this.ml, 195018, "title", this.defaultML)}
                                      alwaysVisibleOnMobileResolution={false}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          backgroundColor: "rgba(255, 255, 255, 1)",
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          paddingTop: 8,
                                          paddingBottom: 8,
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          letterSpacing: "0.5px"
                                        } as any
                                      }
                                    >
                                      <Label
                                        id="820539"
                                        onClick={(e?: any) => {
                                          if (e && e.stopPropagation) e.stopPropagation();
                                          this.EserSahibiKartComponent_820539_onClick();
                                        }}
                                        showCursorPointer
                                        value="[datafield:cariAd]"
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            backgroundPosition: "left",
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 0,
                                            paddingLeft: 0,
                                            display: "inline",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "12px",
                                            color: "rgba(74, 144, 226, 1)",
                                            textDecoration: "underline"
                                          } as any
                                        }
                                      ></Label>
                                    </KTableColumn>

                                    <KTableColumn
                                      id="740979"
                                      title={ReactSystemFunctions.translate(this.ml, 740979, "title", this.defaultML)}
                                      alwaysVisibleOnMobileResolution={false}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          backgroundColor: "rgba(255, 255, 255, 1)",
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          paddingTop: 8,
                                          paddingBottom: 8,
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          letterSpacing: "0.5px"
                                        } as any
                                      }
                                    >
                                      <Label
                                        id="363090"
                                        value="[datafield:gorevi]"
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            backgroundPosition: "left",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "12px",
                                            color: "rgba(47, 47, 47, 1)"
                                          } as any
                                        }
                                      ></Label>
                                    </KTableColumn>

                                    <KTableColumn
                                      id="822894"
                                      title={ReactSystemFunctions.translate(this.ml, 822894, "title", this.defaultML)}
                                      alwaysVisibleOnMobileResolution={false}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          backgroundColor: "rgba(255, 255, 255, 1)",
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          paddingTop: 8,
                                          paddingBottom: 8,
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          letterSpacing: "0.5px"
                                        } as any
                                      }
                                    >
                                      <Label
                                        id="612367"
                                        value="[datafield:hakedisOran]"
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            backgroundPosition: "left",
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 0,
                                            paddingLeft: 0,
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "12px",
                                            color: "rgba(47, 47, 47, 1)"
                                          } as any
                                        }
                                      ></Label>
                                    </KTableColumn>

                                    <KTableColumn
                                      id="200012"
                                      title={ReactSystemFunctions.translate(this.ml, 200012, "title", this.defaultML)}
                                      alwaysVisibleOnMobileResolution={false}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          backgroundColor: "rgba(255, 255, 255, 1)",
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          paddingTop: 8,
                                          paddingBottom: 8,
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          letterSpacing: "0.5px"
                                        } as any
                                      }
                                    >
                                      <Label
                                        id="774189"
                                        value="[datafield:muzOran]"
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            backgroundPosition: "left",
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 0,
                                            paddingLeft: 0,
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "12px",
                                            color: "rgba(47, 47, 47, 1)"
                                          } as any
                                        }
                                      ></Label>
                                    </KTableColumn>

                                    <KTableColumn
                                      id="708387"
                                      title={ReactSystemFunctions.translate(this.ml, 708387, "title", this.defaultML)}
                                      alwaysVisibleOnMobileResolution={false}
                                      style={
                                        {
                                          paddingTop: 8,
                                          paddingBottom: 8,
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(68, 68, 68, 1)",
                                          letterSpacing: "0.5px"
                                        } as any
                                      }
                                    >
                                      <Label
                                        id="232750"
                                        value="[datafield:muzOranTemsili]"
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            backgroundPosition: "left",
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 0,
                                            paddingLeft: 0,
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "12px",
                                            color: "rgba(47, 47, 47, 1)"
                                          } as any
                                        }
                                      ></Label>
                                    </KTableColumn>

                                    <KTableColumn
                                      id="913866"
                                      title={ReactSystemFunctions.translate(this.ml, 913866, "title", this.defaultML)}
                                      alwaysVisibleOnMobileResolution={false}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          backgroundColor: "rgba(255, 255, 255, 1)",
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          paddingTop: 8,
                                          paddingBottom: 8,
                                          textAlign: "-webkit-right",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          letterSpacing: "0.5px"
                                        } as any
                                      }
                                    >
                                      <Spacer
                                        id="362404"
                                        direction="horizontal"
                                        size={12}
                                        style={
                                          {
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            backgroundPosition: "left",
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 0,
                                            paddingLeft: 0
                                          } as any
                                        }
                                      >
                                        <Icon
                                          id="266861"
                                          onClick={(e?: any) => {
                                            if (e && e.stopPropagation) e.stopPropagation();
                                            this.EserSahibiKartComponent_266861_onClick();
                                          }}
                                          showCursorPointer
                                          iconName="delete"
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              backgroundRepeat: "no-repeat",
                                              backgroundSize: "contain",
                                              backgroundPosition: "left",
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 0,
                                              paddingLeft: 0,
                                              fontSize: "24px",
                                              color: "rgba(172, 38, 42, 1)"
                                            } as any
                                          }
                                        ></Icon>

                                        <Icon
                                          id="783941"
                                          onClick={(e?: any) => {
                                            if (e && e.stopPropagation) e.stopPropagation();
                                            this.EserSahibiKartComponent_783941_onClick();
                                          }}
                                          showCursorPointer
                                          iconName="create"
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              backgroundRepeat: "no-repeat",
                                              backgroundSize: "contain",
                                              backgroundPosition: "left",
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 0,
                                              paddingLeft: 0,
                                              fontSize: "24px",
                                              color: "rgba(172, 38, 42, 1)"
                                            } as any
                                          }
                                        ></Icon>
                                      </Spacer>
                                    </KTableColumn>
                                  </KTableRow>
                                </KTable>
                              </KCol>
                            </KRow>

                            <KRow
                              id="57277"
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  paddingTop: 16,
                                  paddingBottom: 0,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            >
                              <KCol
                                id="578662"
                                xs={20}
                                sm={20}
                                md={20}
                                lg={20}
                                xl={20}
                                xxl={20}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    paddingTop: 16,
                                    paddingBottom: 16,
                                    alignItems: "center",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Label
                                  id="274180"
                                  value={ReactSystemFunctions.translate(this.ml, 274180, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      alignItems: "center",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "12px",
                                      color: "rgba(172, 38, 42, 1)"
                                    } as any
                                  }
                                ></Label>
                              </KCol>

                              <KCol
                                id="225130"
                                xs={4}
                                sm={4}
                                md={4}
                                lg={4}
                                xl={4}
                                xxl={4}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "flex-start",
                                    textAlign: "-webkit-right",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Icon
                                  id="549132"
                                  onClick={(e?: any) => {
                                    if (e && e.stopPropagation) e.stopPropagation();
                                    this.EserSahibiKartComponent_549132_onClick();
                                  }}
                                  showCursorPointer
                                  iconName="add_circle"
                                  hoverText="Lisans Sözleşmeleri, Fatura ve İzinler"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 16,
                                      fontSize: "24px",
                                      color: "rgba(172, 38, 42, 1)"
                                    } as any
                                  }
                                ></Icon>
                              </KCol>
                            </KRow>

                            <KRow
                              id="226488"
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            >
                              <KCol
                                id="616924"
                                xs={24}
                                sm={24}
                                md={24}
                                lg={24}
                                xl={24}
                                xxl={24}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <KTable
                                  id="447151"
                                  kuikaRef={this.esersahibikart_447151_value_kuikaTableRef}
                                  form={this.props.form}
                                  dataSource={this.state.getListCariSozlesmeBilgileri}
                                  size="middle"
                                  bordered={true}
                                  showHeader={true}
                                  loading={false}
                                  nodatafoundmessage={ReactSystemFunctions.translate(
                                    this.ml,
                                    447151,
                                    "nodatafoundmessage",
                                    this.defaultML
                                  )}
                                  sorter={false}
                                  pagination={false}
                                  hideTableOnEmpty={true}
                                  striped={false}
                                  stripedColor="#F5F7FA"
                                  insertRowActive={false}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(155, 155, 155, 1)",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                >
                                  <KTableHeader
                                    id="420656"
                                    hideOnMobileResolution={false}
                                    textDirection="Default"
                                    style={
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: "rgba(155, 155, 155, 1)",
                                        borderStyle: "dotted",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        fontFamily: "Poppins",
                                        fontWeight: 400,
                                        fontSize: "11px",
                                        color: "rgba(172, 38, 42, 1)",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  ></KTableHeader>

                                  <KTableRow
                                    id="556705"
                                    hoverFontColor="red"
                                    hoverBgColor="yellow"
                                    style={
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: "rgba(155, 155, 155, 1)",
                                        borderStyle: "dotted",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  >
                                    <KTableColumn
                                      id="420236"
                                      title={ReactSystemFunctions.translate(this.ml, 420236, "title", this.defaultML)}
                                      alwaysVisibleOnMobileResolution={false}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          backgroundColor: "rgba(255, 255, 255, 1)",
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          letterSpacing: "0.5px"
                                        } as any
                                      }
                                    >
                                      <Label
                                        id="181895"
                                        value="[datafield:alan]"
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            backgroundPosition: "left",
                                            paddingTop: 6,
                                            paddingBottom: 6,
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "12px",
                                            color: "rgba(47, 47, 47, 1)"
                                          } as any
                                        }
                                      ></Label>
                                    </KTableColumn>

                                    <KTableColumn
                                      id="649852"
                                      title={ReactSystemFunctions.translate(this.ml, 649852, "title", this.defaultML)}
                                      alwaysVisibleOnMobileResolution={false}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          backgroundColor: "rgba(255, 255, 255, 1)",
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          letterSpacing: "0.5px"
                                        } as any
                                      }
                                    >
                                      <Label
                                        id="259120"
                                        value="[datafield:sozlesmeTipi]"
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            backgroundPosition: "left",
                                            paddingTop: 6,
                                            paddingBottom: 6,
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "12px",
                                            color: "rgba(47, 47, 47, 1)"
                                          } as any
                                        }
                                      ></Label>
                                    </KTableColumn>

                                    <KTableColumn
                                      id="937090"
                                      title={ReactSystemFunctions.translate(this.ml, 937090, "title", this.defaultML)}
                                      alwaysVisibleOnMobileResolution={false}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          backgroundColor: "rgba(255, 255, 255, 1)",
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          letterSpacing: "0.5px"
                                        } as any
                                      }
                                    >
                                      <Label
                                        id="743690"
                                        value="[datafield:sozlesmeKonu]"
                                        formatter=""
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            backgroundPosition: "left",
                                            paddingTop: 6,
                                            paddingBottom: 6,
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "12px",
                                            color: "rgba(47, 47, 47, 1)"
                                          } as any
                                        }
                                      ></Label>
                                    </KTableColumn>

                                    <KTableColumn
                                      id="996202"
                                      title={ReactSystemFunctions.translate(this.ml, 996202, "title", this.defaultML)}
                                      alwaysVisibleOnMobileResolution={false}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          backgroundColor: "rgba(255, 255, 255, 1)",
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          letterSpacing: "0.5px"
                                        } as any
                                      }
                                    >
                                      <Label
                                        id="330589"
                                        value="[datafield:basTarihi]"
                                        formatter="date"
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            backgroundPosition: "left",
                                            paddingTop: 6,
                                            paddingBottom: 6,
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "12px",
                                            color: "rgba(47, 47, 47, 1)"
                                          } as any
                                        }
                                      ></Label>
                                    </KTableColumn>

                                    <KTableColumn
                                      id="789060"
                                      title={ReactSystemFunctions.translate(this.ml, 789060, "title", this.defaultML)}
                                      alwaysVisibleOnMobileResolution={false}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          backgroundColor: "rgba(255, 255, 255, 1)",
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          letterSpacing: "0.5px"
                                        } as any
                                      }
                                    >
                                      <Label
                                        id="857949"
                                        value="[datafield:bitTarihi]"
                                        formatter="date"
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            backgroundPosition: "left",
                                            paddingTop: 6,
                                            paddingBottom: 6,
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "12px",
                                            color: "rgba(47, 47, 47, 1)"
                                          } as any
                                        }
                                      ></Label>
                                    </KTableColumn>

                                    <KTableColumn
                                      id="967890"
                                      title={ReactSystemFunctions.translate(this.ml, 967890, "title", this.defaultML)}
                                      alwaysVisibleOnMobileResolution={false}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          backgroundColor: "rgba(255, 255, 255, 1)",
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          alignItems: "center",
                                          textAlign: "-webkit-center",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          letterSpacing: "0.5px"
                                        } as any
                                      }
                                    >
                                      <Icon
                                        id="444588"
                                        onClick={(e?: any) => {
                                          if (e && e.stopPropagation) e.stopPropagation();
                                          this.EserSahibiKartComponent_444588_onClick();
                                        }}
                                        onChange={(e?: any) => {
                                          if (e && e.stopPropagation) e.stopPropagation();
                                          this.EserSahibiKartComponent_444588_onChange();
                                        }}
                                        showCursorPointer
                                        visibility={this.state.isComp444588Visible}
                                        condition={(rowData) => ReactSystemFunctions.isNotEmpty(rowData.dosya, "")}
                                        iconName="sim_card_download"
                                        hoverText="[datafield:dosyaAd]"
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            backgroundPosition: "left",
                                            fontWeight: 500,
                                            fontSize: "20px",
                                            letterSpacing: "0.5px"
                                          } as any
                                        }
                                      ></Icon>
                                    </KTableColumn>

                                    <KTableColumn
                                      id="854108"
                                      title={ReactSystemFunctions.translate(this.ml, 854108, "title", this.defaultML)}
                                      alwaysVisibleOnMobileResolution={false}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          backgroundColor: "rgba(255, 255, 255, 1)",
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          alignItems: "center",
                                          textAlign: "-webkit-right",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          letterSpacing: "0.5px"
                                        } as any
                                      }
                                    >
                                      <Spacer id="266182" direction="horizontal" size={12} style={{} as any}>
                                        <Icon
                                          id="537518"
                                          onClick={(e?: any) => {
                                            if (e && e.stopPropagation) e.stopPropagation();
                                            this.EserSahibiKartComponent_537518_onClick();
                                          }}
                                          showCursorPointer
                                          iconName="delete"
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              backgroundRepeat: "no-repeat",
                                              backgroundSize: "contain",
                                              backgroundPosition: "left",
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              color: "rgba(172, 38, 42, 1)",
                                              letterSpacing: "0.5px"
                                            } as any
                                          }
                                        ></Icon>

                                        <Icon
                                          id="868262"
                                          onClick={(e?: any) => {
                                            if (e && e.stopPropagation) e.stopPropagation();
                                            this.EserSahibiKartComponent_868262_onClick();
                                          }}
                                          showCursorPointer
                                          iconName="create"
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              backgroundRepeat: "no-repeat",
                                              backgroundSize: "contain",
                                              backgroundPosition: "left",
                                              paddingTop: 8,
                                              paddingBottom: 8,
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              color: "rgba(172, 38, 42, 1)",
                                              letterSpacing: "0.5px"
                                            } as any
                                          }
                                        ></Icon>
                                      </Spacer>
                                    </KTableColumn>
                                  </KTableRow>
                                </KTable>
                              </KCol>
                            </KRow>

                            <KRow
                              id="822247"
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  paddingTop: 16,
                                  paddingBottom: 0,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            >
                              <KCol
                                id="670872"
                                xs={20}
                                sm={20}
                                md={20}
                                lg={20}
                                xl={20}
                                xxl={20}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    paddingTop: 16,
                                    paddingBottom: 16,
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Label
                                  id="91669"
                                  value={ReactSystemFunctions.translate(this.ml, 91669, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "12px",
                                      color: "rgba(172, 38, 42, 1)"
                                    } as any
                                  }
                                ></Label>
                              </KCol>

                              <KCol
                                id="879951"
                                xs={4}
                                sm={4}
                                md={4}
                                lg={4}
                                xl={4}
                                xxl={4}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "flex-start",
                                    textAlign: "-webkit-right",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Icon
                                  id="447183"
                                  onClick={(e?: any) => {
                                    if (e && e.stopPropagation) e.stopPropagation();
                                    this.EserSahibiKartComponent_447183_onClick();
                                  }}
                                  showCursorPointer
                                  iconName="add_circle"
                                  hoverText="Bağlantılı Meslek Birliği (PRO)"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingLeft: 16,
                                      fontSize: "24px",
                                      color: "rgba(172, 38, 42, 1)"
                                    } as any
                                  }
                                ></Icon>
                              </KCol>
                            </KRow>

                            <KRow
                              id="694439"
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            >
                              <KCol
                                id="734203"
                                xs={24}
                                sm={24}
                                md={24}
                                lg={24}
                                xl={24}
                                xxl={24}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <KTable
                                  id="456057"
                                  kuikaRef={this.esersahibikart_456057_value_kuikaTableRef}
                                  form={this.props.form}
                                  dataSource={this.state.getListBaglantiliMeslekBirligiByESId}
                                  size="middle"
                                  bordered={true}
                                  showHeader={true}
                                  loading={false}
                                  nodatafoundmessage={ReactSystemFunctions.translate(
                                    this.ml,
                                    456057,
                                    "nodatafoundmessage",
                                    this.defaultML
                                  )}
                                  sorter={false}
                                  pagination={false}
                                  hideTableOnEmpty={true}
                                  striped={false}
                                  stripedColor="#F5F7FA"
                                  insertRowActive={false}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(155, 155, 155, 1)",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                >
                                  <KTableHeader
                                    id="700550"
                                    hideOnMobileResolution={false}
                                    textDirection="Default"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        fontFamily: "Poppins",
                                        fontWeight: 400,
                                        fontSize: "11px",
                                        color: "rgba(172, 38, 42, 1)",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  ></KTableHeader>

                                  <KTableRow
                                    id="96684"
                                    hoverFontColor="red"
                                    hoverBgColor="yellow"
                                    style={
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: "rgba(155, 155, 155, 1)",
                                        borderStyle: "dotted",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  >
                                    <KTableColumn
                                      id="733025"
                                      title={ReactSystemFunctions.translate(this.ml, 733025, "title", this.defaultML)}
                                      alwaysVisibleOnMobileResolution={false}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          backgroundColor: "rgba(255, 255, 255, 1)",
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          paddingTop: 8,
                                          paddingBottom: 8,
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          letterSpacing: "0.5px"
                                        } as any
                                      }
                                    >
                                      <Label
                                        id="806362"
                                        value="[datafield:meslekBirligiAd]"
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            backgroundPosition: "left",
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 0,
                                            paddingLeft: 0,
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "12px",
                                            color: "rgba(47, 47, 47, 1)"
                                          } as any
                                        }
                                      ></Label>
                                    </KTableColumn>

                                    <KTableColumn
                                      id="19410"
                                      title={ReactSystemFunctions.translate(this.ml, 19410, "title", this.defaultML)}
                                      alwaysVisibleOnMobileResolution={false}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          backgroundColor: "rgba(255, 255, 255, 1)",
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          paddingTop: 8,
                                          paddingBottom: 8,
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          letterSpacing: "0.5px"
                                        } as any
                                      }
                                    >
                                      <Label
                                        id="390233"
                                        value="[datafield:kayitliESAdi]"
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            backgroundPosition: "left",
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 0,
                                            paddingLeft: 0,
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "12px",
                                            color: "rgba(47, 47, 47, 1)"
                                          } as any
                                        }
                                      ></Label>
                                    </KTableColumn>

                                    <KTableColumn
                                      id="993655"
                                      title={ReactSystemFunctions.translate(this.ml, 993655, "title", this.defaultML)}
                                      alwaysVisibleOnMobileResolution={false}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          backgroundColor: "rgba(255, 255, 255, 1)",
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          paddingTop: 8,
                                          paddingBottom: 8,
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          letterSpacing: "0.5px"
                                        } as any
                                      }
                                    >
                                      <Label
                                        id="45984"
                                        value="[datafield:ipi]"
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            backgroundPosition: "left",
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 0,
                                            paddingLeft: 0,
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "12px",
                                            color: "rgba(47, 47, 47, 1)"
                                          } as any
                                        }
                                      ></Label>
                                    </KTableColumn>

                                    <KTableColumn
                                      id="112177"
                                      title={ReactSystemFunctions.translate(this.ml, 112177, "title", this.defaultML)}
                                      alwaysVisibleOnMobileResolution={false}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          backgroundColor: "rgba(255, 255, 255, 1)",
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          paddingTop: 8,
                                          paddingBottom: 8,
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          letterSpacing: "0.5px"
                                        } as any
                                      }
                                    >
                                      <Label
                                        id="177858"
                                        value="[datafield:korumaBolgesi]"
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            backgroundPosition: "left",
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 0,
                                            paddingLeft: 0,
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "12px",
                                            color: "rgba(47, 47, 47, 1)"
                                          } as any
                                        }
                                      ></Label>
                                    </KTableColumn>

                                    <KTableColumn
                                      id="84090"
                                      title={ReactSystemFunctions.translate(this.ml, 84090, "title", this.defaultML)}
                                      alwaysVisibleOnMobileResolution={false}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          backgroundColor: "rgba(255, 255, 255, 1)",
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          paddingTop: 8,
                                          paddingBottom: 8,
                                          textAlign: "-webkit-right",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          letterSpacing: "0.5px"
                                        } as any
                                      }
                                    >
                                      <Spacer
                                        id="592843"
                                        direction="horizontal"
                                        size={12}
                                        style={
                                          {
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            backgroundPosition: "left",
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 0,
                                            paddingLeft: 0
                                          } as any
                                        }
                                      >
                                        <Icon
                                          id="254838"
                                          onClick={(e?: any) => {
                                            if (e && e.stopPropagation) e.stopPropagation();
                                            this.EserSahibiKartComponent_254838_onClick();
                                          }}
                                          showCursorPointer
                                          iconName="delete"
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              backgroundRepeat: "no-repeat",
                                              backgroundSize: "contain",
                                              backgroundPosition: "left",
                                              fontSize: "24px",
                                              color: "rgba(172, 38, 42, 1)"
                                            } as any
                                          }
                                        ></Icon>

                                        <Icon
                                          id="826606"
                                          onClick={(e?: any) => {
                                            if (e && e.stopPropagation) e.stopPropagation();
                                            this.EserSahibiKartComponent_826606_onClick();
                                          }}
                                          showCursorPointer
                                          iconName="create"
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              backgroundRepeat: "no-repeat",
                                              backgroundSize: "contain",
                                              backgroundPosition: "left",
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 0,
                                              paddingLeft: 0,
                                              fontSize: "24px",
                                              color: "rgba(172, 38, 42, 1)"
                                            } as any
                                          }
                                        ></Icon>
                                      </Spacer>
                                    </KTableColumn>
                                  </KTableRow>
                                </KTable>
                              </KCol>
                            </KRow>
                          </KCol>
                        </KRow>
                      </KCol>
                    </KRow>

                    <KRow
                      id="288530"
                      align="top"
                      justify="start"
                      horizontalGutter={0}
                      verticalGutter={0}
                      style={
                        {
                          borderBottomRightRadius: 12,
                          borderBottomLeftRadius: 12,
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          backgroundColor: "rgba(255, 255, 255, 1)",
                          paddingTop: 0,
                          paddingRight: 8,
                          paddingBottom: 8,
                          paddingLeft: 8,
                          alignItems: "flex-start",
                          textAlign: "-webkit-left",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "14px",
                          color: "rgba(68, 68, 68, 1)",
                          letterSpacing: "0.5px"
                        } as any
                      }
                    >
                      <KCol
                        id="962086"
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={24}
                        xxl={24}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 0,
                            paddingRight: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            alignItems: "flex-start",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(68, 68, 68, 1)",
                            letterSpacing: "0.5px"
                          } as any
                        }
                      >
                        <KRow
                          id="246863"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 16,
                              paddingBottom: 16,
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="497375"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={24}
                            xxl={24}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingRight: 16,
                                paddingLeft: 16,
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <KRow
                              id="882311"
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingBottom: 8,
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            >
                              <KCol
                                id="320060"
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                xxl={12}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Label
                                  id="404035"
                                  value={ReactSystemFunctions.translate(this.ml, 404035, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      textAlign: "-webkit-left",
                                      display: "block",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "12px",
                                      color: "rgba(172, 38, 42, 1)"
                                    } as any
                                  }
                                ></Label>
                              </KCol>

                              <KCol
                                id="876929"
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                xxl={12}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-right",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Icon
                                  id="119287"
                                  onClick={(e?: any) => {
                                    if (e && e.stopPropagation) e.stopPropagation();
                                    this.EserSahibiKartComponent_119287_onClick();
                                  }}
                                  showCursorPointer
                                  iconName="article"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingLeft: 16,
                                      fontSize: "24px",
                                      color: "rgba(172, 38, 42, 1)"
                                    } as any
                                  }
                                ></Icon>
                              </KCol>
                            </KRow>

                            <KTable
                              id="281355"
                              visibility={this.state.isComp281355Visible}
                              kuikaRef={this.esersahibikart_281355_value_kuikaTableRef}
                              form={this.props.form}
                              dataSource={this.state.spTarihceEserSahibi}
                              size="middle"
                              bordered={true}
                              showHeader={true}
                              loading={false}
                              nodatafoundmessage={ReactSystemFunctions.translate(
                                this.ml,
                                281355,
                                "nodatafoundmessage",
                                this.defaultML
                              )}
                              sorter={false}
                              pagination={false}
                              striped={false}
                              stripedColor="#F5F7FA"
                              insertRowActive={false}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(155, 155, 155, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            >
                              <KTableHeader
                                id="683168"
                                hideOnMobileResolution={false}
                                textDirection="Default"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(155, 155, 155, 1)",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              ></KTableHeader>

                              <KTableRow
                                id="357130"
                                hoverFontColor="red"
                                hoverBgColor="yellow"
                                style={
                                  {
                                    borderTopLeftRadius: 0,
                                    borderTopRightRadius: 0,
                                    borderBottomRightRadius: 0,
                                    borderBottomLeftRadius: 0,
                                    borderTopWidth: 1,
                                    borderColor: "rgba(204, 204, 204, 1)",
                                    borderStyle: "solid",
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <KTableColumn
                                  id="272087"
                                  title={ReactSystemFunctions.translate(this.ml, 272087, "title", this.defaultML)}
                                  alwaysVisibleOnMobileResolution={false}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      paddingTop: null,
                                      paddingRight: null,
                                      paddingBottom: null,
                                      paddingLeft: 16,
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                >
                                  <Label
                                    id="760655"
                                    value="[datafield:ticketNo]"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        paddingLeft: null,
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(47, 47, 47, 1)",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  ></Label>
                                </KTableColumn>

                                <KTableColumn
                                  id="665266"
                                  title={ReactSystemFunctions.translate(this.ml, 665266, "title", this.defaultML)}
                                  alwaysVisibleOnMobileResolution={false}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      paddingTop: 8,
                                      paddingBottom: 8,
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                >
                                  <Label
                                    id="339046"
                                    value="[datafield:quarterDate]"
                                    formatter="date"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(47, 47, 47, 1)"
                                      } as any
                                    }
                                  ></Label>
                                </KTableColumn>

                                <KTableColumn
                                  id="280713"
                                  title={ReactSystemFunctions.translate(this.ml, 280713, "title", this.defaultML)}
                                  alwaysVisibleOnMobileResolution={false}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      paddingTop: 8,
                                      paddingBottom: 8,
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                >
                                  <Label
                                    id="733958"
                                    value="[datafield:stokAd]"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        textAlign: "-webkit-center",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(47, 47, 47, 1)"
                                      } as any
                                    }
                                  ></Label>
                                </KTableColumn>

                                <KTableColumn
                                  id="27555"
                                  title={ReactSystemFunctions.translate(this.ml, 27555, "title", this.defaultML)}
                                  alwaysVisibleOnMobileResolution={false}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      paddingTop: 8,
                                      paddingBottom: 8,
                                      textAlign: "-webkit-left",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                >
                                  <Label
                                    id="160102"
                                    value="[datafield:eserSahibi]"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        textAlign: "-webkit-left",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(47, 47, 47, 1)"
                                      } as any
                                    }
                                  ></Label>
                                </KTableColumn>

                                <KTableColumn
                                  id="704740"
                                  title={ReactSystemFunctions.translate(this.ml, 704740, "title", this.defaultML)}
                                  alwaysVisibleOnMobileResolution={false}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      paddingTop: 8,
                                      paddingBottom: 8,
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                >
                                  <Label
                                    id="180367"
                                    value="[datafield:lisansTuru]"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 0,
                                        paddingLeft: 0,
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(47, 47, 47, 1)"
                                      } as any
                                    }
                                  ></Label>
                                </KTableColumn>

                                <KTableColumn
                                  id="157316"
                                  title={ReactSystemFunctions.translate(this.ml, 157316, "title", this.defaultML)}
                                  alwaysVisibleOnMobileResolution={false}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      paddingTop: 8,
                                      paddingBottom: 8,
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                >
                                  <Label
                                    id="227366"
                                    value="[datafield:projeAd]"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 0,
                                        paddingLeft: 0,
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(47, 47, 47, 1)"
                                      } as any
                                    }
                                  ></Label>
                                </KTableColumn>

                                <KTableColumn
                                  id="553777"
                                  title={ReactSystemFunctions.translate(this.ml, 553777, "title", this.defaultML)}
                                  alwaysVisibleOnMobileResolution={false}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      paddingTop: 8,
                                      paddingBottom: 8,
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                >
                                  <Label
                                    id="548609"
                                    value="[datafield:projeTuru]"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 0,
                                        paddingLeft: 0,
                                        textAlign: "-webkit-center",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(47, 47, 47, 1)"
                                      } as any
                                    }
                                  ></Label>
                                </KTableColumn>

                                <KTableColumn
                                  id="195162"
                                  title={ReactSystemFunctions.translate(this.ml, 195162, "title", this.defaultML)}
                                  alwaysVisibleOnMobileResolution={false}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      paddingTop: 8,
                                      paddingBottom: 8,
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                >
                                  <Label
                                    id="795682"
                                    value="[datafield:kullanimTuru]"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 0,
                                        paddingLeft: 0,
                                        textAlign: "-webkit-center",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(47, 47, 47, 1)"
                                      } as any
                                    }
                                  ></Label>
                                </KTableColumn>
                              </KTableRow>
                            </KTable>
                          </KCol>
                        </KRow>
                      </KCol>
                    </KRow>
                  </KCol>
                </KRow>

                <KDrawer
                  id="820946"
                  placement="left"
                  onClose={() => {
                    this.setState({ NavVisible820946: false }, () => {
                      this.callCallbackFunction(820946);
                    });
                  }}
                  visible={this.state.NavVisible820946}
                  push={true}
                  children={
                    this.state.NavVisible820946 && (
                      <LeftMenu_Screen
                        onClose={() => {
                          this.setState({ NavVisible820946: false }, () => {
                            this.callCallbackFunction(820946);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                      />
                    )
                  }
                  style={{} as any}
                ></KDrawer>

                <KDrawer
                  id="502693"
                  placement="right"
                  onClose={() => {
                    this.setState({ NavVisible502693: false }, () => {
                      this.callCallbackFunction(502693);
                    });
                  }}
                  visible={this.state.NavVisible502693}
                  width="400"
                  push={true}
                  children={
                    this.state.NavVisible502693 && (
                      <EserSahibiPayDagilimKart_Screen
                        onClose={() => {
                          this.setState({ NavVisible502693: false }, () => {
                            this.callCallbackFunction(502693);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                      />
                    )
                  }
                  style={{} as any}
                ></KDrawer>

                <KDrawer
                  id="626262"
                  placement="right"
                  onClose={() => {
                    this.setState({ NavVisible626262: false }, () => {
                      this.callCallbackFunction(626262);
                    });
                  }}
                  visible={this.state.NavVisible626262}
                  width="480"
                  push={true}
                  children={
                    this.state.NavVisible626262 && (
                      <CariSozlesmeler_Screen
                        onClose={() => {
                          this.setState({ NavVisible626262: false }, () => {
                            this.callCallbackFunction(626262);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                      />
                    )
                  }
                  style={{} as any}
                ></KDrawer>

                <KDrawer
                  id="198262"
                  placement="right"
                  onClose={() => {
                    this.setState({ NavVisible198262: false }, () => {
                      this.callCallbackFunction(198262);
                    });
                  }}
                  visible={this.state.NavVisible198262}
                  width="400"
                  push={true}
                  children={
                    this.state.NavVisible198262 && (
                      <EserSahibiPayDagilimKart_Screen
                        onClose={() => {
                          this.setState({ NavVisible198262: false }, () => {
                            this.callCallbackFunction(198262);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                      />
                    )
                  }
                  style={{} as any}
                ></KDrawer>

                <KDrawer
                  id="433350"
                  placement="right"
                  onClose={() => {
                    this.setState({ NavVisible433350: false }, () => {
                      this.callCallbackFunction(433350);
                    });
                  }}
                  visible={this.state.NavVisible433350}
                  width="480px"
                  height="100vh"
                  push={true}
                  children={
                    this.state.NavVisible433350 && (
                      <BaglantiliMeslekBirligiKart_Screen
                        onClose={() => {
                          this.setState({ NavVisible433350: false }, () => {
                            this.callCallbackFunction(433350);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                      />
                    )
                  }
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 0,
                      paddingRight: 0,
                      paddingBottom: 0,
                      paddingLeft: 0,
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(68, 68, 68, 1)",
                      letterSpacing: "0.5px"
                    } as any
                  }
                ></KDrawer>

                <KDrawer
                  id="659105"
                  placement="right"
                  onClose={() => {
                    this.setState({ NavVisible659105: false }, () => {
                      this.callCallbackFunction(659105);
                    });
                  }}
                  visible={this.state.NavVisible659105}
                  width="480px"
                  height="100vh"
                  push={true}
                  children={
                    this.state.NavVisible659105 && (
                      <BaglantiliMeslekBirligiKart_Screen
                        onClose={() => {
                          this.setState({ NavVisible659105: false }, () => {
                            this.callCallbackFunction(659105);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                      />
                    )
                  }
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 0,
                      paddingRight: 0,
                      paddingBottom: 0,
                      paddingLeft: 0,
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(68, 68, 68, 1)",
                      letterSpacing: "0.5px"
                    } as any
                  }
                ></KDrawer>

                <KDrawer
                  id="676743"
                  placement="right"
                  onClose={() => {
                    this.setState({ NavVisible676743: false }, () => {
                      this.callCallbackFunction(676743);
                    });
                  }}
                  visible={this.state.NavVisible676743}
                  width="480"
                  push={true}
                  children={
                    this.state.NavVisible676743 && (
                      <CariSozlesmeler_Screen
                        onClose={() => {
                          this.setState({ NavVisible676743: false }, () => {
                            this.callCallbackFunction(676743);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                      />
                    )
                  }
                  style={{} as any}
                ></KDrawer>
              </div>
            </div>
          </Form>
        </Spin>
      </>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  return {
    spinnerCount: state.spinnerReducer.spinnerCount,
    photoTakeVisible: state.photoTakeReducer.visible
  };
};
const mapDispatchToProps = (dispatch) => ({
  showPhotoTake: () => dispatch(showPhotoTake()),
  hidePhotoTake: () => dispatch(hidePhotoTake()),
  showStripeDrawer: (data, callback, that, future) => dispatch(showStripeDrawer(data, callback, that, future)),
  hideStripeDrawer: (data, callback, that) => dispatch(hideStripeDrawer(data, callback, that)),
  showIyzicoDrawer: (data, callback, that) => dispatch(showIyzicoDrawer(data, callback, that)),
  hideIyzicoDrawer: (data, callback, that) => dispatch(hideIyzicoDrawer(data, callback, that))
});
const tmp = withGoogleLogin(
  withContext(
    withScreenInput(withForm(withHistory(connect(mapStateToProps, mapDispatchToProps)(EserSahibiKart_Screen))))
  )
);
export { tmp as EserSahibiKart_Screen };
//export default tmp;
//export { tmp as EserSahibiKart_Screen };
