import { FormInstance } from "antd/lib/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface ILeftMenu_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface ILeftMenu_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  getUserInfo: any[];
  getUserInfo_dummy: any[];
  ChangeVisibilityOf: string;
  ChangeVisibilityOf_dummy: string;
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  Logout: boolean;
  Logout_dummy: boolean;
  goToSignInActivity: any;
  goToSignInActivity_dummy: any;
  isComp456073Visible: "visible" | "hidden";
  isComp840102Visible: "visible" | "hidden";
  isComp484206Visible: "visible" | "hidden";
  isCompleftmenu_456073AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_199102AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_7237AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_122459AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_115319AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_570656AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_455871AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_769259AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_872845AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_871783AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_223730AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_851806AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_832747AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_369950AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_798512AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_44910AuthorizationVisible: "visible" | "hidden";
}

export class LeftMenu_ScreenBase extends React.PureComponent<ILeftMenu_ScreenProps, any> {
  ml = [
    {
      Id: "16c151eb-42cf-0b2b-7e4a-e8b04fc43d88",
      Name: "tr_TR",
      ShortName: "Turkish",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "fbdbce9f-3fdb-30c5-c4fe-f7e443068251", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    { Id: "8468043e-f025-01e1-93b8-c71e3215778a", Name: "de_DE", ShortName: "German", LanguagePhrases: [] },
    {
      Id: "31ae9a9c-8561-420a-9774-85f03881f843",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 698488, PropertyName: "value", Value: "Sistemde Ara" },
        { Id: 226214, PropertyName: "value", Value: "Müşteriler" },
        { Id: 904903, PropertyName: "value", Value: "- - - - - - - - - - - - - - - - - - - - -" },
        { Id: 624203, PropertyName: "value", Value: "Yeni Başvurular" },
        { Id: 79312, PropertyName: "value", Value: "İş Takip Listem" },
        { Id: 993687, PropertyName: "value", Value: "Onaylanmış İşler" },
        { Id: 762764, PropertyName: "value", Value: "Muhasebe Takibi" },
        { Id: 757008, PropertyName: "value", Value: "Fatura Takibi" },
        { Id: 301259, PropertyName: "value", Value: "Arşiv" },
        { Id: 213286, PropertyName: "value", Value: "- - - - - - - - - - - - - - - - - - - - -" },
        { Id: 733731, PropertyName: "header", Value: "Eser / Eser Sahibi Bilgileri" },
        { Id: 195348, PropertyName: "value", Value: "Eser Sahipleri" },
        { Id: 151990, PropertyName: "value", Value: "Eserler" },
        { Id: 93482, PropertyName: "value", Value: "Ana Kataloglar" },
        { Id: 494794, PropertyName: "value", Value: "Yapımlar" },
        { Id: 285028, PropertyName: "value", Value: "- - - - - - - - - - - - - - - - - - - - -" },
        { Id: 525982, PropertyName: "header", Value: "Excel Import" },
        { Id: 815004, PropertyName: "value", Value: "Paket Anlaşmalar" },
        { Id: 816567, PropertyName: "value", Value: "Meslek Birliği TR" },
        { Id: 144833, PropertyName: "value", Value: "Alt Yayımcılar" },
        { Id: 197850, PropertyName: "value", Value: "Meslek Birliği WW" },
        { Id: 947025, PropertyName: "value", Value: "Digital Monetization" },
        { Id: 38178, PropertyName: "value", Value: "Yapım Hakları" },
        { Id: 915445, PropertyName: "value", Value: "Dağıtım" },
        { Id: 523277, PropertyName: "value", Value: "Dağıtım Mailleri" },
        { Id: 593723, PropertyName: "value", Value: "- - - - - - - - - - - - - - - - - - - - -" },
        { Id: 676137, PropertyName: "header", Value: "Fiyat Listesi" },
        { Id: 200327, PropertyName: "value", Value: "Fiyat Listeleri" },
        { Id: 421258, PropertyName: "value", Value: "Fiyatlandırma Notları" },
        { Id: 203343, PropertyName: "header", Value: "Tanımlamalar" },
        { Id: 161752, PropertyName: "value", Value: "Kullanıcılar" },
        { Id: 331368, PropertyName: "value", Value: "- - - - - - - - - - - - - - - - - - - - -" },
        { Id: 844532, PropertyName: "value", Value: "BF Proje Türü / Mecra Eşleştir" },
        { Id: 761318, PropertyName: "value", Value: "- - - - - - - - - - - - - - - - - - - - -" },
        { Id: 213912, PropertyName: "value", Value: "Aksiyon Türü" },
        { Id: 285005, PropertyName: "value", Value: "Başvuru Filtre Süre Aralık" },
        { Id: 163119, PropertyName: "value", Value: "Firma Türü" },
        { Id: 766403, PropertyName: "value", Value: "İl" },
        { Id: 272012, PropertyName: "value", Value: "İlçe" },
        { Id: 446028, PropertyName: "value", Value: "İş Alanı" },
        { Id: 855181, PropertyName: "value", Value: "Kanal Tanım (Sözleşme)" },
        { Id: 326742, PropertyName: "value", Value: "Meslek Birliği Adı" },
        { Id: 906722, PropertyName: "value", Value: "Müşteri Türü" },
        { Id: 413023, PropertyName: "value", Value: "Para Birimi" },
        { Id: 33237, PropertyName: "value", Value: "Sözleşme Kanal" },
        { Id: 28363, PropertyName: "value", Value: "Sözleşme Konu" },
        { Id: 355645, PropertyName: "value", Value: "Telif Tür" },
        { Id: 278183, PropertyName: "value", Value: "Temsil (Lisans) Bölgesi" },
        { Id: 668425, PropertyName: "value", Value: "Ülke" },
        { Id: 990350, PropertyName: "value", Value: "Yayımcı" },
        { Id: 742423, PropertyName: "value", Value: "Logout" }
      ]
    }
  ];
  defaultML = "tr_TR";
  screenHash = "";

  constructor(props) {
    super(props);

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      getUserInfo: [],
      ChangeVisibilityOf: "",
      NAVIGATE: "",
      Logout: false,
      goToSignInActivity: "",
      isComp456073Visible: "hidden",
      isComp840102Visible: "hidden",
      isComp484206Visible: "hidden",
      isCompleftmenu_456073AuthorizationVisible: "visible",
      isCompleftmenu_199102AuthorizationVisible: "visible",
      isCompleftmenu_7237AuthorizationVisible: "visible",
      isCompleftmenu_122459AuthorizationVisible: "visible",
      isCompleftmenu_115319AuthorizationVisible: "visible",
      isCompleftmenu_570656AuthorizationVisible: "visible",
      isCompleftmenu_455871AuthorizationVisible: "visible",
      isCompleftmenu_769259AuthorizationVisible: "visible",
      isCompleftmenu_872845AuthorizationVisible: "visible",
      isCompleftmenu_871783AuthorizationVisible: "visible",
      isCompleftmenu_223730AuthorizationVisible: "visible",
      isCompleftmenu_851806AuthorizationVisible: "visible",
      isCompleftmenu_832747AuthorizationVisible: "visible",
      isCompleftmenu_369950AuthorizationVisible: "visible",
      isCompleftmenu_798512AuthorizationVisible: "visible",
      isCompleftmenu_44910AuthorizationVisible: "visible"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("leftmenu", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.LeftMenuPageInit();
    }
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_456073AuthorizationVisible", [
      "89f0bd8c-62b8-cf33-3763-edf7319ca78f",
      "7165e0ad-e354-6630-d176-a4378c7ac2b9",
      "5702a90f-4edb-07c0-5096-8ee6a5c813e6",
      "3699f6cb-db2c-cd20-74c6-a9ef5bd8a887",
      "Muhasebe",
      "Admin",
      "Muz Ekip A",
      "Muz Ekip B"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_199102AuthorizationVisible", [
      "89f0bd8c-62b8-cf33-3763-edf7319ca78f",
      "7165e0ad-e354-6630-d176-a4378c7ac2b9",
      "5702a90f-4edb-07c0-5096-8ee6a5c813e6",
      "Muhasebe",
      "Admin",
      "Muz Ekip A"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_7237AuthorizationVisible", [
      "89f0bd8c-62b8-cf33-3763-edf7319ca78f",
      "7165e0ad-e354-6630-d176-a4378c7ac2b9",
      "Muhasebe",
      "Admin"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_122459AuthorizationVisible", [
      "89f0bd8c-62b8-cf33-3763-edf7319ca78f",
      "7165e0ad-e354-6630-d176-a4378c7ac2b9",
      "Muhasebe",
      "Admin"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_115319AuthorizationVisible", [
      "7165e0ad-e354-6630-d176-a4378c7ac2b9",
      "89f0bd8c-62b8-cf33-3763-edf7319ca78f",
      "Muhasebe",
      "Admin"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_570656AuthorizationVisible", [
      "7165e0ad-e354-6630-d176-a4378c7ac2b9",
      "89f0bd8c-62b8-cf33-3763-edf7319ca78f",
      "Muhasebe",
      "Admin"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_455871AuthorizationVisible", [
      "7165e0ad-e354-6630-d176-a4378c7ac2b9",
      "89f0bd8c-62b8-cf33-3763-edf7319ca78f",
      "Muhasebe",
      "Admin"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_769259AuthorizationVisible", [
      "7165e0ad-e354-6630-d176-a4378c7ac2b9",
      "89f0bd8c-62b8-cf33-3763-edf7319ca78f",
      "Muhasebe",
      "Admin"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_872845AuthorizationVisible", [
      "7165e0ad-e354-6630-d176-a4378c7ac2b9",
      "89f0bd8c-62b8-cf33-3763-edf7319ca78f",
      "Muhasebe",
      "Admin"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_871783AuthorizationVisible", [
      "7165e0ad-e354-6630-d176-a4378c7ac2b9",
      "89f0bd8c-62b8-cf33-3763-edf7319ca78f",
      "Muhasebe",
      "Admin"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_223730AuthorizationVisible", [
      "89f0bd8c-62b8-cf33-3763-edf7319ca78f",
      "Admin"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_851806AuthorizationVisible", [
      "7165e0ad-e354-6630-d176-a4378c7ac2b9",
      "89f0bd8c-62b8-cf33-3763-edf7319ca78f",
      "5702a90f-4edb-07c0-5096-8ee6a5c813e6",
      "Muhasebe",
      "Admin",
      "Muz Ekip A"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_832747AuthorizationVisible", [
      "89f0bd8c-62b8-cf33-3763-edf7319ca78f",
      "Admin"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_369950AuthorizationVisible", [
      "89f0bd8c-62b8-cf33-3763-edf7319ca78f",
      "Admin"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_798512AuthorizationVisible", [
      "89f0bd8c-62b8-cf33-3763-edf7319ca78f",
      "Admin"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_44910AuthorizationVisible", [
      "89f0bd8c-62b8-cf33-3763-edf7319ca78f",
      "Admin"
    ]);

    KuikaAppManager.calculateAndSetBodyHeight("leftmenu", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("leftmenu", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.LeftMenuPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  LeftMenuPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {};

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "LeftMenu/LeftMenuPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.getUserInfo = result?.data.getUserInfo;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.LeftMenuPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  LeftMenuPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.toString(
          this,
          this.state.getUserInfo?.length > 0 ? this.state.getUserInfo[0]?.rolName : null
        ),
        "Bayi"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp456073Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  LeftMenuComponent_698488_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "SearchList",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_226214_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "MusteriList",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_624203_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "TicketList",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_79312_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "IsTakipList",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_432259_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "SatisList",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_993687_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "SatisList",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_762764_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "SiparisList",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_757008_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "FaturaList",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_301259_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "ArsivList",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_195348_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "EserSahibiList",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_151990_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "UrunList",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_93482_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "AnaKatalogList",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_494794_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "YapimList",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_309980_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "IListPaketAnlasma",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_7237_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "IListMeslekBirligiTR",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_122459_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "IListAltYayimci",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_115319_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "IListMeslekBirligiY",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_570656_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "IListDigital",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_455871_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "IListYapim",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_915445_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "DagitimList",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_523277_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "DagitimMailList",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_200327_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "FiyatList",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_421258_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "FiyatlandirmaNotlarList",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_161752_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "KullaniciList",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_844532_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "ProjeTuruMecraEsle",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_213912_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "TAksiyonTur_List",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "25723",
      null,
      "left",
      null,
      "656px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_285005_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "TSureAralik_List",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "111489",
      null,
      "left",
      null,
      "656px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_163119_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "TFirmaTuru_List",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "794739",
      null,
      "left",
      null,
      "656px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_766403_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "il_List",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "730997",
      null,
      "left",
      null,
      "656px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_272012_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "ilce_List",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "583624",
      null,
      "left",
      null,
      "656px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_446028_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "TIsAlani_List",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "225586",
      null,
      "left",
      null,
      "656px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_855181_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "TSozlesmeKanal_List",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "627771",
      null,
      "left",
      null,
      "656px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_326742_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "TMeslekBirligiAd_List",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "765059",
      null,
      "left",
      null,
      "656px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_906722_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "TMusteriTuru_List",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "497117",
      null,
      "left",
      null,
      "656px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_413023_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "TParaBirim_List",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "658027",
      null,
      "left",
      null,
      "656px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_33237_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "TSozlesmeKanal_List",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "500573",
      null,
      "left",
      null,
      "656px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_28363_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "TSozlesmeKonu_List",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "904965",
      null,
      "left",
      null,
      "656px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_355645_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "TTelifTur_List",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "330461",
      null,
      "left",
      null,
      "656px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_278183_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "TTemsilBolgesi_List",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "761143",
      null,
      "left",
      null,
      "656px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_668425_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "Ulke_List",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "785986",
      null,
      "left",
      null,
      "656px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_990350_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "TYayimci_List",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "282474",
      null,
      "left",
      null,
      "656px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_742423_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.Logout = await ReactSystemFunctions.logout();
    stateVars.tmpBoolResult = await ReactSystemFunctions.goToSignInActivity(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      25723,
      111489,
      794739,
      730997,
      583624,
      225586,
      627771,
      765059,
      497117,
      658027,
      500573,
      904965,
      330461,
      761143,
      785986,
      282474
    ] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.LeftMenuPageInit();
    }
  }
}
