import { FormInstance } from "antd/lib/form";
import { Guid } from "guid-typescript";
import React from "react";
import Swal from "sweetalert2";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IIKartDigital_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IIKartDigital_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  GoBack: any;
  GoBack_dummy: any;
  getListCariIlgiliKisiler: any[];
  getListCariIlgiliKisiler_dummy: any[];
  getListBFormuAksiyon: any[];
  getListBFormuAksiyon_dummy: any[];
  getListCariVarisBilgileri: any[];
  getListCariVarisBilgileri_dummy: any[];
  getListCariSozlesmeBilgileri: any[];
  getListCariSozlesmeBilgileri_dummy: any[];
  SelectCariById: any[];
  SelectCariById_dummy: any[];
  sbCariGrupList: any[];
  sbCariGrupList_dummy: any[];
  sbMusteriTuruList: any[];
  sbMusteriTuruList_dummy: any[];
  IKartDigital: any[];
  IKartDigital_dummy: any[];
  sbCariList: any[];
  sbCariList_dummy: any[];
  sbSozlesmeDonem: any[];
  sbSozlesmeDonem_dummy: any[];
  sbSozlesmeDurumu: any[];
  sbSozlesmeDurumu_dummy: any[];
  sbSozlesmeKanal: any[];
  sbSozlesmeKanal_dummy: any[];
  ImportInfoDigitalListByKartID: any[];
  ImportInfoDigitalListByKartID_dummy: any[];
  spGetSozlesmeDagitilacakFaturaNoList: any[];
  spGetSozlesmeDagitilacakFaturaNoList_dummy: any[];
  SozlesmeDagitIsTakipNoEsleListByImportKartPaketAnlasma: any[];
  SozlesmeDagitIsTakipNoEsleListByImportKartPaketAnlasma_dummy: any[];
  sbQuarterYearList: any[];
  sbQuarterYearList_dummy: any[];
  sbQuarterList: any[];
  sbQuarterList_dummy: any[];
  sbIDigitalExcelTur: any[];
  sbIDigitalExcelTur_dummy: any[];
  ChangeEnabledOf: any;
  ChangeEnabledOf_dummy: any;
  sbIlList: any[];
  sbIlList_dummy: any[];
  ChangeVisibilityOf: string;
  ChangeVisibilityOf_dummy: string;
  CariMusteriTuruSave: number;
  CariMusteriTuruSave_dummy: number;
  getListMusteriTuruByCariID: any[];
  getListMusteriTuruByCariID_dummy: any[];
  CariMusteriTuruDelete: number;
  CariMusteriTuruDelete_dummy: number;
  IKartDigitalSave: number;
  IKartDigitalSave_dummy: number;
  TriggerEvent: string;
  TriggerEvent_dummy: string;
  setBasvuruFormuAksiyonlarDelete: number;
  setBasvuruFormuAksiyonlarDelete_dummy: number;
  setKartCariIlgiliKisilerDelete: number;
  setKartCariIlgiliKisilerDelete_dummy: number;
  DownloadFile: any;
  DownloadFile_dummy: any;
  setKartCariSozlesmeBilgileriDelete: number;
  setKartCariSozlesmeBilgileriDelete_dummy: number;
  spGetSozlesmeDagitilacakFaturaTTutar: any[];
  spGetSozlesmeDagitilacakFaturaTTutar_dummy: any[];
  SetValueOf: any;
  SetValueOf_dummy: any;
  UploadFile: any;
  UploadFile_dummy: any;
  spGetSplitParaBirimID: any[];
  spGetSplitParaBirimID_dummy: any[];
  spImportDigitalInsert: any[];
  spImportDigitalInsert_dummy: any[];
  Notify: boolean;
  Notify_dummy: boolean;
  fnImportExcelDigital: any;
  fnImportExcelDigital_dummy: any;
  spImportInfoDigitalToplamSureSSayisiUpdate: any[];
  spImportInfoDigitalToplamSureSSayisiUpdate_dummy: any[];
  ImportInfoMeslekBirligiTRListByPaketAnlasmaID: any[];
  ImportInfoMeslekBirligiTRListByPaketAnlasmaID_dummy: any[];
  ImportInfoDigitalDelete: number;
  ImportInfoDigitalDelete_dummy: number;
  isComp435813Visible: "visible" | "hidden";
  isComp44395Visible: "visible" | "hidden";
  isComp908497Visible: "visible" | "hidden";
  isComp626253Visible: "visible" | "hidden";
  isComp855595Visible: "visible" | "hidden";
  isComp993420Visible: "visible" | "hidden";
  isComp925870Visible: "visible" | "hidden";
  isComp697593Visible: "visible" | "hidden";
  isComp645853Visible: "visible" | "hidden";
  isComp93844Visible: "visible" | "hidden";
  isComp43802Enabled: "enabled" | "disabled";
}

export class IKartDigital_ScreenBase extends React.PureComponent<IIKartDigital_ScreenProps, any> {
  ikartdigital_788213_value_kuikaSelectBoxRef: React.RefObject<any>;
  ikartdigital_626253_value_kuikaSelectBoxRef: React.RefObject<any>;
  ikartdigital_50856_value_kuikaSelectBoxRef: React.RefObject<any>;
  ikartdigital_387331_value_kuikaSelectBoxRef: React.RefObject<any>;
  ikartdigital_989333_value_kuikaTableRef: React.RefObject<any>;
  ikartdigital_130742_value_kuikaTableRef: React.RefObject<any>;
  ikartdigital_586219_value_kuikaTableRef: React.RefObject<any>;
  ikartdigital_98439_value_kuikaTableRef: React.RefObject<any>;
  ikartdigital_24013_value_kuikaTableRef: React.RefObject<any>;
  ikartdigital_758566_value_kuikaSelectBoxRef: React.RefObject<any>;
  ikartdigital_75605_value_kuikaSelectBoxRef: React.RefObject<any>;
  ikartdigital_722303_value_kuikaSelectBoxRef: React.RefObject<any>;
  ikartdigital_437247_value_kuikaTableRef: React.RefObject<any>;
  ml = [
    {
      Id: "16c151eb-42cf-0b2b-7e4a-e8b04fc43d88",
      Name: "tr_TR",
      ShortName: "Turkish",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "fbdbce9f-3fdb-30c5-c4fe-f7e443068251", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    { Id: "8468043e-f025-01e1-93b8-c71e3215778a", Name: "de_DE", ShortName: "German", LanguagePhrases: [] },
    {
      Id: "9bb21ee1-9ec8-4f93-b817-38f785a321c1",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 785916, PropertyName: "value", Value: "Digital Monetization Telif Kartı" },
        { Id: 70279, PropertyName: "value", Value: "Müşteri Adı" },
        { Id: 597603, PropertyName: "value", Value: "Sektörel Ad" },
        { Id: 911053, PropertyName: "value", Value: "Temsil Bölgesi" },
        { Id: 536198, PropertyName: "value", Value: "Kanal" },
        { Id: 908497, PropertyName: "value", Value: "Sözleşme Dönemi" },
        { Id: 556787, PropertyName: "value", Value: "Sözleşme Durumu" },
        { Id: 238965, PropertyName: "value", Value: "Kullanım Liste Durumu" },
        { Id: 387331, PropertyName: "placeholder", Value: "Kişi Seçiniz..." },
        { Id: 989333, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 483786, PropertyName: "value", Value: "[datafield:mturutanim]" },
        { Id: 861691, PropertyName: "label", Value: "Vazgeç" },
        { Id: 356553, PropertyName: "label", Value: "Kaydet" },
        { Id: 516287, PropertyName: "value", Value: "Önemli Notlar" },
        { Id: 635010, PropertyName: "value", Value: "Güncel Gelişmeler ve Notlar" },
        { Id: 124150, PropertyName: "title", Value: "Tarih" },
        { Id: 588709, PropertyName: "value", Value: "[datafield:aksiyontarihi]" },
        { Id: 790683, PropertyName: "title", Value: "Güncellemeyi Yapan" },
        { Id: 635026, PropertyName: "value", Value: "[datafield:nick]" },
        { Id: 156255, PropertyName: "title", Value: "Güncelleme Türü" },
        { Id: 534677, PropertyName: "value", Value: "[datafield:aksiyontanim]" },
        { Id: 178001, PropertyName: "title", Value: "Güncel Durum Detayı" },
        { Id: 58767, PropertyName: "value", Value: "[datafield:aciklama]" },
        { Id: 450613, PropertyName: "value", Value: "Ek İsim ve İletişim Bilgileri" },
        { Id: 586219, PropertyName: "nodatafoundmessage", Value: "Veri Bulunamadı" },
        { Id: 839802, PropertyName: "title", Value: "Ad Soyad" },
        { Id: 432076, PropertyName: "value", Value: "[datafield:adsoyad]" },
        { Id: 574033, PropertyName: "title", Value: "Görevi" },
        { Id: 56614, PropertyName: "value", Value: "[datafield:gorevi]" },
        { Id: 330558, PropertyName: "title", Value: "Telefon" },
        { Id: 164471, PropertyName: "value", Value: "[datafield:telefon]" },
        { Id: 754739, PropertyName: "title", Value: "Mail" },
        { Id: 128319, PropertyName: "value", Value: "[datafield:email]" },
        { Id: 764802, PropertyName: "value", Value: "Lisans Sözleşmeleri, Fatura ve İzinler" },
        { Id: 98439, PropertyName: "nodatafoundmessage", Value: "Veri Bulunamadı" },
        { Id: 901829, PropertyName: "title", Value: "Dönem" },
        { Id: 843021, PropertyName: "value", Value: "[datafield:yildonem]" },
        { Id: 361784, PropertyName: "title", Value: "S.Tutar" },
        { Id: 561783, PropertyName: "value", Value: "[datafield:toplamtutar]" },
        { Id: 993420, PropertyName: "title", Value: "Bakiye" },
        { Id: 284906, PropertyName: "value", Value: "[datafield:donemtutar]" },
        { Id: 925870, PropertyName: "title", Value: "Baş.Tarihi" },
        { Id: 938284, PropertyName: "value", Value: "[datafield:bastarihi]" },
        { Id: 697593, PropertyName: "title", Value: "Bit.Tarihi" },
        { Id: 97617, PropertyName: "value", Value: "[datafield:bittarihi]" },
        { Id: 387286, PropertyName: "title", Value: "PDF" },
        { Id: 436358, PropertyName: "value", Value: "İş Takip No Listesi" },
        { Id: 24013, PropertyName: "nodatafoundmessage", Value: "Veri Bulunamadı" },
        { Id: 730704, PropertyName: "title", Value: "Fatura İşlem Tarih" },
        { Id: 18628, PropertyName: "value", Value: "[datafield:faturatarihi]" },
        { Id: 315084, PropertyName: "title", Value: "İş Takip No" },
        { Id: 29743, PropertyName: "value", Value: "[datafield:ticketno]" },
        { Id: 870616, PropertyName: "value", Value: "Dönem" },
        { Id: 470309, PropertyName: "value", Value: "D.Yıl" },
        { Id: 534076, PropertyName: "value", Value: "D.Quarter" },
        { Id: 961625, PropertyName: "value", Value: "Eşlenecek Fatura" },
        { Id: 386196, PropertyName: "value", Value: "Fatura T.Tutar" },
        { Id: 898344, PropertyName: "value", Value: "Kur" },
        { Id: 734625, PropertyName: "value", Value: "Excel Türü" },
        { Id: 453101, PropertyName: "value", Value: "Aktarılan Excel Dosya Bilgisi" },
        { Id: 437247, PropertyName: "nodatafoundmessage", Value: "Veri Bulunamadı" },
        { Id: 842805, PropertyName: "title", Value: "Quarter / Dönem" },
        { Id: 549299, PropertyName: "value", Value: "[datafield:quarteryear]" },
        { Id: 702170, PropertyName: "value", Value: "[datafield:quarterkod]" },
        { Id: 364157, PropertyName: "value", Value: "[datafield:yildonem]" },
        { Id: 229869, PropertyName: "title", Value: "Liste Adı" },
        { Id: 414647, PropertyName: "value", Value: "[datafield:exceldosyaad]" },
        { Id: 259347, PropertyName: "title", Value: "Excel Türü" },
        { Id: 681735, PropertyName: "value", Value: "[datafield:exceltur]" },
        { Id: 331355, PropertyName: "title", Value: "Telif Tutar" },
        { Id: 891653, PropertyName: "value", Value: "[datafield:toplamtutar]" },
        { Id: 839610, PropertyName: "title", Value: "Satır Sayısı" },
        { Id: 32918, PropertyName: "value", Value: "[datafield:satirsayisi]" },
        { Id: 865635, PropertyName: "title", Value: "Kur" },
        { Id: 511973, PropertyName: "value", Value: "[datafield:kur]" },
        { Id: 178723, PropertyName: "title", Value: "Dağıtılacak Telif" },
        { Id: 930238, PropertyName: "value", Value: "[datafield:kontolttutar]" },
        { Id: 481437, PropertyName: "title", Value: "Fatura No" },
        { Id: 176896, PropertyName: "value", Value: "[datafield:faturanumaras]" },
        { Id: 350340, PropertyName: "label", Value: "Hata Kontrol" },
        { Id: 93844, PropertyName: "label", Value: "Hesapla" }
      ]
    }
  ];
  defaultML = "tr_TR";
  screenHash = "";

  constructor(props) {
    super(props);
    this.ikartdigital_788213_value_kuikaSelectBoxRef = React.createRef();
    this.ikartdigital_626253_value_kuikaSelectBoxRef = React.createRef();
    this.ikartdigital_50856_value_kuikaSelectBoxRef = React.createRef();
    this.ikartdigital_387331_value_kuikaSelectBoxRef = React.createRef();
    this.ikartdigital_989333_value_kuikaTableRef = React.createRef();
    this.ikartdigital_130742_value_kuikaTableRef = React.createRef();
    this.ikartdigital_586219_value_kuikaTableRef = React.createRef();
    this.ikartdigital_98439_value_kuikaTableRef = React.createRef();
    this.ikartdigital_24013_value_kuikaTableRef = React.createRef();
    this.ikartdigital_758566_value_kuikaSelectBoxRef = React.createRef();
    this.ikartdigital_75605_value_kuikaSelectBoxRef = React.createRef();
    this.ikartdigital_722303_value_kuikaSelectBoxRef = React.createRef();
    this.ikartdigital_437247_value_kuikaTableRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      NAVIGATE: "",
      GoBack: "",
      getListCariIlgiliKisiler: [],
      getListBFormuAksiyon: [],
      getListCariVarisBilgileri: [],
      getListCariSozlesmeBilgileri: [],
      SelectCariById: [],
      sbCariGrupList: [],
      sbMusteriTuruList: [],
      IKartDigital: [],
      sbCariList: [],
      sbSozlesmeDonem: [],
      sbSozlesmeDurumu: [],
      sbSozlesmeKanal: [],
      ImportInfoDigitalListByKartID: [],
      spGetSozlesmeDagitilacakFaturaNoList: [],
      SozlesmeDagitIsTakipNoEsleListByImportKartPaketAnlasma: [],
      sbQuarterYearList: [],
      sbQuarterList: [],
      sbIDigitalExcelTur: [],
      ChangeEnabledOf: "",
      sbIlList: [],
      ChangeVisibilityOf: "",
      CariMusteriTuruSave: "",
      getListMusteriTuruByCariID: [],
      CariMusteriTuruDelete: "",
      IKartDigitalSave: "",
      TriggerEvent: "",
      setBasvuruFormuAksiyonlarDelete: "",
      setKartCariIlgiliKisilerDelete: "",
      DownloadFile: "",
      setKartCariSozlesmeBilgileriDelete: "",
      spGetSozlesmeDagitilacakFaturaTTutar: [],
      SetValueOf: "",
      UploadFile: "",
      spGetSplitParaBirimID: [],
      spImportDigitalInsert: [],
      Notify: false,
      fnImportExcelDigital: "",
      spImportInfoDigitalToplamSureSSayisiUpdate: [],
      ImportInfoMeslekBirligiTRListByPaketAnlasmaID: [],
      ImportInfoDigitalDelete: "",
      isComp435813Visible: "hidden",
      isComp44395Visible: "hidden",
      isComp908497Visible: "hidden",
      isComp626253Visible: "hidden",
      isComp855595Visible: "hidden",
      isComp993420Visible: "hidden",
      isComp925870Visible: "hidden",
      isComp697593Visible: "hidden",
      isComp645853Visible: "hidden",
      isComp93844Visible: "hidden",
      isComp43802Enabled: "enabled"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("ikartdigital", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.IKartDigitalPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("ikartdigital", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("ikartdigital", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.IKartDigitalPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      ikartdigital_788213_value: this.state.IKartDigital?.at?.(0)?.musteriID ?? undefined,
      ikartdigital_941181_value: this.state.IKartDigital?.at?.(0)?.sektorelAd ?? undefined,
      ikartdigital_531670_value: this.state.IKartDigital?.at?.(0)?.yapimAdi ?? undefined,
      ikartdigital_120240_value: this.state.IKartDigital?.at?.(0)?.kanal ?? undefined,
      ikartdigital_626253_value: this.state.IKartDigital?.at?.(0)?.sozlesmeDonemID ?? undefined,
      ikartdigital_50856_value: this.state.IKartDigital?.at?.(0)?.sozlesmeDurumuID ?? undefined,
      ikartdigital_846368_value: this.state.IKartDigital?.at?.(0)?.onemliNot ?? undefined,
      ikartdigital_43802_value: undefined,
      ikartdigital_580968_value: undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  IKartDigitalPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      CariID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      ),
      BasvuruFormuID_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      ),
      CariID_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      ),
      CariID_3: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      ),
      CariID_4: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      ),
      Id_7: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      ),
      KartID_12: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      ),
      KartPaketID_13: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "string"
      ),
      ImportKartPaketAnlasmaID_14: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "IKartDigital/IKartDigitalPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.getListCariIlgiliKisiler = result?.data.getListCariIlgiliKisiler;

    stateVars.getListBFormuAksiyon = result?.data.getListBFormuAksiyon;

    stateVars.getListCariVarisBilgileri = result?.data.getListCariVarisBilgileri;
    stateVars.getListCariSozlesmeBilgileri = result?.data.getListCariSozlesmeBilgileri;

    stateVars.SelectCariById = result?.data.selectCariById;
    stateVars.sbCariGrupList = result?.data.sbCariGrupList;
    stateVars.sbMusteriTuruList = result?.data.sbMusteriTuruList;

    formVars.ikartdigital_387331_options = stateVars.sbMusteriTuruList;
    stateVars.IKartDigital = result?.data.iKartDigital;
    formVars.ikartdigital_788213_value =
      stateVars.IKartDigital?.length > 0 ? stateVars.IKartDigital[0]?.musteriID : null;
    formVars.ikartdigital_788213_options = stateVars.sbCariList;
    formVars.ikartdigital_941181_value = ReactSystemFunctions.toString(
      this,
      stateVars.IKartDigital?.length > 0 ? stateVars.IKartDigital[0]?.sektorelAd : null
    );
    formVars.ikartdigital_531670_value = ReactSystemFunctions.toString(
      this,
      stateVars.IKartDigital?.length > 0 ? stateVars.IKartDigital[0]?.yapimAdi : null
    );
    formVars.ikartdigital_120240_options = stateVars.sbSozlesmeKanal;
    formVars.ikartdigital_120240_value = stateVars.IKartDigital?.length > 0 ? stateVars.IKartDigital[0]?.kanal : null;
    formVars.ikartdigital_626253_value =
      stateVars.IKartDigital?.length > 0 ? stateVars.IKartDigital[0]?.sozlesmeDonemID : null;
    formVars.ikartdigital_626253_options = stateVars.sbSozlesmeDonem;
    formVars.ikartdigital_50856_value =
      stateVars.IKartDigital?.length > 0 ? stateVars.IKartDigital[0]?.sozlesmeDurumuID : null;
    formVars.ikartdigital_50856_options = stateVars.sbSozlesmeDurumu;
    formVars.ikartdigital_846368_value = ReactSystemFunctions.toString(
      this,
      stateVars.IKartDigital?.length > 0 ? stateVars.IKartDigital[0]?.onemliNot : null
    );
    stateVars.sbCariList = result?.data.sbCariList;

    formVars.ikartdigital_788213_value =
      stateVars.IKartDigital?.length > 0 ? stateVars.IKartDigital[0]?.musteriID : null;
    formVars.ikartdigital_788213_options = stateVars.sbCariList;
    stateVars.sbSozlesmeDonem = result?.data.sbSozlesmeDonem;

    formVars.ikartdigital_626253_value =
      stateVars.IKartDigital?.length > 0 ? stateVars.IKartDigital[0]?.sozlesmeDonemID : null;
    formVars.ikartdigital_626253_options = stateVars.sbSozlesmeDonem;
    stateVars.sbSozlesmeDurumu = result?.data.sbSozlesmeDurumu;

    formVars.ikartdigital_50856_value =
      stateVars.IKartDigital?.length > 0 ? stateVars.IKartDigital[0]?.sozlesmeDurumuID : null;
    formVars.ikartdigital_50856_options = stateVars.sbSozlesmeDurumu;
    stateVars.sbSozlesmeKanal = result?.data.sbSozlesmeKanal;

    formVars.ikartdigital_120240_options = stateVars.sbSozlesmeKanal;
    formVars.ikartdigital_120240_value = stateVars.IKartDigital?.length > 0 ? stateVars.IKartDigital[0]?.kanal : null;
    stateVars.ImportInfoDigitalListByKartID = result?.data.importInfoDigitalListByKartID;

    stateVars.spGetSozlesmeDagitilacakFaturaNoList = result?.data.spGetSozlesmeDagitilacakFaturaNoList;

    formVars.ikartdigital_914269_options = stateVars.spGetSozlesmeDagitilacakFaturaNoList;
    stateVars.SozlesmeDagitIsTakipNoEsleListByImportKartPaketAnlasma =
      result?.data.sozlesmeDagitIsTakipNoEsleListByImportKartPaketAnlasma;

    stateVars.sbQuarterYearList = result?.data.sbQuarterYearList;

    formVars.ikartdigital_758566_options = stateVars.sbQuarterYearList;
    stateVars.sbQuarterList = result?.data.sbQuarterList;

    formVars.ikartdigital_75605_options = stateVars.sbQuarterList;
    stateVars.sbIDigitalExcelTur = result?.data.sbIDigitalExcelTur;

    formVars.ikartdigital_722303_options = stateVars.sbIDigitalExcelTur;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.IKartDigitalPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  IKartDigitalPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp43802Enabled", "disabled");
    formVars.ikartdigital_788213_value = ReactSystemFunctions.toString(
      this,
      this.state.IKartDigital?.length > 0 ? this.state.IKartDigital[0]?.musteriID : null
    );

    stateVars.dataSource_788213 = this.state.sbCariList;
    stateVars.dataSource_788213 = this.state.sbCariList;
    formVars.ikartdigital_941181_value = ReactSystemFunctions.toString(
      this,
      this.state.IKartDigital?.length > 0 ? this.state.IKartDigital[0]?.sektorelAd : null
    );

    formVars.ikartdigital_531670_value = ReactSystemFunctions.toString(
      this,
      this.state.IKartDigital?.length > 0 ? this.state.IKartDigital[0]?.yapimAdi : null
    );

    formVars.ikartdigital_120240_value = ReactSystemFunctions.toString(
      this,
      this.state.IKartDigital?.length > 0 ? this.state.IKartDigital[0]?.kanal : null
    );

    stateVars.dataSource_120240 = this.state.sbSozlesmeKanal;
    stateVars.dataSource_120240 = this.state.sbSozlesmeKanal;
    formVars.ikartdigital_626253_value = ReactSystemFunctions.toString(
      this,
      this.state.IKartDigital?.length > 0 ? this.state.IKartDigital[0]?.sozlesmeDonemID : null
    );

    stateVars.dataSource_626253 = this.state.sbSozlesmeDonem;
    stateVars.dataSource_626253 = this.state.sbSozlesmeDonem;
    formVars.ikartdigital_50856_value = ReactSystemFunctions.toString(
      this,
      this.state.IKartDigital?.length > 0 ? this.state.IKartDigital[0]?.sozlesmeDurumuID : null
    );

    stateVars.dataSource_50856 = this.state.sbSozlesmeDurumu;
    stateVars.dataSource_50856 = this.state.sbSozlesmeDurumu;

    stateVars.dataSource_387331 = this.state.sbMusteriTuruList;
    stateVars.dataSource_387331 = this.state.sbMusteriTuruList;
    formVars.ikartdigital_846368_value = ReactSystemFunctions.toString(
      this,
      this.state.IKartDigital?.length > 0 ? this.state.IKartDigital[0]?.onemliNot : null
    );

    stateVars.dataSource_130742 = this.state.getListBFormuAksiyon;

    stateVars.dataSource_586219 = this.state.getListCariIlgiliKisiler;

    stateVars.dataSource_98439 = this.state.getListCariSozlesmeBilgileri;
    stateVars.isComp645853Visible =
      ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.value(this, "ikartdigital_98439_value", "dosya"), null) ===
      true
        ? "visible"
        : "hidden";

    stateVars.dataSource_24013 = this.state.SozlesmeDagitIsTakipNoEsleListByImportKartPaketAnlasma;

    stateVars.dataSource_758566 = this.state.sbQuarterYearList;
    stateVars.dataSource_758566 = this.state.sbQuarterYearList;

    stateVars.dataSource_75605 = this.state.sbQuarterList;
    stateVars.dataSource_75605 = this.state.sbQuarterList;

    stateVars.dataSource_914269 = this.state.spGetSozlesmeDagitilacakFaturaNoList;
    stateVars.dataSource_914269 = this.state.spGetSozlesmeDagitilacakFaturaNoList;

    stateVars.dataSource_722303 = this.state.sbIDigitalExcelTur;
    stateVars.dataSource_722303 = this.state.sbIDigitalExcelTur;

    stateVars.dataSource_437247 = this.state.ImportInfoDigitalListByKartID;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  IKartDigitalComponent_83067_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "IKartDigital",
      "LeftMenu",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "820946",
      "",
      "left",
      null,
      "",
      "",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  IKartDigitalComponent_996082_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  IKartDigitalComponent_626253_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      UlkeMhKodu_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "ikartdigital_626253_value", "value", "sbSozlesmeDonem", "id", "mhKodu")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "IKartDigital/IKartDigitalComponent_626253_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.sbIlList = result?.data.sbIlList;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  IKartDigitalComponent_782963_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
      this,
      "isComp435813Visible",
      "visible"
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  IKartDigitalComponent_121663_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      CariID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      ),
      MusteriTuruID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ikartdigital_387331_value", "value", "sbMusteriTuruList", "id", "id")
        ),
        "Guid"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(Guid.create().toString(), "Guid"),
      CariID_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "IKartDigital/IKartDigitalComponent_121663_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.CariMusteriTuruSave = result?.data.cariMusteriTuruSave;
    stateVars.getListMusteriTuruByCariID = result?.data.getListMusteriTuruByCariID;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.IKartDigitalComponent_121663_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  IKartDigitalComponent_121663_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_989333 = this.state.getListMusteriTuruByCariID;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  IKartDigitalComponent_69679_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, "isComp435813Visible", "hidden");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  IKartDigitalComponent_989982_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "ikartdigital_989333_value", "id"),
        "Guid"
      ),
      CariID_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    stateVars.Confirm = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "d97a4ef4_6cec_9df0_8452_44d3b04f92c2_confirmation",
        this.defaultML,
        "Seçilen Kayıt Silinecek; Devam Edilsin mi?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel",
      customClass: {
        title: "swal2-font-poppins"
      }
    });

    if (stateVars.Confirm.isConfirmed) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "IKartDigital/IKartDigitalComponent_989982_onClick",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.CariMusteriTuruDelete = result?.data.cariMusteriTuruDelete;
      stateVars.getListMusteriTuruByCariID = result?.data.getListMusteriTuruByCariID;

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.IKartDigitalComponent_989982_onClick1_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  IKartDigitalComponent_989982_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_989333 = this.state.getListMusteriTuruByCariID;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  IKartDigitalComponent_861691_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  IKartDigitalComponent_356553_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Kanal_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "ikartdigital_120240_value", "value", "sbSozlesmeKanal", "id", "id")
        ),
        "string"
      ),
      MusteriID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ikartdigital_788213_value", "value", "sbCariList", "id", "id")
        ),
        "Guid"
      ),
      OnemliNot_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "ikartdigital_846368_value", "value", "", "", "")
        ),
        "string"
      ),
      SektorelAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "ikartdigital_941181_value", "value", "", "", "")
        ),
        "string"
      ),
      SozlesmeDonemID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ikartdigital_626253_value", "value", "sbSozlesmeDonem", "id", "id")
        ),
        "Guid"
      ),
      SozlesmeDurumuID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ikartdigital_50856_value", "value", "sbSozlesmeDurumu", "id", "id")
        ),
        "Guid"
      ),
      YapimAdi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "ikartdigital_531670_value", "value", "", "", "")
        ),
        "string"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "IKartDigital/IKartDigitalComponent_356553_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.IKartDigitalSave = result?.data.iKartDigitalSave;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  IKartDigitalComponent_429025_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    if (await this.IKartDigitalComponent_356553_onClick()) return true;

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables("AksiyonKart", "prmGuid", Guid.create().toString());
    KuikaAppManager.addToPageInputVariables("AksiyonKart", "NewDisplay", "N");
    KuikaAppManager.addToPageInputVariables(
      "AksiyonKart",
      "BasvuruFormuID",
      this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "IKartDigital",
      "AksiyonKart",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "854058",
      "",
      "right",
      null,
      "480",
      "",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  IKartDigitalComponent_232864_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "ikartdigital_130742_value", "id"),
        "Guid"
      ),
      BasvuruFormuID_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "IKartDigital/IKartDigitalComponent_232864_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.setBasvuruFormuAksiyonlarDelete = result?.data.setBasvuruFormuAksiyonlarDelete;
    stateVars.getListBFormuAksiyon = result?.data.getListBFormuAksiyon;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.IKartDigitalComponent_232864_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  IKartDigitalComponent_232864_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_130742 = this.state.getListBFormuAksiyon;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  IKartDigitalComponent_8393_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "AksiyonKart",
      "prmGuid",
      ReactSystemFunctions.value(this, "ikartdigital_130742_value", "id")
    );
    KuikaAppManager.addToPageInputVariables("AksiyonKart", "NewDisplay", "E");
    KuikaAppManager.addToPageInputVariables(
      "AksiyonKart",
      "BasvuruFormuID",
      this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      this.IKartDigitalComponent_8393_onClick1_,
      "IKartDigital",
      "AksiyonKart",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "402622",
      "",
      "right",
      null,
      "480",
      "",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };
  IKartDigitalComponent_8393_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      BasvuruFormuID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "IKartDigital/IKartDigitalComponent_8393_onClick1_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.getListBFormuAksiyon = result?.data.getListBFormuAksiyon;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.IKartDigitalComponent_8393_onClick2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  IKartDigitalComponent_8393_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_130742 = this.state.getListBFormuAksiyon;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  IKartDigitalComponent_758058_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    if (await this.IKartDigitalComponent_356553_onClick()) return true;

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables("CariIlgiliKisiler", "prmGuid", Guid.create().toString());
    KuikaAppManager.addToPageInputVariables(
      "CariIlgiliKisiler",
      "prmCariID",
      this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "IKartDigital",
      "CariIlgiliKisiler",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "211229",
      null,
      "right",
      null,
      "480",
      "",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  IKartDigitalComponent_123813_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "ikartdigital_586219_value", "id"),
        "Guid"
      ),
      CariID_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    stateVars.Confirm = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "5156216c_728b_d3a2_1939_536fa9f0f06c_confirmation",
        this.defaultML,
        "Seçilen Kayır Silinecek; Devm Edilsin mi?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel",
      customClass: {
        title: "swal2-font-poppins"
      }
    });

    if (stateVars.Confirm.isConfirmed) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "IKartDigital/IKartDigitalComponent_123813_onClick",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.setKartCariIlgiliKisilerDelete = result?.data.setKartCariIlgiliKisilerDelete;
      stateVars.getListCariIlgiliKisiler = result?.data.getListCariIlgiliKisiler;

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.IKartDigitalComponent_123813_onClick1_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  IKartDigitalComponent_123813_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_586219 = this.state.getListCariIlgiliKisiler;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  IKartDigitalComponent_574746_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "CariIlgiliKisiler",
      "prmGuid",
      ReactSystemFunctions.value(this, "ikartdigital_586219_value", "id")
    );
    KuikaAppManager.addToPageInputVariables(
      "CariIlgiliKisiler",
      "prmCariID",
      this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      this.IKartDigitalComponent_574746_onClick1_,
      "IKartDigital",
      "CariIlgiliKisiler",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "199855",
      null,
      "right",
      null,
      "480",
      "",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };
  IKartDigitalComponent_574746_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      CariID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "IKartDigital/IKartDigitalComponent_574746_onClick1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.getListCariIlgiliKisiler = result?.data.getListCariIlgiliKisiler;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.IKartDigitalComponent_574746_onClick2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  IKartDigitalComponent_574746_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_586219 = this.state.getListCariIlgiliKisiler;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  IKartDigitalComponent_238029_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    if (await this.IKartDigitalComponent_356553_onClick()) return true;

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables("CariSozlesmeler", "prmGuid", Guid.create().toString());
    KuikaAppManager.addToPageInputVariables(
      "CariSozlesmeler",
      "prmCariID",
      this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid
    );
    KuikaAppManager.addToPageInputVariables("CariSozlesmeler", "prmNerdenGeldi", "sozlesme");
    KuikaAppManager.addToPageInputVariables(
      "CariSozlesmeler",
      "prmIsKartTuruID",
      "00000000-0000-0000-0000-000000000005"
    );
    KuikaAppManager.addToPageInputVariables(
      "CariSozlesmeler",
      "prmMusteriID",
      ReactSystemFunctions.value(
        this,
        ReactSystemFunctions.value(this, "ikartdigital_788213_value", "value", "sbCariList", "id", "id")
      )
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      this.IKartDigitalComponent_238029_onClick1_,
      "IKartDigital",
      "CariSozlesmeler",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "201256",
      null,
      "right",
      null,
      "50%",
      "",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };
  IKartDigitalComponent_238029_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      CariID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      ),
      ImportKartPaketAnlasmaID_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "IKartDigital/IKartDigitalComponent_238029_onClick1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.getListCariSozlesmeBilgileri = result?.data.getListCariSozlesmeBilgileri;

    stateVars.SozlesmeDagitIsTakipNoEsleListByImportKartPaketAnlasma =
      result?.data.sozlesmeDagitIsTakipNoEsleListByImportKartPaketAnlasma;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.IKartDigitalComponent_238029_onClick2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  IKartDigitalComponent_238029_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_98439 = this.state.getListCariSozlesmeBilgileri;

    stateVars.dataSource_24013 = this.state.SozlesmeDagitIsTakipNoEsleListByImportKartPaketAnlasma;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  IKartDigitalComponent_645853_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.DownloadFile = await ReactSystemFunctions.downloadFile(
      ReactSystemFunctions.value(this, "ikartdigital_98439_value", "dosya"),
      ReactSystemFunctions.value(this, "ikartdigital_98439_value", "dosyaAd")
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  IKartDigitalComponent_482140_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "ikartdigital_98439_value", "id"),
        "Guid"
      ),
      CariID_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    stateVars.Confirm = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "f0cd172f_d6e0_a12c_4a8d_f1ef1a129b93_confirmation",
        this.defaultML,
        "Seçilen Kayıt Silinecek; Devam Edilsin mi?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel",
      customClass: {
        title: "swal2-font-poppins"
      }
    });

    if (stateVars.Confirm.isConfirmed) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "IKartDigital/IKartDigitalComponent_482140_onClick",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.setKartCariSozlesmeBilgileriDelete = result?.data.setKartCariSozlesmeBilgileriDelete;
      stateVars.getListCariSozlesmeBilgileri = result?.data.getListCariSozlesmeBilgileri;

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.IKartDigitalComponent_482140_onClick1_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  IKartDigitalComponent_482140_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_98439 = this.state.getListCariSozlesmeBilgileri;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  IKartDigitalComponent_227884_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "CariSozlesmeler",
      "prmGuid",
      ReactSystemFunctions.value(this, "ikartdigital_98439_value", "id")
    );
    KuikaAppManager.addToPageInputVariables(
      "CariSozlesmeler",
      "prmCariID",
      this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid
    );
    KuikaAppManager.addToPageInputVariables("CariSozlesmeler", "prmNerdenGeldi", "sozlesme");
    KuikaAppManager.addToPageInputVariables(
      "CariSozlesmeler",
      "prmIsKartTuruID",
      "00000000-0000-0000-0000-000000000005"
    );
    KuikaAppManager.addToPageInputVariables(
      "CariSozlesmeler",
      "prmMusteriID",
      ReactSystemFunctions.value(
        this,
        ReactSystemFunctions.value(this, "ikartdigital_788213_value", "value", "sbCariList", "id", "id")
      )
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      this.IKartDigitalComponent_227884_onClick1_,
      "IKartDigital",
      "CariSozlesmeler",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "397141",
      null,
      "right",
      null,
      "50%",
      "",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };
  IKartDigitalComponent_227884_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      CariID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      ),
      ImportKartPaketAnlasmaID_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "IKartDigital/IKartDigitalComponent_227884_onClick1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.getListCariSozlesmeBilgileri = result?.data.getListCariSozlesmeBilgileri;

    stateVars.SozlesmeDagitIsTakipNoEsleListByImportKartPaketAnlasma =
      result?.data.sozlesmeDagitIsTakipNoEsleListByImportKartPaketAnlasma;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.IKartDigitalComponent_227884_onClick2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  IKartDigitalComponent_227884_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_98439 = this.state.getListCariSozlesmeBilgileri;

    stateVars.dataSource_24013 = this.state.SozlesmeDagitIsTakipNoEsleListByImportKartPaketAnlasma;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  IKartDigitalComponent_914269_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "ikartdigital_914269_value",
            "value",
            "spGetSozlesmeDagitilacakFaturaNoList",
            "logoFaturaNo",
            ""
          )
        ),
        null
      ),
      message: "*",
      formName: "ikartdigital_914269_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      FaturaNos_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "ikartdigital_914269_value",
            "value",
            "spGetSozlesmeDagitilacakFaturaNoList",
            "logoFaturaNo",
            "logoFaturaNo"
          )
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "IKartDigital/IKartDigitalComponent_914269_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.spGetSozlesmeDagitilacakFaturaTTutar = result?.data.spGetSozlesmeDagitilacakFaturaTTutar;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.IKartDigitalComponent_914269_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  IKartDigitalComponent_914269_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "ikartdigital_43802_value",
      ReactSystemFunctions.value(
        this,
        this.state.spGetSozlesmeDagitilacakFaturaTTutar?.length > 0
          ? this.state.spGetSozlesmeDagitilacakFaturaTTutar[0]?.logoFaturaTTutar
          : null
      ),
      null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  IKartDigitalComponent_725999_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.UploadFile = ((await ReactSystemFunctions.uploadFileWithInfo()) as any).data;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.IKartDigitalComponent_725999_onClick1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  IKartDigitalComponent_725999_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "ikartdigital_914269_value",
            "value",
            "spGetSozlesmeDagitilacakFaturaNoList",
            "logoFaturaNo",
            ""
          )
        ),
        null
      ),
      message: "*",
      formName: "ikartdigital_914269_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      ids_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "ikartdigital_914269_value",
            "value",
            "spGetSozlesmeDagitilacakFaturaNoList",
            "logoFaturaNo",
            "paraBirimID"
          )
        ),
        "string"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "IKartDigital/IKartDigitalComponent_725999_onClick1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.spGetSplitParaBirimID = result?.data.spGetSplitParaBirimID;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.IKartDigitalComponent_725999_onClick2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  IKartDigitalComponent_725999_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "ikartdigital_693150_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "ikartdigital_693150_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "ikartdigital_758566_value", "value", "sbQuarterYearList", "quarterYear", "")
        ),
        null
      ),
      message: "*",
      formName: "ikartdigital_758566_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "ikartdigital_75605_value", "value", "sbQuarterList", "id", "")
        ),
        null
      ),
      message: "*",
      formName: "ikartdigital_75605_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "ikartdigital_914269_value",
            "value",
            "spGetSozlesmeDagitilacakFaturaNoList",
            "logoFaturaNo",
            ""
          )
        ),
        null
      ),
      message: "*",
      formName: "ikartdigital_914269_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ikartdigital_580968_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "ikartdigital_580968_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      ExcelDosyaAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.name),
        "string"
      ),
      ImportKartID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      ),
      YilDonem_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ikartdigital_693150_value", "value", "", "", "")
        ),
        "string"
      ),
      ExcelData_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.data),
        "string"
      ),
      ParaBirimID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.spGetSplitParaBirimID?.length > 0 ? this.state.spGetSplitParaBirimID[0]?.getParaBirimID : null
        ),
        "Guid"
      ),
      QuarterYear_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "ikartdigital_758566_value",
            "value",
            "sbQuarterYearList",
            "quarterYear",
            "quarterYear"
          )
        ),
        "number"
      ),
      QuarterKod_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "ikartdigital_75605_value", "value", "sbQuarterList", "id", "quarterKod")
        ),
        "string"
      ),
      faturanumaras_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "ikartdigital_914269_value",
            "value",
            "spGetSozlesmeDagitilacakFaturaNoList",
            "logoFaturaNo",
            "logoFaturaNo"
          )
        ),
        "string"
      ),
      Kur_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ikartdigital_580968_value", "value", "", "", "")
        ),
        "number"
      )
    };

    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.UploadFile?.name), null)) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "IKartDigital/IKartDigitalComponent_725999_onClick2_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.spImportDigitalInsert = result?.data.spImportDigitalInsert;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.IKartDigitalComponent_725999_onClick3_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.IKartDigitalComponent_725999_onClick3_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  IKartDigitalComponent_725999_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.UploadFile?.name), null)) {
      stateVars.Notify = await ReactSystemFunctions.notify(
        this,
        ReactSystemFunctions.translateCustomActions(
          this.ml,
          "5e88e7b8_8db6_c877_2895_fde47e7b9b87_notify",
          this.defaultML,
          "excel import ediliyor"
        ),
        "info",
        "bottom-right",
        2,
        null,
        null,
        null
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.IKartDigitalComponent_725999_onClick4_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.IKartDigitalComponent_725999_onClick4_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  IKartDigitalComponent_725999_onClick4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ikartdigital_580968_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "ikartdigital_580968_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "ikartdigital_722303_value", "value", "sbIDigitalExcelTur", "id", "")
        ),
        null
      ),
      message: "*",
      formName: "ikartdigital_722303_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      ImportKartID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "string"
      ),
      ImportInfoID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.spImportDigitalInsert?.length > 0 ? this.state.spImportDigitalInsert[0]?.getNewId : null
        ),
        "string"
      ),
      Kur_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ikartdigital_580968_value", "value", "", "", "")
        ),
        "number"
      ),
      ExcelTurKodu_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ikartdigital_722303_value", "value", "sbIDigitalExcelTur", "id", "kod")
        ),
        "number"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "IKartDigital/IKartDigitalComponent_725999_onClick4_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.IKartDigitalComponent_725999_onClick5_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  IKartDigitalComponent_725999_onClick5_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.UploadFile?.name), null)) {
      stateVars.Notify = await ReactSystemFunctions.notify(
        this,
        ReactSystemFunctions.translateCustomActions(
          this.ml,
          "e07e74ea_f428_6b28_a0a0_2c007151cc98_notify",
          this.defaultML,
          "excel import edildi"
        ),
        "success",
        "bottom-right",
        2,
        null,
        null,
        null
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.IKartDigitalComponent_725999_onClick6_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.IKartDigitalComponent_725999_onClick6_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  IKartDigitalComponent_725999_onClick6_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      KartPaketID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "string"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "IKartDigital/IKartDigitalComponent_725999_onClick6_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.spGetSozlesmeDagitilacakFaturaNoList = result?.data.spGetSozlesmeDagitilacakFaturaNoList;

    formVars.ikartdigital_914269_options = stateVars.spGetSozlesmeDagitilacakFaturaNoList;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.IKartDigitalComponent_725999_onClick7_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  IKartDigitalComponent_725999_onClick7_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      ImportKartDigitalID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      ),
      ImportInfoDigitalID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.spImportDigitalInsert?.length > 0 ? this.state.spImportDigitalInsert[0]?.getNewId : null
        ),
        "Guid"
      )
    };

    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.UploadFile?.name), null)) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "IKartDigital/IKartDigitalComponent_725999_onClick7_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.spImportInfoDigitalToplamSureSSayisiUpdate = result?.data.spImportInfoDigitalToplamSureSSayisiUpdate;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.IKartDigitalComponent_725999_onClick8_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.IKartDigitalComponent_725999_onClick8_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  IKartDigitalComponent_725999_onClick8_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      KartID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "IKartDigital/IKartDigitalComponent_725999_onClick8_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.ImportInfoDigitalListByKartID = result?.data.importInfoDigitalListByKartID;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.IKartDigitalComponent_725999_onClick9_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  IKartDigitalComponent_725999_onClick9_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.UploadFile?.name), null)) {
      if (await this.IKartDigitalComponent_350340_onClick()) return true;

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.IKartDigitalComponent_725999_onClick10_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.IKartDigitalComponent_725999_onClick10_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  IKartDigitalComponent_725999_onClick10_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_914269 = this.state.spGetSozlesmeDagitilacakFaturaNoList;
    stateVars.dataSource_914269 = this.state.spGetSozlesmeDagitilacakFaturaNoList;

    stateVars.dataSource_437247 = this.state.ImportInfoDigitalListByKartID;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  IKartDigitalComponent_522651_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "QuarterChange",
      "prmGuid",
      ReactSystemFunctions.value(this, "ikartdigital_437247_value", "id")
    );
    KuikaAppManager.addToPageInputVariables("QuarterChange", "nereden", "excelD");

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      this.IKartDigitalComponent_522651_onClick1_,
      "IKartDigital",
      "QuarterChange",
      "",
      "9924ef5f-4b84-4200-b624-8d76348c7686",
      "570586",
      null,
      null,
      "true",
      "380px",
      "25vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };
  IKartDigitalComponent_522651_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      PaketAnlasmaID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "IKartDigital/IKartDigitalComponent_522651_onClick1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.ImportInfoMeslekBirligiTRListByPaketAnlasmaID =
      result?.data.importInfoMeslekBirligiTRListByPaketAnlasmaID;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  IKartDigitalComponent_302087_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      ImportInfoID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "ikartdigital_437247_value", "id"),
        "Guid"
      ),
      KartPaketID_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "string"
      ),
      KartID_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    stateVars.Confirm = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "a11067c4_26d3_2bd9_8f70_b5916913adff_confirmation",
        this.defaultML,
        "Seçilen Kayıt Silinecek; Devam Edilsin mi?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel",
      customClass: {
        title: "swal2-font-poppins"
      }
    });

    if (stateVars.Confirm.isConfirmed) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "IKartDigital/IKartDigitalComponent_302087_onClick",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.ImportInfoDigitalDelete = result?.data.importInfoDigitalDelete;
      stateVars.spGetSozlesmeDagitilacakFaturaNoList = result?.data.spGetSozlesmeDagitilacakFaturaNoList;

      formVars.ikartdigital_914269_options = stateVars.spGetSozlesmeDagitilacakFaturaNoList;
      stateVars.ImportInfoDigitalListByKartID = result?.data.importInfoDigitalListByKartID;

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.IKartDigitalComponent_302087_onClick1_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  IKartDigitalComponent_302087_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_914269 = this.state.spGetSozlesmeDagitilacakFaturaNoList;
    stateVars.dataSource_914269 = this.state.spGetSozlesmeDagitilacakFaturaNoList;

    stateVars.dataSource_437247 = this.state.ImportInfoDigitalListByKartID;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  IKartDigitalComponent_350340_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables("HataKontrol", "KontrolParametresi", "D");

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "IKartDigital",
      "HataKontrol",
      "",
      "9924ef5f-4b84-4200-b624-8d76348c7686",
      "836869",
      null,
      null,
      "true",
      "800px",
      "70vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  IKartDigitalComponent_645853_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [820946, 854058, 402622, 211229, 199855, 201256, 397141, 570586, 836869] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.IKartDigitalPageInit();
    }
    if (diId == 402622) {
      isErrorOccurred = await this.IKartDigitalComponent_8393_onClick1_();
      if (isErrorOccurred) return true;
    } else if (diId == 199855) {
      isErrorOccurred = await this.IKartDigitalComponent_574746_onClick1_();
      if (isErrorOccurred) return true;
    } else if (diId == 201256) {
      isErrorOccurred = await this.IKartDigitalComponent_238029_onClick1_();
      if (isErrorOccurred) return true;
    } else if (diId == 397141) {
      isErrorOccurred = await this.IKartDigitalComponent_227884_onClick1_();
      if (isErrorOccurred) return true;
    } else if (diId == 570586) {
      isErrorOccurred = await this.IKartDigitalComponent_522651_onClick1_();
      if (isErrorOccurred) return true;
    }
  }
}
