import { FormInstance } from "antd/lib/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IParcaEkle_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IParcaEkle_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GoBack: any;
  GoBack_dummy: any;
  SelectParcalarByID: any[];
  SelectParcalarByID_dummy: any[];
  SaveParcalar: number;
  SaveParcalar_dummy: number;
  Notify: boolean;
  Notify_dummy: boolean;
}

export class ParcaEkle_ScreenBase extends React.PureComponent<IParcaEkle_ScreenProps, any> {
  ml = [
    {
      Id: "16c151eb-42cf-0b2b-7e4a-e8b04fc43d88",
      Name: "tr_TR",
      ShortName: "Turkish",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "fbdbce9f-3fdb-30c5-c4fe-f7e443068251", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    { Id: "8468043e-f025-01e1-93b8-c71e3215778a", Name: "de_DE", ShortName: "German", LanguagePhrases: [] },
    {
      Id: "edfab940-9da0-4775-b3d4-e3a5817c12c1",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 423965, PropertyName: "value", Value: "Parça" },
        { Id: 426824, PropertyName: "value", Value: "Parça No" },
        { Id: 535770, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 425702, PropertyName: "value", Value: "Parçanın İsmi" },
        { Id: 372734, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 171115, PropertyName: "label", Value: "Kaydet" }
      ]
    }
  ];
  defaultML = "tr_TR";
  screenHash = "";

  constructor(props) {
    super(props);

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GoBack: "",
      SelectParcalarByID: [],
      SaveParcalar: "",
      Notify: false
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("parcaekle", "");
      return;
    }

    if (true) {
      await this.ParcaEklePageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("parcaekle", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("parcaekle", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.ParcaEklePageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      parcaekle_535770_value: this.state.SelectParcalarByID?.at?.(0)?.trackNo ?? undefined,
      parcaekle_372734_value: this.state.SelectParcalarByID?.at?.(0)?.parcaAdi ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  ParcaEklePageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prm_AlbumID ?? this.props.screenInputs.prm_albumid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "ParcaEkle/ParcaEklePageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SelectParcalarByID = result?.data.selectParcalarByID;
    formVars.parcaekle_535770_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectParcalarByID?.length > 0 ? stateVars.SelectParcalarByID[0]?.trackNo : null
    );
    formVars.parcaekle_372734_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectParcalarByID?.length > 0 ? stateVars.SelectParcalarByID[0]?.parcaAdi : null
    );
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.ParcaEklePageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ParcaEklePageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.parcaekle_535770_value = ReactSystemFunctions.toString(
      this,
      this.state.SelectParcalarByID?.length > 0 ? this.state.SelectParcalarByID[0]?.trackNo : null
    );

    formVars.parcaekle_372734_value = ReactSystemFunctions.toString(
      this,
      this.state.SelectParcalarByID?.length > 0 ? this.state.SelectParcalarByID[0]?.parcaAdi : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  ParcaEkleComponent_674464_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParcaEkleComponent_171115_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Album_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prm_AlbumID ?? this.props.screenInputs.prm_albumid,
        "Guid"
      ),
      ParcaAdi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parcaekle_372734_value", "value", "", "", "")
        ),
        "string"
      ),
      TrackNo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parcaekle_535770_value", "value", "", "", "")
        ),
        "string"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prm_EserID ?? this.props.screenInputs.prm_eserid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "ParcaEkle/ParcaEkleComponent_171115_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveParcalar = result?.data.saveParcalar;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.ParcaEkleComponent_171115_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ParcaEkleComponent_171115_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "7f3660c9_d46a_a5b1_68a6_78955f47553b_notify",
        this.defaultML,
        "Parça Eklendi"
      ),
      "default",
      "bottom-right",
      0,
      null,
      null,
      null
    );
    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
