import { FormInstance } from "antd/lib/form";
import { Guid } from "guid-typescript";
import React from "react";
import Swal from "sweetalert2";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IIsTakipKart_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IIsTakipKart_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  getBasvuruTuruKodById: any[];
  getBasvuruTuruKodById_dummy: any[];
  getProjeTuruFormKodById: any[];
  getProjeTuruFormKodById_dummy: any[];
  getFormNo: any[];
  getFormNo_dummy: any[];
  SendMail: boolean;
  SendMail_dummy: boolean;
  GoBack: any;
  GoBack_dummy: any;
  getUserInfo: any[];
  getUserInfo_dummy: any[];
  spGetIsKartReadOnly: any[];
  spGetIsKartReadOnly_dummy: any[];
  sbFormStatuList: any[];
  sbFormStatuList_dummy: any[];
  sbProjeTuruList: any[];
  sbProjeTuruList_dummy: any[];
  sbMecraList: any[];
  sbMecraList_dummy: any[];
  sbBolgeList: any[];
  sbBolgeList_dummy: any[];
  sbTelifTurList: any[];
  sbTelifTurList_dummy: any[];
  sbBasvuruTuruList: any[];
  sbBasvuruTuruList_dummy: any[];
  sbLisansTipiList: any[];
  sbLisansTipiList_dummy: any[];
  sbStopajList: any[];
  sbStopajList_dummy: any[];
  sbIzinTuruList: any[];
  sbIzinTuruList_dummy: any[];
  sbMunhasirlikList: any[];
  sbMunhasirlikList_dummy: any[];
  sbVadeGunList: any[];
  sbVadeGunList_dummy: any[];
  getListBFormuAksiyon: any[];
  getListBFormuAksiyon_dummy: any[];
  getListCari: any[];
  getListCari_dummy: any[];
  KUserList: any[];
  KUserList_dummy: any[];
  getBasvuruFormuById: any[];
  getBasvuruFormuById_dummy: any[];
  getListKullanimSureleriByBFId: any[];
  getListKullanimSureleriByBFId_dummy: any[];
  spgetListKullanimSureleriByBFId_brief: any[];
  spgetListKullanimSureleriByBFId_brief_dummy: any[];
  spDipToplamByBFId: any[];
  spDipToplamByBFId_dummy: any[];
  getListFiyatlandirmaNotlar: any[];
  getListFiyatlandirmaNotlar_dummy: any[];
  getListIsTakipCCbyBFID: any[];
  getListIsTakipCCbyBFID_dummy: any[];
  spGetProjeTuruByBTuruKod: any[];
  spGetProjeTuruByBTuruKod_dummy: any[];
  spArsivGeriAlVisible: any[];
  spArsivGeriAlVisible_dummy: any[];
  ChangeEnabledOf: any;
  ChangeEnabledOf_dummy: any;
  ChangeVisibilityOf: string;
  ChangeVisibilityOf_dummy: string;
  spSetArsivGeriAl: number;
  spSetArsivGeriAl_dummy: number;
  Notify: boolean;
  Notify_dummy: boolean;
  setIsKartArsivDurumu: number;
  setIsKartArsivDurumu_dummy: number;
  setIsTakipGeriBirak: number;
  setIsTakipGeriBirak_dummy: number;
  DiziFilmAlanKontrol: any[];
  DiziFilmAlanKontrol_dummy: any[];
  FaturaKesilecekFirmaKontrol: number;
  FaturaKesilecekFirmaKontrol_dummy: number;
  setUpdateIsKartiDurum: any[];
  setUpdateIsKartiDurum_dummy: any[];
  BasvuruFormuLogoStokSatirAciklamaUpdate: number;
  BasvuruFormuLogoStokSatirAciklamaUpdate_dummy: number;
  MailSubjectOnaylandi: any[];
  MailSubjectOnaylandi_dummy: any[];
  setUpdateIsKartiAksiyonKisi: number;
  setUpdateIsKartiAksiyonKisi_dummy: number;
  setIsTakipCCSave: number;
  setIsTakipCCSave_dummy: number;
  SetValueOf: any;
  SetValueOf_dummy: any;
  setIsTakipCCDelete: number;
  setIsTakipCCDelete_dummy: number;
  spUpdateBFormuinISTakip: any[];
  spUpdateBFormuinISTakip_dummy: any[];
  setBasvuruFormuAksiyonlarDelete: number;
  setBasvuruFormuAksiyonlarDelete_dummy: number;
  spUpdateServisUcret: any[];
  spUpdateServisUcret_dummy: any[];
  setBasvuruFormuSozlesmeBilgileriSave: number;
  setBasvuruFormuSozlesmeBilgileriSave_dummy: number;
  setUpdateCariId: number;
  setUpdateCariId_dummy: number;
  spUpdateFiyatlandirmaByBFId: any[];
  spUpdateFiyatlandirmaByBFId_dummy: any[];
  spUpdateIndirimliFiyatKullan: any[];
  spUpdateIndirimliFiyatKullan_dummy: any[];
  setUpdateLisansTipiID: number;
  setUpdateLisansTipiID_dummy: number;
  setUpdateTelifTurId: number;
  setUpdateTelifTurId_dummy: number;
  setKullanimSureleriInsert: any[];
  setKullanimSureleriInsert_dummy: any[];
  spUpdateFiyatlandirmaByInsertId: any[];
  spUpdateFiyatlandirmaByInsertId_dummy: any[];
  spUpdateFiyatlandirmaByCurrentId: any[];
  spUpdateFiyatlandirmaByCurrentId_dummy: any[];
  delKullanimSureleriById: number;
  delKullanimSureleriById_dummy: number;
  setStopajOranUpdate: number;
  setStopajOranUpdate_dummy: number;
  spUpdateGenelIndirimTutar: any[];
  spUpdateGenelIndirimTutar_dummy: any[];
  setLogoSatirVadeUpdate: number;
  setLogoSatirVadeUpdate_dummy: number;
  setLogoFaturaAciklamaUpdate: number;
  setLogoFaturaAciklamaUpdate_dummy: number;
  TriggerEvent: string;
  TriggerEvent_dummy: string;
  spLogoAktarKontrol: any[];
  spLogoAktarKontrol_dummy: any[];
  LogoObjectAddSalesOrderFromLogoconnectapi: any;
  LogoObjectAddSalesOrderFromLogoconnectapi_dummy: any;
  setQuarterDate: number;
  setQuarterDate_dummy: number;
  LogoObjectTigerLogoRemoteUpdateFromLogoconnectapi: any;
  LogoObjectTigerLogoRemoteUpdateFromLogoconnectapi_dummy: any;
  isComp756009Visible: "visible" | "hidden";
  isComp104543Visible: "visible" | "hidden";
  isComp417866Visible: "visible" | "hidden";
  isComp927155Visible: "visible" | "hidden";
  isComp363488Visible: "visible" | "hidden";
  isComp591012Visible: "visible" | "hidden";
  isComp451131Visible: "visible" | "hidden";
  isComp217616Visible: "visible" | "hidden";
  isComp103661Visible: "visible" | "hidden";
  isComp209641Visible: "visible" | "hidden";
  isComp377079Visible: "visible" | "hidden";
  isComp23905Visible: "visible" | "hidden";
  isComp176459Visible: "visible" | "hidden";
  isComp391583Visible: "visible" | "hidden";
  isComp608275Visible: "visible" | "hidden";
  isComp866931Visible: "visible" | "hidden";
  isComp794716Visible: "visible" | "hidden";
  isComp18544Visible: "visible" | "hidden";
  isComp545617Visible: "visible" | "hidden";
  isComp803842Visible: "visible" | "hidden";
  isComp816615Visible: "visible" | "hidden";
  isComp572328Visible: "visible" | "hidden";
  isComp803993Visible: "visible" | "hidden";
  isComp464557Visible: "visible" | "hidden";
  isComp999090Visible: "visible" | "hidden";
  isComp459375Visible: "visible" | "hidden";
  isComp553628Visible: "visible" | "hidden";
  isComp91798Visible: "visible" | "hidden";
  isComp513972Visible: "visible" | "hidden";
  isComp659451Visible: "visible" | "hidden";
  isComp382094Visible: "visible" | "hidden";
  isComp57039Visible: "visible" | "hidden";
  isComp999531Visible: "visible" | "hidden";
  isComp324369Visible: "visible" | "hidden";
  isComp432446Visible: "visible" | "hidden";
  isComp934923Visible: "visible" | "hidden";
  isComp375212Visible: "visible" | "hidden";
  isComp679331Visible: "visible" | "hidden";
  isComp909777Visible: "visible" | "hidden";
  isComp765584Visible: "visible" | "hidden";
  isComp183673Visible: "visible" | "hidden";
  isComp969221Visible: "visible" | "hidden";
  isComp284293Visible: "visible" | "hidden";
  isComp528021Visible: "visible" | "hidden";
  isComp291667Visible: "visible" | "hidden";
  isComp310363Visible: "visible" | "hidden";
  isComp956828Visible: "visible" | "hidden";
  isComp204534Visible: "visible" | "hidden";
  isComp449363Visible: "visible" | "hidden";
  isComp583165Visible: "visible" | "hidden";
  isComp397201Visible: "visible" | "hidden";
  isComp28971Visible: "visible" | "hidden";
  isComp401049Visible: "visible" | "hidden";
  isComp771200Visible: "visible" | "hidden";
  isComp864345Visible: "visible" | "hidden";
  isComp260313Visible: "visible" | "hidden";
  isComp831469Visible: "visible" | "hidden";
  isComp845132Visible: "visible" | "hidden";
  isComp681754Visible: "visible" | "hidden";
  isComp176618Visible: "visible" | "hidden";
  isComp921407Visible: "visible" | "hidden";
  isComp969Visible: "visible" | "hidden";
  isComp518152Visible: "visible" | "hidden";
  isComp739682Visible: "visible" | "hidden";
  isComp681149Visible: "visible" | "hidden";
  isComp202682Visible: "visible" | "hidden";
  isComp330703Visible: "visible" | "hidden";
  isComp218524Visible: "visible" | "hidden";
  isComp991225Visible: "visible" | "hidden";
  isComp476748Visible: "visible" | "hidden";
  isComp478750Visible: "visible" | "hidden";
  isComp336407Visible: "visible" | "hidden";
  isComp975148Visible: "visible" | "hidden";
  isComp691652Visible: "visible" | "hidden";
  isComp852077Visible: "visible" | "hidden";
  isComp13593Visible: "visible" | "hidden";
  isComp695930Visible: "visible" | "hidden";
  isComp343505Visible: "visible" | "hidden";
  isComp846928Visible: "visible" | "hidden";
  isComp781928Visible: "visible" | "hidden";
  isComp684788Visible: "visible" | "hidden";
  isComp369885Visible: "visible" | "hidden";
  isComp939691Visible: "visible" | "hidden";
  isComp115361Visible: "visible" | "hidden";
  isComp809063Visible: "visible" | "hidden";
  isComp122685Visible: "visible" | "hidden";
  isComp734427Visible: "visible" | "hidden";
  isComp586759Visible: "visible" | "hidden";
  isComp326018Visible: "visible" | "hidden";
  isComp949335Visible: "visible" | "hidden";
  isComp797216Visible: "visible" | "hidden";
  isComp593330Visible: "visible" | "hidden";
  isComp19843Visible: "visible" | "hidden";
  isComp436915Visible: "visible" | "hidden";
  isComp972677Visible: "visible" | "hidden";
  isComp234474Visible: "visible" | "hidden";
  isComp352216Enabled: "enabled" | "disabled";
  isComp168257Enabled: "enabled" | "disabled";
  isComp215911Enabled: "enabled" | "disabled";
  isComp805288Enabled: "enabled" | "disabled";
  isComp679331Enabled: "enabled" | "disabled";
  isComp909777Enabled: "enabled" | "disabled";
  isComp765584Enabled: "enabled" | "disabled";
  isComp183673Enabled: "enabled" | "disabled";
  isComp367546Enabled: "enabled" | "disabled";
  isComp681754Enabled: "enabled" | "disabled";
  isComp771873Enabled: "enabled" | "disabled";
  isComp780759Enabled: "enabled" | "disabled";
}

export class IsTakipKart_ScreenBase extends React.PureComponent<IIsTakipKart_ScreenProps, any> {
  istakipkart_609729_value_kuikaSelectBoxRef: React.RefObject<any>;
  istakipkart_352216_value_kuikaSelectBoxRef: React.RefObject<any>;
  istakipkart_815119_value_kuikaSelectBoxRef: React.RefObject<any>;
  istakipkart_805288_value_kuikaTableRef: React.RefObject<any>;
  istakipkart_729731_value_kuikaTableRef: React.RefObject<any>;
  istakipkart_765584_value_kuikaSelectBoxRef: React.RefObject<any>;
  istakipkart_183673_value_kuikaSelectBoxRef: React.RefObject<any>;
  istakipkart_969221_value_kuikaSelectBoxRef: React.RefObject<any>;
  istakipkart_956828_value_kuikaDatePickerRef: React.RefObject<any>;
  istakipkart_73196_value_kuikaTableRef: React.RefObject<any>;
  istakipkart_449550_value_kuikaDateRef: React.RefObject<any>;
  istakipkart_218026_value_kuikaDateRef: React.RefObject<any>;
  istakipkart_345176_value_kuikaDateRef: React.RefObject<any>;
  istakipkart_168257_value_kuikaSelectBoxRef: React.RefObject<any>;
  istakipkart_771873_value_kuikaSelectBoxRef: React.RefObject<any>;
  istakipkart_780759_value_kuikaSelectBoxRef: React.RefObject<any>;
  istakipkart_953041_value_kuikaDateRef: React.RefObject<any>;
  istakipkart_215911_value_kuikaTableRef: React.RefObject<any>;
  istakipkart_959874_value_kuikaTableRef: React.RefObject<any>;
  istakipkart_161516_value_kuikaSelectBoxRef: React.RefObject<any>;
  istakipkart_116506_value_kuikaSelectBoxRef: React.RefObject<any>;
  ml = [
    {
      Id: "16c151eb-42cf-0b2b-7e4a-e8b04fc43d88",
      Name: "tr_TR",
      ShortName: "Turkish",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "fbdbce9f-3fdb-30c5-c4fe-f7e443068251", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    { Id: "8468043e-f025-01e1-93b8-c71e3215778a", Name: "de_DE", ShortName: "German", LanguagePhrases: [] },
    {
      Id: "a24a9909-994d-4549-97a9-7dfe0402b7d3",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 861144, PropertyName: "value", Value: "#" },
        { Id: 656565, PropertyName: "value", Value: "İş Takip Kartı" },
        { Id: 47131, PropertyName: "value", Value: "Formu İncele/Güncelle" },
        { Id: 418150, PropertyName: "label", Value: "Listeye Dön" },
        { Id: 91798, PropertyName: "label", Value: "Arşivden Geri Al" },
        { Id: 513972, PropertyName: "label", Value: "Arşivle" },
        { Id: 659451, PropertyName: "label", Value: "Kartı Geri Bırak" },
        { Id: 357085, PropertyName: "value", Value: "Başvuru Tarihi" },
        { Id: 121807, PropertyName: "value", Value: "Açılış Tarihi" },
        { Id: 809164, PropertyName: "value", Value: "Güncelleme Tarihi" },
        { Id: 462797, PropertyName: "value", Value: "Termin Tarihi" },
        { Id: 823422, PropertyName: "value", Value: "İş takip durumu" },
        { Id: 609729, PropertyName: "placeholder", Value: "..." },
        { Id: 481416, PropertyName: "value", Value: "İş Kimde?" },
        { Id: 352216, PropertyName: "placeholder", Value: "Kişi Seçiniz..." },
        { Id: 204969, PropertyName: "value", Value: "CC" },
        { Id: 815119, PropertyName: "placeholder", Value: "Kişi Seçiniz..." },
        { Id: 805288, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 140494, PropertyName: "title", Value: "Title" },
        { Id: 946132, PropertyName: "value", Value: "[datafield:ilgilikisi]" },
        { Id: 987639, PropertyName: "value", Value: "Önemli Notlar" },
        { Id: 846368, PropertyName: "placeholder", Value: "..." },
        { Id: 324369, PropertyName: "label", Value: "Vazgeç" },
        { Id: 140302, PropertyName: "label", Value: "Kaydet" },
        { Id: 536756, PropertyName: "value", Value: "Güncel Gelişmeler / Notlar" },
        { Id: 554538, PropertyName: "title", Value: "Tarih" },
        { Id: 432518, PropertyName: "value", Value: "[datafield:aksiyontarihi]" },
        { Id: 476479, PropertyName: "title", Value: "Güncellemeyi Yapan" },
        { Id: 501187, PropertyName: "value", Value: "[datafield:aksiyonyapan]" },
        { Id: 692404, PropertyName: "title", Value: "Güncelleme Türü" },
        { Id: 74606, PropertyName: "value", Value: "[datafield:aksiyontanim]" },
        { Id: 153446, PropertyName: "title", Value: "Güncel Durum Detayı" },
        { Id: 873477, PropertyName: "value", Value: "[datafield:aciklama]" },
        { Id: 591608, PropertyName: "value", Value: "Formu İncele/Güncelle" },
        { Id: 572328, PropertyName: "value", Value: "Proje Bilgileri Göster" },
        { Id: 803842, PropertyName: "value", Value: "Proje Bilgileri Gizle" },
        { Id: 594005, PropertyName: "value", Value: "Eser Adı" },
        { Id: 679331, PropertyName: "placeholder", Value: "Eser Adı" },
        { Id: 713140, PropertyName: "value", Value: "Eser Sahibi" },
        { Id: 909777, PropertyName: "placeholder", Value: "Eser Sahibi" },
        { Id: 298171, PropertyName: "value", Value: "Başvuru Türü" },
        { Id: 765584, PropertyName: "placeholder", Value: "Başvuru Türü Seçiniz..." },
        { Id: 445583, PropertyName: "value", Value: "Projenin Türü" },
        { Id: 183673, PropertyName: "placeholder", Value: "Proje Türü Seçiniz..." },
        { Id: 646665, PropertyName: "value", Value: "Proje Bilgileri" },
        { Id: 659206, PropertyName: "value", Value: "Başvuru Yapan Kişi" },
        { Id: 969221, PropertyName: "placeholder", Value: "Başvuru Yapan Kişi Seçiniz..." },
        { Id: 962183, PropertyName: "value", Value: "İletişim Bilgileri (Email) *" },
        { Id: 284293, PropertyName: "placeholder", Value: "EMail Giriniz..." },
        { Id: 708692, PropertyName: "value", Value: "GSM No" },
        { Id: 528021, PropertyName: "placeholder", Value: "Gsm No Giriniz..." },
        { Id: 888592, PropertyName: "value", Value: "İş Yapılacak Firma" },
        { Id: 473202, PropertyName: "placeholder", Value: "Firma Adı Giriniz..." },
        { Id: 157741, PropertyName: "value", Value: "Müşteri Ek Not" },
        { Id: 310363, PropertyName: "placeholder", Value: "..." },
        { Id: 225806, PropertyName: "value", Value: "Acil / İstenilen Termin Tarihi" },
        { Id: 956828, PropertyName: "placeholder", Value: "..." },
        { Id: 450852, PropertyName: "value", Value: "Servis Ücreti Var" },
        { Id: 786823, PropertyName: "value", Value: "Projenin Adı" },
        { Id: 204534, PropertyName: "placeholder", Value: "Proje Adı Giriniz..." },
        { Id: 315469, PropertyName: "value", Value: "Yayınlandığı TV Kanalı ve/veya Platform" },
        { Id: 984986, PropertyName: "value", Value: "Sezon" },
        { Id: 207250, PropertyName: "value", Value: "Bölüm Numarası" },
        { Id: 323988, PropertyName: "value", Value: "Organizasyon Yeri ve Tarihi" },
        { Id: 105568, PropertyName: "value", Value: "Ürün" },
        { Id: 906518, PropertyName: "value", Value: "Yayınlandığı TV Kanalı ve/veya Platform" },
        { Id: 584575, PropertyName: "value", Value: "Sezon" },
        { Id: 564757, PropertyName: "value", Value: "Bölüm Numarası" },
        { Id: 42373, PropertyName: "value", Value: "Sanatçı / Grup Adı" },
        { Id: 15363, PropertyName: "value", Value: "Yayınlanma Türü" },
        { Id: 642625, PropertyName: "value", Value: "Müzik Briefi" },
        { Id: 73196, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 358878, PropertyName: "title", Value: "Title" },
        { Id: 766577, PropertyName: "value", Value: "[datafield:suresaat]" },
        { Id: 92674, PropertyName: "value", Value: "[datafield:kturu]" },
        { Id: 577917, PropertyName: "value", Value: "[datafield:muzikkod]" },
        { Id: 681754, PropertyName: "placeholder", Value: "Lütfen Eklemek İstediğiniz Notları Yazınız" },
        { Id: 847627, PropertyName: "value", Value: "Sözleşme Tarihi" },
        { Id: 449550, PropertyName: "placeholder", Value: "..." },
        { Id: 761581, PropertyName: "value", Value: "Lisans Başlangıcı" },
        { Id: 218026, PropertyName: "placeholder", Value: "..." },
        { Id: 97468, PropertyName: "value", Value: "Lisans Bitişi" },
        { Id: 345176, PropertyName: "placeholder", Value: "..." },
        { Id: 451851, PropertyName: "value", Value: "Süresiz" },
        { Id: 450613, PropertyName: "value", Value: "Fatura Düzenlenecek Firma Adı" },
        { Id: 168257, PropertyName: "placeholder", Value: "..." },
        { Id: 614206, PropertyName: "value", Value: 'Fiyat Listesinden "İndirimli Fiyat" Kullan' },
        { Id: 201254, PropertyName: "value", Value: "Lisans Tipi" },
        { Id: 771873, PropertyName: "placeholder", Value: "Lisans Tipi Seçiniz..." },
        { Id: 700915, PropertyName: "value", Value: "Telif Türü" },
        { Id: 780759, PropertyName: "placeholder", Value: "Telif Türü Seçiniz..." },
        { Id: 990778, PropertyName: "value", Value: "Fatura Tarihi" },
        { Id: 870787, PropertyName: "value", Value: "Fatura Tutar" },
        { Id: 474550, PropertyName: "value", Value: "Müziğin Proje/Versiyon İçindeki Kullanım Süresi" },
        { Id: 66331, PropertyName: "value", Value: "Sizin için Müzik Seçimi mi İstiyorusnuz?" },
        { Id: 838902, PropertyName: "value", Value: "Proje/Versiyon Bilgi" },
        { Id: 999090, PropertyName: "value", Value: "Müzik Kod / Ad" },
        { Id: 459375, PropertyName: "value", Value: "Müzik Seçimi İçin Açıklama Giriniz..!" },
        { Id: 591201, PropertyName: "value", Value: "Saat" },
        { Id: 877336, PropertyName: "value", Value: "Dakika" },
        { Id: 58056, PropertyName: "value", Value: "Saniye" },
        { Id: 748063, PropertyName: "placeholder", Value: "Giriniz..." },
        { Id: 515308, PropertyName: "placeholder", Value: "Giriniz..." },
        { Id: 77490, PropertyName: "placeholder", Value: "0" },
        { Id: 471403, PropertyName: "placeholder", Value: "0" },
        { Id: 462579, PropertyName: "placeholder", Value: "0" },
        { Id: 681149, PropertyName: "value", Value: "Müzik Ekle" },
        { Id: 13498, PropertyName: "value", Value: "Proje/Versiyon Bilgi" },
        { Id: 330703, PropertyName: "value", Value: "Müzik Kod / Ad" },
        { Id: 218524, PropertyName: "value", Value: "Eser ve Eser Sahibi" },
        { Id: 945768, PropertyName: "value", Value: "Kullanim Adedi" },
        { Id: 991225, PropertyName: "value", Value: "Sn / Birim" },
        { Id: 476748, PropertyName: "value", Value: "B.Fiyat" },
        { Id: 478750, PropertyName: "value", Value: "Özel Fiyat" },
        { Id: 336407, PropertyName: "value", Value: "Fiyat" },
        { Id: 171984, PropertyName: "value", Value: "Toplam" },
        { Id: 215911, PropertyName: "nodatafoundmessage", Value: "Veri Bulunamadı" },
        { Id: 860332, PropertyName: "value", Value: "[datafield:kturu]" },
        { Id: 688538, PropertyName: "value", Value: "[datafield:tablerowstokad]" },
        { Id: 129398, PropertyName: "value", Value: "[datafield:mecra]" },
        { Id: 104971, PropertyName: "value", Value: "[datafield:bolge]" },
        { Id: 170851, PropertyName: "value", Value: "[datafield:adet]" },
        { Id: 504748, PropertyName: "value", Value: "[datafield:ksure]" },
        { Id: 852077, PropertyName: "value", Value: "[datafield:tsn]" },
        { Id: 13593, PropertyName: "value", Value: "/" },
        { Id: 695930, PropertyName: "value", Value: "[datafield:hsn]" },
        { Id: 156094, PropertyName: "value", Value: "[datafield:fiyatlandirmatipitanim]" },
        { Id: 89387, PropertyName: "value", Value: "[datafield:fiyat]" },
        { Id: 290565, PropertyName: "value", Value: "[datafield:simge1]" },
        { Id: 109511, PropertyName: "value", Value: "[datafield:fiyatozel]" },
        { Id: 343505, PropertyName: "value", Value: "[datafield:simge2]" },
        { Id: 267908, PropertyName: "value", Value: "[datafield:tutar]" },
        { Id: 496326, PropertyName: "value", Value: "[datafield:simge2]" },
        { Id: 684788, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 369885, PropertyName: "value", Value: "Fiyatlandırma İçin Önemli Notlar" },
        { Id: 959874, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 438580, PropertyName: "title", Value: "Title" },
        { Id: 115361, PropertyName: "value", Value: "[datafield:aciklama]" },
        { Id: 809063, PropertyName: "value", Value: "[datafield:aciklama]" },
        { Id: 948285, PropertyName: "value", Value: "Lisans Bedeli" },
        { Id: 742579, PropertyName: "value", Value: "Net Tutar" },
        { Id: 960811, PropertyName: "value", Value: "Servis" },
        { Id: 637169, PropertyName: "value", Value: "Stopaj" },
        { Id: 734427, PropertyName: "value", Value: "KDV Hariç Tutar" },
        { Id: 586759, PropertyName: "value", Value: "KDV Hariç Tutar" },
        { Id: 326018, PropertyName: "value", Value: "Stopajlı Toplam (KDV Hariç)" },
        { Id: 502118, PropertyName: "value", Value: "İndirim" },
        { Id: 959223, PropertyName: "value", Value: "Toplam" },
        { Id: 541448, PropertyName: "value", Value: "KDV" },
        { Id: 720880, PropertyName: "value", Value: "Genel Toplam" },
        { Id: 905699, PropertyName: "value", Value: "Projenin Adı / Türü" },
        { Id: 175543, PropertyName: "value", Value: "Ödeme Vade Gün" },
        { Id: 186221, PropertyName: "value", Value: "Fatura Açıklama" },
        { Id: 624781, PropertyName: "placeholder", Value: "1.Satır" },
        { Id: 608425, PropertyName: "placeholder", Value: "2.Satır" },
        { Id: 93784, PropertyName: "placeholder", Value: "3.Satır" },
        { Id: 969763, PropertyName: "label", Value: "Logoya Aktar" }
      ]
    }
  ];
  defaultML = "tr_TR";
  screenHash = "";

  constructor(props) {
    super(props);
    this.istakipkart_609729_value_kuikaSelectBoxRef = React.createRef();
    this.istakipkart_352216_value_kuikaSelectBoxRef = React.createRef();
    this.istakipkart_815119_value_kuikaSelectBoxRef = React.createRef();
    this.istakipkart_805288_value_kuikaTableRef = React.createRef();
    this.istakipkart_729731_value_kuikaTableRef = React.createRef();
    this.istakipkart_765584_value_kuikaSelectBoxRef = React.createRef();
    this.istakipkart_183673_value_kuikaSelectBoxRef = React.createRef();
    this.istakipkart_969221_value_kuikaSelectBoxRef = React.createRef();
    this.istakipkart_956828_value_kuikaDatePickerRef = React.createRef();
    this.istakipkart_73196_value_kuikaTableRef = React.createRef();
    this.istakipkart_449550_value_kuikaDateRef = React.createRef();
    this.istakipkart_218026_value_kuikaDateRef = React.createRef();
    this.istakipkart_345176_value_kuikaDateRef = React.createRef();
    this.istakipkart_168257_value_kuikaSelectBoxRef = React.createRef();
    this.istakipkart_771873_value_kuikaSelectBoxRef = React.createRef();
    this.istakipkart_780759_value_kuikaSelectBoxRef = React.createRef();
    this.istakipkart_953041_value_kuikaDateRef = React.createRef();
    this.istakipkart_215911_value_kuikaTableRef = React.createRef();
    this.istakipkart_959874_value_kuikaTableRef = React.createRef();
    this.istakipkart_161516_value_kuikaSelectBoxRef = React.createRef();
    this.istakipkart_116506_value_kuikaSelectBoxRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      NAVIGATE: "",
      getBasvuruTuruKodById: [],
      getProjeTuruFormKodById: [],
      getFormNo: [],
      SendMail: false,
      GoBack: "",
      getUserInfo: [],
      spGetIsKartReadOnly: [],
      sbFormStatuList: [],
      sbProjeTuruList: [],
      sbMecraList: [],
      sbBolgeList: [],
      sbTelifTurList: [],
      sbBasvuruTuruList: [],
      sbLisansTipiList: [],
      sbStopajList: [],
      sbIzinTuruList: [],
      sbMunhasirlikList: [],
      sbVadeGunList: [],
      getListBFormuAksiyon: [],
      getListCari: [],
      KUserList: [],
      getBasvuruFormuById: [],
      getListKullanimSureleriByBFId: [],
      spgetListKullanimSureleriByBFId_brief: [],
      spDipToplamByBFId: [],
      getListFiyatlandirmaNotlar: [],
      getListIsTakipCCbyBFID: [],
      spGetProjeTuruByBTuruKod: [],
      spArsivGeriAlVisible: [],
      ChangeEnabledOf: "",
      ChangeVisibilityOf: "",
      spSetArsivGeriAl: 0,
      Notify: false,
      setIsKartArsivDurumu: "",
      setIsTakipGeriBirak: "",
      DiziFilmAlanKontrol: [],
      FaturaKesilecekFirmaKontrol: 0,
      setUpdateIsKartiDurum: [],
      BasvuruFormuLogoStokSatirAciklamaUpdate: 0,
      MailSubjectOnaylandi: [],
      setUpdateIsKartiAksiyonKisi: 0,
      setIsTakipCCSave: "",
      SetValueOf: "",
      setIsTakipCCDelete: "",
      spUpdateBFormuinISTakip: [],
      setBasvuruFormuAksiyonlarDelete: "",
      spUpdateServisUcret: [],
      setBasvuruFormuSozlesmeBilgileriSave: 0,
      setUpdateCariId: 0,
      spUpdateFiyatlandirmaByBFId: [],
      spUpdateIndirimliFiyatKullan: [],
      setUpdateLisansTipiID: "",
      setUpdateTelifTurId: 0,
      setKullanimSureleriInsert: [],
      spUpdateFiyatlandirmaByInsertId: [],
      spUpdateFiyatlandirmaByCurrentId: [],
      delKullanimSureleriById: 0,
      setStopajOranUpdate: "",
      spUpdateGenelIndirimTutar: [],
      setLogoSatirVadeUpdate: 0,
      setLogoFaturaAciklamaUpdate: 0,
      TriggerEvent: "",
      spLogoAktarKontrol: [],
      LogoObjectAddSalesOrderFromLogoconnectapi: "",
      setQuarterDate: "",
      LogoObjectTigerLogoRemoteUpdateFromLogoconnectapi: "",
      isComp756009Visible: "hidden",
      isComp104543Visible: "hidden",
      isComp417866Visible: "hidden",
      isComp927155Visible: "hidden",
      isComp363488Visible: "hidden",
      isComp591012Visible: "hidden",
      isComp451131Visible: "hidden",
      isComp217616Visible: "hidden",
      isComp103661Visible: "hidden",
      isComp209641Visible: "hidden",
      isComp377079Visible: "hidden",
      isComp23905Visible: "hidden",
      isComp176459Visible: "hidden",
      isComp391583Visible: "hidden",
      isComp608275Visible: "hidden",
      isComp866931Visible: "hidden",
      isComp794716Visible: "hidden",
      isComp18544Visible: "hidden",
      isComp545617Visible: "visible",
      isComp803842Visible: "visible",
      isComp816615Visible: "visible",
      isComp572328Visible: "hidden",
      isComp803993Visible: "hidden",
      isComp464557Visible: "visible",
      isComp999090Visible: "visible",
      isComp459375Visible: "hidden",
      isComp553628Visible: "hidden",
      isComp91798Visible: "hidden",
      isComp513972Visible: "hidden",
      isComp659451Visible: "hidden",
      isComp382094Visible: "hidden",
      isComp57039Visible: "hidden",
      isComp999531Visible: "hidden",
      isComp324369Visible: "hidden",
      isComp432446Visible: "hidden",
      isComp934923Visible: "hidden",
      isComp375212Visible: "hidden",
      isComp679331Visible: "hidden",
      isComp909777Visible: "hidden",
      isComp765584Visible: "hidden",
      isComp183673Visible: "hidden",
      isComp969221Visible: "hidden",
      isComp284293Visible: "hidden",
      isComp528021Visible: "hidden",
      isComp291667Visible: "hidden",
      isComp310363Visible: "hidden",
      isComp956828Visible: "hidden",
      isComp204534Visible: "hidden",
      isComp449363Visible: "hidden",
      isComp583165Visible: "hidden",
      isComp397201Visible: "hidden",
      isComp28971Visible: "hidden",
      isComp401049Visible: "hidden",
      isComp771200Visible: "hidden",
      isComp864345Visible: "hidden",
      isComp260313Visible: "hidden",
      isComp831469Visible: "hidden",
      isComp845132Visible: "hidden",
      isComp681754Visible: "hidden",
      isComp176618Visible: "hidden",
      isComp921407Visible: "hidden",
      isComp969Visible: "hidden",
      isComp518152Visible: "hidden",
      isComp739682Visible: "hidden",
      isComp681149Visible: "hidden",
      isComp202682Visible: "hidden",
      isComp330703Visible: "hidden",
      isComp218524Visible: "hidden",
      isComp991225Visible: "hidden",
      isComp476748Visible: "hidden",
      isComp478750Visible: "hidden",
      isComp336407Visible: "hidden",
      isComp975148Visible: "hidden",
      isComp691652Visible: "hidden",
      isComp852077Visible: "hidden",
      isComp13593Visible: "hidden",
      isComp695930Visible: "hidden",
      isComp343505Visible: "hidden",
      isComp846928Visible: "hidden",
      isComp781928Visible: "hidden",
      isComp684788Visible: "hidden",
      isComp369885Visible: "hidden",
      isComp939691Visible: "hidden",
      isComp115361Visible: "hidden",
      isComp809063Visible: "hidden",
      isComp122685Visible: "hidden",
      isComp734427Visible: "hidden",
      isComp586759Visible: "hidden",
      isComp326018Visible: "hidden",
      isComp949335Visible: "hidden",
      isComp797216Visible: "hidden",
      isComp593330Visible: "hidden",
      isComp19843Visible: "hidden",
      isComp436915Visible: "hidden",
      isComp972677Visible: "hidden",
      isComp234474Visible: "hidden",
      isComp352216Enabled: "enabled",
      isComp168257Enabled: "enabled",
      isComp215911Enabled: "enabled",
      isComp805288Enabled: "enabled",
      isComp679331Enabled: "enabled",
      isComp909777Enabled: "enabled",
      isComp765584Enabled: "enabled",
      isComp183673Enabled: "enabled",
      isComp367546Enabled: "enabled",
      isComp681754Enabled: "enabled",
      isComp771873Enabled: "enabled",
      isComp780759Enabled: "enabled"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("istakipkart", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.IsTakipKartPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("istakipkart", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("istakipkart", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.IsTakipKartPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      istakipkart_609729_value: this.state.getBasvuruFormuById?.at?.(0)?.formStatuID ?? undefined,
      istakipkart_352216_value: this.state.getBasvuruFormuById?.at?.(0)?.aksiyonIlgiliKisi ?? undefined,
      istakipkart_846368_value: this.state.getBasvuruFormuById?.at?.(0)?.onemliNot ?? undefined,
      istakipkart_679331_value: this.state.getBasvuruFormuById?.at?.(0)?.eserAd ?? undefined,
      istakipkart_909777_value: this.state.getBasvuruFormuById?.at?.(0)?.eserSahibi ?? undefined,
      istakipkart_765584_value: this.state.getBasvuruFormuById?.at?.(0)?.basvuruTuruID ?? undefined,
      istakipkart_183673_value: this.state.getBasvuruFormuById?.at?.(0)?.projeTuruID ?? undefined,
      istakipkart_969221_value: this.state.getBasvuruFormuById?.at?.(0)?.olusturanKisi ?? undefined,
      istakipkart_284293_value: this.state.getBasvuruFormuById?.at?.(0)?.eMail ?? undefined,
      istakipkart_528021_value: this.state.getBasvuruFormuById?.at?.(0)?.gsm ?? undefined,
      istakipkart_473202_value: this.state.getBasvuruFormuById?.at?.(0)?.sirketAd ?? undefined,
      istakipkart_310363_value: this.state.getBasvuruFormuById?.at?.(0)?.aciklama ?? undefined,
      istakipkart_367546_value: this.state.getBasvuruFormuById?.at?.(0)?.acil ?? undefined,
      istakipkart_956828_value: this.state.getBasvuruFormuById?.at?.(0)?.terminTarihi ?? undefined,
      istakipkart_743413_value: this.state.getBasvuruFormuById?.at?.(0)?.servisUcretiVar ?? undefined,
      istakipkart_204534_value: this.state.getBasvuruFormuById?.at?.(0)?.projeAd ?? undefined,
      istakipkart_681754_value: this.state.getBasvuruFormuById?.at?.(0)?.briefAciklama ?? undefined,
      istakipkart_449550_value: this.state.getBasvuruFormuById?.at?.(0)?.sozlesmeTarihi ?? undefined,
      istakipkart_218026_value: this.state.getBasvuruFormuById?.at?.(0)?.lisansBasTarihi ?? undefined,
      istakipkart_345176_value: this.state.getBasvuruFormuById?.at?.(0)?.lisansBitTarihi ?? undefined,
      istakipkart_787576_value: this.state.getBasvuruFormuById?.at?.(0)?.lisansSuresiz ?? undefined,
      istakipkart_168257_value: this.state.getBasvuruFormuById?.at?.(0)?.cariID ?? undefined,
      istakipkart_494755_value: this.state.getBasvuruFormuById?.at?.(0)?.isIndirimliFiyat ?? undefined,
      istakipkart_771873_value: this.state.getBasvuruFormuById?.at?.(0)?.lisansTipiID ?? undefined,
      istakipkart_780759_value: this.state.getBasvuruFormuById?.at?.(0)?.telifTurID ?? undefined,
      istakipkart_953041_value: this.state.getBasvuruFormuById?.at?.(0)?.faturaTarihi ?? undefined,
      istakipkart_552514_value: this.state.getBasvuruFormuById?.at?.(0)?.faturaTutar ?? undefined,
      istakipkart_77490_value: NaN,
      istakipkart_471403_value: NaN,
      istakipkart_462579_value: NaN,
      istakipkart_649647_value: this.state.getBasvuruFormuById?.at?.(0)?.dipNetTutar ?? undefined,
      istakipkart_952267_value: this.state.getBasvuruFormuById?.at?.(0)?.dipServisTutar ?? undefined,
      istakipkart_161516_value: this.state.getBasvuruFormuById?.at?.(0)?.stopajOran ?? undefined,
      istakipkart_830227_value: this.state.getBasvuruFormuById?.at?.(0)?.dipStopajTutar ?? undefined,
      istakipkart_20311_value: this.state.getBasvuruFormuById?.at?.(0)?.dipBrutTutar ?? undefined,
      istakipkart_368022_value: this.state.getBasvuruFormuById?.at?.(0)?.dipIndirim ?? undefined,
      istakipkart_241361_value: this.state.getBasvuruFormuById?.at?.(0)?.dipToplam ?? undefined,
      istakipkart_286867_value: this.state.getBasvuruFormuById?.at?.(0)?.dipKdv ?? undefined,
      istakipkart_228291_value: this.state.getBasvuruFormuById?.at?.(0)?.dipGenelToplam ?? undefined,
      istakipkart_353223_value: this.state.getBasvuruFormuById?.at?.(0)?.satirAciklama ?? undefined,
      istakipkart_116506_value: this.state.getBasvuruFormuById?.at?.(0)?.vadeGunID ?? undefined,
      istakipkart_624781_value: this.state.getBasvuruFormuById?.at?.(0)?.notSatir1 ?? undefined,
      istakipkart_608425_value: this.state.getBasvuruFormuById?.at?.(0)?.notSatir2 ?? undefined,
      istakipkart_93784_value: this.state.getBasvuruFormuById?.at?.(0)?.notSatir3 ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  IsTakipKartPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      UserName_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      BFId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      ),
      ProjeTuruRefKod_5: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "istakipkart_183673_value",
            "value",
            "spGetProjeTuruByBTuruKod",
            "id",
            "projeTuruRefKod"
          )
        ),
        "string"
      ),
      BasvuruFormuID_13: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      ),
      Search_14: ReactSystemFunctions.convertToTypeByName("", "string"),
      Search_15: ReactSystemFunctions.convertToTypeByName("", "string"),
      Id_16: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      ),
      BasvuruFormuID_18: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      ),
      BasvuruFormuID_19: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      ),
      BasvuruFormuID_20: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      ),
      BasvuruFormuID_22: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "IsTakipKart/IsTakipKartPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.getUserInfo = result?.data.getUserInfo;
    stateVars.spGetIsKartReadOnly = result?.data.spGetIsKartReadOnly;
    stateVars.sbFormStatuList = result?.data.sbFormStatuList;

    formVars.istakipkart_609729_value =
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formStatuID : null;
    formVars.istakipkart_609729_options = stateVars.sbFormStatuList;
    stateVars.sbProjeTuruList = result?.data.sbProjeTuruList;
    stateVars.sbMecraList = result?.data.sbMecraList;
    stateVars.sbBolgeList = result?.data.sbBolgeList;
    stateVars.sbTelifTurList = result?.data.sbTelifTurList;

    formVars.istakipkart_780759_value =
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.telifTurID : null;
    formVars.istakipkart_780759_options = stateVars.sbTelifTurList;
    stateVars.sbBasvuruTuruList = result?.data.sbBasvuruTuruList;

    formVars.istakipkart_765584_value =
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruID : null;
    formVars.istakipkart_765584_options = stateVars.sbBasvuruTuruList;
    stateVars.sbLisansTipiList = result?.data.sbLisansTipiList;

    formVars.istakipkart_771873_value =
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.lisansTipiID : null;
    formVars.istakipkart_771873_options = stateVars.sbLisansTipiList;
    stateVars.sbStopajList = result?.data.sbStopajList;

    formVars.istakipkart_161516_value =
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.stopajOran : null;
    formVars.istakipkart_161516_options = stateVars.sbStopajList;
    stateVars.sbIzinTuruList = result?.data.sbIzinTuruList;
    stateVars.sbMunhasirlikList = result?.data.sbMunhasirlikList;
    stateVars.sbVadeGunList = result?.data.sbVadeGunList;

    formVars.istakipkart_116506_value =
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.vadeGunID : null;
    formVars.istakipkart_116506_options = stateVars.sbVadeGunList;
    stateVars.getListBFormuAksiyon = result?.data.getListBFormuAksiyon;

    stateVars.getListCari = result?.data.getListCari;

    formVars.istakipkart_168257_value =
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.cariID : null;
    formVars.istakipkart_168257_options = stateVars.getListCari;
    stateVars.KUserList = result?.data.kUserList;

    formVars.istakipkart_352216_value =
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.aksiyonIlgiliKisi : null;
    formVars.istakipkart_352216_options = stateVars.KUserList;

    formVars.istakipkart_815119_options = stateVars.KUserList;

    formVars.istakipkart_969221_value =
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.olusturanKisi : null;
    formVars.istakipkart_969221_options = stateVars.KUserList;
    stateVars.getBasvuruFormuById = result?.data.getBasvuruFormuById;
    formVars.istakipkart_785916_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.ticketNo : null
    );
    formVars.istakipkart_308766_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.olusturmaTarihi : null
    );
    formVars.istakipkart_354958_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.isTakipBaslangicTarihi : null
    );
    formVars.istakipkart_72949_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.isTakipGuncellemeZaman : null
    );
    formVars.istakipkart_174064_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.terminTarihi : null
    );
    formVars.istakipkart_609729_value =
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formStatuID : null;
    formVars.istakipkart_609729_options = stateVars.sbFormStatuList;
    formVars.istakipkart_352216_value =
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.aksiyonIlgiliKisi : null;
    formVars.istakipkart_352216_options = stateVars.KUserList;
    formVars.istakipkart_846368_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.onemliNot : null
    );
    formVars.istakipkart_679771_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.eserAd : null
    );
    formVars.istakipkart_679331_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.eserAd : null
    );
    formVars.istakipkart_872419_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.eserSahibi : null
    );
    formVars.istakipkart_909777_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.eserSahibi : null
    );
    formVars.istakipkart_880403_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruTanim : null
    );
    formVars.istakipkart_765584_value =
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruID : null;
    formVars.istakipkart_765584_options = stateVars.sbBasvuruTuruList;
    formVars.istakipkart_334144_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.projeTuru : null
    );
    formVars.istakipkart_183673_value =
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.projeTuruID : null;
    formVars.istakipkart_183673_options = stateVars.spGetProjeTuruByBTuruKod;
    formVars.istakipkart_707596_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.adSoyad : null
    );
    formVars.istakipkart_969221_value =
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.olusturanKisi : null;
    formVars.istakipkart_969221_options = stateVars.KUserList;
    formVars.istakipkart_96402_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.eMail : null
    );
    formVars.istakipkart_284293_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.eMail : null
    );
    formVars.istakipkart_610845_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.gsm : null
    );
    formVars.istakipkart_528021_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.gsm : null
    );
    formVars.istakipkart_473202_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.sirketAd : null
    );
    formVars.istakipkart_865525_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.aciklama : null
    );
    formVars.istakipkart_310363_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.aciklama : null
    );
    formVars.istakipkart_367546_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.acil : null
    );
    formVars.istakipkart_490476_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.terminTarihi : null
    );
    formVars.istakipkart_956828_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.terminTarihi : null
    );
    formVars.istakipkart_743413_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.servisUcretiVar : null
    );
    formVars.istakipkart_253070_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.projeAd : null
    );
    formVars.istakipkart_204534_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.projeAd : null
    );
    formVars.istakipkart_449363_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.yayinKanali : null
    );
    formVars.istakipkart_583165_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.tvKanal : null
    );
    formVars.istakipkart_741340_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.sezon : null
    );
    formVars.istakipkart_397201_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.bolum : null
    );
    formVars.istakipkart_28971_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.diziBolumNo : null
    );
    formVars.istakipkart_218936_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.konserYerTarih : null
    );
    formVars.istakipkart_309538_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.urun : null
    );
    formVars.istakipkart_401049_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.tvKanal : null
    );
    formVars.istakipkart_771200_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.yayinKanali : null
    );
    formVars.istakipkart_915575_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.sezon : null
    );
    formVars.istakipkart_864345_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.tvBolumNo : null
    );
    formVars.istakipkart_260313_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.bolum : null
    );
    formVars.istakipkart_831469_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.diziBolumNo : null
    );
    formVars.istakipkart_883142_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.sanatcilar : null
    );
    formVars.istakipkart_427688_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.kullanimSekliCDTanim : null
    );
    formVars.istakipkart_681754_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.briefAciklama : null
    );
    formVars.istakipkart_449550_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.sozlesmeTarihi : null
    );
    formVars.istakipkart_218026_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.lisansBasTarihi : null
    );
    formVars.istakipkart_218026_disablebeforedate = ReactSystemFunctions.value(
      this,
      ReactSystemFunctions.value(this, "istakipkart_449550_value", "value", "", "", "")
    );
    formVars.istakipkart_345176_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.lisansBitTarihi : null
    );
    formVars.istakipkart_345176_disablebeforedate = ReactSystemFunctions.value(
      this,
      ReactSystemFunctions.value(this, "istakipkart_218026_value", "value", "", "", "")
    );
    formVars.istakipkart_787576_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.lisansSuresiz : null
    );
    formVars.istakipkart_168257_value =
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.cariID : null;
    formVars.istakipkart_168257_options = stateVars.getListCari;
    formVars.istakipkart_494755_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.isIndirimliFiyat : null
    );
    formVars.istakipkart_771873_value =
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.lisansTipiID : null;
    formVars.istakipkart_771873_options = stateVars.sbLisansTipiList;
    formVars.istakipkart_780759_value =
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.telifTurID : null;
    formVars.istakipkart_780759_options = stateVars.sbTelifTurList;
    formVars.istakipkart_953041_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.faturaTarihi : null
    );
    formVars.istakipkart_552514_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.faturaTutar : null
    );
    formVars.istakipkart_73134_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.simge : null
    );
    formVars.istakipkart_649647_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.dipNetTutar : null
    );
    formVars.istakipkart_952267_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.dipServisTutar : null
    );
    formVars.istakipkart_161516_value =
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.stopajOran : null;
    formVars.istakipkart_161516_options = stateVars.sbStopajList;
    formVars.istakipkart_830227_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.dipStopajTutar : null
    );
    formVars.istakipkart_20311_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.dipBrutTutar : null
    );
    formVars.istakipkart_368022_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.dipIndirim : null
    );
    formVars.istakipkart_241361_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.dipToplam : null
    );
    formVars.istakipkart_286867_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.dipKdv : null
    );
    formVars.istakipkart_228291_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.dipGenelToplam : null
    );
    formVars.istakipkart_353223_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.satirAciklama : null
    );
    formVars.istakipkart_116506_value =
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.vadeGunID : null;
    formVars.istakipkart_116506_options = stateVars.sbVadeGunList;
    formVars.istakipkart_624781_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.notSatir1 : null
    );
    formVars.istakipkart_608425_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.notSatir2 : null
    );
    formVars.istakipkart_93784_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.notSatir3 : null
    );
    stateVars.isComp659451Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formReadOnly : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp382094Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0
            ? stateVars.getBasvuruFormuById[0]?.isTakipDurumChangeButtonVisibily
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp57039Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formReadOnly : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp927155Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formReadOnly : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp363488Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formReadOnly : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp432446Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formReadOnly : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp934923Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formReadOnly : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp375212Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp449363Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.yayinKanali : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp583165Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.tvKanal : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp397201Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.bolum : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp28971Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.diziBolumNo : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp401049Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.tvKanal : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp771200Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.yayinKanali : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp864345Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.tvBolumNo : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp260313Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.bolum : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp831469Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.diziBolumNo : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp681754Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.briefAciklama : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp176618Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formReadOnly : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp739682Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp681149Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formReadOnly : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp202682Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formReadOnly : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp330703Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp218524Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp991225Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp476748Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp478750Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp336407Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp852077Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp13593Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp695930Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp846928Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formReadOnly : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp781928Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formReadOnly : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp122685Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp104543Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp734427Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp586759Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.sozlesmeID : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp326018Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp19843Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formReadOnly : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp436915Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formReadOnly : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp972677Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formReadOnly : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.getFormNo = result?.data.getFormNo;
    stateVars.isComp553628Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(this, stateVars.getFormNo?.length > 0 ? stateVars.getFormNo[0]?.formNo : null),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp845132Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, stateVars.getFormNo?.length > 0 ? stateVars.getFormNo[0]?.formNo : null),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp921407Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, stateVars.getFormNo?.length > 0 ? stateVars.getFormNo[0]?.formNo : null),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp691652Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, stateVars.getFormNo?.length > 0 ? stateVars.getFormNo[0]?.formNo : null),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.getListKullanimSureleriByBFId = result?.data.getListKullanimSureleriByBFId;

    formVars.istakipkart_256375_value = ReactSystemFunctions.toString(
      this,
      stateVars.getListKullanimSureleriByBFId?.length > 0 ? stateVars.getListKullanimSureleriByBFId[0]?.simge : null
    );
    stateVars.spgetListKullanimSureleriByBFId_brief = result?.data.spgetListKullanimSureleriByBFId_brief;

    stateVars.spDipToplamByBFId = result?.data.spDipToplamByBFId;
    stateVars.getListFiyatlandirmaNotlar = result?.data.getListFiyatlandirmaNotlar;

    stateVars.isComp369885Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.getListFiyatlandirmaNotlar?.length > 0 ? stateVars.getListFiyatlandirmaNotlar[0]?.id : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.getListIsTakipCCbyBFID = result?.data.getListIsTakipCCbyBFID;

    stateVars.spGetProjeTuruByBTuruKod = result?.data.spGetProjeTuruByBTuruKod;

    formVars.istakipkart_183673_value =
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.projeTuruID : null;
    formVars.istakipkart_183673_options = stateVars.spGetProjeTuruByBTuruKod;
    stateVars.spArsivGeriAlVisible = result?.data.spArsivGeriAlVisible;
    stateVars.isComp91798Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.spArsivGeriAlVisible?.length > 0 ? stateVars.spArsivGeriAlVisible[0]?.toArsiv : null
        ),
        true
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.IsTakipKartPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  IsTakipKartPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp352216Enabled", "disabled");

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.IsTakipKartPageInit2_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  IsTakipKartPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.prmNerdenGeldi ?? this.props.screenInputs.prmnerdengeldi,
        "muhasebe"
      )
    ) {
      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp352216Enabled", "enabled");

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.IsTakipKartPageInit3_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.IsTakipKartPageInit3_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  IsTakipKartPageInit3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, this.state.getFormNo?.length > 0 ? this.state.getFormNo[0]?.formNo : null),
        "0"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp756009Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.IsTakipKartPageInit4_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.IsTakipKartPageInit4_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  IsTakipKartPageInit4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(this, this.state.getFormNo?.length > 0 ? this.state.getFormNo[0]?.formNo : null),
        "0"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp104543Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.IsTakipKartPageInit5_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.IsTakipKartPageInit5_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  IsTakipKartPageInit5_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.spGetIsKartReadOnly?.length > 0 ? this.state.spGetIsKartReadOnly[0]?.readOnlyTrue1False0 : null
        ),
        true
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp417866Visible",
        "hidden"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.IsTakipKartPageInit6_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.IsTakipKartPageInit6_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  IsTakipKartPageInit6_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.spGetIsKartReadOnly?.length > 0 ? this.state.spGetIsKartReadOnly[0]?.readOnlyTrue1False0 : null
        ),
        true
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp927155Visible",
        "hidden"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.IsTakipKartPageInit7_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.IsTakipKartPageInit7_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  IsTakipKartPageInit7_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.spGetIsKartReadOnly?.length > 0 ? this.state.spGetIsKartReadOnly[0]?.readOnlyTrue1False0 : null
        ),
        true
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp363488Visible",
        "hidden"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.IsTakipKartPageInit8_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.IsTakipKartPageInit8_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  IsTakipKartPageInit8_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.spGetIsKartReadOnly?.length > 0 ? this.state.spGetIsKartReadOnly[0]?.readOnlyTrue1False0 : null
        ),
        true
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp591012Visible",
        "hidden"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.IsTakipKartPageInit9_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.IsTakipKartPageInit9_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  IsTakipKartPageInit9_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.spGetIsKartReadOnly?.length > 0 ? this.state.spGetIsKartReadOnly[0]?.readOnlyTrue1False0 : null
        ),
        true
      )
    ) {
      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp168257Enabled", "disabled");

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.IsTakipKartPageInit10_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.IsTakipKartPageInit10_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  IsTakipKartPageInit10_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.spGetIsKartReadOnly?.length > 0 ? this.state.spGetIsKartReadOnly[0]?.readOnlyTrue1False0 : null
        ),
        true
      )
    ) {
      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp215911Enabled", "disabled");

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.IsTakipKartPageInit11_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.IsTakipKartPageInit11_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  IsTakipKartPageInit11_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.spGetIsKartReadOnly?.length > 0 ? this.state.spGetIsKartReadOnly[0]?.readOnlyTrue1False0 : null
        ),
        true
      )
    ) {
      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp805288Enabled", "disabled");

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.IsTakipKartPageInit12_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.IsTakipKartPageInit12_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  IsTakipKartPageInit12_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp679331Enabled", "disabled");
    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp909777Enabled", "disabled");
    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp765584Enabled", "disabled");
    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp183673Enabled", "disabled");
    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp367546Enabled", "disabled");

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.IsTakipKartPageInit13_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  IsTakipKartPageInit13_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.projeTuruKod : null
        ),
        "3"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp451131Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.IsTakipKartPageInit14_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.IsTakipKartPageInit14_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  IsTakipKartPageInit14_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.projeTuruKod : null
        ),
        "4"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp217616Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.IsTakipKartPageInit15_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.IsTakipKartPageInit15_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  IsTakipKartPageInit15_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.projeTuruKod : null
        ),
        "7"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp103661Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.IsTakipKartPageInit16_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.IsTakipKartPageInit16_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  IsTakipKartPageInit16_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.projeTuruKod : null
        ),
        "9"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp209641Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.IsTakipKartPageInit17_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.IsTakipKartPageInit17_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  IsTakipKartPageInit17_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.projeTuruKod : null
        ),
        "2"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp377079Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.IsTakipKartPageInit18_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.IsTakipKartPageInit18_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  IsTakipKartPageInit18_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "1"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp377079Visible",
        "hidden"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.IsTakipKartPageInit19_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.IsTakipKartPageInit19_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  IsTakipKartPageInit19_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp681754Enabled", "disabled");

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.IsTakipKartPageInit20_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  IsTakipKartPageInit20_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.prmNerdenGeldi ?? this.props.screenInputs.prmnerdengeldi,
        "EI"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp23905Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.IsTakipKartPageInit21_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.IsTakipKartPageInit21_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  IsTakipKartPageInit21_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.sozlesmeID : null
        ),
        null
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp176459Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.IsTakipKartPageInit22_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.IsTakipKartPageInit22_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  IsTakipKartPageInit22_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.prmNerdenGeldi ?? this.props.screenInputs.prmnerdengeldi,
        "SA"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp176459Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.IsTakipKartPageInit23_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.IsTakipKartPageInit23_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  IsTakipKartPageInit23_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "1"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp391583Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.IsTakipKartPageInit24_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.IsTakipKartPageInit24_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  IsTakipKartPageInit24_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "2"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp391583Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.IsTakipKartPageInit25_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.IsTakipKartPageInit25_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  IsTakipKartPageInit25_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "1"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp608275Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.IsTakipKartPageInit26_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.IsTakipKartPageInit26_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  IsTakipKartPageInit26_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "2"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp608275Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.IsTakipKartPageInit27_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.IsTakipKartPageInit27_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  IsTakipKartPageInit27_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "1"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp866931Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.IsTakipKartPageInit28_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.IsTakipKartPageInit28_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  IsTakipKartPageInit28_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "2"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp866931Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.IsTakipKartPageInit29_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.IsTakipKartPageInit29_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  IsTakipKartPageInit29_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "1"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp794716Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.IsTakipKartPageInit30_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.IsTakipKartPageInit30_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  IsTakipKartPageInit30_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "2"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp794716Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.IsTakipKartPageInit31_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.IsTakipKartPageInit31_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  IsTakipKartPageInit31_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.sozlesmeID : null
        ),
        null
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp794716Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.IsTakipKartPageInit32_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.IsTakipKartPageInit32_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  IsTakipKartPageInit32_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.formReadOnly : null
        ),
        "1"
      )
    ) {
      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp168257Enabled", "disabled");

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.IsTakipKartPageInit33_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.IsTakipKartPageInit33_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  IsTakipKartPageInit33_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.formReadOnly : null
        ),
        "1"
      )
    ) {
      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp771873Enabled", "disabled");

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.IsTakipKartPageInit34_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.IsTakipKartPageInit34_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  IsTakipKartPageInit34_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.formReadOnly : null
        ),
        "1"
      )
    ) {
      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp780759Enabled", "disabled");

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.IsTakipKartPageInit35_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.IsTakipKartPageInit35_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  IsTakipKartPageInit35_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.istakipkart_785916_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.ticketNo : null
    );

    stateVars.isComp553628Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(this, this.state.getFormNo?.length > 0 ? this.state.getFormNo[0]?.formNo : null),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp91798Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.spArsivGeriAlVisible?.length > 0 ? this.state.spArsivGeriAlVisible[0]?.toArsiv : null
        ),
        true
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp659451Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.formReadOnly : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    formVars.istakipkart_308766_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.olusturmaTarihi : null
    );

    formVars.istakipkart_354958_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.isTakipBaslangicTarihi : null
    );

    formVars.istakipkart_72949_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.isTakipGuncellemeZaman : null
    );

    formVars.istakipkart_174064_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.terminTarihi : null
    );

    formVars.istakipkart_609729_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.formStatuID : null
    );

    stateVars.dataSource_609729 = this.state.sbFormStatuList;
    stateVars.dataSource_609729 = this.state.sbFormStatuList;
    stateVars.isComp382094Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.getBasvuruFormuById?.length > 0
            ? this.state.getBasvuruFormuById[0]?.isTakipDurumChangeButtonVisibily
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.istakipkart_352216_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.aksiyonIlgiliKisi : null
    );

    stateVars.dataSource_352216 = this.state.KUserList;
    stateVars.dataSource_352216 = this.state.KUserList;
    stateVars.isComp57039Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.formReadOnly : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp927155Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.formReadOnly : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";

    stateVars.dataSource_815119 = this.state.KUserList;
    stateVars.dataSource_815119 = this.state.KUserList;

    stateVars.dataSource_805288 = this.state.getListIsTakipCCbyBFID;
    formVars.istakipkart_846368_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.onemliNot : null
    );

    stateVars.isComp363488Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.formReadOnly : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";

    stateVars.dataSource_729731 = this.state.getListBFormuAksiyon;
    stateVars.isComp432446Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.formReadOnly : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp934923Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.formReadOnly : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp375212Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    formVars.istakipkart_679771_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.eserAd : null
    );

    formVars.istakipkart_679331_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.eserAd : null
    );

    formVars.istakipkart_872419_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.eserSahibi : null
    );

    formVars.istakipkart_909777_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.eserSahibi : null
    );

    formVars.istakipkart_880403_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.basvuruTuruTanim : null
    );

    formVars.istakipkart_765584_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.basvuruTuruID : null
    );

    stateVars.dataSource_765584 = this.state.sbBasvuruTuruList;
    stateVars.dataSource_765584 = this.state.sbBasvuruTuruList;
    formVars.istakipkart_334144_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.projeTuru : null
    );

    formVars.istakipkart_183673_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.projeTuruID : null
    );

    stateVars.dataSource_183673 = this.state.spGetProjeTuruByBTuruKod;
    stateVars.dataSource_183673 = this.state.spGetProjeTuruByBTuruKod;
    formVars.istakipkart_707596_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.adSoyad : null
    );

    formVars.istakipkart_969221_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.olusturanKisi : null
    );

    stateVars.dataSource_969221 = this.state.KUserList;
    stateVars.dataSource_969221 = this.state.KUserList;
    formVars.istakipkart_96402_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.eMail : null
    );

    formVars.istakipkart_284293_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.eMail : null
    );

    formVars.istakipkart_610845_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.gsm : null
    );

    formVars.istakipkart_528021_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.gsm : null
    );

    formVars.istakipkart_473202_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.sirketAd : null
    );

    formVars.istakipkart_865525_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.aciklama : null
    );

    formVars.istakipkart_310363_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.aciklama : null
    );

    formVars.istakipkart_367546_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.acil : null
    );

    formVars.istakipkart_490476_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.terminTarihi : null
    );

    formVars.istakipkart_956828_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.terminTarihi : null
    );

    formVars.istakipkart_743413_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.servisUcretiVar : null
    );

    formVars.istakipkart_253070_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.projeAd : null
    );

    formVars.istakipkart_204534_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.projeAd : null
    );

    formVars.istakipkart_449363_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.yayinKanali : null
    );

    stateVars.isComp449363Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.yayinKanali : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.istakipkart_583165_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.tvKanal : null
    );

    stateVars.isComp583165Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.tvKanal : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.istakipkart_741340_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.sezon : null
    );

    formVars.istakipkart_397201_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.bolum : null
    );

    stateVars.isComp397201Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.bolum : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.istakipkart_28971_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.diziBolumNo : null
    );

    stateVars.isComp28971Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.diziBolumNo : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.istakipkart_218936_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.konserYerTarih : null
    );

    formVars.istakipkart_309538_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.urun : null
    );

    formVars.istakipkart_401049_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.tvKanal : null
    );

    stateVars.isComp401049Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.tvKanal : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.istakipkart_771200_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.yayinKanali : null
    );

    stateVars.isComp771200Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.yayinKanali : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.istakipkart_915575_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.sezon : null
    );

    formVars.istakipkart_864345_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.tvBolumNo : null
    );

    stateVars.isComp864345Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.tvBolumNo : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.istakipkart_260313_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.bolum : null
    );

    stateVars.isComp260313Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.bolum : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.istakipkart_831469_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.diziBolumNo : null
    );

    stateVars.isComp831469Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.diziBolumNo : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.istakipkart_883142_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.sanatcilar : null
    );

    formVars.istakipkart_427688_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.kullanimSekliCDTanim : null
    );

    stateVars.isComp845132Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, this.state.getFormNo?.length > 0 ? this.state.getFormNo[0]?.formNo : null),
        "0"
      ) === true
        ? "visible"
        : "hidden";

    stateVars.dataSource_73196 = this.state.spgetListKullanimSureleriByBFId_brief;
    formVars.istakipkart_681754_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.briefAciklama : null
    );

    stateVars.isComp681754Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.briefAciklama : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.istakipkart_449550_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.sozlesmeTarihi : null
    );

    formVars.istakipkart_218026_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.lisansBasTarihi : null
    );

    formVars.istakipkart_345176_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.lisansBitTarihi : null
    );

    formVars.istakipkart_787576_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.lisansSuresiz : null
    );

    stateVars.isComp176618Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.formReadOnly : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    formVars.istakipkart_168257_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.cariID : null
    );

    stateVars.dataSource_168257 = this.state.getListCari;
    stateVars.dataSource_168257 = this.state.getListCari;
    stateVars.isComp921407Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, this.state.getFormNo?.length > 0 ? this.state.getFormNo[0]?.formNo : null),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    formVars.istakipkart_494755_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.isIndirimliFiyat : null
    );

    formVars.istakipkart_771873_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.lisansTipiID : null
    );

    stateVars.dataSource_771873 = this.state.sbLisansTipiList;
    stateVars.dataSource_771873 = this.state.sbLisansTipiList;
    formVars.istakipkart_780759_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.telifTurID : null
    );

    stateVars.dataSource_780759 = this.state.sbTelifTurList;
    stateVars.dataSource_780759 = this.state.sbTelifTurList;
    formVars.istakipkart_953041_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.faturaTarihi : null
    );

    formVars.istakipkart_552514_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.faturaTutar : null
    );

    formVars.istakipkart_73134_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.simge : null
    );

    stateVars.isComp739682Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp681149Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.formReadOnly : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp202682Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.formReadOnly : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp330703Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp218524Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp991225Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp476748Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp478750Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp336407Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";

    stateVars.dataSource_215911 = this.state.getListKullanimSureleriByBFId;
    stateVars.isComp975148Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, "istakipkart_215911_value", "groupRow"), "1") ===
      true
        ? "visible"
        : "hidden";
    stateVars.isComp691652Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, this.state.getFormNo?.length > 0 ? this.state.getFormNo[0]?.formNo : null),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp852077Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp13593Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp695930Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp343505Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(this, "istakipkart_215911_value", "fiyatOzel"),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp846928Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.formReadOnly : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp781928Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.formReadOnly : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp369885Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          this.state.getListFiyatlandirmaNotlar?.length > 0 ? this.state.getListFiyatlandirmaNotlar[0]?.id : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";

    stateVars.dataSource_959874 = this.state.getListFiyatlandirmaNotlar;
    stateVars.isComp939691Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, "istakipkart_959874_value", "onemli"), true) ===
      true
        ? "visible"
        : "hidden";
    stateVars.isComp115361Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, "istakipkart_959874_value", "baslik"), true) ===
      true
        ? "visible"
        : "hidden";
    stateVars.isComp809063Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(this, "istakipkart_959874_value", "baslik"),
        true
      ) === true
        ? "visible"
        : "hidden";
    formVars.istakipkart_256375_value = ReactSystemFunctions.toString(
      this,
      this.state.getListKullanimSureleriByBFId?.length > 0 ? this.state.getListKullanimSureleriByBFId[0]?.simge : null
    );

    formVars.istakipkart_649647_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.dipNetTutar : null
    );

    stateVars.isComp122685Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.istakipkart_952267_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.dipServisTutar : null
    );

    stateVars.isComp104543Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    formVars.istakipkart_161516_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.stopajOran : null
    );

    stateVars.dataSource_161516 = this.state.sbStopajList;
    stateVars.dataSource_161516 = this.state.sbStopajList;
    formVars.istakipkart_830227_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.dipStopajTutar : null
    );

    stateVars.isComp734427Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp586759Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.sozlesmeID : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp326018Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    formVars.istakipkart_20311_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.dipBrutTutar : null
    );

    formVars.istakipkart_368022_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.dipIndirim : null
    );

    formVars.istakipkart_241361_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.dipToplam : null
    );

    formVars.istakipkart_286867_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.dipKdv : null
    );

    formVars.istakipkart_228291_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.dipGenelToplam : null
    );

    stateVars.isComp593330Visible =
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.prmNerdenGeldi ?? this.props.screenInputs.prmnerdengeldi,
        "muhasebe"
      ) === true
        ? "visible"
        : "hidden";
    formVars.istakipkart_353223_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.satirAciklama : null
    );

    stateVars.isComp19843Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.formReadOnly : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    formVars.istakipkart_116506_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.vadeGunID : null
    );

    stateVars.dataSource_116506 = this.state.sbVadeGunList;
    stateVars.dataSource_116506 = this.state.sbVadeGunList;
    stateVars.isComp436915Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.formReadOnly : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    formVars.istakipkart_624781_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.notSatir1 : null
    );

    formVars.istakipkart_608425_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.notSatir2 : null
    );

    formVars.istakipkart_93784_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.notSatir3 : null
    );

    stateVars.isComp972677Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.formReadOnly : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp234474Visible =
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.prmNerdenGeldi ?? this.props.screenInputs.prmnerdengeldi,
        "muhasebe"
      ) === true
        ? "visible"
        : "hidden";

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  IsTakipKartComponent_580322_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "IsTakipKart",
      "LeftMenu",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "733845",
      "",
      "left",
      null,
      "",
      "",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  IsTakipKartComponent_927915_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, this.state.getFormNo?.length > 0 ? this.state.getFormNo[0]?.formNo : null),
        "0"
      )
    ) {
      KuikaAppManager.showSpinner(this);

      KuikaAppManager.prepareForNavigation();
      KuikaAppManager.addToPageInputVariables(
        "BFormuBankaV2",
        "prmGuid",
        ReactSystemFunctions.value(
          this,
          this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.id : null
        )
      );
      KuikaAppManager.addToPageInputVariables(
        "BFormuBankaV2",
        "prmBavuruTuruID",
        ReactSystemFunctions.value(
          this,
          this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.basvuruTuruID : null
        )
      );
      KuikaAppManager.addToPageInputVariables("BFormuBankaV2", "NewDisplay", "D");
      KuikaAppManager.addToPageInputVariables("BFormuBankaV2", "prmNerdenGeldi", "istakip");

      stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
        this,
        this.IsTakipKartComponent_927915_onClick1_,
        "IsTakipKart",
        "BFormuBankaV2",
        "",
        "7d969ddf-5801-4eb6-b92b-12be35bdd565",
        "621069",
        null,
        "left",
        null,
        "680px",
        "100vh",
        true
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.IsTakipKartComponent_927915_onClick1_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  IsTakipKartComponent_927915_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(this, this.state.getFormNo?.length > 0 ? this.state.getFormNo[0]?.formNo : null),
        "0"
      )
    ) {
      KuikaAppManager.prepareForNavigation();
      KuikaAppManager.addToPageInputVariables(
        "BFormuTicari",
        "prmGuid",
        ReactSystemFunctions.value(
          this,
          this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.id : null
        )
      );
      KuikaAppManager.addToPageInputVariables(
        "BFormuTicari",
        "prmBavuruTuruID",
        ReactSystemFunctions.value(
          this,
          this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.basvuruTuruID : null
        )
      );
      KuikaAppManager.addToPageInputVariables("BFormuTicari", "NewDisplay", "D");
      KuikaAppManager.addToPageInputVariables(
        "BFormuTicari",
        "prmProjeTuruID",
        ReactSystemFunctions.value(
          this,
          this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.projeTuruID : null
        )
      );
      KuikaAppManager.addToPageInputVariables("BFormuTicari", "prmNerdenGeldi", "istakip");

      stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
        this,
        undefined,
        "IsTakipKart",
        "BFormuTicari",
        "",
        "7d969ddf-5801-4eb6-b92b-12be35bdd565",
        "769334",
        null,
        "left",
        null,
        "680px",
        "100vh",
        true
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  IsTakipKartComponent_817677_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, this.state.getFormNo?.length > 0 ? this.state.getFormNo[0]?.formNo : null),
        "0"
      )
    ) {
      KuikaAppManager.showSpinner(this);

      KuikaAppManager.prepareForNavigation();
      KuikaAppManager.addToPageInputVariables(
        "BFormuBankaV2",
        "prmGuid",
        ReactSystemFunctions.value(
          this,
          this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.id : null
        )
      );
      KuikaAppManager.addToPageInputVariables(
        "BFormuBankaV2",
        "prmBavuruTuruID",
        ReactSystemFunctions.value(
          this,
          this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.basvuruTuruID : null
        )
      );
      KuikaAppManager.addToPageInputVariables("BFormuBankaV2", "NewDisplay", "D");
      KuikaAppManager.addToPageInputVariables("BFormuBankaV2", "prmNerdenGeldi", "istakip");

      stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
        this,
        this.IsTakipKartComponent_817677_onClick1_,
        "IsTakipKart",
        "BFormuBankaV2",
        "",
        "7d969ddf-5801-4eb6-b92b-12be35bdd565",
        "366826",
        null,
        "right",
        null,
        "680px",
        "100vh",
        true
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.IsTakipKartComponent_817677_onClick1_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  IsTakipKartComponent_817677_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(this, this.state.getFormNo?.length > 0 ? this.state.getFormNo[0]?.formNo : null),
        "0"
      )
    ) {
      KuikaAppManager.prepareForNavigation();
      KuikaAppManager.addToPageInputVariables(
        "BFormuTicari",
        "prmGuid",
        ReactSystemFunctions.value(
          this,
          this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.id : null
        )
      );
      KuikaAppManager.addToPageInputVariables(
        "BFormuTicari",
        "prmBavuruTuruID",
        ReactSystemFunctions.value(
          this,
          this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.basvuruTuruID : null
        )
      );
      KuikaAppManager.addToPageInputVariables("BFormuTicari", "NewDisplay", "D");
      KuikaAppManager.addToPageInputVariables(
        "BFormuTicari",
        "prmProjeTuruID",
        ReactSystemFunctions.value(
          this,
          this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.projeTuruID : null
        )
      );
      KuikaAppManager.addToPageInputVariables("BFormuTicari", "prmNerdenGeldi", "istakip");

      stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
        this,
        undefined,
        "IsTakipKart",
        "BFormuTicari",
        "",
        "7d969ddf-5801-4eb6-b92b-12be35bdd565",
        "75984",
        null,
        "right",
        null,
        "680px",
        "100vh",
        true
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  IsTakipKartComponent_553628_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.basvuruTuruID : null
        ),
        "Guid"
      ),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.projeTuruID : null
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "IsTakipKart/IsTakipKartComponent_553628_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.getBasvuruTuruKodById = result?.data.getBasvuruTuruKodById;
    stateVars.getProjeTuruFormKodById = result?.data.getProjeTuruFormKodById;
    stateVars.getFormNo = result?.data.getFormNo;
    stateVars.isComp553628Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(this, stateVars.getFormNo?.length > 0 ? stateVars.getFormNo[0]?.formNo : null),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp845132Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, stateVars.getFormNo?.length > 0 ? stateVars.getFormNo[0]?.formNo : null),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp921407Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, stateVars.getFormNo?.length > 0 ? stateVars.getFormNo[0]?.formNo : null),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp691652Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, stateVars.getFormNo?.length > 0 ? stateVars.getFormNo[0]?.formNo : null),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.IsTakipKartComponent_553628_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  IsTakipKartComponent_553628_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      templateId_0: ReactSystemFunctions.convertToTypeByName("bc58ac79-71e4-4c0f-8387-08dbeb7dbb87", "string"),
      receivers_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      subject_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.ticketNo : null
        ),
        "string"
      ),
      sender_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      cc_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      bcc_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentFileName_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentBase64_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      Eser_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.eserAd : null
        ),
        "string"
      ),
      EserSahibi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.eserSahibi : null
        ),
        "string"
      ),
      TicketNo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.ticketNo : null
        ),
        "string"
      )
    };

    if (ReactSystemFunctions.isEqualTo(true, false)) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "IsTakipKart/IsTakipKartComponent_553628_onClick1_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.SendMail = result?.data.sendMail;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.IsTakipKartComponent_553628_onClick2_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.IsTakipKartComponent_553628_onClick2_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  IsTakipKartComponent_553628_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(this, this.state.getFormNo?.length > 0 ? this.state.getFormNo[0]?.formNo : null),
        "0"
      )
    ) {
      KuikaAppManager.prepareForNavigation();
      KuikaAppManager.addToPageInputVariables(
        "BFormuTicariPrint",
        "prmGuid",
        ReactSystemFunctions.value(
          this,
          this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.id : null
        )
      );
      KuikaAppManager.addToPageInputVariables(
        "BFormuTicariPrint",
        "prmBavuruTuruID",
        ReactSystemFunctions.value(
          this,
          this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.basvuruTuruID : null
        )
      );
      KuikaAppManager.addToPageInputVariables("BFormuTicariPrint", "NewDisplay", "D");
      KuikaAppManager.addToPageInputVariables(
        "BFormuTicariPrint",
        "prmProjeTuruID",
        ReactSystemFunctions.value(
          this,
          this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.projeTuruID : null
        )
      );
      KuikaAppManager.addToPageInputVariables("BFormuTicariPrint", "prmNerdenGeldi", "istakip");

      stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
        this,
        undefined,
        "IsTakipKart",
        "BFormuTicariPrint",
        "",
        "7d969ddf-5801-4eb6-b92b-12be35bdd565",
        "130718",
        null,
        "right",
        null,
        "880px",
        "100vh",
        true
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  IsTakipKartComponent_418150_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  IsTakipKartComponent_91798_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "IsTakipKart/IsTakipKartComponent_91798_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.spSetArsivGeriAl = result?.data.spSetArsivGeriAl;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.IsTakipKartComponent_91798_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  IsTakipKartComponent_91798_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "f23a179a_41b8_0782_9bf4_7fa4ca9c7b0b_notify",
        this.defaultML,
        "İş kartı arşivden çıkartıldı."
      ),
      "success",
      "bottom-right",
      2,
      "",
      "",
      0
    );
    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  IsTakipKartComponent_513972_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      isArsiv_0: ReactSystemFunctions.convertToTypeByName(true, "boolean"),
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    stateVars.Confirm = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "41962633_0c5e_b6c8_e70e_bde0c9e1548a_confirmation",
        this.defaultML,
        "İş kartı arşivlenecek; devam edilsin mi?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel",
      customClass: {
        title: "swal2-font-poppins"
      }
    });

    if (stateVars.Confirm.isConfirmed) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(this, "IsTakipKart/IsTakipKartComponent_513972_onClick", localVar);
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.setIsKartArsivDurumu = result?.data.setIsKartArsivDurumu;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.IsTakipKartComponent_513972_onClick1_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  IsTakipKartComponent_513972_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "b0e8bdeb_99bd_bab2_58a0_fad8f7616ddf_notify",
        this.defaultML,
        "İş kartı arşivlendi."
      ),
      "default",
      "bottom-right",
      2,
      "",
      "",
      0
    );
    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  IsTakipKartComponent_659451_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    stateVars.Confirm = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "78e30e38_26ab_0ec0_611f_9fc1778b8029_confirmation",
        this.defaultML,
        "İş kartı geri bırakılacak; devam edilsin mi?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel",
      customClass: {
        title: "swal2-font-poppins"
      }
    });

    if (stateVars.Confirm.isConfirmed) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(this, "IsTakipKart/IsTakipKartComponent_659451_onClick", localVar);
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.setIsTakipGeriBirak = result?.data.setIsTakipGeriBirak;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.IsTakipKartComponent_659451_onClick1_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  IsTakipKartComponent_659451_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "5996a759_1268_c857_21dd_dd0fc9c8cb83_notify",
        this.defaultML,
        "İş kartı geri bırakıldı."
      ),
      "default",
      "bottom-right",
      2,
      "",
      "",
      0
    );
    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  IsTakipKartComponent_382094_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      BasvuruFormuID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "istakipkart_609729_value", "value", "sbFormStatuList", "id", "id")
        ),
        "00000000-0000-0000-0000-000000000000"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(this, "IsTakipKart/IsTakipKartComponent_382094_onClick", localVar);
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.DiziFilmAlanKontrol = result?.data.diziFilmAlanKontrol;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.IsTakipKartComponent_382094_onClick1_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.IsTakipKartComponent_382094_onClick1_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  IsTakipKartComponent_382094_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      sbCariKod_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "istakipkart_168257_value", "value", "getListCari", "id", "cariAd")
        ),
        "string"
      )
    };

    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "istakipkart_609729_value", "value", "sbFormStatuList", "id", "id")
        ),
        "00000000-0000-0000-0000-000000000000"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "IsTakipKart/IsTakipKartComponent_382094_onClick1_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.FaturaKesilecekFirmaKontrol = result?.data.faturaKesilecekFirmaKontrol;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.IsTakipKartComponent_382094_onClick2_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.IsTakipKartComponent_382094_onClick2_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  IsTakipKartComponent_382094_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      FormStatuID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "istakipkart_609729_value", "value", "sbFormStatuList", "id", "id")
        ),
        "Guid"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "IsTakipKart/IsTakipKartComponent_382094_onClick2_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.setUpdateIsKartiDurum = result?.data.setUpdateIsKartiDurum;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.IsTakipKartComponent_382094_onClick3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  IsTakipKartComponent_382094_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      ProjeAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.projeAd : null
        ),
        "string"
      ),
      ProjeTuru_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.projeTuru : null
        ),
        "string"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    };

    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "istakipkart_609729_value", "value", "sbFormStatuList", "id", "id")
        ),
        "00000000-0000-0000-0000-000000000000"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "IsTakipKart/IsTakipKartComponent_382094_onClick3_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.BasvuruFormuLogoStokSatirAciklamaUpdate = result?.data.basvuruFormuLogoStokSatirAciklamaUpdate;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.IsTakipKartComponent_382094_onClick4_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.IsTakipKartComponent_382094_onClick4_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  IsTakipKartComponent_382094_onClick4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      TicketNo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.ticketNo : null
        ),
        "string"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "IsTakipKart/IsTakipKartComponent_382094_onClick4_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MailSubjectOnaylandi = result?.data.mailSubjectOnaylandi;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.IsTakipKartComponent_382094_onClick5_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  IsTakipKartComponent_382094_onClick5_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      templateId_0: ReactSystemFunctions.convertToTypeByName("4c0fcedf-0f4d-47af-97ec-b803e3b6da96", "string"),
      receivers_0: ReactSystemFunctions.convertToTypeByName(
        "sara@muzikotek.com.tr,baris@muzikotek.com.tr,muhasebe@muzikotek.com.tr",
        "string"
      ),
      subject_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.MailSubjectOnaylandi?.length > 0 ? this.state.MailSubjectOnaylandi[0]?.subject : null
        ),
        "string"
      ),
      sender_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      cc_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      bcc_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentFileName_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentBase64_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      IsNo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.ticketNo : null
        ),
        "string"
      ),
      BasvuruTuru_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.basvuruTuruTanim : null
        ),
        "string"
      ),
      ProjeTuru_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.projeTuru : null
        ),
        "string"
      ),
      ProjeAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.projeAd : null
        ),
        "string"
      ),
      BasvuruSahip_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.adSoyad : null
        ),
        "string"
      ),
      FaturaFirma_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.cariAd : null
        ),
        "string"
      ),
      IlgiliKisi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.İlgiliAdSoyad : null
        ),
        "string"
      ),
      Link_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.mailLink : null
        ),
        "string"
      )
    };

    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "istakipkart_609729_value", "value", "sbFormStatuList", "id", "id")
        ),
        "00000000-0000-0000-0000-000000000000"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "IsTakipKart/IsTakipKartComponent_382094_onClick5_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.SendMail = result?.data.sendMail;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.IsTakipKartComponent_382094_onClick6_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.IsTakipKartComponent_382094_onClick6_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  IsTakipKartComponent_382094_onClick6_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "6aeb5a7a_7351_d9e5_8c70_316214943b7f_notify",
        this.defaultML,
        "İş Takip Durumu Güncellendi!"
      ),
      "success",
      "bottom-right",
      3,
      "",
      "",
      0
    );

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.IsTakipKartComponent_382094_onClick7_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  IsTakipKartComponent_382094_onClick7_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "istakipkart_609729_value", "value", "sbFormStatuList", "id", "id")
        ),
        "00000000-0000-0000-0000-000000000000"
      )
    ) {
      stateVars.GoBack = await ReactSystemFunctions.goBack(this);

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  IsTakipKartComponent_57039_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "istakipkart_352216_value", "value", "KUserList", "userName", "")
        ),
        null
      ),
      message: "*",
      formName: "istakipkart_352216_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      AksiyonIlgiliKisi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "istakipkart_352216_value", "value", "KUserList", "userName", "userName")
        ),
        "string"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "IsTakipKart/IsTakipKartComponent_57039_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.setUpdateIsKartiAksiyonKisi = result?.data.setUpdateIsKartiAksiyonKisi;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.IsTakipKartComponent_57039_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  IsTakipKartComponent_57039_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "b70fc15e_79cf_0b9d_8f94_d0e4898c2c54_notify",
        this.defaultML,
        "Güncellendi!"
      ),
      "success",
      "bottom-right",
      0,
      "",
      "",
      0
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  IsTakipKartComponent_927155_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, "isComp18544Visible", "visible");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  IsTakipKartComponent_994969_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "istakipkart_352216_value", "value", "KUserList", "userName", "")
        ),
        null
      ),
      message: "*",
      formName: "istakipkart_815119_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      BasvuruFormuID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      ),
      IlgiliKisi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "istakipkart_815119_value", "value", "KUserList", "userName", "userName")
        ),
        "string"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(Guid.create().toString(), "Guid")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "IsTakipKart/IsTakipKartComponent_994969_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.setIsTakipCCSave = result?.data.setIsTakipCCSave;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.IsTakipKartComponent_994969_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  IsTakipKartComponent_994969_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "istakipkart_815119_value",
      null,
      null,
      "UserName"
    );
    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, "isComp18544Visible", "hidden");

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.IsTakipKartComponent_994969_onClick2_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  IsTakipKartComponent_994969_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      BasvuruFormuID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "IsTakipKart/IsTakipKartComponent_994969_onClick2_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.getListIsTakipCCbyBFID = result?.data.getListIsTakipCCbyBFID;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.IsTakipKartComponent_994969_onClick3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  IsTakipKartComponent_994969_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_805288 = this.state.getListIsTakipCCbyBFID;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  IsTakipKartComponent_461079_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "istakipkart_815119_value",
      null,
      null,
      "UserName"
    );
    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, "isComp18544Visible", "hidden");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  IsTakipKartComponent_261717_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "istakipkart_805288_value", "id"),
        "Guid"
      ),
      BasvuruFormuID_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    stateVars.Confirm = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "7d0aa17c_4511_7966_83d9_0cb4dbc3787b_confirmation",
        this.defaultML,
        "Seçilen Kayıt Silinecek; Devam Edilsin mi?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel",
      customClass: {
        title: "swal2-font-poppins"
      }
    });

    if (stateVars.Confirm.isConfirmed) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(this, "IsTakipKart/IsTakipKartComponent_261717_onClick", localVar);
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.setIsTakipCCDelete = result?.data.setIsTakipCCDelete;
      stateVars.getListIsTakipCCbyBFID = result?.data.getListIsTakipCCbyBFID;

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.IsTakipKartComponent_261717_onClick1_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  IsTakipKartComponent_261717_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_805288 = this.state.getListIsTakipCCbyBFID;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  IsTakipKartComponent_324369_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  IsTakipKartComponent_140302_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "istakipkart_284293_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "istakipkart_284293_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "istakipkart_352216_value", "value", "KUserList", "userName", "")
        ),
        null
      ),
      message: "*",
      formName: "istakipkart_969221_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "istakipkart_352216_value", "value", "KUserList", "userName", "")
        ),
        null
      ),
      message: "*",
      formName: "istakipkart_352216_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      ),
      FormStatuID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "istakipkart_609729_value", "value", "sbFormStatuList", "id", "id")
        ),
        "Guid"
      ),
      MecraID_0: ReactSystemFunctions.convertToTypeByName(null, "Guid"),
      BolgeID_0: ReactSystemFunctions.convertToTypeByName(null, "Guid"),
      SirketAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "istakipkart_473202_value", "value", "", "", "")
        ),
        "string"
      ),
      ProjeAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "istakipkart_204534_value", "value", "", "", "")
        ),
        "string"
      ),
      EMail_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "istakipkart_284293_value", "value", "", "", "")
        ),
        "string"
      ),
      GSM_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "istakipkart_528021_value", "value", "", "", "")
        ),
        "string"
      ),
      AdSoyad_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "istakipkart_969221_value",
            "value",
            "KUserList",
            "userName",
            "firstLastName"
          )
        ),
        "string"
      ),
      OnemliNot_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "istakipkart_846368_value", "value", "", "", "")
        ),
        "string"
      ),
      NerdenGeldi_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmNerdenGeldi ?? this.props.screenInputs.prmnerdengeldi,
        "string"
      ),
      AksiyonIlgiliKisi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "istakipkart_352216_value", "value", "KUserList", "userName", "userName")
        ),
        "string"
      ),
      OlusturanKisi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "istakipkart_969221_value", "value", "KUserList", "userName", "userName")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "IsTakipKart/IsTakipKartComponent_140302_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.spUpdateBFormuinISTakip = result?.data.spUpdateBFormuinISTakip;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.IsTakipKartComponent_140302_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  IsTakipKartComponent_140302_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "5c379224_ba9c_d217_9fd4_0bbc942f67ef_notify",
        this.defaultML,
        "Kayıt Edildi..!"
      ),
      "success",
      "bottom-right",
      5,
      null,
      null,
      null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  IsTakipKartComponent_363488_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables("AksiyonKart", "prmGuid", Guid.create().toString());
    KuikaAppManager.addToPageInputVariables("AksiyonKart", "NewDisplay", "N");
    KuikaAppManager.addToPageInputVariables(
      "AksiyonKart",
      "BasvuruFormuID",
      this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      this.IsTakipKartComponent_363488_onClick1_,
      "IsTakipKart",
      "AksiyonKart",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "276007",
      "",
      "right",
      null,
      "480",
      "",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };
  IsTakipKartComponent_363488_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      BasvuruFormuID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "IsTakipKart/IsTakipKartComponent_363488_onClick1_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.getListBFormuAksiyon = result?.data.getListBFormuAksiyon;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.IsTakipKartComponent_363488_onClick2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  IsTakipKartComponent_363488_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_729731 = this.state.getListBFormuAksiyon;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  IsTakipKartComponent_432446_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "istakipkart_729731_value", "id"),
        "Guid"
      ),
      BasvuruFormuID_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    stateVars.Confirm = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "2ea52f1e_5ffd_6397_e671_4309d53033b2_confirmation",
        this.defaultML,
        "Seçilen Kayıt Silinecek; Devam Edilsin mi?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel",
      customClass: {
        title: "swal2-font-poppins"
      }
    });

    if (stateVars.Confirm.isConfirmed) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(this, "IsTakipKart/IsTakipKartComponent_432446_onClick", localVar);
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.setBasvuruFormuAksiyonlarDelete = result?.data.setBasvuruFormuAksiyonlarDelete;
      stateVars.getListBFormuAksiyon = result?.data.getListBFormuAksiyon;

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.IsTakipKartComponent_432446_onClick1_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  IsTakipKartComponent_432446_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_729731 = this.state.getListBFormuAksiyon;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  IsTakipKartComponent_934923_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "AksiyonKart",
      "prmGuid",
      ReactSystemFunctions.value(this, "istakipkart_729731_value", "id")
    );
    KuikaAppManager.addToPageInputVariables("AksiyonKart", "NewDisplay", "E");
    KuikaAppManager.addToPageInputVariables(
      "AksiyonKart",
      "BasvuruFormuID",
      ReactSystemFunctions.value(this, "istakipkart_729731_value", "basvuruFormuID")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      this.IsTakipKartComponent_934923_onClick1_,
      "IsTakipKart",
      "AksiyonKart",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "568048",
      "",
      "right",
      null,
      "480",
      "",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };
  IsTakipKartComponent_934923_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      BasvuruFormuID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "IsTakipKart/IsTakipKartComponent_934923_onClick1_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.getListBFormuAksiyon = result?.data.getListBFormuAksiyon;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.IsTakipKartComponent_934923_onClick2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  IsTakipKartComponent_934923_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_729731 = this.state.getListBFormuAksiyon;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  IsTakipKartComponent_685284_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, this.state.getFormNo?.length > 0 ? this.state.getFormNo[0]?.formNo : null),
        "0"
      )
    ) {
      KuikaAppManager.showSpinner(this);

      KuikaAppManager.prepareForNavigation();
      KuikaAppManager.addToPageInputVariables(
        "BFormuBankaV2",
        "prmGuid",
        ReactSystemFunctions.value(
          this,
          this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.id : null
        )
      );
      KuikaAppManager.addToPageInputVariables(
        "BFormuBankaV2",
        "prmBavuruTuruID",
        ReactSystemFunctions.value(
          this,
          this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.basvuruTuruID : null
        )
      );
      KuikaAppManager.addToPageInputVariables("BFormuBankaV2", "NewDisplay", "D");
      KuikaAppManager.addToPageInputVariables("BFormuBankaV2", "prmNerdenGeldi", "istakip");

      stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
        this,
        this.IsTakipKartComponent_685284_onClick1_,
        "IsTakipKart",
        "BFormuBankaV2",
        "",
        "7d969ddf-5801-4eb6-b92b-12be35bdd565",
        "70755",
        null,
        "left",
        null,
        "680px",
        "100vh",
        true
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.IsTakipKartComponent_685284_onClick1_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  IsTakipKartComponent_685284_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(this, this.state.getFormNo?.length > 0 ? this.state.getFormNo[0]?.formNo : null),
        "0"
      )
    ) {
      KuikaAppManager.prepareForNavigation();
      KuikaAppManager.addToPageInputVariables(
        "BFormuTicari",
        "prmGuid",
        ReactSystemFunctions.value(
          this,
          this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.id : null
        )
      );
      KuikaAppManager.addToPageInputVariables(
        "BFormuTicari",
        "prmBavuruTuruID",
        ReactSystemFunctions.value(
          this,
          this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.basvuruTuruID : null
        )
      );
      KuikaAppManager.addToPageInputVariables("BFormuTicari", "NewDisplay", "D");
      KuikaAppManager.addToPageInputVariables(
        "BFormuTicari",
        "prmProjeTuruID",
        ReactSystemFunctions.value(
          this,
          this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.projeTuruID : null
        )
      );
      KuikaAppManager.addToPageInputVariables("BFormuTicari", "prmNerdenGeldi", "istakip");

      stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
        this,
        undefined,
        "IsTakipKart",
        "BFormuTicari",
        "",
        "7d969ddf-5801-4eb6-b92b-12be35bdd565",
        "253929",
        null,
        "left",
        null,
        "680px",
        "100vh",
        true
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  IsTakipKartComponent_451453_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, this.state.getFormNo?.length > 0 ? this.state.getFormNo[0]?.formNo : null),
        "0"
      )
    ) {
      KuikaAppManager.showSpinner(this);

      KuikaAppManager.prepareForNavigation();
      KuikaAppManager.addToPageInputVariables(
        "BFormuBankaV2",
        "prmGuid",
        ReactSystemFunctions.value(
          this,
          this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.id : null
        )
      );
      KuikaAppManager.addToPageInputVariables(
        "BFormuBankaV2",
        "prmBavuruTuruID",
        ReactSystemFunctions.value(
          this,
          this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.basvuruTuruID : null
        )
      );
      KuikaAppManager.addToPageInputVariables("BFormuBankaV2", "NewDisplay", "D");
      KuikaAppManager.addToPageInputVariables("BFormuBankaV2", "prmNerdenGeldi", "istakip");

      stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
        this,
        this.IsTakipKartComponent_451453_onClick1_,
        "IsTakipKart",
        "BFormuBankaV2",
        "",
        "7d969ddf-5801-4eb6-b92b-12be35bdd565",
        "827710",
        null,
        "right",
        null,
        "680px",
        "100vh",
        true
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.IsTakipKartComponent_451453_onClick1_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  IsTakipKartComponent_451453_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(this, this.state.getFormNo?.length > 0 ? this.state.getFormNo[0]?.formNo : null),
        "0"
      )
    ) {
      KuikaAppManager.prepareForNavigation();
      KuikaAppManager.addToPageInputVariables(
        "BFormuTicari",
        "prmGuid",
        ReactSystemFunctions.value(
          this,
          this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.id : null
        )
      );
      KuikaAppManager.addToPageInputVariables(
        "BFormuTicari",
        "prmBavuruTuruID",
        ReactSystemFunctions.value(
          this,
          this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.basvuruTuruID : null
        )
      );
      KuikaAppManager.addToPageInputVariables("BFormuTicari", "NewDisplay", "D");
      KuikaAppManager.addToPageInputVariables(
        "BFormuTicari",
        "prmProjeTuruID",
        ReactSystemFunctions.value(
          this,
          this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.projeTuruID : null
        )
      );
      KuikaAppManager.addToPageInputVariables("BFormuTicari", "prmNerdenGeldi", "istakip");

      stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
        this,
        undefined,
        "IsTakipKart",
        "BFormuTicari",
        "",
        "7d969ddf-5801-4eb6-b92b-12be35bdd565",
        "921294",
        null,
        "right",
        null,
        "680px",
        "100vh",
        true
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  IsTakipKartComponent_803993_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
      this,
      "isComp545617Visible",
      "visible"
    );
    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
      this,
      "isComp803842Visible",
      "visible"
    );
    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
      this,
      "isComp816615Visible",
      "visible"
    );
    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, "isComp572328Visible", "hidden");
    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, "isComp803993Visible", "hidden");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  IsTakipKartComponent_816615_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, "isComp545617Visible", "hidden");
    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, "isComp803842Visible", "hidden");
    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, "isComp816615Visible", "hidden");
    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
      this,
      "isComp572328Visible",
      "visible"
    );
    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
      this,
      "isComp803993Visible",
      "visible"
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  IsTakipKartComponent_743413_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      ),
      ServisUcretiVar_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "istakipkart_743413_value", "value", "", "", "")
        ),
        "boolean"
      ),
      BasvuruFormuID_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      ),
      BasvuruTuru_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "istakipkart_765584_value", "value", "sbBasvuruTuruList", "id", "kod")
        ),
        "number"
      ),
      BasvuruFormuID_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      ),
      ServisUcretiVar_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "istakipkart_743413_value", "value", "", "", "")
        ),
        "boolean"
      ),
      Stopaj_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "istakipkart_168257_value", "value", "getListCari", "id", "satisStopaj")
        ),
        "number"
      ),
      Id_3: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      ),
      Rol_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.getUserInfo?.length > 0 ? this.state.getUserInfo[0]?.rolName : null
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "IsTakipKart/IsTakipKartComponent_743413_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.spUpdateServisUcret = result?.data.spUpdateServisUcret;
    stateVars.getListKullanimSureleriByBFId = result?.data.getListKullanimSureleriByBFId;

    formVars.istakipkart_256375_value = ReactSystemFunctions.toString(
      this,
      stateVars.getListKullanimSureleriByBFId?.length > 0 ? stateVars.getListKullanimSureleriByBFId[0]?.simge : null
    );
    stateVars.spDipToplamByBFId = result?.data.spDipToplamByBFId;
    stateVars.getBasvuruFormuById = result?.data.getBasvuruFormuById;
    formVars.istakipkart_785916_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.ticketNo : null
    );
    formVars.istakipkart_308766_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.olusturmaTarihi : null
    );
    formVars.istakipkart_354958_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.isTakipBaslangicTarihi : null
    );
    formVars.istakipkart_72949_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.isTakipGuncellemeZaman : null
    );
    formVars.istakipkart_174064_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.terminTarihi : null
    );
    formVars.istakipkart_609729_value =
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formStatuID : null;
    formVars.istakipkart_352216_value =
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.aksiyonIlgiliKisi : null;
    formVars.istakipkart_846368_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.onemliNot : null
    );
    formVars.istakipkart_679771_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.eserAd : null
    );
    formVars.istakipkart_679331_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.eserAd : null
    );
    formVars.istakipkart_872419_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.eserSahibi : null
    );
    formVars.istakipkart_909777_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.eserSahibi : null
    );
    formVars.istakipkart_880403_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruTanim : null
    );
    formVars.istakipkart_765584_value =
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruID : null;
    formVars.istakipkart_334144_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.projeTuru : null
    );
    formVars.istakipkart_183673_value =
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.projeTuruID : null;
    formVars.istakipkart_707596_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.adSoyad : null
    );
    formVars.istakipkart_969221_value =
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.olusturanKisi : null;
    formVars.istakipkart_96402_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.eMail : null
    );
    formVars.istakipkart_284293_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.eMail : null
    );
    formVars.istakipkart_610845_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.gsm : null
    );
    formVars.istakipkart_528021_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.gsm : null
    );
    formVars.istakipkart_473202_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.sirketAd : null
    );
    formVars.istakipkart_865525_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.aciklama : null
    );
    formVars.istakipkart_310363_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.aciklama : null
    );
    formVars.istakipkart_367546_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.acil : null
    );
    formVars.istakipkart_490476_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.terminTarihi : null
    );
    formVars.istakipkart_956828_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.terminTarihi : null
    );
    formVars.istakipkart_743413_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.servisUcretiVar : null
    );
    formVars.istakipkart_253070_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.projeAd : null
    );
    formVars.istakipkart_204534_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.projeAd : null
    );
    formVars.istakipkart_449363_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.yayinKanali : null
    );
    formVars.istakipkart_583165_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.tvKanal : null
    );
    formVars.istakipkart_741340_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.sezon : null
    );
    formVars.istakipkart_397201_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.bolum : null
    );
    formVars.istakipkart_28971_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.diziBolumNo : null
    );
    formVars.istakipkart_218936_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.konserYerTarih : null
    );
    formVars.istakipkart_309538_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.urun : null
    );
    formVars.istakipkart_401049_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.tvKanal : null
    );
    formVars.istakipkart_771200_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.yayinKanali : null
    );
    formVars.istakipkart_915575_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.sezon : null
    );
    formVars.istakipkart_864345_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.tvBolumNo : null
    );
    formVars.istakipkart_260313_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.bolum : null
    );
    formVars.istakipkart_831469_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.diziBolumNo : null
    );
    formVars.istakipkart_883142_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.sanatcilar : null
    );
    formVars.istakipkart_427688_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.kullanimSekliCDTanim : null
    );
    formVars.istakipkart_681754_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.briefAciklama : null
    );
    formVars.istakipkart_449550_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.sozlesmeTarihi : null
    );
    formVars.istakipkart_218026_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.lisansBasTarihi : null
    );
    formVars.istakipkart_218026_disablebeforedate = ReactSystemFunctions.value(
      this,
      ReactSystemFunctions.value(this, "istakipkart_449550_value", "value", "", "", "")
    );
    formVars.istakipkart_345176_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.lisansBitTarihi : null
    );
    formVars.istakipkart_345176_disablebeforedate = ReactSystemFunctions.value(
      this,
      ReactSystemFunctions.value(this, "istakipkart_218026_value", "value", "", "", "")
    );
    formVars.istakipkart_787576_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.lisansSuresiz : null
    );
    formVars.istakipkart_168257_value =
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.cariID : null;
    formVars.istakipkart_494755_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.isIndirimliFiyat : null
    );
    formVars.istakipkart_771873_value =
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.lisansTipiID : null;
    formVars.istakipkart_780759_value =
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.telifTurID : null;
    formVars.istakipkart_953041_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.faturaTarihi : null
    );
    formVars.istakipkart_552514_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.faturaTutar : null
    );
    formVars.istakipkart_73134_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.simge : null
    );
    formVars.istakipkart_649647_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.dipNetTutar : null
    );
    formVars.istakipkart_952267_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.dipServisTutar : null
    );
    formVars.istakipkart_161516_value =
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.stopajOran : null;
    formVars.istakipkart_830227_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.dipStopajTutar : null
    );
    formVars.istakipkart_20311_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.dipBrutTutar : null
    );
    formVars.istakipkart_368022_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.dipIndirim : null
    );
    formVars.istakipkart_241361_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.dipToplam : null
    );
    formVars.istakipkart_286867_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.dipKdv : null
    );
    formVars.istakipkart_228291_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.dipGenelToplam : null
    );
    formVars.istakipkart_353223_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.satirAciklama : null
    );
    formVars.istakipkart_116506_value =
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.vadeGunID : null;
    formVars.istakipkart_624781_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.notSatir1 : null
    );
    formVars.istakipkart_608425_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.notSatir2 : null
    );
    formVars.istakipkart_93784_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.notSatir3 : null
    );
    stateVars.isComp659451Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formReadOnly : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp382094Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0
            ? stateVars.getBasvuruFormuById[0]?.isTakipDurumChangeButtonVisibily
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp57039Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formReadOnly : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp927155Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formReadOnly : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp363488Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formReadOnly : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp432446Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formReadOnly : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp934923Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formReadOnly : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp375212Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp449363Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.yayinKanali : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp583165Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.tvKanal : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp397201Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.bolum : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp28971Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.diziBolumNo : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp401049Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.tvKanal : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp771200Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.yayinKanali : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp864345Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.tvBolumNo : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp260313Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.bolum : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp831469Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.diziBolumNo : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp681754Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.briefAciklama : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp176618Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formReadOnly : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp739682Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp681149Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formReadOnly : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp202682Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formReadOnly : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp330703Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp218524Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp991225Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp476748Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp478750Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp336407Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp852077Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp13593Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp695930Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp846928Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formReadOnly : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp781928Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formReadOnly : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp122685Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp104543Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp734427Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp586759Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.sozlesmeID : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp326018Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp19843Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formReadOnly : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp436915Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formReadOnly : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp972677Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formReadOnly : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.IsTakipKartComponent_743413_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  IsTakipKartComponent_743413_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.istakipkart_785916_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.ticketNo : null
    );

    formVars.istakipkart_308766_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.olusturmaTarihi : null
    );

    formVars.istakipkart_354958_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.isTakipBaslangicTarihi : null
    );

    formVars.istakipkart_72949_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.isTakipGuncellemeZaman : null
    );

    formVars.istakipkart_174064_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.terminTarihi : null
    );

    formVars.istakipkart_609729_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.formStatuID : null
    );

    formVars.istakipkart_352216_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.aksiyonIlgiliKisi : null
    );

    formVars.istakipkart_846368_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.onemliNot : null
    );

    formVars.istakipkart_679771_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.eserAd : null
    );

    formVars.istakipkart_679331_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.eserAd : null
    );

    formVars.istakipkart_872419_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.eserSahibi : null
    );

    formVars.istakipkart_909777_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.eserSahibi : null
    );

    formVars.istakipkart_880403_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.basvuruTuruTanim : null
    );

    formVars.istakipkart_765584_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.basvuruTuruID : null
    );

    formVars.istakipkart_334144_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.projeTuru : null
    );

    formVars.istakipkart_183673_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.projeTuruID : null
    );

    formVars.istakipkart_707596_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.adSoyad : null
    );

    formVars.istakipkart_969221_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.olusturanKisi : null
    );

    formVars.istakipkart_96402_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.eMail : null
    );

    formVars.istakipkart_284293_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.eMail : null
    );

    formVars.istakipkart_610845_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.gsm : null
    );

    formVars.istakipkart_528021_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.gsm : null
    );

    formVars.istakipkart_473202_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.sirketAd : null
    );

    formVars.istakipkart_865525_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.aciklama : null
    );

    formVars.istakipkart_310363_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.aciklama : null
    );

    formVars.istakipkart_367546_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.acil : null
    );

    formVars.istakipkart_490476_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.terminTarihi : null
    );

    formVars.istakipkart_956828_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.terminTarihi : null
    );

    formVars.istakipkart_743413_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.servisUcretiVar : null
    );

    formVars.istakipkart_253070_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.projeAd : null
    );

    formVars.istakipkart_204534_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.projeAd : null
    );

    formVars.istakipkart_449363_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.yayinKanali : null
    );

    formVars.istakipkart_583165_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.tvKanal : null
    );

    formVars.istakipkart_741340_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.sezon : null
    );

    formVars.istakipkart_397201_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.bolum : null
    );

    formVars.istakipkart_28971_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.diziBolumNo : null
    );

    formVars.istakipkart_218936_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.konserYerTarih : null
    );

    formVars.istakipkart_309538_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.urun : null
    );

    formVars.istakipkart_401049_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.tvKanal : null
    );

    formVars.istakipkart_771200_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.yayinKanali : null
    );

    formVars.istakipkart_915575_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.sezon : null
    );

    formVars.istakipkart_864345_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.tvBolumNo : null
    );

    formVars.istakipkart_260313_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.bolum : null
    );

    formVars.istakipkart_831469_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.diziBolumNo : null
    );

    formVars.istakipkart_883142_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.sanatcilar : null
    );

    formVars.istakipkart_427688_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.kullanimSekliCDTanim : null
    );

    formVars.istakipkart_681754_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.briefAciklama : null
    );

    formVars.istakipkart_449550_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.sozlesmeTarihi : null
    );

    formVars.istakipkart_218026_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.lisansBasTarihi : null
    );

    formVars.istakipkart_345176_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.lisansBitTarihi : null
    );

    formVars.istakipkart_787576_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.lisansSuresiz : null
    );

    formVars.istakipkart_168257_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.cariID : null
    );

    formVars.istakipkart_494755_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.isIndirimliFiyat : null
    );

    formVars.istakipkart_771873_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.lisansTipiID : null
    );

    formVars.istakipkart_780759_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.telifTurID : null
    );

    formVars.istakipkart_953041_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.faturaTarihi : null
    );

    formVars.istakipkart_552514_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.faturaTutar : null
    );

    formVars.istakipkart_73134_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.simge : null
    );

    stateVars.dataSource_215911 = this.state.getListKullanimSureleriByBFId;
    formVars.istakipkart_256375_value = ReactSystemFunctions.toString(
      this,
      this.state.getListKullanimSureleriByBFId?.length > 0 ? this.state.getListKullanimSureleriByBFId[0]?.simge : null
    );

    formVars.istakipkart_649647_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.dipNetTutar : null
    );

    formVars.istakipkart_952267_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.dipServisTutar : null
    );

    formVars.istakipkart_161516_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.stopajOran : null
    );

    formVars.istakipkart_830227_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.dipStopajTutar : null
    );

    formVars.istakipkart_20311_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.dipBrutTutar : null
    );

    formVars.istakipkart_368022_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.dipIndirim : null
    );

    formVars.istakipkart_241361_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.dipToplam : null
    );

    formVars.istakipkart_286867_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.dipKdv : null
    );

    formVars.istakipkart_228291_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.dipGenelToplam : null
    );

    formVars.istakipkart_353223_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.satirAciklama : null
    );

    formVars.istakipkart_116506_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.vadeGunID : null
    );

    formVars.istakipkart_624781_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.notSatir1 : null
    );

    formVars.istakipkart_608425_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.notSatir2 : null
    );

    formVars.istakipkart_93784_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.notSatir3 : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  IsTakipKartComponent_176618_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      SozlesmeTarihi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "istakipkart_449550_value", "value", "", "", "")
        ),
        "Date"
      ),
      LisansBasTarihi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "istakipkart_218026_value", "value", "", "", "")
        ),
        "Date"
      ),
      LisansBitTarihi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "istakipkart_345176_value", "value", "", "", "")
        ),
        "Date"
      ),
      LisansSuresiz_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "istakipkart_787576_value", "value", "", "", "")
        ),
        "boolean"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "IsTakipKart/IsTakipKartComponent_176618_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.setBasvuruFormuSozlesmeBilgileriSave = result?.data.setBasvuruFormuSozlesmeBilgileriSave;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  IsTakipKartComponent_168257_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      CariId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "istakipkart_168257_value", "value", "getListCari", "id", "id")
        ),
        "Guid"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "IsTakipKart/IsTakipKartComponent_168257_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.setUpdateCariId = result?.data.setUpdateCariId;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.IsTakipKartComponent_168257_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  IsTakipKartComponent_168257_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      BasvuruFormuID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    };

    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, this.state.getFormNo?.length > 0 ? this.state.getFormNo[0]?.formNo : null),
        "0"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "IsTakipKart/IsTakipKartComponent_168257_onChange1_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.spUpdateFiyatlandirmaByBFId = result?.data.spUpdateFiyatlandirmaByBFId;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.IsTakipKartComponent_168257_onChange2_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.IsTakipKartComponent_168257_onChange2_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  IsTakipKartComponent_168257_onChange2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      BasvuruFormuID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      ),
      BasvuruTuru_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "istakipkart_765584_value", "value", "sbBasvuruTuruList", "id", "kod")
        ),
        "number"
      ),
      BasvuruFormuID_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      ),
      ServisUcretiVar_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "istakipkart_743413_value", "value", "", "", "")
        ),
        "boolean"
      ),
      Stopaj_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "istakipkart_161516_value",
            "value",
            "sbStopajList",
            "stopajOran",
            "stopajOran"
          )
        ),
        "number"
      ),
      Id_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      ),
      Rol_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.getUserInfo?.length > 0 ? this.state.getUserInfo[0]?.rolName : null
        ),
        "string"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "IsTakipKart/IsTakipKartComponent_168257_onChange2_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.getListKullanimSureleriByBFId = result?.data.getListKullanimSureleriByBFId;

    formVars.istakipkart_256375_value = ReactSystemFunctions.toString(
      this,
      stateVars.getListKullanimSureleriByBFId?.length > 0 ? stateVars.getListKullanimSureleriByBFId[0]?.simge : null
    );
    stateVars.spDipToplamByBFId = result?.data.spDipToplamByBFId;
    stateVars.getBasvuruFormuById = result?.data.getBasvuruFormuById;
    formVars.istakipkart_785916_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.ticketNo : null
    );
    formVars.istakipkart_308766_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.olusturmaTarihi : null
    );
    formVars.istakipkart_354958_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.isTakipBaslangicTarihi : null
    );
    formVars.istakipkart_72949_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.isTakipGuncellemeZaman : null
    );
    formVars.istakipkart_174064_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.terminTarihi : null
    );
    formVars.istakipkart_609729_value =
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formStatuID : null;
    formVars.istakipkart_352216_value =
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.aksiyonIlgiliKisi : null;
    formVars.istakipkart_846368_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.onemliNot : null
    );
    formVars.istakipkart_679771_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.eserAd : null
    );
    formVars.istakipkart_679331_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.eserAd : null
    );
    formVars.istakipkart_872419_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.eserSahibi : null
    );
    formVars.istakipkart_909777_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.eserSahibi : null
    );
    formVars.istakipkart_880403_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruTanim : null
    );
    formVars.istakipkart_765584_value =
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruID : null;
    formVars.istakipkart_334144_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.projeTuru : null
    );
    formVars.istakipkart_183673_value =
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.projeTuruID : null;
    formVars.istakipkart_707596_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.adSoyad : null
    );
    formVars.istakipkart_969221_value =
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.olusturanKisi : null;
    formVars.istakipkart_96402_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.eMail : null
    );
    formVars.istakipkart_284293_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.eMail : null
    );
    formVars.istakipkart_610845_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.gsm : null
    );
    formVars.istakipkart_528021_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.gsm : null
    );
    formVars.istakipkart_473202_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.sirketAd : null
    );
    formVars.istakipkart_865525_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.aciklama : null
    );
    formVars.istakipkart_310363_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.aciklama : null
    );
    formVars.istakipkart_367546_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.acil : null
    );
    formVars.istakipkart_490476_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.terminTarihi : null
    );
    formVars.istakipkart_956828_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.terminTarihi : null
    );
    formVars.istakipkart_743413_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.servisUcretiVar : null
    );
    formVars.istakipkart_253070_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.projeAd : null
    );
    formVars.istakipkart_204534_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.projeAd : null
    );
    formVars.istakipkart_449363_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.yayinKanali : null
    );
    formVars.istakipkart_583165_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.tvKanal : null
    );
    formVars.istakipkart_741340_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.sezon : null
    );
    formVars.istakipkart_397201_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.bolum : null
    );
    formVars.istakipkart_28971_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.diziBolumNo : null
    );
    formVars.istakipkart_218936_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.konserYerTarih : null
    );
    formVars.istakipkart_309538_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.urun : null
    );
    formVars.istakipkart_401049_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.tvKanal : null
    );
    formVars.istakipkart_771200_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.yayinKanali : null
    );
    formVars.istakipkart_915575_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.sezon : null
    );
    formVars.istakipkart_864345_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.tvBolumNo : null
    );
    formVars.istakipkart_260313_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.bolum : null
    );
    formVars.istakipkart_831469_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.diziBolumNo : null
    );
    formVars.istakipkart_883142_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.sanatcilar : null
    );
    formVars.istakipkart_427688_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.kullanimSekliCDTanim : null
    );
    formVars.istakipkart_681754_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.briefAciklama : null
    );
    formVars.istakipkart_449550_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.sozlesmeTarihi : null
    );
    formVars.istakipkart_218026_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.lisansBasTarihi : null
    );
    formVars.istakipkart_218026_disablebeforedate = ReactSystemFunctions.value(
      this,
      ReactSystemFunctions.value(this, "istakipkart_449550_value", "value", "", "", "")
    );
    formVars.istakipkart_345176_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.lisansBitTarihi : null
    );
    formVars.istakipkart_345176_disablebeforedate = ReactSystemFunctions.value(
      this,
      ReactSystemFunctions.value(this, "istakipkart_218026_value", "value", "", "", "")
    );
    formVars.istakipkart_787576_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.lisansSuresiz : null
    );
    formVars.istakipkart_168257_value =
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.cariID : null;
    formVars.istakipkart_494755_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.isIndirimliFiyat : null
    );
    formVars.istakipkart_771873_value =
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.lisansTipiID : null;
    formVars.istakipkart_780759_value =
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.telifTurID : null;
    formVars.istakipkart_953041_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.faturaTarihi : null
    );
    formVars.istakipkart_552514_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.faturaTutar : null
    );
    formVars.istakipkart_73134_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.simge : null
    );
    formVars.istakipkart_649647_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.dipNetTutar : null
    );
    formVars.istakipkart_952267_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.dipServisTutar : null
    );
    formVars.istakipkart_161516_value =
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.stopajOran : null;
    formVars.istakipkart_830227_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.dipStopajTutar : null
    );
    formVars.istakipkart_20311_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.dipBrutTutar : null
    );
    formVars.istakipkart_368022_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.dipIndirim : null
    );
    formVars.istakipkart_241361_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.dipToplam : null
    );
    formVars.istakipkart_286867_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.dipKdv : null
    );
    formVars.istakipkart_228291_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.dipGenelToplam : null
    );
    formVars.istakipkart_353223_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.satirAciklama : null
    );
    formVars.istakipkart_116506_value =
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.vadeGunID : null;
    formVars.istakipkart_624781_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.notSatir1 : null
    );
    formVars.istakipkart_608425_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.notSatir2 : null
    );
    formVars.istakipkart_93784_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.notSatir3 : null
    );
    stateVars.isComp659451Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formReadOnly : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp382094Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0
            ? stateVars.getBasvuruFormuById[0]?.isTakipDurumChangeButtonVisibily
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp57039Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formReadOnly : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp927155Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formReadOnly : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp363488Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formReadOnly : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp432446Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formReadOnly : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp934923Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formReadOnly : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp375212Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp449363Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.yayinKanali : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp583165Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.tvKanal : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp397201Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.bolum : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp28971Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.diziBolumNo : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp401049Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.tvKanal : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp771200Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.yayinKanali : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp864345Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.tvBolumNo : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp260313Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.bolum : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp831469Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.diziBolumNo : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp681754Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.briefAciklama : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp176618Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formReadOnly : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp739682Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp681149Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formReadOnly : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp202682Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formReadOnly : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp330703Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp218524Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp991225Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp476748Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp478750Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp336407Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp852077Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp13593Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp695930Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp846928Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formReadOnly : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp781928Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formReadOnly : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp122685Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp104543Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp734427Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp586759Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.sozlesmeID : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp326018Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp19843Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formReadOnly : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp436915Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formReadOnly : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp972677Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formReadOnly : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.IsTakipKartComponent_168257_onChange3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  IsTakipKartComponent_168257_onChange3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.istakipkart_785916_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.ticketNo : null
    );

    formVars.istakipkart_308766_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.olusturmaTarihi : null
    );

    formVars.istakipkart_354958_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.isTakipBaslangicTarihi : null
    );

    formVars.istakipkart_72949_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.isTakipGuncellemeZaman : null
    );

    formVars.istakipkart_174064_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.terminTarihi : null
    );

    formVars.istakipkart_609729_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.formStatuID : null
    );

    formVars.istakipkart_352216_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.aksiyonIlgiliKisi : null
    );

    formVars.istakipkart_846368_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.onemliNot : null
    );

    formVars.istakipkart_679771_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.eserAd : null
    );

    formVars.istakipkart_679331_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.eserAd : null
    );

    formVars.istakipkart_872419_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.eserSahibi : null
    );

    formVars.istakipkart_909777_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.eserSahibi : null
    );

    formVars.istakipkart_880403_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.basvuruTuruTanim : null
    );

    formVars.istakipkart_765584_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.basvuruTuruID : null
    );

    formVars.istakipkart_334144_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.projeTuru : null
    );

    formVars.istakipkart_183673_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.projeTuruID : null
    );

    formVars.istakipkart_707596_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.adSoyad : null
    );

    formVars.istakipkart_969221_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.olusturanKisi : null
    );

    formVars.istakipkart_96402_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.eMail : null
    );

    formVars.istakipkart_284293_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.eMail : null
    );

    formVars.istakipkart_610845_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.gsm : null
    );

    formVars.istakipkart_528021_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.gsm : null
    );

    formVars.istakipkart_473202_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.sirketAd : null
    );

    formVars.istakipkart_865525_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.aciklama : null
    );

    formVars.istakipkart_310363_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.aciklama : null
    );

    formVars.istakipkart_367546_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.acil : null
    );

    formVars.istakipkart_490476_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.terminTarihi : null
    );

    formVars.istakipkart_956828_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.terminTarihi : null
    );

    formVars.istakipkart_743413_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.servisUcretiVar : null
    );

    formVars.istakipkart_253070_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.projeAd : null
    );

    formVars.istakipkart_204534_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.projeAd : null
    );

    formVars.istakipkart_449363_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.yayinKanali : null
    );

    formVars.istakipkart_583165_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.tvKanal : null
    );

    formVars.istakipkart_741340_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.sezon : null
    );

    formVars.istakipkart_397201_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.bolum : null
    );

    formVars.istakipkart_28971_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.diziBolumNo : null
    );

    formVars.istakipkart_218936_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.konserYerTarih : null
    );

    formVars.istakipkart_309538_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.urun : null
    );

    formVars.istakipkart_401049_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.tvKanal : null
    );

    formVars.istakipkart_771200_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.yayinKanali : null
    );

    formVars.istakipkart_915575_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.sezon : null
    );

    formVars.istakipkart_864345_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.tvBolumNo : null
    );

    formVars.istakipkart_260313_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.bolum : null
    );

    formVars.istakipkart_831469_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.diziBolumNo : null
    );

    formVars.istakipkart_883142_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.sanatcilar : null
    );

    formVars.istakipkart_427688_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.kullanimSekliCDTanim : null
    );

    formVars.istakipkart_681754_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.briefAciklama : null
    );

    formVars.istakipkart_449550_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.sozlesmeTarihi : null
    );

    formVars.istakipkart_218026_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.lisansBasTarihi : null
    );

    formVars.istakipkart_345176_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.lisansBitTarihi : null
    );

    formVars.istakipkart_787576_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.lisansSuresiz : null
    );

    formVars.istakipkart_168257_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.cariID : null
    );

    formVars.istakipkart_494755_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.isIndirimliFiyat : null
    );

    formVars.istakipkart_771873_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.lisansTipiID : null
    );

    formVars.istakipkart_780759_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.telifTurID : null
    );

    formVars.istakipkart_953041_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.faturaTarihi : null
    );

    formVars.istakipkart_552514_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.faturaTutar : null
    );

    formVars.istakipkart_73134_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.simge : null
    );

    stateVars.dataSource_215911 = this.state.getListKullanimSureleriByBFId;
    formVars.istakipkart_256375_value = ReactSystemFunctions.toString(
      this,
      this.state.getListKullanimSureleriByBFId?.length > 0 ? this.state.getListKullanimSureleriByBFId[0]?.simge : null
    );

    formVars.istakipkart_649647_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.dipNetTutar : null
    );

    formVars.istakipkart_952267_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.dipServisTutar : null
    );

    formVars.istakipkart_161516_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.stopajOran : null
    );

    formVars.istakipkart_830227_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.dipStopajTutar : null
    );

    formVars.istakipkart_20311_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.dipBrutTutar : null
    );

    formVars.istakipkart_368022_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.dipIndirim : null
    );

    formVars.istakipkart_241361_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.dipToplam : null
    );

    formVars.istakipkart_286867_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.dipKdv : null
    );

    formVars.istakipkart_228291_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.dipGenelToplam : null
    );

    formVars.istakipkart_353223_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.satirAciklama : null
    );

    formVars.istakipkart_116506_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.vadeGunID : null
    );

    formVars.istakipkart_624781_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.notSatir1 : null
    );

    formVars.istakipkart_608425_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.notSatir2 : null
    );

    formVars.istakipkart_93784_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.notSatir3 : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  IsTakipKartComponent_494755_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      ),
      IndirimliFiyat_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "istakipkart_494755_value", "value", "", "", "")
        ),
        "boolean"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "IsTakipKart/IsTakipKartComponent_494755_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.spUpdateIndirimliFiyatKullan = result?.data.spUpdateIndirimliFiyatKullan;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.IsTakipKartComponent_494755_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  IsTakipKartComponent_494755_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      BasvuruFormuID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    };

    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, this.state.getFormNo?.length > 0 ? this.state.getFormNo[0]?.formNo : null),
        "0"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "IsTakipKart/IsTakipKartComponent_494755_onChange1_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.spUpdateFiyatlandirmaByBFId = result?.data.spUpdateFiyatlandirmaByBFId;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.IsTakipKartComponent_494755_onChange2_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.IsTakipKartComponent_494755_onChange2_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  IsTakipKartComponent_494755_onChange2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      BasvuruFormuID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      ),
      BasvuruTuru_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "istakipkart_765584_value", "value", "sbBasvuruTuruList", "id", "kod")
        ),
        "number"
      ),
      BasvuruFormuID_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      ),
      ServisUcretiVar_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "istakipkart_743413_value", "value", "", "", "")
        ),
        "boolean"
      ),
      Stopaj_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "istakipkart_168257_value", "value", "getListCari", "id", "satisStopaj")
        ),
        "number"
      ),
      Id_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      ),
      Rol_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.getUserInfo?.length > 0 ? this.state.getUserInfo[0]?.rolName : null
        ),
        "string"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "IsTakipKart/IsTakipKartComponent_494755_onChange2_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.getListKullanimSureleriByBFId = result?.data.getListKullanimSureleriByBFId;

    formVars.istakipkart_256375_value = ReactSystemFunctions.toString(
      this,
      stateVars.getListKullanimSureleriByBFId?.length > 0 ? stateVars.getListKullanimSureleriByBFId[0]?.simge : null
    );
    stateVars.spDipToplamByBFId = result?.data.spDipToplamByBFId;
    stateVars.getBasvuruFormuById = result?.data.getBasvuruFormuById;
    formVars.istakipkart_785916_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.ticketNo : null
    );
    formVars.istakipkart_308766_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.olusturmaTarihi : null
    );
    formVars.istakipkart_354958_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.isTakipBaslangicTarihi : null
    );
    formVars.istakipkart_72949_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.isTakipGuncellemeZaman : null
    );
    formVars.istakipkart_174064_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.terminTarihi : null
    );
    formVars.istakipkart_609729_value =
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formStatuID : null;
    formVars.istakipkart_352216_value =
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.aksiyonIlgiliKisi : null;
    formVars.istakipkart_846368_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.onemliNot : null
    );
    formVars.istakipkart_679771_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.eserAd : null
    );
    formVars.istakipkart_679331_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.eserAd : null
    );
    formVars.istakipkart_872419_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.eserSahibi : null
    );
    formVars.istakipkart_909777_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.eserSahibi : null
    );
    formVars.istakipkart_880403_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruTanim : null
    );
    formVars.istakipkart_765584_value =
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruID : null;
    formVars.istakipkart_334144_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.projeTuru : null
    );
    formVars.istakipkart_183673_value =
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.projeTuruID : null;
    formVars.istakipkart_707596_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.adSoyad : null
    );
    formVars.istakipkart_969221_value =
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.olusturanKisi : null;
    formVars.istakipkart_96402_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.eMail : null
    );
    formVars.istakipkart_284293_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.eMail : null
    );
    formVars.istakipkart_610845_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.gsm : null
    );
    formVars.istakipkart_528021_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.gsm : null
    );
    formVars.istakipkart_473202_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.sirketAd : null
    );
    formVars.istakipkart_865525_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.aciklama : null
    );
    formVars.istakipkart_310363_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.aciklama : null
    );
    formVars.istakipkart_367546_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.acil : null
    );
    formVars.istakipkart_490476_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.terminTarihi : null
    );
    formVars.istakipkart_956828_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.terminTarihi : null
    );
    formVars.istakipkart_743413_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.servisUcretiVar : null
    );
    formVars.istakipkart_253070_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.projeAd : null
    );
    formVars.istakipkart_204534_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.projeAd : null
    );
    formVars.istakipkart_449363_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.yayinKanali : null
    );
    formVars.istakipkart_583165_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.tvKanal : null
    );
    formVars.istakipkart_741340_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.sezon : null
    );
    formVars.istakipkart_397201_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.bolum : null
    );
    formVars.istakipkart_28971_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.diziBolumNo : null
    );
    formVars.istakipkart_218936_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.konserYerTarih : null
    );
    formVars.istakipkart_309538_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.urun : null
    );
    formVars.istakipkart_401049_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.tvKanal : null
    );
    formVars.istakipkart_771200_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.yayinKanali : null
    );
    formVars.istakipkart_915575_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.sezon : null
    );
    formVars.istakipkart_864345_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.tvBolumNo : null
    );
    formVars.istakipkart_260313_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.bolum : null
    );
    formVars.istakipkart_831469_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.diziBolumNo : null
    );
    formVars.istakipkart_883142_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.sanatcilar : null
    );
    formVars.istakipkart_427688_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.kullanimSekliCDTanim : null
    );
    formVars.istakipkart_681754_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.briefAciklama : null
    );
    formVars.istakipkart_449550_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.sozlesmeTarihi : null
    );
    formVars.istakipkart_218026_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.lisansBasTarihi : null
    );
    formVars.istakipkart_218026_disablebeforedate = ReactSystemFunctions.value(
      this,
      ReactSystemFunctions.value(this, "istakipkart_449550_value", "value", "", "", "")
    );
    formVars.istakipkart_345176_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.lisansBitTarihi : null
    );
    formVars.istakipkart_345176_disablebeforedate = ReactSystemFunctions.value(
      this,
      ReactSystemFunctions.value(this, "istakipkart_218026_value", "value", "", "", "")
    );
    formVars.istakipkart_787576_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.lisansSuresiz : null
    );
    formVars.istakipkart_168257_value =
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.cariID : null;
    formVars.istakipkart_494755_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.isIndirimliFiyat : null
    );
    formVars.istakipkart_771873_value =
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.lisansTipiID : null;
    formVars.istakipkart_780759_value =
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.telifTurID : null;
    formVars.istakipkart_953041_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.faturaTarihi : null
    );
    formVars.istakipkart_552514_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.faturaTutar : null
    );
    formVars.istakipkart_73134_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.simge : null
    );
    formVars.istakipkart_649647_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.dipNetTutar : null
    );
    formVars.istakipkart_952267_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.dipServisTutar : null
    );
    formVars.istakipkart_161516_value =
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.stopajOran : null;
    formVars.istakipkart_830227_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.dipStopajTutar : null
    );
    formVars.istakipkart_20311_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.dipBrutTutar : null
    );
    formVars.istakipkart_368022_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.dipIndirim : null
    );
    formVars.istakipkart_241361_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.dipToplam : null
    );
    formVars.istakipkart_286867_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.dipKdv : null
    );
    formVars.istakipkart_228291_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.dipGenelToplam : null
    );
    formVars.istakipkart_353223_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.satirAciklama : null
    );
    formVars.istakipkart_116506_value =
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.vadeGunID : null;
    formVars.istakipkart_624781_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.notSatir1 : null
    );
    formVars.istakipkart_608425_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.notSatir2 : null
    );
    formVars.istakipkart_93784_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.notSatir3 : null
    );
    stateVars.isComp659451Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formReadOnly : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp382094Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0
            ? stateVars.getBasvuruFormuById[0]?.isTakipDurumChangeButtonVisibily
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp57039Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formReadOnly : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp927155Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formReadOnly : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp363488Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formReadOnly : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp432446Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formReadOnly : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp934923Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formReadOnly : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp375212Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp449363Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.yayinKanali : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp583165Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.tvKanal : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp397201Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.bolum : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp28971Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.diziBolumNo : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp401049Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.tvKanal : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp771200Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.yayinKanali : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp864345Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.tvBolumNo : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp260313Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.bolum : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp831469Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.diziBolumNo : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp681754Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.briefAciklama : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp176618Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formReadOnly : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp739682Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp681149Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formReadOnly : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp202682Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formReadOnly : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp330703Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp218524Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp991225Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp476748Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp478750Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp336407Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp852077Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp13593Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp695930Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp846928Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formReadOnly : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp781928Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formReadOnly : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp122685Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp104543Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp734427Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp586759Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.sozlesmeID : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp326018Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp19843Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formReadOnly : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp436915Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formReadOnly : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp972677Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formReadOnly : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.IsTakipKartComponent_494755_onChange3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  IsTakipKartComponent_494755_onChange3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.istakipkart_785916_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.ticketNo : null
    );

    formVars.istakipkart_308766_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.olusturmaTarihi : null
    );

    formVars.istakipkart_354958_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.isTakipBaslangicTarihi : null
    );

    formVars.istakipkart_72949_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.isTakipGuncellemeZaman : null
    );

    formVars.istakipkart_174064_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.terminTarihi : null
    );

    formVars.istakipkart_609729_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.formStatuID : null
    );

    formVars.istakipkart_352216_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.aksiyonIlgiliKisi : null
    );

    formVars.istakipkart_846368_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.onemliNot : null
    );

    formVars.istakipkart_679771_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.eserAd : null
    );

    formVars.istakipkart_679331_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.eserAd : null
    );

    formVars.istakipkart_872419_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.eserSahibi : null
    );

    formVars.istakipkart_909777_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.eserSahibi : null
    );

    formVars.istakipkart_880403_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.basvuruTuruTanim : null
    );

    formVars.istakipkart_765584_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.basvuruTuruID : null
    );

    formVars.istakipkart_334144_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.projeTuru : null
    );

    formVars.istakipkart_183673_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.projeTuruID : null
    );

    formVars.istakipkart_707596_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.adSoyad : null
    );

    formVars.istakipkart_969221_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.olusturanKisi : null
    );

    formVars.istakipkart_96402_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.eMail : null
    );

    formVars.istakipkart_284293_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.eMail : null
    );

    formVars.istakipkart_610845_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.gsm : null
    );

    formVars.istakipkart_528021_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.gsm : null
    );

    formVars.istakipkart_473202_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.sirketAd : null
    );

    formVars.istakipkart_865525_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.aciklama : null
    );

    formVars.istakipkart_310363_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.aciklama : null
    );

    formVars.istakipkart_367546_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.acil : null
    );

    formVars.istakipkart_490476_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.terminTarihi : null
    );

    formVars.istakipkart_956828_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.terminTarihi : null
    );

    formVars.istakipkart_743413_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.servisUcretiVar : null
    );

    formVars.istakipkart_253070_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.projeAd : null
    );

    formVars.istakipkart_204534_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.projeAd : null
    );

    formVars.istakipkart_449363_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.yayinKanali : null
    );

    formVars.istakipkart_583165_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.tvKanal : null
    );

    formVars.istakipkart_741340_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.sezon : null
    );

    formVars.istakipkart_397201_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.bolum : null
    );

    formVars.istakipkart_28971_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.diziBolumNo : null
    );

    formVars.istakipkart_218936_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.konserYerTarih : null
    );

    formVars.istakipkart_309538_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.urun : null
    );

    formVars.istakipkart_401049_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.tvKanal : null
    );

    formVars.istakipkart_771200_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.yayinKanali : null
    );

    formVars.istakipkart_915575_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.sezon : null
    );

    formVars.istakipkart_864345_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.tvBolumNo : null
    );

    formVars.istakipkart_260313_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.bolum : null
    );

    formVars.istakipkart_831469_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.diziBolumNo : null
    );

    formVars.istakipkart_883142_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.sanatcilar : null
    );

    formVars.istakipkart_427688_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.kullanimSekliCDTanim : null
    );

    formVars.istakipkart_681754_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.briefAciklama : null
    );

    formVars.istakipkart_449550_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.sozlesmeTarihi : null
    );

    formVars.istakipkart_218026_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.lisansBasTarihi : null
    );

    formVars.istakipkart_345176_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.lisansBitTarihi : null
    );

    formVars.istakipkart_787576_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.lisansSuresiz : null
    );

    formVars.istakipkart_168257_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.cariID : null
    );

    formVars.istakipkart_494755_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.isIndirimliFiyat : null
    );

    formVars.istakipkart_771873_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.lisansTipiID : null
    );

    formVars.istakipkart_780759_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.telifTurID : null
    );

    formVars.istakipkart_953041_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.faturaTarihi : null
    );

    formVars.istakipkart_552514_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.faturaTutar : null
    );

    formVars.istakipkart_73134_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.simge : null
    );

    stateVars.dataSource_215911 = this.state.getListKullanimSureleriByBFId;
    formVars.istakipkart_256375_value = ReactSystemFunctions.toString(
      this,
      this.state.getListKullanimSureleriByBFId?.length > 0 ? this.state.getListKullanimSureleriByBFId[0]?.simge : null
    );

    formVars.istakipkart_649647_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.dipNetTutar : null
    );

    formVars.istakipkart_952267_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.dipServisTutar : null
    );

    formVars.istakipkart_161516_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.stopajOran : null
    );

    formVars.istakipkart_830227_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.dipStopajTutar : null
    );

    formVars.istakipkart_20311_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.dipBrutTutar : null
    );

    formVars.istakipkart_368022_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.dipIndirim : null
    );

    formVars.istakipkart_241361_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.dipToplam : null
    );

    formVars.istakipkart_286867_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.dipKdv : null
    );

    formVars.istakipkart_228291_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.dipGenelToplam : null
    );

    formVars.istakipkart_353223_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.satirAciklama : null
    );

    formVars.istakipkart_116506_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.vadeGunID : null
    );

    formVars.istakipkart_624781_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.notSatir1 : null
    );

    formVars.istakipkart_608425_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.notSatir2 : null
    );

    formVars.istakipkart_93784_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.notSatir3 : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  IsTakipKartComponent_771873_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      LisansTipiID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "istakipkart_771873_value", "value", "sbLisansTipiList", "id", "id")
        ),
        "Guid"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "IsTakipKart/IsTakipKartComponent_771873_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.setUpdateLisansTipiID = result?.data.setUpdateLisansTipiID;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.IsTakipKartComponent_771873_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  IsTakipKartComponent_771873_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, "isComp464557Visible", "hidden");

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.IsTakipKartComponent_771873_onChange2_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  IsTakipKartComponent_771873_onChange2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "istakipkart_771873_value", "value", "sbLisansTipiList", "id", "kod")
        ),
        "P"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp464557Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  IsTakipKartComponent_780759_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "istakipkart_780759_value", "value", "sbTelifTurList", "id", "")
        ),
        null
      ),
      message: "*",
      formName: "istakipkart_780759_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      TelifTurID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "istakipkart_780759_value", "value", "sbTelifTurList", "id", "id")
        ),
        "Guid"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "IsTakipKart/IsTakipKartComponent_780759_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.setUpdateTelifTurId = result?.data.setUpdateTelifTurId;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  IsTakipKartComponent_13037_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "istakipkart_13037_value", "value", "", "", "")
        ),
        false
      )
    ) {
      KuikaAppManager.showSpinner(this);

      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp999090Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.IsTakipKartComponent_13037_onChange1_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.IsTakipKartComponent_13037_onChange1_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  IsTakipKartComponent_13037_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "istakipkart_13037_value", "value", "", "", "")
        ),
        true
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp459375Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.IsTakipKartComponent_13037_onChange2_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.IsTakipKartComponent_13037_onChange2_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  IsTakipKartComponent_13037_onChange2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "istakipkart_13037_value", "value", "", "", "")
        ),
        true
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp999090Visible",
        "hidden"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.IsTakipKartComponent_13037_onChange3_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.IsTakipKartComponent_13037_onChange3_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  IsTakipKartComponent_13037_onChange3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "istakipkart_13037_value", "value", "", "", "")
        ),
        false
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp459375Visible",
        "hidden"
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  IsTakipKartComponent_786097_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "istakipkart_684788_value",
      Guid.create().toString(),
      null
    );

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.IsTakipKartComponent_786097_onClick1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  IsTakipKartComponent_786097_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "istakipkart_748063_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "istakipkart_748063_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "istakipkart_515308_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "istakipkart_515308_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "istakipkart_462579_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "istakipkart_462579_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Bilgi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "istakipkart_748063_value", "value", "", "", "")
        ),
        "string"
      ),
      MuzikKod_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "istakipkart_515308_value", "value", "", "", "")
        ),
        "string"
      ),
      SureSaat_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "istakipkart_77490_value", "value", "", "", "")
        ),
        "number"
      ),
      SureDakika_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "istakipkart_471403_value", "value", "", "", "")
        ),
        "number"
      ),
      SureSaniye_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "istakipkart_462579_value", "value", "", "", "")
        ),
        "number"
      ),
      Aciklama_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      BolgeID_0: ReactSystemFunctions.convertToTypeByName("", "Guid"),
      KullanimTuruID_0: ReactSystemFunctions.convertToTypeByName("", "Guid"),
      MecraID_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      BasvuruFormuID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      ),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "istakipkart_684788_value", "value", "", "", "")
        ),
        "Guid"
      ),
      BasvuruFormuID_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      ),
      BasvuruTuru_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "istakipkart_765584_value", "value", "sbBasvuruTuruList", "id", "kod")
        ),
        "number"
      ),
      BasvuruFormuID_3: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      ),
      ServisUcretiVar_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "istakipkart_743413_value", "value", "", "", "")
        ),
        "boolean"
      ),
      Stopaj_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "istakipkart_168257_value", "value", "getListCari", "id", "satisStopaj")
        ),
        "number"
      ),
      Id_4: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      ),
      Rol_4: ReactSystemFunctions.convertToTypeByName("", "string")
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "IsTakipKart/IsTakipKartComponent_786097_onClick1_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.setKullanimSureleriInsert = result?.data.setKullanimSureleriInsert;
    stateVars.spUpdateFiyatlandirmaByInsertId = result?.data.spUpdateFiyatlandirmaByInsertId;
    stateVars.getListKullanimSureleriByBFId = result?.data.getListKullanimSureleriByBFId;

    formVars.istakipkart_256375_value = ReactSystemFunctions.toString(
      this,
      stateVars.getListKullanimSureleriByBFId?.length > 0 ? stateVars.getListKullanimSureleriByBFId[0]?.simge : null
    );
    stateVars.spDipToplamByBFId = result?.data.spDipToplamByBFId;
    stateVars.getBasvuruFormuById = result?.data.getBasvuruFormuById;
    formVars.istakipkart_785916_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.ticketNo : null
    );
    formVars.istakipkart_308766_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.olusturmaTarihi : null
    );
    formVars.istakipkart_354958_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.isTakipBaslangicTarihi : null
    );
    formVars.istakipkart_72949_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.isTakipGuncellemeZaman : null
    );
    formVars.istakipkart_174064_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.terminTarihi : null
    );
    formVars.istakipkart_609729_value =
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formStatuID : null;
    formVars.istakipkart_352216_value =
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.aksiyonIlgiliKisi : null;
    formVars.istakipkart_846368_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.onemliNot : null
    );
    formVars.istakipkart_679771_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.eserAd : null
    );
    formVars.istakipkart_679331_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.eserAd : null
    );
    formVars.istakipkart_872419_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.eserSahibi : null
    );
    formVars.istakipkart_909777_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.eserSahibi : null
    );
    formVars.istakipkart_880403_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruTanim : null
    );
    formVars.istakipkart_765584_value =
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruID : null;
    formVars.istakipkart_334144_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.projeTuru : null
    );
    formVars.istakipkart_183673_value =
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.projeTuruID : null;
    formVars.istakipkart_707596_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.adSoyad : null
    );
    formVars.istakipkart_969221_value =
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.olusturanKisi : null;
    formVars.istakipkart_96402_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.eMail : null
    );
    formVars.istakipkart_284293_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.eMail : null
    );
    formVars.istakipkart_610845_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.gsm : null
    );
    formVars.istakipkart_528021_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.gsm : null
    );
    formVars.istakipkart_473202_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.sirketAd : null
    );
    formVars.istakipkart_865525_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.aciklama : null
    );
    formVars.istakipkart_310363_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.aciklama : null
    );
    formVars.istakipkart_367546_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.acil : null
    );
    formVars.istakipkart_490476_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.terminTarihi : null
    );
    formVars.istakipkart_956828_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.terminTarihi : null
    );
    formVars.istakipkart_743413_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.servisUcretiVar : null
    );
    formVars.istakipkart_253070_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.projeAd : null
    );
    formVars.istakipkart_204534_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.projeAd : null
    );
    formVars.istakipkart_449363_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.yayinKanali : null
    );
    formVars.istakipkart_583165_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.tvKanal : null
    );
    formVars.istakipkart_741340_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.sezon : null
    );
    formVars.istakipkart_397201_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.bolum : null
    );
    formVars.istakipkart_28971_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.diziBolumNo : null
    );
    formVars.istakipkart_218936_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.konserYerTarih : null
    );
    formVars.istakipkart_309538_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.urun : null
    );
    formVars.istakipkart_401049_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.tvKanal : null
    );
    formVars.istakipkart_771200_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.yayinKanali : null
    );
    formVars.istakipkart_915575_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.sezon : null
    );
    formVars.istakipkart_864345_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.tvBolumNo : null
    );
    formVars.istakipkart_260313_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.bolum : null
    );
    formVars.istakipkart_831469_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.diziBolumNo : null
    );
    formVars.istakipkart_883142_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.sanatcilar : null
    );
    formVars.istakipkart_427688_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.kullanimSekliCDTanim : null
    );
    formVars.istakipkart_681754_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.briefAciklama : null
    );
    formVars.istakipkart_449550_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.sozlesmeTarihi : null
    );
    formVars.istakipkart_218026_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.lisansBasTarihi : null
    );
    formVars.istakipkart_218026_disablebeforedate = ReactSystemFunctions.value(
      this,
      ReactSystemFunctions.value(this, "istakipkart_449550_value", "value", "", "", "")
    );
    formVars.istakipkart_345176_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.lisansBitTarihi : null
    );
    formVars.istakipkart_345176_disablebeforedate = ReactSystemFunctions.value(
      this,
      ReactSystemFunctions.value(this, "istakipkart_218026_value", "value", "", "", "")
    );
    formVars.istakipkart_787576_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.lisansSuresiz : null
    );
    formVars.istakipkart_168257_value =
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.cariID : null;
    formVars.istakipkart_494755_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.isIndirimliFiyat : null
    );
    formVars.istakipkart_771873_value =
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.lisansTipiID : null;
    formVars.istakipkart_780759_value =
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.telifTurID : null;
    formVars.istakipkart_953041_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.faturaTarihi : null
    );
    formVars.istakipkart_552514_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.faturaTutar : null
    );
    formVars.istakipkart_73134_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.simge : null
    );
    formVars.istakipkart_649647_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.dipNetTutar : null
    );
    formVars.istakipkart_952267_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.dipServisTutar : null
    );
    formVars.istakipkart_161516_value =
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.stopajOran : null;
    formVars.istakipkart_830227_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.dipStopajTutar : null
    );
    formVars.istakipkart_20311_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.dipBrutTutar : null
    );
    formVars.istakipkart_368022_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.dipIndirim : null
    );
    formVars.istakipkart_241361_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.dipToplam : null
    );
    formVars.istakipkart_286867_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.dipKdv : null
    );
    formVars.istakipkart_228291_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.dipGenelToplam : null
    );
    formVars.istakipkart_353223_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.satirAciklama : null
    );
    formVars.istakipkart_116506_value =
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.vadeGunID : null;
    formVars.istakipkart_624781_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.notSatir1 : null
    );
    formVars.istakipkart_608425_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.notSatir2 : null
    );
    formVars.istakipkart_93784_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.notSatir3 : null
    );
    stateVars.isComp659451Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formReadOnly : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp382094Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0
            ? stateVars.getBasvuruFormuById[0]?.isTakipDurumChangeButtonVisibily
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp57039Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formReadOnly : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp927155Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formReadOnly : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp363488Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formReadOnly : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp432446Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formReadOnly : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp934923Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formReadOnly : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp375212Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp449363Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.yayinKanali : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp583165Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.tvKanal : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp397201Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.bolum : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp28971Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.diziBolumNo : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp401049Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.tvKanal : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp771200Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.yayinKanali : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp864345Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.tvBolumNo : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp260313Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.bolum : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp831469Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.diziBolumNo : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp681754Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.briefAciklama : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp176618Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formReadOnly : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp739682Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp681149Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formReadOnly : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp202682Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formReadOnly : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp330703Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp218524Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp991225Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp476748Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp478750Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp336407Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp852077Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp13593Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp695930Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp846928Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formReadOnly : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp781928Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formReadOnly : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp122685Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp104543Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp734427Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp586759Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.sozlesmeID : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp326018Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp19843Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formReadOnly : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp436915Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formReadOnly : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp972677Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formReadOnly : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.IsTakipKartComponent_786097_onClick2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  IsTakipKartComponent_786097_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "istakipkart_748063_value", "", null);
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "istakipkart_515308_value", "", null);
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "istakipkart_77490_value", null, null);
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "istakipkart_471403_value", null, null);
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "istakipkart_462579_value", null, null);
    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "7918f736_d060_e6f4_24a9_d2225ba02557_notify",
        this.defaultML,
        "Kayıt Eklendi..!"
      ),
      "success",
      "bottom-right",
      5,
      "",
      "",
      0
    );
    formVars.istakipkart_785916_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.ticketNo : null
    );

    formVars.istakipkart_308766_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.olusturmaTarihi : null
    );

    formVars.istakipkart_354958_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.isTakipBaslangicTarihi : null
    );

    formVars.istakipkart_72949_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.isTakipGuncellemeZaman : null
    );

    formVars.istakipkart_174064_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.terminTarihi : null
    );

    formVars.istakipkart_609729_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.formStatuID : null
    );

    formVars.istakipkart_352216_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.aksiyonIlgiliKisi : null
    );

    formVars.istakipkart_846368_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.onemliNot : null
    );

    formVars.istakipkart_679771_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.eserAd : null
    );

    formVars.istakipkart_679331_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.eserAd : null
    );

    formVars.istakipkart_872419_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.eserSahibi : null
    );

    formVars.istakipkart_909777_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.eserSahibi : null
    );

    formVars.istakipkart_880403_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.basvuruTuruTanim : null
    );

    formVars.istakipkart_765584_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.basvuruTuruID : null
    );

    formVars.istakipkart_334144_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.projeTuru : null
    );

    formVars.istakipkart_183673_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.projeTuruID : null
    );

    formVars.istakipkart_707596_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.adSoyad : null
    );

    formVars.istakipkart_969221_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.olusturanKisi : null
    );

    formVars.istakipkart_96402_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.eMail : null
    );

    formVars.istakipkart_284293_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.eMail : null
    );

    formVars.istakipkart_610845_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.gsm : null
    );

    formVars.istakipkart_528021_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.gsm : null
    );

    formVars.istakipkart_473202_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.sirketAd : null
    );

    formVars.istakipkart_865525_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.aciklama : null
    );

    formVars.istakipkart_310363_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.aciklama : null
    );

    formVars.istakipkart_367546_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.acil : null
    );

    formVars.istakipkart_490476_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.terminTarihi : null
    );

    formVars.istakipkart_956828_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.terminTarihi : null
    );

    formVars.istakipkart_743413_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.servisUcretiVar : null
    );

    formVars.istakipkart_253070_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.projeAd : null
    );

    formVars.istakipkart_204534_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.projeAd : null
    );

    formVars.istakipkart_449363_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.yayinKanali : null
    );

    formVars.istakipkart_583165_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.tvKanal : null
    );

    formVars.istakipkart_741340_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.sezon : null
    );

    formVars.istakipkart_397201_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.bolum : null
    );

    formVars.istakipkart_28971_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.diziBolumNo : null
    );

    formVars.istakipkart_218936_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.konserYerTarih : null
    );

    formVars.istakipkart_309538_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.urun : null
    );

    formVars.istakipkart_401049_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.tvKanal : null
    );

    formVars.istakipkart_771200_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.yayinKanali : null
    );

    formVars.istakipkart_915575_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.sezon : null
    );

    formVars.istakipkart_864345_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.tvBolumNo : null
    );

    formVars.istakipkart_260313_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.bolum : null
    );

    formVars.istakipkart_831469_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.diziBolumNo : null
    );

    formVars.istakipkart_883142_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.sanatcilar : null
    );

    formVars.istakipkart_427688_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.kullanimSekliCDTanim : null
    );

    formVars.istakipkart_681754_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.briefAciklama : null
    );

    formVars.istakipkart_449550_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.sozlesmeTarihi : null
    );

    formVars.istakipkart_218026_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.lisansBasTarihi : null
    );

    formVars.istakipkart_345176_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.lisansBitTarihi : null
    );

    formVars.istakipkart_787576_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.lisansSuresiz : null
    );

    formVars.istakipkart_168257_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.cariID : null
    );

    formVars.istakipkart_494755_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.isIndirimliFiyat : null
    );

    formVars.istakipkart_771873_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.lisansTipiID : null
    );

    formVars.istakipkart_780759_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.telifTurID : null
    );

    formVars.istakipkart_953041_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.faturaTarihi : null
    );

    formVars.istakipkart_552514_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.faturaTutar : null
    );

    formVars.istakipkart_73134_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.simge : null
    );

    stateVars.dataSource_215911 = this.state.getListKullanimSureleriByBFId;
    formVars.istakipkart_256375_value = ReactSystemFunctions.toString(
      this,
      this.state.getListKullanimSureleriByBFId?.length > 0 ? this.state.getListKullanimSureleriByBFId[0]?.simge : null
    );

    formVars.istakipkart_649647_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.dipNetTutar : null
    );

    formVars.istakipkart_952267_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.dipServisTutar : null
    );

    formVars.istakipkart_161516_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.stopajOran : null
    );

    formVars.istakipkart_830227_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.dipStopajTutar : null
    );

    formVars.istakipkart_20311_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.dipBrutTutar : null
    );

    formVars.istakipkart_368022_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.dipIndirim : null
    );

    formVars.istakipkart_241361_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.dipToplam : null
    );

    formVars.istakipkart_286867_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.dipKdv : null
    );

    formVars.istakipkart_228291_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.dipGenelToplam : null
    );

    formVars.istakipkart_353223_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.satirAciklama : null
    );

    formVars.istakipkart_116506_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.vadeGunID : null
    );

    formVars.istakipkart_624781_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.notSatir1 : null
    );

    formVars.istakipkart_608425_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.notSatir2 : null
    );

    formVars.istakipkart_93784_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.notSatir3 : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  IsTakipKartComponent_445836_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "71f2cd0d_bacf_a9b4_90bd_3336e4f1d2cd_notify",
        this.defaultML,
        "Aktarılacak Excel Türü Seçilir"
      ),
      "default",
      "bottom-right",
      5,
      null,
      null,
      null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  IsTakipKartComponent_202682_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "istakipkart_684788_value",
      Guid.create().toString(),
      null
    );
    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "FiyatlandirmaOzelKart",
      "prmGuid",
      ReactSystemFunctions.value(
        this,
        ReactSystemFunctions.value(this, "istakipkart_684788_value", "value", "", "", "")
      )
    );
    KuikaAppManager.addToPageInputVariables("FiyatlandirmaOzelKart", "NewEdit", "N");
    KuikaAppManager.addToPageInputVariables(
      "FiyatlandirmaOzelKart",
      "BTuru",
      ReactSystemFunctions.toString(
        this,
        ReactSystemFunctions.value(this, "istakipkart_765584_value", "value", "sbBasvuruTuruList", "id", "kod")
      )
    );
    KuikaAppManager.addToPageInputVariables(
      "FiyatlandirmaOzelKart",
      "PTuru",
      ReactSystemFunctions.toString(
        this,
        ReactSystemFunctions.value(
          this,
          "istakipkart_183673_value",
          "value",
          "spGetProjeTuruByBTuruKod",
          "id",
          "projeTuruRefKod"
        )
      )
    );
    KuikaAppManager.addToPageInputVariables(
      "FiyatlandirmaOzelKart",
      "BFId",
      this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid
    );
    KuikaAppManager.addToPageInputVariables(
      "FiyatlandirmaOzelKart",
      "FiyatListeAdID",
      ReactSystemFunctions.value(
        this,
        ReactSystemFunctions.value(this, "istakipkart_168257_value", "value", "getListCari", "id", "fiyatListeAdID")
      )
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      this.IsTakipKartComponent_202682_onClick1_,
      "IsTakipKart",
      "FiyatlandirmaOzelKart",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "165788",
      null,
      "right",
      null,
      "380px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };
  IsTakipKartComponent_202682_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "istakipkart_684788_value", "value", "", "", "")
        ),
        "Guid"
      ),
      BasvuruFormuID_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      ),
      BasvuruTuru_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "istakipkart_765584_value", "value", "sbBasvuruTuruList", "id", "kod")
        ),
        "number"
      ),
      BasvuruFormuID_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      ),
      ServisUcretiVar_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "istakipkart_743413_value", "value", "", "", "")
        ),
        "boolean"
      ),
      Stopaj_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "istakipkart_161516_value",
            "value",
            "sbStopajList",
            "stopajOran",
            "stopajOran"
          )
        ),
        "number"
      ),
      Id_3: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      ),
      Rol_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.getUserInfo?.length > 0 ? this.state.getUserInfo[0]?.rolName : null
        ),
        "string"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "IsTakipKart/IsTakipKartComponent_202682_onClick1_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.spUpdateFiyatlandirmaByCurrentId = result?.data.spUpdateFiyatlandirmaByCurrentId;
    stateVars.getListKullanimSureleriByBFId = result?.data.getListKullanimSureleriByBFId;

    formVars.istakipkart_256375_value = ReactSystemFunctions.toString(
      this,
      stateVars.getListKullanimSureleriByBFId?.length > 0 ? stateVars.getListKullanimSureleriByBFId[0]?.simge : null
    );
    stateVars.spDipToplamByBFId = result?.data.spDipToplamByBFId;
    stateVars.getBasvuruFormuById = result?.data.getBasvuruFormuById;
    formVars.istakipkart_785916_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.ticketNo : null
    );
    formVars.istakipkart_308766_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.olusturmaTarihi : null
    );
    formVars.istakipkart_354958_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.isTakipBaslangicTarihi : null
    );
    formVars.istakipkart_72949_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.isTakipGuncellemeZaman : null
    );
    formVars.istakipkart_174064_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.terminTarihi : null
    );
    formVars.istakipkart_609729_value =
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formStatuID : null;
    formVars.istakipkart_352216_value =
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.aksiyonIlgiliKisi : null;
    formVars.istakipkart_846368_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.onemliNot : null
    );
    formVars.istakipkart_679771_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.eserAd : null
    );
    formVars.istakipkart_679331_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.eserAd : null
    );
    formVars.istakipkart_872419_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.eserSahibi : null
    );
    formVars.istakipkart_909777_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.eserSahibi : null
    );
    formVars.istakipkart_880403_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruTanim : null
    );
    formVars.istakipkart_765584_value =
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruID : null;
    formVars.istakipkart_334144_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.projeTuru : null
    );
    formVars.istakipkart_183673_value =
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.projeTuruID : null;
    formVars.istakipkart_707596_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.adSoyad : null
    );
    formVars.istakipkart_969221_value =
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.olusturanKisi : null;
    formVars.istakipkart_96402_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.eMail : null
    );
    formVars.istakipkart_284293_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.eMail : null
    );
    formVars.istakipkart_610845_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.gsm : null
    );
    formVars.istakipkart_528021_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.gsm : null
    );
    formVars.istakipkart_473202_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.sirketAd : null
    );
    formVars.istakipkart_865525_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.aciklama : null
    );
    formVars.istakipkart_310363_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.aciklama : null
    );
    formVars.istakipkart_367546_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.acil : null
    );
    formVars.istakipkart_490476_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.terminTarihi : null
    );
    formVars.istakipkart_956828_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.terminTarihi : null
    );
    formVars.istakipkart_743413_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.servisUcretiVar : null
    );
    formVars.istakipkart_253070_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.projeAd : null
    );
    formVars.istakipkart_204534_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.projeAd : null
    );
    formVars.istakipkart_449363_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.yayinKanali : null
    );
    formVars.istakipkart_583165_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.tvKanal : null
    );
    formVars.istakipkart_741340_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.sezon : null
    );
    formVars.istakipkart_397201_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.bolum : null
    );
    formVars.istakipkart_28971_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.diziBolumNo : null
    );
    formVars.istakipkart_218936_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.konserYerTarih : null
    );
    formVars.istakipkart_309538_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.urun : null
    );
    formVars.istakipkart_401049_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.tvKanal : null
    );
    formVars.istakipkart_771200_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.yayinKanali : null
    );
    formVars.istakipkart_915575_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.sezon : null
    );
    formVars.istakipkart_864345_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.tvBolumNo : null
    );
    formVars.istakipkart_260313_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.bolum : null
    );
    formVars.istakipkart_831469_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.diziBolumNo : null
    );
    formVars.istakipkart_883142_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.sanatcilar : null
    );
    formVars.istakipkart_427688_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.kullanimSekliCDTanim : null
    );
    formVars.istakipkart_681754_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.briefAciklama : null
    );
    formVars.istakipkart_449550_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.sozlesmeTarihi : null
    );
    formVars.istakipkart_218026_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.lisansBasTarihi : null
    );
    formVars.istakipkart_218026_disablebeforedate = ReactSystemFunctions.value(
      this,
      ReactSystemFunctions.value(this, "istakipkart_449550_value", "value", "", "", "")
    );
    formVars.istakipkart_345176_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.lisansBitTarihi : null
    );
    formVars.istakipkart_345176_disablebeforedate = ReactSystemFunctions.value(
      this,
      ReactSystemFunctions.value(this, "istakipkart_218026_value", "value", "", "", "")
    );
    formVars.istakipkart_787576_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.lisansSuresiz : null
    );
    formVars.istakipkart_168257_value =
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.cariID : null;
    formVars.istakipkart_494755_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.isIndirimliFiyat : null
    );
    formVars.istakipkart_771873_value =
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.lisansTipiID : null;
    formVars.istakipkart_780759_value =
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.telifTurID : null;
    formVars.istakipkart_953041_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.faturaTarihi : null
    );
    formVars.istakipkart_552514_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.faturaTutar : null
    );
    formVars.istakipkart_73134_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.simge : null
    );
    formVars.istakipkart_649647_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.dipNetTutar : null
    );
    formVars.istakipkart_952267_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.dipServisTutar : null
    );
    formVars.istakipkart_161516_value =
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.stopajOran : null;
    formVars.istakipkart_830227_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.dipStopajTutar : null
    );
    formVars.istakipkart_20311_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.dipBrutTutar : null
    );
    formVars.istakipkart_368022_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.dipIndirim : null
    );
    formVars.istakipkart_241361_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.dipToplam : null
    );
    formVars.istakipkart_286867_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.dipKdv : null
    );
    formVars.istakipkart_228291_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.dipGenelToplam : null
    );
    formVars.istakipkart_353223_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.satirAciklama : null
    );
    formVars.istakipkart_116506_value =
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.vadeGunID : null;
    formVars.istakipkart_624781_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.notSatir1 : null
    );
    formVars.istakipkart_608425_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.notSatir2 : null
    );
    formVars.istakipkart_93784_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.notSatir3 : null
    );
    stateVars.isComp659451Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formReadOnly : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp382094Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0
            ? stateVars.getBasvuruFormuById[0]?.isTakipDurumChangeButtonVisibily
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp57039Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formReadOnly : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp927155Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formReadOnly : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp363488Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formReadOnly : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp432446Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formReadOnly : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp934923Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formReadOnly : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp375212Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp449363Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.yayinKanali : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp583165Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.tvKanal : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp397201Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.bolum : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp28971Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.diziBolumNo : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp401049Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.tvKanal : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp771200Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.yayinKanali : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp864345Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.tvBolumNo : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp260313Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.bolum : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp831469Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.diziBolumNo : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp681754Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.briefAciklama : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp176618Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formReadOnly : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp739682Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp681149Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formReadOnly : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp202682Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formReadOnly : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp330703Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp218524Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp991225Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp476748Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp478750Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp336407Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp852077Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp13593Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp695930Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp846928Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formReadOnly : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp781928Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formReadOnly : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp122685Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp104543Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp734427Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp586759Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.sozlesmeID : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp326018Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp19843Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formReadOnly : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp436915Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formReadOnly : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp972677Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formReadOnly : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.IsTakipKartComponent_202682_onClick2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  IsTakipKartComponent_202682_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.istakipkart_785916_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.ticketNo : null
    );

    formVars.istakipkart_308766_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.olusturmaTarihi : null
    );

    formVars.istakipkart_354958_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.isTakipBaslangicTarihi : null
    );

    formVars.istakipkart_72949_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.isTakipGuncellemeZaman : null
    );

    formVars.istakipkart_174064_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.terminTarihi : null
    );

    formVars.istakipkart_609729_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.formStatuID : null
    );

    formVars.istakipkart_352216_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.aksiyonIlgiliKisi : null
    );

    formVars.istakipkart_846368_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.onemliNot : null
    );

    formVars.istakipkart_679771_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.eserAd : null
    );

    formVars.istakipkart_679331_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.eserAd : null
    );

    formVars.istakipkart_872419_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.eserSahibi : null
    );

    formVars.istakipkart_909777_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.eserSahibi : null
    );

    formVars.istakipkart_880403_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.basvuruTuruTanim : null
    );

    formVars.istakipkart_765584_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.basvuruTuruID : null
    );

    formVars.istakipkart_334144_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.projeTuru : null
    );

    formVars.istakipkart_183673_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.projeTuruID : null
    );

    formVars.istakipkart_707596_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.adSoyad : null
    );

    formVars.istakipkart_969221_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.olusturanKisi : null
    );

    formVars.istakipkart_96402_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.eMail : null
    );

    formVars.istakipkart_284293_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.eMail : null
    );

    formVars.istakipkart_610845_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.gsm : null
    );

    formVars.istakipkart_528021_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.gsm : null
    );

    formVars.istakipkart_473202_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.sirketAd : null
    );

    formVars.istakipkart_865525_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.aciklama : null
    );

    formVars.istakipkart_310363_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.aciklama : null
    );

    formVars.istakipkart_367546_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.acil : null
    );

    formVars.istakipkart_490476_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.terminTarihi : null
    );

    formVars.istakipkart_956828_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.terminTarihi : null
    );

    formVars.istakipkart_743413_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.servisUcretiVar : null
    );

    formVars.istakipkart_253070_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.projeAd : null
    );

    formVars.istakipkart_204534_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.projeAd : null
    );

    formVars.istakipkart_449363_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.yayinKanali : null
    );

    formVars.istakipkart_583165_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.tvKanal : null
    );

    formVars.istakipkart_741340_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.sezon : null
    );

    formVars.istakipkart_397201_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.bolum : null
    );

    formVars.istakipkart_28971_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.diziBolumNo : null
    );

    formVars.istakipkart_218936_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.konserYerTarih : null
    );

    formVars.istakipkart_309538_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.urun : null
    );

    formVars.istakipkart_401049_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.tvKanal : null
    );

    formVars.istakipkart_771200_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.yayinKanali : null
    );

    formVars.istakipkart_915575_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.sezon : null
    );

    formVars.istakipkart_864345_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.tvBolumNo : null
    );

    formVars.istakipkart_260313_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.bolum : null
    );

    formVars.istakipkart_831469_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.diziBolumNo : null
    );

    formVars.istakipkart_883142_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.sanatcilar : null
    );

    formVars.istakipkart_427688_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.kullanimSekliCDTanim : null
    );

    formVars.istakipkart_681754_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.briefAciklama : null
    );

    formVars.istakipkart_449550_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.sozlesmeTarihi : null
    );

    formVars.istakipkart_218026_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.lisansBasTarihi : null
    );

    formVars.istakipkart_345176_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.lisansBitTarihi : null
    );

    formVars.istakipkart_787576_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.lisansSuresiz : null
    );

    formVars.istakipkart_168257_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.cariID : null
    );

    formVars.istakipkart_494755_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.isIndirimliFiyat : null
    );

    formVars.istakipkart_771873_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.lisansTipiID : null
    );

    formVars.istakipkart_780759_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.telifTurID : null
    );

    formVars.istakipkart_953041_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.faturaTarihi : null
    );

    formVars.istakipkart_552514_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.faturaTutar : null
    );

    formVars.istakipkart_73134_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.simge : null
    );

    stateVars.dataSource_215911 = this.state.getListKullanimSureleriByBFId;
    formVars.istakipkart_256375_value = ReactSystemFunctions.toString(
      this,
      this.state.getListKullanimSureleriByBFId?.length > 0 ? this.state.getListKullanimSureleriByBFId[0]?.simge : null
    );

    formVars.istakipkart_649647_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.dipNetTutar : null
    );

    formVars.istakipkart_952267_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.dipServisTutar : null
    );

    formVars.istakipkart_161516_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.stopajOran : null
    );

    formVars.istakipkart_830227_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.dipStopajTutar : null
    );

    formVars.istakipkart_20311_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.dipBrutTutar : null
    );

    formVars.istakipkart_368022_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.dipIndirim : null
    );

    formVars.istakipkart_241361_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.dipToplam : null
    );

    formVars.istakipkart_286867_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.dipKdv : null
    );

    formVars.istakipkart_228291_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.dipGenelToplam : null
    );

    formVars.istakipkart_353223_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.satirAciklama : null
    );

    formVars.istakipkart_116506_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.vadeGunID : null
    );

    formVars.istakipkart_624781_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.notSatir1 : null
    );

    formVars.istakipkart_608425_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.notSatir2 : null
    );

    formVars.istakipkart_93784_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.notSatir3 : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  IsTakipKartComponent_691652_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      sbCariKod_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "istakipkart_168257_value", "value", "getListCari", "id", "cariAd")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "IsTakipKart/IsTakipKartComponent_691652_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.FaturaKesilecekFirmaKontrol = result?.data.faturaKesilecekFirmaKontrol;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.IsTakipKartComponent_691652_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  IsTakipKartComponent_691652_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "PopupMuzikBankasiSecimi",
      "prmGuid",
      ReactSystemFunctions.value(this, "istakipkart_215911_value", "id")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      this.IsTakipKartComponent_691652_onClick2_,
      "IsTakipKart",
      "PopupMuzikBankasiSecimi",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "398595",
      null,
      "left",
      null,
      "380px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };
  IsTakipKartComponent_691652_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      BasvuruFormuID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "IsTakipKart/IsTakipKartComponent_691652_onClick2_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.getListKullanimSureleriByBFId = result?.data.getListKullanimSureleriByBFId;

    formVars.istakipkart_256375_value = ReactSystemFunctions.toString(
      this,
      stateVars.getListKullanimSureleriByBFId?.length > 0 ? stateVars.getListKullanimSureleriByBFId[0]?.simge : null
    );
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.IsTakipKartComponent_691652_onClick3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  IsTakipKartComponent_691652_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_215911 = this.state.getListKullanimSureleriByBFId;
    formVars.istakipkart_256375_value = ReactSystemFunctions.toString(
      this,
      this.state.getListKullanimSureleriByBFId?.length > 0 ? this.state.getListKullanimSureleriByBFId[0]?.simge : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  IsTakipKartComponent_846928_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "istakipkart_215911_value", "id"),
        "Guid"
      ),
      BasvuruFormuID_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      ),
      BasvuruTuru_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "istakipkart_765584_value", "value", "sbBasvuruTuruList", "id", "kod")
        ),
        "number"
      ),
      BasvuruFormuID_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      ),
      ServisUcretiVar_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "istakipkart_743413_value", "value", "", "", "")
        ),
        "boolean"
      ),
      Stopaj_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "istakipkart_161516_value",
            "value",
            "sbStopajList",
            "stopajOran",
            "stopajOran"
          )
        ),
        "number"
      ),
      Id_3: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      ),
      Rol_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.getUserInfo?.length > 0 ? this.state.getUserInfo[0]?.rolName : null
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    stateVars.Confirm = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "3ab11201_963d_91f2_5ad4_80acc8f8ab92_confirmation",
        this.defaultML,
        "Seçilen kayıt silinecek; devam edilsin mi?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel",
      customClass: {
        title: "swal2-font-poppins"
      }
    });

    if (stateVars.Confirm.isConfirmed) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(this, "IsTakipKart/IsTakipKartComponent_846928_onClick", localVar);
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.delKullanimSureleriById = result?.data.delKullanimSureleriById;
      stateVars.getListKullanimSureleriByBFId = result?.data.getListKullanimSureleriByBFId;

      formVars.istakipkart_256375_value = ReactSystemFunctions.toString(
        this,
        stateVars.getListKullanimSureleriByBFId?.length > 0 ? stateVars.getListKullanimSureleriByBFId[0]?.simge : null
      );
      stateVars.spDipToplamByBFId = result?.data.spDipToplamByBFId;
      stateVars.getBasvuruFormuById = result?.data.getBasvuruFormuById;
      formVars.istakipkart_785916_value = ReactSystemFunctions.toString(
        this,
        stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.ticketNo : null
      );
      formVars.istakipkart_308766_value = ReactSystemFunctions.toString(
        this,
        stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.olusturmaTarihi : null
      );
      formVars.istakipkart_354958_value = ReactSystemFunctions.toString(
        this,
        stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.isTakipBaslangicTarihi : null
      );
      formVars.istakipkart_72949_value = ReactSystemFunctions.toString(
        this,
        stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.isTakipGuncellemeZaman : null
      );
      formVars.istakipkart_174064_value = ReactSystemFunctions.toString(
        this,
        stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.terminTarihi : null
      );
      formVars.istakipkart_609729_value =
        stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formStatuID : null;
      formVars.istakipkart_352216_value =
        stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.aksiyonIlgiliKisi : null;
      formVars.istakipkart_846368_value = ReactSystemFunctions.toString(
        this,
        stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.onemliNot : null
      );
      formVars.istakipkart_679771_value = ReactSystemFunctions.toString(
        this,
        stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.eserAd : null
      );
      formVars.istakipkart_679331_value = ReactSystemFunctions.toString(
        this,
        stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.eserAd : null
      );
      formVars.istakipkart_872419_value = ReactSystemFunctions.toString(
        this,
        stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.eserSahibi : null
      );
      formVars.istakipkart_909777_value = ReactSystemFunctions.toString(
        this,
        stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.eserSahibi : null
      );
      formVars.istakipkart_880403_value = ReactSystemFunctions.toString(
        this,
        stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruTanim : null
      );
      formVars.istakipkart_765584_value =
        stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruID : null;
      formVars.istakipkart_334144_value = ReactSystemFunctions.toString(
        this,
        stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.projeTuru : null
      );
      formVars.istakipkart_183673_value =
        stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.projeTuruID : null;
      formVars.istakipkart_707596_value = ReactSystemFunctions.toString(
        this,
        stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.adSoyad : null
      );
      formVars.istakipkart_969221_value =
        stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.olusturanKisi : null;
      formVars.istakipkart_96402_value = ReactSystemFunctions.toString(
        this,
        stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.eMail : null
      );
      formVars.istakipkart_284293_value = ReactSystemFunctions.toString(
        this,
        stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.eMail : null
      );
      formVars.istakipkart_610845_value = ReactSystemFunctions.toString(
        this,
        stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.gsm : null
      );
      formVars.istakipkart_528021_value = ReactSystemFunctions.toString(
        this,
        stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.gsm : null
      );
      formVars.istakipkart_473202_value = ReactSystemFunctions.toString(
        this,
        stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.sirketAd : null
      );
      formVars.istakipkart_865525_value = ReactSystemFunctions.toString(
        this,
        stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.aciklama : null
      );
      formVars.istakipkart_310363_value = ReactSystemFunctions.toString(
        this,
        stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.aciklama : null
      );
      formVars.istakipkart_367546_value = ReactSystemFunctions.value(
        this,
        stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.acil : null
      );
      formVars.istakipkart_490476_value = ReactSystemFunctions.toString(
        this,
        stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.terminTarihi : null
      );
      formVars.istakipkart_956828_value = ReactSystemFunctions.value(
        this,
        stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.terminTarihi : null
      );
      formVars.istakipkart_743413_value = ReactSystemFunctions.value(
        this,
        stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.servisUcretiVar : null
      );
      formVars.istakipkart_253070_value = ReactSystemFunctions.toString(
        this,
        stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.projeAd : null
      );
      formVars.istakipkart_204534_value = ReactSystemFunctions.toString(
        this,
        stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.projeAd : null
      );
      formVars.istakipkart_449363_value = ReactSystemFunctions.toString(
        this,
        stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.yayinKanali : null
      );
      formVars.istakipkart_583165_value = ReactSystemFunctions.toString(
        this,
        stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.tvKanal : null
      );
      formVars.istakipkart_741340_value = ReactSystemFunctions.toString(
        this,
        stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.sezon : null
      );
      formVars.istakipkart_397201_value = ReactSystemFunctions.toString(
        this,
        stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.bolum : null
      );
      formVars.istakipkart_28971_value = ReactSystemFunctions.toString(
        this,
        stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.diziBolumNo : null
      );
      formVars.istakipkart_218936_value = ReactSystemFunctions.toString(
        this,
        stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.konserYerTarih : null
      );
      formVars.istakipkart_309538_value = ReactSystemFunctions.toString(
        this,
        stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.urun : null
      );
      formVars.istakipkart_401049_value = ReactSystemFunctions.toString(
        this,
        stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.tvKanal : null
      );
      formVars.istakipkart_771200_value = ReactSystemFunctions.toString(
        this,
        stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.yayinKanali : null
      );
      formVars.istakipkart_915575_value = ReactSystemFunctions.toString(
        this,
        stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.sezon : null
      );
      formVars.istakipkart_864345_value = ReactSystemFunctions.toString(
        this,
        stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.tvBolumNo : null
      );
      formVars.istakipkart_260313_value = ReactSystemFunctions.toString(
        this,
        stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.bolum : null
      );
      formVars.istakipkart_831469_value = ReactSystemFunctions.toString(
        this,
        stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.diziBolumNo : null
      );
      formVars.istakipkart_883142_value = ReactSystemFunctions.toString(
        this,
        stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.sanatcilar : null
      );
      formVars.istakipkart_427688_value = ReactSystemFunctions.toString(
        this,
        stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.kullanimSekliCDTanim : null
      );
      formVars.istakipkart_681754_value = ReactSystemFunctions.toString(
        this,
        stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.briefAciklama : null
      );
      formVars.istakipkart_449550_value = ReactSystemFunctions.value(
        this,
        stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.sozlesmeTarihi : null
      );
      formVars.istakipkart_218026_value = ReactSystemFunctions.value(
        this,
        stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.lisansBasTarihi : null
      );
      formVars.istakipkart_218026_disablebeforedate = ReactSystemFunctions.value(
        this,
        ReactSystemFunctions.value(this, "istakipkart_449550_value", "value", "", "", "")
      );
      formVars.istakipkart_345176_value = ReactSystemFunctions.value(
        this,
        stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.lisansBitTarihi : null
      );
      formVars.istakipkart_345176_disablebeforedate = ReactSystemFunctions.value(
        this,
        ReactSystemFunctions.value(this, "istakipkart_218026_value", "value", "", "", "")
      );
      formVars.istakipkart_787576_value = ReactSystemFunctions.value(
        this,
        stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.lisansSuresiz : null
      );
      formVars.istakipkart_168257_value =
        stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.cariID : null;
      formVars.istakipkart_494755_value = ReactSystemFunctions.value(
        this,
        stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.isIndirimliFiyat : null
      );
      formVars.istakipkart_771873_value =
        stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.lisansTipiID : null;
      formVars.istakipkart_780759_value =
        stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.telifTurID : null;
      formVars.istakipkart_953041_value = ReactSystemFunctions.value(
        this,
        stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.faturaTarihi : null
      );
      formVars.istakipkart_552514_value = ReactSystemFunctions.value(
        this,
        stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.faturaTutar : null
      );
      formVars.istakipkart_73134_value = ReactSystemFunctions.toString(
        this,
        stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.simge : null
      );
      formVars.istakipkart_649647_value = ReactSystemFunctions.value(
        this,
        stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.dipNetTutar : null
      );
      formVars.istakipkart_952267_value = ReactSystemFunctions.value(
        this,
        stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.dipServisTutar : null
      );
      formVars.istakipkart_161516_value =
        stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.stopajOran : null;
      formVars.istakipkart_830227_value = ReactSystemFunctions.value(
        this,
        stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.dipStopajTutar : null
      );
      formVars.istakipkart_20311_value = ReactSystemFunctions.value(
        this,
        stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.dipBrutTutar : null
      );
      formVars.istakipkart_368022_value = ReactSystemFunctions.value(
        this,
        stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.dipIndirim : null
      );
      formVars.istakipkart_241361_value = ReactSystemFunctions.value(
        this,
        stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.dipToplam : null
      );
      formVars.istakipkart_286867_value = ReactSystemFunctions.value(
        this,
        stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.dipKdv : null
      );
      formVars.istakipkart_228291_value = ReactSystemFunctions.value(
        this,
        stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.dipGenelToplam : null
      );
      formVars.istakipkart_353223_value = ReactSystemFunctions.toString(
        this,
        stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.satirAciklama : null
      );
      formVars.istakipkart_116506_value =
        stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.vadeGunID : null;
      formVars.istakipkart_624781_value = ReactSystemFunctions.toString(
        this,
        stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.notSatir1 : null
      );
      formVars.istakipkart_608425_value = ReactSystemFunctions.toString(
        this,
        stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.notSatir2 : null
      );
      formVars.istakipkart_93784_value = ReactSystemFunctions.toString(
        this,
        stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.notSatir3 : null
      );
      stateVars.isComp659451Visible =
        ReactSystemFunctions.isEqualTo(
          ReactSystemFunctions.value(
            this,
            stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formReadOnly : null
          ),
          "0"
        ) === true
          ? "visible"
          : "hidden";
      stateVars.isComp382094Visible =
        ReactSystemFunctions.isEqualTo(
          ReactSystemFunctions.value(
            this,
            stateVars.getBasvuruFormuById?.length > 0
              ? stateVars.getBasvuruFormuById[0]?.isTakipDurumChangeButtonVisibily
              : null
          ),
          "1"
        ) === true
          ? "visible"
          : "hidden";
      stateVars.isComp57039Visible =
        ReactSystemFunctions.isEqualTo(
          ReactSystemFunctions.value(
            this,
            stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formReadOnly : null
          ),
          "0"
        ) === true
          ? "visible"
          : "hidden";
      stateVars.isComp927155Visible =
        ReactSystemFunctions.isEqualTo(
          ReactSystemFunctions.value(
            this,
            stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formReadOnly : null
          ),
          "0"
        ) === true
          ? "visible"
          : "hidden";
      stateVars.isComp363488Visible =
        ReactSystemFunctions.isEqualTo(
          ReactSystemFunctions.value(
            this,
            stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formReadOnly : null
          ),
          "0"
        ) === true
          ? "visible"
          : "hidden";
      stateVars.isComp432446Visible =
        ReactSystemFunctions.isEqualTo(
          ReactSystemFunctions.value(
            this,
            stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formReadOnly : null
          ),
          "0"
        ) === true
          ? "visible"
          : "hidden";
      stateVars.isComp934923Visible =
        ReactSystemFunctions.isEqualTo(
          ReactSystemFunctions.value(
            this,
            stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formReadOnly : null
          ),
          "0"
        ) === true
          ? "visible"
          : "hidden";
      stateVars.isComp375212Visible =
        ReactSystemFunctions.isEqualTo(
          ReactSystemFunctions.value(
            this,
            stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
          ),
          "2"
        ) === true
          ? "visible"
          : "hidden";
      stateVars.isComp449363Visible =
        ReactSystemFunctions.isNotEmpty(
          ReactSystemFunctions.toString(
            this,
            stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.yayinKanali : null
          ),
          null
        ) === true
          ? "visible"
          : "hidden";
      stateVars.isComp583165Visible =
        ReactSystemFunctions.isNotEmpty(
          ReactSystemFunctions.toString(
            this,
            stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.tvKanal : null
          ),
          null
        ) === true
          ? "visible"
          : "hidden";
      stateVars.isComp397201Visible =
        ReactSystemFunctions.isNotEmpty(
          ReactSystemFunctions.toString(
            this,
            stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.bolum : null
          ),
          null
        ) === true
          ? "visible"
          : "hidden";
      stateVars.isComp28971Visible =
        ReactSystemFunctions.isNotEmpty(
          ReactSystemFunctions.toString(
            this,
            stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.diziBolumNo : null
          ),
          null
        ) === true
          ? "visible"
          : "hidden";
      stateVars.isComp401049Visible =
        ReactSystemFunctions.isNotEmpty(
          ReactSystemFunctions.toString(
            this,
            stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.tvKanal : null
          ),
          null
        ) === true
          ? "visible"
          : "hidden";
      stateVars.isComp771200Visible =
        ReactSystemFunctions.isNotEmpty(
          ReactSystemFunctions.toString(
            this,
            stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.yayinKanali : null
          ),
          null
        ) === true
          ? "visible"
          : "hidden";
      stateVars.isComp864345Visible =
        ReactSystemFunctions.isNotEmpty(
          ReactSystemFunctions.toString(
            this,
            stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.tvBolumNo : null
          ),
          null
        ) === true
          ? "visible"
          : "hidden";
      stateVars.isComp260313Visible =
        ReactSystemFunctions.isNotEmpty(
          ReactSystemFunctions.toString(
            this,
            stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.bolum : null
          ),
          null
        ) === true
          ? "visible"
          : "hidden";
      stateVars.isComp831469Visible =
        ReactSystemFunctions.isNotEmpty(
          ReactSystemFunctions.toString(
            this,
            stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.diziBolumNo : null
          ),
          null
        ) === true
          ? "visible"
          : "hidden";
      stateVars.isComp681754Visible =
        ReactSystemFunctions.isNotEmpty(
          ReactSystemFunctions.toString(
            this,
            stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.briefAciklama : null
          ),
          null
        ) === true
          ? "visible"
          : "hidden";
      stateVars.isComp176618Visible =
        ReactSystemFunctions.isEqualTo(
          ReactSystemFunctions.value(
            this,
            stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formReadOnly : null
          ),
          "0"
        ) === true
          ? "visible"
          : "hidden";
      stateVars.isComp739682Visible =
        ReactSystemFunctions.isEqualTo(
          ReactSystemFunctions.value(
            this,
            stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
          ),
          "2"
        ) === true
          ? "visible"
          : "hidden";
      stateVars.isComp681149Visible =
        ReactSystemFunctions.isEqualTo(
          ReactSystemFunctions.value(
            this,
            stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formReadOnly : null
          ),
          "0"
        ) === true
          ? "visible"
          : "hidden";
      stateVars.isComp202682Visible =
        ReactSystemFunctions.isEqualTo(
          ReactSystemFunctions.value(
            this,
            stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formReadOnly : null
          ),
          "0"
        ) === true
          ? "visible"
          : "hidden";
      stateVars.isComp330703Visible =
        ReactSystemFunctions.isEqualTo(
          ReactSystemFunctions.value(
            this,
            stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
          ),
          "1"
        ) === true
          ? "visible"
          : "hidden";
      stateVars.isComp218524Visible =
        ReactSystemFunctions.isEqualTo(
          ReactSystemFunctions.value(
            this,
            stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
          ),
          "2"
        ) === true
          ? "visible"
          : "hidden";
      stateVars.isComp991225Visible =
        ReactSystemFunctions.isEqualTo(
          ReactSystemFunctions.value(
            this,
            stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
          ),
          "1"
        ) === true
          ? "visible"
          : "hidden";
      stateVars.isComp476748Visible =
        ReactSystemFunctions.isEqualTo(
          ReactSystemFunctions.value(
            this,
            stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
          ),
          "1"
        ) === true
          ? "visible"
          : "hidden";
      stateVars.isComp478750Visible =
        ReactSystemFunctions.isEqualTo(
          ReactSystemFunctions.value(
            this,
            stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
          ),
          "1"
        ) === true
          ? "visible"
          : "hidden";
      stateVars.isComp336407Visible =
        ReactSystemFunctions.isEqualTo(
          ReactSystemFunctions.value(
            this,
            stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
          ),
          "2"
        ) === true
          ? "visible"
          : "hidden";
      stateVars.isComp852077Visible =
        ReactSystemFunctions.isEqualTo(
          ReactSystemFunctions.value(
            this,
            stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
          ),
          "1"
        ) === true
          ? "visible"
          : "hidden";
      stateVars.isComp13593Visible =
        ReactSystemFunctions.isEqualTo(
          ReactSystemFunctions.value(
            this,
            stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
          ),
          "1"
        ) === true
          ? "visible"
          : "hidden";
      stateVars.isComp695930Visible =
        ReactSystemFunctions.isEqualTo(
          ReactSystemFunctions.value(
            this,
            stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
          ),
          "1"
        ) === true
          ? "visible"
          : "hidden";
      stateVars.isComp846928Visible =
        ReactSystemFunctions.isEqualTo(
          ReactSystemFunctions.value(
            this,
            stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formReadOnly : null
          ),
          "0"
        ) === true
          ? "visible"
          : "hidden";
      stateVars.isComp781928Visible =
        ReactSystemFunctions.isEqualTo(
          ReactSystemFunctions.value(
            this,
            stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formReadOnly : null
          ),
          "0"
        ) === true
          ? "visible"
          : "hidden";
      stateVars.isComp122685Visible =
        ReactSystemFunctions.isEqualTo(
          ReactSystemFunctions.value(
            this,
            stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
          ),
          "1"
        ) === true
          ? "visible"
          : "hidden";
      stateVars.isComp104543Visible =
        ReactSystemFunctions.isEqualTo(
          ReactSystemFunctions.value(
            this,
            stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
          ),
          "2"
        ) === true
          ? "visible"
          : "hidden";
      stateVars.isComp734427Visible =
        ReactSystemFunctions.isEqualTo(
          ReactSystemFunctions.value(
            this,
            stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
          ),
          "1"
        ) === true
          ? "visible"
          : "hidden";
      stateVars.isComp586759Visible =
        ReactSystemFunctions.isNotEmpty(
          ReactSystemFunctions.value(
            this,
            stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.sozlesmeID : null
          ),
          null
        ) === true
          ? "visible"
          : "hidden";
      stateVars.isComp326018Visible =
        ReactSystemFunctions.isEqualTo(
          ReactSystemFunctions.value(
            this,
            stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
          ),
          "2"
        ) === true
          ? "visible"
          : "hidden";
      stateVars.isComp19843Visible =
        ReactSystemFunctions.isEqualTo(
          ReactSystemFunctions.value(
            this,
            stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formReadOnly : null
          ),
          "0"
        ) === true
          ? "visible"
          : "hidden";
      stateVars.isComp436915Visible =
        ReactSystemFunctions.isEqualTo(
          ReactSystemFunctions.value(
            this,
            stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formReadOnly : null
          ),
          "0"
        ) === true
          ? "visible"
          : "hidden";
      stateVars.isComp972677Visible =
        ReactSystemFunctions.isEqualTo(
          ReactSystemFunctions.value(
            this,
            stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formReadOnly : null
          ),
          "0"
        ) === true
          ? "visible"
          : "hidden";
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.IsTakipKartComponent_846928_onClick1_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  IsTakipKartComponent_846928_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.istakipkart_785916_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.ticketNo : null
    );

    formVars.istakipkart_308766_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.olusturmaTarihi : null
    );

    formVars.istakipkart_354958_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.isTakipBaslangicTarihi : null
    );

    formVars.istakipkart_72949_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.isTakipGuncellemeZaman : null
    );

    formVars.istakipkart_174064_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.terminTarihi : null
    );

    formVars.istakipkart_609729_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.formStatuID : null
    );

    formVars.istakipkart_352216_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.aksiyonIlgiliKisi : null
    );

    formVars.istakipkart_846368_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.onemliNot : null
    );

    formVars.istakipkart_679771_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.eserAd : null
    );

    formVars.istakipkart_679331_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.eserAd : null
    );

    formVars.istakipkart_872419_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.eserSahibi : null
    );

    formVars.istakipkart_909777_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.eserSahibi : null
    );

    formVars.istakipkart_880403_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.basvuruTuruTanim : null
    );

    formVars.istakipkart_765584_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.basvuruTuruID : null
    );

    formVars.istakipkart_334144_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.projeTuru : null
    );

    formVars.istakipkart_183673_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.projeTuruID : null
    );

    formVars.istakipkart_707596_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.adSoyad : null
    );

    formVars.istakipkart_969221_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.olusturanKisi : null
    );

    formVars.istakipkart_96402_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.eMail : null
    );

    formVars.istakipkart_284293_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.eMail : null
    );

    formVars.istakipkart_610845_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.gsm : null
    );

    formVars.istakipkart_528021_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.gsm : null
    );

    formVars.istakipkart_473202_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.sirketAd : null
    );

    formVars.istakipkart_865525_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.aciklama : null
    );

    formVars.istakipkart_310363_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.aciklama : null
    );

    formVars.istakipkart_367546_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.acil : null
    );

    formVars.istakipkart_490476_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.terminTarihi : null
    );

    formVars.istakipkart_956828_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.terminTarihi : null
    );

    formVars.istakipkart_743413_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.servisUcretiVar : null
    );

    formVars.istakipkart_253070_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.projeAd : null
    );

    formVars.istakipkart_204534_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.projeAd : null
    );

    formVars.istakipkart_449363_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.yayinKanali : null
    );

    formVars.istakipkart_583165_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.tvKanal : null
    );

    formVars.istakipkart_741340_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.sezon : null
    );

    formVars.istakipkart_397201_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.bolum : null
    );

    formVars.istakipkart_28971_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.diziBolumNo : null
    );

    formVars.istakipkart_218936_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.konserYerTarih : null
    );

    formVars.istakipkart_309538_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.urun : null
    );

    formVars.istakipkart_401049_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.tvKanal : null
    );

    formVars.istakipkart_771200_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.yayinKanali : null
    );

    formVars.istakipkart_915575_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.sezon : null
    );

    formVars.istakipkart_864345_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.tvBolumNo : null
    );

    formVars.istakipkart_260313_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.bolum : null
    );

    formVars.istakipkart_831469_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.diziBolumNo : null
    );

    formVars.istakipkart_883142_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.sanatcilar : null
    );

    formVars.istakipkart_427688_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.kullanimSekliCDTanim : null
    );

    formVars.istakipkart_681754_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.briefAciklama : null
    );

    formVars.istakipkart_449550_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.sozlesmeTarihi : null
    );

    formVars.istakipkart_218026_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.lisansBasTarihi : null
    );

    formVars.istakipkart_345176_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.lisansBitTarihi : null
    );

    formVars.istakipkart_787576_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.lisansSuresiz : null
    );

    formVars.istakipkart_168257_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.cariID : null
    );

    formVars.istakipkart_494755_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.isIndirimliFiyat : null
    );

    formVars.istakipkart_771873_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.lisansTipiID : null
    );

    formVars.istakipkart_780759_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.telifTurID : null
    );

    formVars.istakipkart_953041_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.faturaTarihi : null
    );

    formVars.istakipkart_552514_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.faturaTutar : null
    );

    formVars.istakipkart_73134_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.simge : null
    );

    stateVars.dataSource_215911 = this.state.getListKullanimSureleriByBFId;
    formVars.istakipkart_256375_value = ReactSystemFunctions.toString(
      this,
      this.state.getListKullanimSureleriByBFId?.length > 0 ? this.state.getListKullanimSureleriByBFId[0]?.simge : null
    );

    formVars.istakipkart_649647_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.dipNetTutar : null
    );

    formVars.istakipkart_952267_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.dipServisTutar : null
    );

    formVars.istakipkart_161516_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.stopajOran : null
    );

    formVars.istakipkart_830227_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.dipStopajTutar : null
    );

    formVars.istakipkart_20311_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.dipBrutTutar : null
    );

    formVars.istakipkart_368022_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.dipIndirim : null
    );

    formVars.istakipkart_241361_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.dipToplam : null
    );

    formVars.istakipkart_286867_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.dipKdv : null
    );

    formVars.istakipkart_228291_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.dipGenelToplam : null
    );

    formVars.istakipkart_353223_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.satirAciklama : null
    );

    formVars.istakipkart_116506_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.vadeGunID : null
    );

    formVars.istakipkart_624781_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.notSatir1 : null
    );

    formVars.istakipkart_608425_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.notSatir2 : null
    );

    formVars.istakipkart_93784_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.notSatir3 : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  IsTakipKartComponent_781928_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "FiyatlandirmaOzelKart",
      "prmGuid",
      ReactSystemFunctions.value(this, "istakipkart_215911_value", "id")
    );
    KuikaAppManager.addToPageInputVariables("FiyatlandirmaOzelKart", "NewEdit", "E");
    KuikaAppManager.addToPageInputVariables(
      "FiyatlandirmaOzelKart",
      "BTuru",
      ReactSystemFunctions.toString(
        this,
        ReactSystemFunctions.value(this, "istakipkart_765584_value", "value", "sbBasvuruTuruList", "id", "kod")
      )
    );
    KuikaAppManager.addToPageInputVariables(
      "FiyatlandirmaOzelKart",
      "PTuru",
      ReactSystemFunctions.toString(
        this,
        ReactSystemFunctions.value(
          this,
          "istakipkart_183673_value",
          "value",
          "spGetProjeTuruByBTuruKod",
          "id",
          "projeTuruRefKod"
        )
      )
    );
    KuikaAppManager.addToPageInputVariables(
      "FiyatlandirmaOzelKart",
      "BFId",
      this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid
    );
    KuikaAppManager.addToPageInputVariables(
      "FiyatlandirmaOzelKart",
      "FiyatListeAdID",
      ReactSystemFunctions.value(
        this,
        ReactSystemFunctions.value(this, "istakipkart_168257_value", "value", "getListCari", "id", "fiyatListeAdID")
      )
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      this.IsTakipKartComponent_781928_onClick1_,
      "IsTakipKart",
      "FiyatlandirmaOzelKart",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "678135",
      null,
      "right",
      null,
      "380",
      "",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };
  IsTakipKartComponent_781928_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "istakipkart_215911_value", "id"),
        "Guid"
      ),
      BasvuruFormuID_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      ),
      BasvuruTuru_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "istakipkart_765584_value", "value", "sbBasvuruTuruList", "id", "kod")
        ),
        "number"
      ),
      BasvuruFormuID_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      ),
      ServisUcretiVar_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "istakipkart_743413_value", "value", "", "", "")
        ),
        "boolean"
      ),
      Stopaj_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "istakipkart_161516_value",
            "value",
            "sbStopajList",
            "stopajOran",
            "stopajOran"
          )
        ),
        "number"
      ),
      Id_3: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      ),
      Rol_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.getUserInfo?.length > 0 ? this.state.getUserInfo[0]?.rolName : null
        ),
        "string"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "IsTakipKart/IsTakipKartComponent_781928_onClick1_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.spUpdateFiyatlandirmaByCurrentId = result?.data.spUpdateFiyatlandirmaByCurrentId;
    stateVars.getListKullanimSureleriByBFId = result?.data.getListKullanimSureleriByBFId;

    formVars.istakipkart_256375_value = ReactSystemFunctions.toString(
      this,
      stateVars.getListKullanimSureleriByBFId?.length > 0 ? stateVars.getListKullanimSureleriByBFId[0]?.simge : null
    );
    stateVars.spDipToplamByBFId = result?.data.spDipToplamByBFId;
    stateVars.getBasvuruFormuById = result?.data.getBasvuruFormuById;
    formVars.istakipkart_785916_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.ticketNo : null
    );
    formVars.istakipkart_308766_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.olusturmaTarihi : null
    );
    formVars.istakipkart_354958_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.isTakipBaslangicTarihi : null
    );
    formVars.istakipkart_72949_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.isTakipGuncellemeZaman : null
    );
    formVars.istakipkart_174064_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.terminTarihi : null
    );
    formVars.istakipkart_609729_value =
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formStatuID : null;
    formVars.istakipkart_352216_value =
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.aksiyonIlgiliKisi : null;
    formVars.istakipkart_846368_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.onemliNot : null
    );
    formVars.istakipkart_679771_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.eserAd : null
    );
    formVars.istakipkart_679331_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.eserAd : null
    );
    formVars.istakipkart_872419_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.eserSahibi : null
    );
    formVars.istakipkart_909777_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.eserSahibi : null
    );
    formVars.istakipkart_880403_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruTanim : null
    );
    formVars.istakipkart_765584_value =
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruID : null;
    formVars.istakipkart_334144_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.projeTuru : null
    );
    formVars.istakipkart_183673_value =
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.projeTuruID : null;
    formVars.istakipkart_707596_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.adSoyad : null
    );
    formVars.istakipkart_969221_value =
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.olusturanKisi : null;
    formVars.istakipkart_96402_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.eMail : null
    );
    formVars.istakipkart_284293_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.eMail : null
    );
    formVars.istakipkart_610845_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.gsm : null
    );
    formVars.istakipkart_528021_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.gsm : null
    );
    formVars.istakipkart_473202_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.sirketAd : null
    );
    formVars.istakipkart_865525_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.aciklama : null
    );
    formVars.istakipkart_310363_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.aciklama : null
    );
    formVars.istakipkart_367546_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.acil : null
    );
    formVars.istakipkart_490476_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.terminTarihi : null
    );
    formVars.istakipkart_956828_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.terminTarihi : null
    );
    formVars.istakipkart_743413_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.servisUcretiVar : null
    );
    formVars.istakipkart_253070_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.projeAd : null
    );
    formVars.istakipkart_204534_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.projeAd : null
    );
    formVars.istakipkart_449363_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.yayinKanali : null
    );
    formVars.istakipkart_583165_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.tvKanal : null
    );
    formVars.istakipkart_741340_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.sezon : null
    );
    formVars.istakipkart_397201_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.bolum : null
    );
    formVars.istakipkart_28971_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.diziBolumNo : null
    );
    formVars.istakipkart_218936_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.konserYerTarih : null
    );
    formVars.istakipkart_309538_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.urun : null
    );
    formVars.istakipkart_401049_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.tvKanal : null
    );
    formVars.istakipkart_771200_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.yayinKanali : null
    );
    formVars.istakipkart_915575_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.sezon : null
    );
    formVars.istakipkart_864345_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.tvBolumNo : null
    );
    formVars.istakipkart_260313_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.bolum : null
    );
    formVars.istakipkart_831469_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.diziBolumNo : null
    );
    formVars.istakipkart_883142_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.sanatcilar : null
    );
    formVars.istakipkart_427688_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.kullanimSekliCDTanim : null
    );
    formVars.istakipkart_681754_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.briefAciklama : null
    );
    formVars.istakipkart_449550_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.sozlesmeTarihi : null
    );
    formVars.istakipkart_218026_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.lisansBasTarihi : null
    );
    formVars.istakipkart_218026_disablebeforedate = ReactSystemFunctions.value(
      this,
      ReactSystemFunctions.value(this, "istakipkart_449550_value", "value", "", "", "")
    );
    formVars.istakipkart_345176_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.lisansBitTarihi : null
    );
    formVars.istakipkart_345176_disablebeforedate = ReactSystemFunctions.value(
      this,
      ReactSystemFunctions.value(this, "istakipkart_218026_value", "value", "", "", "")
    );
    formVars.istakipkart_787576_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.lisansSuresiz : null
    );
    formVars.istakipkart_168257_value =
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.cariID : null;
    formVars.istakipkart_494755_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.isIndirimliFiyat : null
    );
    formVars.istakipkart_771873_value =
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.lisansTipiID : null;
    formVars.istakipkart_780759_value =
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.telifTurID : null;
    formVars.istakipkart_953041_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.faturaTarihi : null
    );
    formVars.istakipkart_552514_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.faturaTutar : null
    );
    formVars.istakipkart_73134_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.simge : null
    );
    formVars.istakipkart_649647_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.dipNetTutar : null
    );
    formVars.istakipkart_952267_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.dipServisTutar : null
    );
    formVars.istakipkart_161516_value =
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.stopajOran : null;
    formVars.istakipkart_830227_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.dipStopajTutar : null
    );
    formVars.istakipkart_20311_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.dipBrutTutar : null
    );
    formVars.istakipkart_368022_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.dipIndirim : null
    );
    formVars.istakipkart_241361_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.dipToplam : null
    );
    formVars.istakipkart_286867_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.dipKdv : null
    );
    formVars.istakipkart_228291_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.dipGenelToplam : null
    );
    formVars.istakipkart_353223_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.satirAciklama : null
    );
    formVars.istakipkart_116506_value =
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.vadeGunID : null;
    formVars.istakipkart_624781_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.notSatir1 : null
    );
    formVars.istakipkart_608425_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.notSatir2 : null
    );
    formVars.istakipkart_93784_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.notSatir3 : null
    );
    stateVars.isComp659451Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formReadOnly : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp382094Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0
            ? stateVars.getBasvuruFormuById[0]?.isTakipDurumChangeButtonVisibily
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp57039Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formReadOnly : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp927155Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formReadOnly : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp363488Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formReadOnly : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp432446Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formReadOnly : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp934923Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formReadOnly : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp375212Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp449363Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.yayinKanali : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp583165Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.tvKanal : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp397201Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.bolum : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp28971Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.diziBolumNo : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp401049Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.tvKanal : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp771200Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.yayinKanali : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp864345Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.tvBolumNo : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp260313Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.bolum : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp831469Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.diziBolumNo : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp681754Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.briefAciklama : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp176618Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formReadOnly : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp739682Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp681149Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formReadOnly : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp202682Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formReadOnly : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp330703Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp218524Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp991225Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp476748Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp478750Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp336407Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp852077Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp13593Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp695930Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp846928Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formReadOnly : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp781928Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formReadOnly : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp122685Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp104543Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp734427Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp586759Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.sozlesmeID : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp326018Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp19843Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formReadOnly : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp436915Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formReadOnly : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp972677Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formReadOnly : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.IsTakipKartComponent_781928_onClick2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  IsTakipKartComponent_781928_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.istakipkart_785916_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.ticketNo : null
    );

    formVars.istakipkart_308766_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.olusturmaTarihi : null
    );

    formVars.istakipkart_354958_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.isTakipBaslangicTarihi : null
    );

    formVars.istakipkart_72949_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.isTakipGuncellemeZaman : null
    );

    formVars.istakipkart_174064_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.terminTarihi : null
    );

    formVars.istakipkart_609729_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.formStatuID : null
    );

    formVars.istakipkart_352216_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.aksiyonIlgiliKisi : null
    );

    formVars.istakipkart_846368_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.onemliNot : null
    );

    formVars.istakipkart_679771_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.eserAd : null
    );

    formVars.istakipkart_679331_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.eserAd : null
    );

    formVars.istakipkart_872419_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.eserSahibi : null
    );

    formVars.istakipkart_909777_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.eserSahibi : null
    );

    formVars.istakipkart_880403_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.basvuruTuruTanim : null
    );

    formVars.istakipkart_765584_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.basvuruTuruID : null
    );

    formVars.istakipkart_334144_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.projeTuru : null
    );

    formVars.istakipkart_183673_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.projeTuruID : null
    );

    formVars.istakipkart_707596_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.adSoyad : null
    );

    formVars.istakipkart_969221_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.olusturanKisi : null
    );

    formVars.istakipkart_96402_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.eMail : null
    );

    formVars.istakipkart_284293_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.eMail : null
    );

    formVars.istakipkart_610845_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.gsm : null
    );

    formVars.istakipkart_528021_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.gsm : null
    );

    formVars.istakipkart_473202_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.sirketAd : null
    );

    formVars.istakipkart_865525_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.aciklama : null
    );

    formVars.istakipkart_310363_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.aciklama : null
    );

    formVars.istakipkart_367546_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.acil : null
    );

    formVars.istakipkart_490476_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.terminTarihi : null
    );

    formVars.istakipkart_956828_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.terminTarihi : null
    );

    formVars.istakipkart_743413_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.servisUcretiVar : null
    );

    formVars.istakipkart_253070_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.projeAd : null
    );

    formVars.istakipkart_204534_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.projeAd : null
    );

    formVars.istakipkart_449363_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.yayinKanali : null
    );

    formVars.istakipkart_583165_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.tvKanal : null
    );

    formVars.istakipkart_741340_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.sezon : null
    );

    formVars.istakipkart_397201_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.bolum : null
    );

    formVars.istakipkart_28971_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.diziBolumNo : null
    );

    formVars.istakipkart_218936_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.konserYerTarih : null
    );

    formVars.istakipkart_309538_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.urun : null
    );

    formVars.istakipkart_401049_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.tvKanal : null
    );

    formVars.istakipkart_771200_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.yayinKanali : null
    );

    formVars.istakipkart_915575_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.sezon : null
    );

    formVars.istakipkart_864345_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.tvBolumNo : null
    );

    formVars.istakipkart_260313_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.bolum : null
    );

    formVars.istakipkart_831469_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.diziBolumNo : null
    );

    formVars.istakipkart_883142_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.sanatcilar : null
    );

    formVars.istakipkart_427688_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.kullanimSekliCDTanim : null
    );

    formVars.istakipkart_681754_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.briefAciklama : null
    );

    formVars.istakipkart_449550_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.sozlesmeTarihi : null
    );

    formVars.istakipkart_218026_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.lisansBasTarihi : null
    );

    formVars.istakipkart_345176_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.lisansBitTarihi : null
    );

    formVars.istakipkart_787576_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.lisansSuresiz : null
    );

    formVars.istakipkart_168257_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.cariID : null
    );

    formVars.istakipkart_494755_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.isIndirimliFiyat : null
    );

    formVars.istakipkart_771873_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.lisansTipiID : null
    );

    formVars.istakipkart_780759_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.telifTurID : null
    );

    formVars.istakipkart_953041_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.faturaTarihi : null
    );

    formVars.istakipkart_552514_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.faturaTutar : null
    );

    formVars.istakipkart_73134_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.simge : null
    );

    stateVars.dataSource_215911 = this.state.getListKullanimSureleriByBFId;
    formVars.istakipkart_256375_value = ReactSystemFunctions.toString(
      this,
      this.state.getListKullanimSureleriByBFId?.length > 0 ? this.state.getListKullanimSureleriByBFId[0]?.simge : null
    );

    formVars.istakipkart_649647_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.dipNetTutar : null
    );

    formVars.istakipkart_952267_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.dipServisTutar : null
    );

    formVars.istakipkart_161516_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.stopajOran : null
    );

    formVars.istakipkart_830227_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.dipStopajTutar : null
    );

    formVars.istakipkart_20311_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.dipBrutTutar : null
    );

    formVars.istakipkart_368022_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.dipIndirim : null
    );

    formVars.istakipkart_241361_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.dipToplam : null
    );

    formVars.istakipkart_286867_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.dipKdv : null
    );

    formVars.istakipkart_228291_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.dipGenelToplam : null
    );

    formVars.istakipkart_353223_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.satirAciklama : null
    );

    formVars.istakipkart_116506_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.vadeGunID : null
    );

    formVars.istakipkart_624781_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.notSatir1 : null
    );

    formVars.istakipkart_608425_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.notSatir2 : null
    );

    formVars.istakipkart_93784_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.notSatir3 : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  IsTakipKartComponent_161516_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      StopajOran_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "istakipkart_161516_value",
            "value",
            "sbStopajList",
            "stopajOran",
            "stopajOran"
          )
        ),
        "number"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      ),
      BasvuruTuru_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "istakipkart_765584_value", "value", "sbBasvuruTuruList", "id", "kod")
        ),
        "number"
      ),
      BasvuruFormuID_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      ),
      ServisUcretiVar_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "istakipkart_743413_value", "value", "", "", "")
        ),
        "boolean"
      ),
      Stopaj_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "istakipkart_161516_value",
            "value",
            "sbStopajList",
            "stopajOran",
            "stopajOran"
          )
        ),
        "number"
      ),
      Id_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      ),
      Rol_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.getUserInfo?.length > 0 ? this.state.getUserInfo[0]?.rolName : null
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "IsTakipKart/IsTakipKartComponent_161516_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.setStopajOranUpdate = result?.data.setStopajOranUpdate;
    stateVars.spDipToplamByBFId = result?.data.spDipToplamByBFId;
    stateVars.getBasvuruFormuById = result?.data.getBasvuruFormuById;
    formVars.istakipkart_785916_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.ticketNo : null
    );
    formVars.istakipkart_308766_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.olusturmaTarihi : null
    );
    formVars.istakipkart_354958_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.isTakipBaslangicTarihi : null
    );
    formVars.istakipkart_72949_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.isTakipGuncellemeZaman : null
    );
    formVars.istakipkart_174064_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.terminTarihi : null
    );
    formVars.istakipkart_609729_value =
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formStatuID : null;
    formVars.istakipkart_352216_value =
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.aksiyonIlgiliKisi : null;
    formVars.istakipkart_846368_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.onemliNot : null
    );
    formVars.istakipkart_679771_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.eserAd : null
    );
    formVars.istakipkart_679331_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.eserAd : null
    );
    formVars.istakipkart_872419_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.eserSahibi : null
    );
    formVars.istakipkart_909777_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.eserSahibi : null
    );
    formVars.istakipkart_880403_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruTanim : null
    );
    formVars.istakipkart_765584_value =
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruID : null;
    formVars.istakipkart_334144_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.projeTuru : null
    );
    formVars.istakipkart_183673_value =
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.projeTuruID : null;
    formVars.istakipkart_707596_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.adSoyad : null
    );
    formVars.istakipkart_969221_value =
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.olusturanKisi : null;
    formVars.istakipkart_96402_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.eMail : null
    );
    formVars.istakipkart_284293_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.eMail : null
    );
    formVars.istakipkart_610845_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.gsm : null
    );
    formVars.istakipkart_528021_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.gsm : null
    );
    formVars.istakipkart_473202_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.sirketAd : null
    );
    formVars.istakipkart_865525_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.aciklama : null
    );
    formVars.istakipkart_310363_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.aciklama : null
    );
    formVars.istakipkart_367546_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.acil : null
    );
    formVars.istakipkart_490476_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.terminTarihi : null
    );
    formVars.istakipkart_956828_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.terminTarihi : null
    );
    formVars.istakipkart_743413_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.servisUcretiVar : null
    );
    formVars.istakipkart_253070_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.projeAd : null
    );
    formVars.istakipkart_204534_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.projeAd : null
    );
    formVars.istakipkart_449363_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.yayinKanali : null
    );
    formVars.istakipkart_583165_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.tvKanal : null
    );
    formVars.istakipkart_741340_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.sezon : null
    );
    formVars.istakipkart_397201_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.bolum : null
    );
    formVars.istakipkart_28971_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.diziBolumNo : null
    );
    formVars.istakipkart_218936_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.konserYerTarih : null
    );
    formVars.istakipkart_309538_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.urun : null
    );
    formVars.istakipkart_401049_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.tvKanal : null
    );
    formVars.istakipkart_771200_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.yayinKanali : null
    );
    formVars.istakipkart_915575_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.sezon : null
    );
    formVars.istakipkart_864345_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.tvBolumNo : null
    );
    formVars.istakipkart_260313_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.bolum : null
    );
    formVars.istakipkart_831469_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.diziBolumNo : null
    );
    formVars.istakipkart_883142_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.sanatcilar : null
    );
    formVars.istakipkart_427688_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.kullanimSekliCDTanim : null
    );
    formVars.istakipkart_681754_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.briefAciklama : null
    );
    formVars.istakipkart_449550_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.sozlesmeTarihi : null
    );
    formVars.istakipkart_218026_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.lisansBasTarihi : null
    );
    formVars.istakipkart_218026_disablebeforedate = ReactSystemFunctions.value(
      this,
      ReactSystemFunctions.value(this, "istakipkart_449550_value", "value", "", "", "")
    );
    formVars.istakipkart_345176_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.lisansBitTarihi : null
    );
    formVars.istakipkart_345176_disablebeforedate = ReactSystemFunctions.value(
      this,
      ReactSystemFunctions.value(this, "istakipkart_218026_value", "value", "", "", "")
    );
    formVars.istakipkart_787576_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.lisansSuresiz : null
    );
    formVars.istakipkart_168257_value =
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.cariID : null;
    formVars.istakipkart_494755_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.isIndirimliFiyat : null
    );
    formVars.istakipkart_771873_value =
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.lisansTipiID : null;
    formVars.istakipkart_780759_value =
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.telifTurID : null;
    formVars.istakipkart_953041_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.faturaTarihi : null
    );
    formVars.istakipkart_552514_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.faturaTutar : null
    );
    formVars.istakipkart_73134_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.simge : null
    );
    formVars.istakipkart_649647_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.dipNetTutar : null
    );
    formVars.istakipkart_952267_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.dipServisTutar : null
    );
    formVars.istakipkart_161516_value =
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.stopajOran : null;
    formVars.istakipkart_830227_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.dipStopajTutar : null
    );
    formVars.istakipkart_20311_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.dipBrutTutar : null
    );
    formVars.istakipkart_368022_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.dipIndirim : null
    );
    formVars.istakipkart_241361_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.dipToplam : null
    );
    formVars.istakipkart_286867_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.dipKdv : null
    );
    formVars.istakipkart_228291_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.dipGenelToplam : null
    );
    formVars.istakipkart_353223_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.satirAciklama : null
    );
    formVars.istakipkart_116506_value =
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.vadeGunID : null;
    formVars.istakipkart_624781_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.notSatir1 : null
    );
    formVars.istakipkart_608425_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.notSatir2 : null
    );
    formVars.istakipkart_93784_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.notSatir3 : null
    );
    stateVars.isComp659451Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formReadOnly : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp382094Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0
            ? stateVars.getBasvuruFormuById[0]?.isTakipDurumChangeButtonVisibily
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp57039Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formReadOnly : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp927155Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formReadOnly : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp363488Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formReadOnly : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp432446Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formReadOnly : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp934923Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formReadOnly : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp375212Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp449363Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.yayinKanali : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp583165Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.tvKanal : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp397201Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.bolum : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp28971Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.diziBolumNo : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp401049Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.tvKanal : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp771200Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.yayinKanali : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp864345Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.tvBolumNo : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp260313Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.bolum : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp831469Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.diziBolumNo : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp681754Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.briefAciklama : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp176618Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formReadOnly : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp739682Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp681149Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formReadOnly : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp202682Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formReadOnly : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp330703Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp218524Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp991225Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp476748Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp478750Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp336407Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp852077Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp13593Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp695930Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp846928Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formReadOnly : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp781928Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formReadOnly : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp122685Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp104543Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp734427Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp586759Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.sozlesmeID : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp326018Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.basvuruTuruKod : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp19843Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formReadOnly : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp436915Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formReadOnly : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp972677Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0 ? stateVars.getBasvuruFormuById[0]?.formReadOnly : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.IsTakipKartComponent_161516_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  IsTakipKartComponent_161516_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.istakipkart_785916_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.ticketNo : null
    );

    formVars.istakipkart_308766_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.olusturmaTarihi : null
    );

    formVars.istakipkart_354958_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.isTakipBaslangicTarihi : null
    );

    formVars.istakipkart_72949_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.isTakipGuncellemeZaman : null
    );

    formVars.istakipkart_174064_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.terminTarihi : null
    );

    formVars.istakipkart_609729_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.formStatuID : null
    );

    formVars.istakipkart_352216_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.aksiyonIlgiliKisi : null
    );

    formVars.istakipkart_846368_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.onemliNot : null
    );

    formVars.istakipkart_679771_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.eserAd : null
    );

    formVars.istakipkart_679331_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.eserAd : null
    );

    formVars.istakipkart_872419_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.eserSahibi : null
    );

    formVars.istakipkart_909777_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.eserSahibi : null
    );

    formVars.istakipkart_880403_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.basvuruTuruTanim : null
    );

    formVars.istakipkart_765584_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.basvuruTuruID : null
    );

    formVars.istakipkart_334144_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.projeTuru : null
    );

    formVars.istakipkart_183673_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.projeTuruID : null
    );

    formVars.istakipkart_707596_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.adSoyad : null
    );

    formVars.istakipkart_969221_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.olusturanKisi : null
    );

    formVars.istakipkart_96402_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.eMail : null
    );

    formVars.istakipkart_284293_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.eMail : null
    );

    formVars.istakipkart_610845_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.gsm : null
    );

    formVars.istakipkart_528021_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.gsm : null
    );

    formVars.istakipkart_473202_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.sirketAd : null
    );

    formVars.istakipkart_865525_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.aciklama : null
    );

    formVars.istakipkart_310363_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.aciklama : null
    );

    formVars.istakipkart_367546_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.acil : null
    );

    formVars.istakipkart_490476_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.terminTarihi : null
    );

    formVars.istakipkart_956828_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.terminTarihi : null
    );

    formVars.istakipkart_743413_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.servisUcretiVar : null
    );

    formVars.istakipkart_253070_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.projeAd : null
    );

    formVars.istakipkart_204534_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.projeAd : null
    );

    formVars.istakipkart_449363_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.yayinKanali : null
    );

    formVars.istakipkart_583165_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.tvKanal : null
    );

    formVars.istakipkart_741340_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.sezon : null
    );

    formVars.istakipkart_397201_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.bolum : null
    );

    formVars.istakipkart_28971_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.diziBolumNo : null
    );

    formVars.istakipkart_218936_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.konserYerTarih : null
    );

    formVars.istakipkart_309538_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.urun : null
    );

    formVars.istakipkart_401049_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.tvKanal : null
    );

    formVars.istakipkart_771200_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.yayinKanali : null
    );

    formVars.istakipkart_915575_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.sezon : null
    );

    formVars.istakipkart_864345_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.tvBolumNo : null
    );

    formVars.istakipkart_260313_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.bolum : null
    );

    formVars.istakipkart_831469_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.diziBolumNo : null
    );

    formVars.istakipkart_883142_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.sanatcilar : null
    );

    formVars.istakipkart_427688_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.kullanimSekliCDTanim : null
    );

    formVars.istakipkart_681754_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.briefAciklama : null
    );

    formVars.istakipkart_449550_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.sozlesmeTarihi : null
    );

    formVars.istakipkart_218026_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.lisansBasTarihi : null
    );

    formVars.istakipkart_345176_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.lisansBitTarihi : null
    );

    formVars.istakipkart_787576_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.lisansSuresiz : null
    );

    formVars.istakipkart_168257_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.cariID : null
    );

    formVars.istakipkart_494755_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.isIndirimliFiyat : null
    );

    formVars.istakipkart_771873_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.lisansTipiID : null
    );

    formVars.istakipkart_780759_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.telifTurID : null
    );

    formVars.istakipkart_953041_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.faturaTarihi : null
    );

    formVars.istakipkart_552514_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.faturaTutar : null
    );

    formVars.istakipkart_73134_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.simge : null
    );

    formVars.istakipkart_649647_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.dipNetTutar : null
    );

    formVars.istakipkart_952267_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.dipServisTutar : null
    );

    formVars.istakipkart_161516_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.stopajOran : null
    );

    formVars.istakipkart_830227_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.dipStopajTutar : null
    );

    formVars.istakipkart_20311_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.dipBrutTutar : null
    );

    formVars.istakipkart_368022_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.dipIndirim : null
    );

    formVars.istakipkart_241361_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.dipToplam : null
    );

    formVars.istakipkart_286867_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.dipKdv : null
    );

    formVars.istakipkart_228291_value = ReactSystemFunctions.value(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.dipGenelToplam : null
    );

    formVars.istakipkart_353223_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.satirAciklama : null
    );

    formVars.istakipkart_116506_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.vadeGunID : null
    );

    formVars.istakipkart_624781_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.notSatir1 : null
    );

    formVars.istakipkart_608425_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.notSatir2 : null
    );

    formVars.istakipkart_93784_value = ReactSystemFunctions.toString(
      this,
      this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.notSatir3 : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  IsTakipKartComponent_368022_onPressEnter = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      ),
      GenelIndirimTutar_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "istakipkart_368022_value", "value", "", "", "")
        ),
        "number"
      ),
      BasvuruTuru_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "istakipkart_765584_value", "value", "sbBasvuruTuruList", "id", "kod")
        ),
        "number"
      ),
      BasvuruFormuID_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      ),
      ServisUcretiVar_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "istakipkart_743413_value", "value", "", "", "")
        ),
        "boolean"
      ),
      Stopaj_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "istakipkart_168257_value", "value", "getListCari", "id", "satisStopaj")
        ),
        "number"
      ),
      SozlesmeID_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.getBasvuruFormuById?.length > 0 ? this.state.getBasvuruFormuById[0]?.sozlesmeID : null
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "IsTakipKart/IsTakipKartComponent_368022_onPressEnter",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.spUpdateGenelIndirimTutar = result?.data.spUpdateGenelIndirimTutar;
    stateVars.spDipToplamByBFId = result?.data.spDipToplamByBFId;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  IsTakipKartComponent_19843_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "istakipkart_353223_value", "value", "", "", "")
        ),
        null
      ),
      message: "!",
      formName: "istakipkart_353223_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "istakipkart_116506_value", "value", "sbVadeGunList", "id", "")
        ),
        null
      ),
      message: "!",
      formName: "istakipkart_116506_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      SatirAciklama_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "istakipkart_353223_value", "value", "", "", "")
        ),
        "string"
      ),
      VadeGunID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "istakipkart_116506_value", "value", "sbVadeGunList", "id", "id")
        ),
        "Guid"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "IsTakipKart/IsTakipKartComponent_19843_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.setLogoSatirVadeUpdate = result?.data.setLogoSatirVadeUpdate;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  IsTakipKartComponent_436915_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "istakipkart_353223_value", "value", "", "", "")
        ),
        null
      ),
      message: "!",
      formName: "istakipkart_353223_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "istakipkart_116506_value", "value", "sbVadeGunList", "id", "")
        ),
        null
      ),
      message: "!",
      formName: "istakipkart_116506_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      SatirAciklama_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "istakipkart_353223_value", "value", "", "", "")
        ),
        "string"
      ),
      VadeGunID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "istakipkart_116506_value", "value", "sbVadeGunList", "id", "id")
        ),
        "Guid"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "IsTakipKart/IsTakipKartComponent_436915_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.setLogoSatirVadeUpdate = result?.data.setLogoSatirVadeUpdate;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  IsTakipKartComponent_972677_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      NotSatir1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "istakipkart_624781_value", "value", "", "", "")
        ),
        "string"
      ),
      NotSatir2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "istakipkart_608425_value", "value", "", "", "")
        ),
        "string"
      ),
      NotSatir3_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "istakipkart_93784_value", "value", "", "", "")
        ),
        "string"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "IsTakipKart/IsTakipKartComponent_972677_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.setLogoFaturaAciklamaUpdate = result?.data.setLogoFaturaAciklamaUpdate;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  IsTakipKartComponent_969763_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    if (await this.IsTakipKartComponent_19843_onClick()) return true;

    if (await this.IsTakipKartComponent_436915_onClick()) return true;

    if (await this.IsTakipKartComponent_972677_onClick()) return true;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.IsTakipKartComponent_969763_onClick1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  IsTakipKartComponent_969763_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      BasvuruFormuId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "IsTakipKart/IsTakipKartComponent_969763_onClick1_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.spLogoAktarKontrol = result?.data.spLogoAktarKontrol;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.IsTakipKartComponent_969763_onClick2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  IsTakipKartComponent_969763_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "526e37fd_d574_8bee_396b_3446691ba69e_notify",
        this.defaultML,
        "Logo API ye bağlanıyor..."
      ),
      "info",
      "bottom-right",
      1,
      "",
      "",
      0
    );

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.IsTakipKartComponent_969763_onClick3_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  IsTakipKartComponent_969763_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      basvuruFormuId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "string"
      ),
      BasvuruFormuID_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "IsTakipKart/IsTakipKartComponent_969763_onClick3_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.LogoObjectAddSalesOrderFromLogoconnectapi = result?.data.logoObjectAddSalesOrderFromLogoconnectapi;
    stateVars.setQuarterDate = result?.data.setQuarterDate;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.IsTakipKartComponent_969763_onClick4_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  IsTakipKartComponent_969763_onClick4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.toString(this, this.state.LogoObjectAddSalesOrderFromLogoconnectapi?.returnMesaj),
      "info",
      "bottom-right",
      5,
      "",
      "",
      0
    );

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.IsTakipKartComponent_969763_onClick5_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  IsTakipKartComponent_969763_onClick5_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {};

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "IsTakipKart/IsTakipKartComponent_969763_onClick5_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.LogoObjectTigerLogoRemoteUpdateFromLogoconnectapi =
      result?.data.logoObjectTigerLogoRemoteUpdateFromLogoconnectapi;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.IsTakipKartComponent_969763_onClick6_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  IsTakipKartComponent_969763_onClick6_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  IsTakipKartComponent_449550_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    ReactSystemFunctions.setValueOf(
      this,
      "istakipkart_218026_value",
      ReactSystemFunctions.value(this, "istakipkart_449550_value", "value"),
      "disablebeforedate"
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  IsTakipKartComponent_218026_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    ReactSystemFunctions.setValueOf(
      this,
      "istakipkart_345176_value",
      ReactSystemFunctions.value(this, "istakipkart_218026_value", "value"),
      "disablebeforedate"
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [
      733845, 621069, 769334, 366826, 75984, 130718, 276007, 568048, 70755, 253929, 827710, 921294, 165788, 398595,
      678135
    ] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.IsTakipKartPageInit();
    }
    if (diId == 621069) {
      isErrorOccurred = await this.IsTakipKartComponent_927915_onClick1_();
      if (isErrorOccurred) return true;
    } else if (diId == 366826) {
      isErrorOccurred = await this.IsTakipKartComponent_817677_onClick1_();
      if (isErrorOccurred) return true;
    } else if (diId == 276007) {
      isErrorOccurred = await this.IsTakipKartComponent_363488_onClick1_();
      if (isErrorOccurred) return true;
    } else if (diId == 568048) {
      isErrorOccurred = await this.IsTakipKartComponent_934923_onClick1_();
      if (isErrorOccurred) return true;
    } else if (diId == 70755) {
      isErrorOccurred = await this.IsTakipKartComponent_685284_onClick1_();
      if (isErrorOccurred) return true;
    } else if (diId == 827710) {
      isErrorOccurred = await this.IsTakipKartComponent_451453_onClick1_();
      if (isErrorOccurred) return true;
    } else if (diId == 165788) {
      isErrorOccurred = await this.IsTakipKartComponent_202682_onClick1_();
      if (isErrorOccurred) return true;
    } else if (diId == 398595) {
      isErrorOccurred = await this.IsTakipKartComponent_691652_onClick2_();
      if (isErrorOccurred) return true;
    } else if (diId == 678135) {
      isErrorOccurred = await this.IsTakipKartComponent_781928_onClick1_();
      if (isErrorOccurred) return true;
    }
  }
}
