import { Form, Spin, Typography } from "antd";
import { connect } from "react-redux";
import {
  Checkbox,
  Header,
  HorizontalStack,
  Icon,
  Image,
  KCol,
  KDrawer,
  KPagination,
  KRow,
  KTable,
  KTableColumn,
  KTableHeader,
  KTableRow,
  Label,
  TextInput
} from "../../components/web-components";
import { KSelectBox } from "../../kuika";
import {
  hideIyzicoDrawer,
  hidePhotoTake,
  hideStripeDrawer,
  showIyzicoDrawer,
  showPhotoTake,
  showStripeDrawer
} from "../../redux/photo-take/actions";
import { AppState } from "../../redux/root-reducer";
import { withContext } from "../../shared/hoc/with-context";
import { withForm } from "../../shared/hoc/with-form";
import { withGoogleLogin } from "../../shared/hoc/with-google-login";
import { withHistory } from "../../shared/hoc/with-history";
import { withScreenInput } from "../../shared/hoc/with-screen-input";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";
import { FiyatlandirmaNotlarList_ScreenBase } from "./fiyatlandirmanotlarlist-base";

import { FiyatlandirmaNotlarKart_Screen } from "../fiyatlandirmanotlarkart/fiyatlandirmanotlarkart";
import { LeftMenu_Screen } from "../leftmenu/leftmenu";

declare let window: any;
const { Title, Paragraph, Text, Link } = Typography;
const menuMap = [];

class FiyatlandirmaNotlarList_Screen extends FiyatlandirmaNotlarList_ScreenBase {
  render() {
    if (this.state.isPageVisible === false) {
      return <></>;
    }
    return (
      <>
        <Spin spinning={this.state.spinnerCount > 0}>
          <Form
            form={this.props.form}
            name="basic"
            initialValues={{
              fiyatlandirmanotlarlist_703976_value: "283ac3c9-74b0-40c1-7273-08dbdfa0a8af",
              fiyatlandirmanotlarlist_666715_value:
                this.state.getFilterParametersByUserName?.at?.(0)?.fiyatlandirmaNotlarListSBox1 ?? undefined,
              fiyatlandirmanotlarlist_65965_value:
                this.state.getFilterParametersByUserName?.at?.(0)?.fiyatlandirmaNotlarListSBox2 ?? undefined,
              fiyatlandirmanotlarlist_725438_value: undefined
            }}
          >
            <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
              <Header
                id="fiyatlandirmanotlarlist_header"
                style={
                  {
                    borderStyle: "solid",
                    borderTopWidth: 0,
                    borderRightWidth: 0,
                    borderBottomWidth: 0,
                    borderLeftWidth: 0,
                    backgroundColor: "rgba(255, 255, 255, 1)",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                    backgroundPosition: "left"
                  } as any
                }
              >
                <KRow
                  id="726727"
                  align="top"
                  justify="start"
                  horizontalGutter={0}
                  verticalGutter={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "contain",
                      backgroundPosition: "left",
                      paddingTop: 16,
                      paddingBottom: 16,
                      alignItems: "center",
                      fontWeight: 500,
                      fontSize: "14px",
                      letterSpacing: "0.5px"
                    } as any
                  }
                >
                  <KCol
                    id="952559"
                    xs={24}
                    sm={8}
                    md={8}
                    lg={8}
                    xl={8}
                    xxl={8}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingRight: 16,
                        paddingLeft: 16,
                        fontWeight: 500,
                        fontSize: "14px",
                        letterSpacing: "0.5px"
                      } as any
                    }
                  >
                    <HorizontalStack
                      id="105995"
                      direction="horizontal"
                      size={2}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 0,
                          paddingRight: 0,
                          paddingBottom: 0,
                          paddingLeft: 0,
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "14px",
                          color: "rgba(68, 68, 68, 1)",
                          letterSpacing: "0.5px"
                        } as any
                      }
                    >
                      <Icon
                        id="798391"
                        onClick={(e?: any) => {
                          if (e && e.stopPropagation) e.stopPropagation();
                          this.FiyatlandirmaNotlarListComponent_798391_onClick();
                        }}
                        showCursorPointer
                        iconName="menu"
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "left",
                            fontSize: "24px",
                            color: "rgba(172, 38, 42, 1)"
                          } as any
                        }
                      ></Icon>

                      <Form.Item className="kFormItem" name="fiyatlandirmanotlarlist_703976_value">
                        <Image
                          id="703976"
                          placeHolderImage="data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAASABIAAD/7QA4UGhvdG9zaG9wIDMuMAA4QklNBAQAAAAAAAA4QklNBCUAAAAAABDUHYzZjwCyBOmACZjs+EJ+/9sAQwABAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEB/8AACwgANgBgAQERAP/EABkAAQADAQEAAAAAAAAAAAAAAAAEBQYHCv/EACoQAAEEAgMBAAADCQAAAAAAAAIAAwQFAQYREhMUByEiJDEzNjd1drS1/9oACAEBAAA/APWAiIiIiIiIiIiIiIiIiIqTZrU6HXNgvGmQkO01Ja2rbDhZAHzroD8wGTMeSAHCawBEOM5HBFnHOccKmbpdyyAZe3Zv2yI+vhrVeDHpxjv4g9JfdBrtz5i6+84IcYN1wsZMsq9c25FeRqnf4064oIdhNODN1IW4EvNSWG5zDcttyCE9qLKy3XWjlRPecqpb4MTMsysgyfUq6X99fAnefl9sOLL8u3fz+lgHvPv1Ht079e3Ue3HPGOeFMRERERY78RP6f71/h2z/APEnKlnWG4VVfI2+xkw40SL3lS9Od+BtqPTYHH6BvCHDh7QP8XqUrNG88WKdoBzlu7Wcfk1dY1d3kzU5Ott7TWXLNbbS7CU/jD9jg5BRbKnex8unzdkl4ZneMbsNrPFlu5dZvMx4jvVdc/l6h/s1X/osK5RERERV1xVx7uotKWWTgxbeunVcomSEXhjz4zsR4miITEXMNullssgY4LjORLGM4WAk6hu8+XVS7LctcsBpwA40OTo9h8Dtk3x53UuNG3mOMiyaxgvm7fsMNwykQ4LEkQfCxs9e3O6rp1RZbLqxV9nFfgzcRdHsG5XzSWyadzGcm7xZQ25AgWSYckwJjLbuANyM8I5AtvEjNQosaGx28IkdmMz2z2LyYbFpvsXGOxdAxznGMc5/PjH7lIREREREREREREREREREX//Z"
                          zoomOnClick={false}
                          imageFit="fit"
                          style={
                            {
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingLeft: 16,
                              height: "24px"
                            } as any
                          }
                        ></Image>
                      </Form.Item>
                    </HorizontalStack>

                    <Label
                      id="237712"
                      value={ReactSystemFunctions.getMyUsername()}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "contain",
                          backgroundPosition: "left",
                          paddingTop: 8,
                          paddingLeft: 42,
                          display: "block",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "12px",
                          color: "rgba(155, 155, 155, 1)",
                          letterSpacing: "0.5px"
                        } as any
                      }
                    ></Label>
                  </KCol>

                  <KCol
                    id="798104"
                    xs={24}
                    sm={8}
                    md={8}
                    lg={8}
                    xl={8}
                    xxl={8}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "contain",
                        backgroundPosition: "left",
                        paddingRight: 16,
                        paddingLeft: 16,
                        alignItems: "center",
                        textAlign: "-webkit-center",
                        fontWeight: 500,
                        fontSize: "14px",
                        letterSpacing: "0.5px"
                      } as any
                    }
                  >
                    <Label
                      id="513636"
                      value={ReactSystemFunctions.translate(this.ml, 513636, "value", this.defaultML)}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "contain",
                          backgroundPosition: "left",
                          fontFamily: "Poppins",
                          fontWeight: 600,
                          fontSize: "18px",
                          color: "rgba(183, 14, 14, 1)"
                        } as any
                      }
                    ></Label>
                  </KCol>

                  <KCol
                    id="876439"
                    xs={24}
                    sm={8}
                    md={8}
                    lg={8}
                    xl={8}
                    xxl={8}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "contain",
                        backgroundPosition: "left",
                        paddingRight: 16,
                        paddingLeft: 16,
                        textAlign: "-webkit-right",
                        fontWeight: 500,
                        fontSize: "14px",
                        letterSpacing: "0.5px"
                      } as any
                    }
                  >
                    <Icon
                      id="102318"
                      onClick={(e?: any) => {
                        if (e && e.stopPropagation) e.stopPropagation();
                        this.FiyatlandirmaNotlarListComponent_102318_onClick();
                      }}
                      showCursorPointer
                      iconName="add_circle"
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 0,
                          paddingRight: 0,
                          paddingBottom: 0,
                          paddingLeft: 0,
                          fontSize: "24px",
                          color: "rgba(172, 38, 42, 1)"
                        } as any
                      }
                    ></Icon>
                  </KCol>
                </KRow>
              </Header>

              <div
                id="fiyatlandirmanotlarlist_body"
                style={
                  {
                    borderStyle: "solid",
                    borderTopWidth: 0,
                    borderRightWidth: 0,
                    borderBottomWidth: 0,
                    borderLeftWidth: 0,
                    backgroundColor: "rgba(255, 255, 255, 1)",
                    backgroundImage:
                      "url(" +
                      KuikaAppManager.GetBackendUrl() +
                      "/resource/runtime/byid?resourceId=31886a70-856c-4afc-8e82-08da211d182d)",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    display: "block"
                  } as any
                }
              >
                <KRow
                  id="359459"
                  align="top"
                  justify="start"
                  horizontalGutter={0}
                  verticalGutter={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "contain",
                      backgroundPosition: "left",
                      paddingTop: 32,
                      paddingRight: 48,
                      paddingBottom: 16,
                      paddingLeft: 48,
                      fontWeight: 500,
                      fontSize: "14px",
                      letterSpacing: "0.5px"
                    } as any
                  }
                >
                  <KCol
                    id="25048"
                    xs={8}
                    sm={8}
                    md={8}
                    lg={8}
                    xl={8}
                    xxl={8}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingRight: 16,
                        paddingLeft: 16,
                        fontWeight: 500,
                        fontSize: "14px",
                        letterSpacing: "0.5px"
                      } as any
                    }
                  >
                    <Form.Item className="kFormItem" name="fiyatlandirmanotlarlist_666715_value">
                      <KSelectBox
                        id="666715"
                        onChange={(e?: any) => {
                          if (e && e.stopPropagation) e.stopPropagation();
                          this.FiyatlandirmaNotlarListComponent_666715_onChange();
                        }}
                        kuikaRef={this.fiyatlandirmanotlarlist_666715_value_kuikaSelectBoxRef}
                        options={this.state.sbBasvuruTuruList}
                        placeholder={ReactSystemFunctions.translate(this.ml, 666715, "placeholder", this.defaultML)}
                        allowClear={true}
                        autoClearSearchValue={true}
                        showSearch={false}
                        onSearch={{}}
                        widthType="fill"
                        containsNullItem={false}
                        sortBy="none"
                        datavaluefield="id"
                        datatextfield="tanim"
                        style={
                          {
                            borderBottomWidth: 1,
                            borderColor: "rgba(155, 155, 155, 1)",
                            borderStyle: "dotted",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderLeftWidth: 0,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "left",
                            height: "36px",
                            display: "block",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "12px",
                            color: "rgba(47, 47, 47, 1)",
                            letterSpacing: "0.5px"
                          } as any
                        }
                      ></KSelectBox>
                    </Form.Item>
                  </KCol>

                  <KCol
                    id="609432"
                    xs={8}
                    sm={8}
                    md={8}
                    lg={8}
                    xl={8}
                    xxl={8}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "contain",
                        backgroundPosition: "left",
                        paddingRight: 16,
                        paddingLeft: 0,
                        alignItems: "center",
                        textAlign: "-webkit-left",
                        fontWeight: 500,
                        fontSize: "14px",
                        letterSpacing: "0.5px"
                      } as any
                    }
                  >
                    <Form.Item className="kFormItem" name="fiyatlandirmanotlarlist_65965_value">
                      <KSelectBox
                        id="65965"
                        onChange={(e?: any) => {
                          if (e && e.stopPropagation) e.stopPropagation();
                          this.FiyatlandirmaNotlarListComponent_65965_onChange();
                        }}
                        kuikaRef={this.fiyatlandirmanotlarlist_65965_value_kuikaSelectBoxRef}
                        options={this.state.spGetProjeTuruByBTuruKod}
                        placeholder={ReactSystemFunctions.translate(this.ml, 65965, "placeholder", this.defaultML)}
                        allowClear={true}
                        autoClearSearchValue={true}
                        showSearch={false}
                        onSearch={{}}
                        widthType="fill"
                        containsNullItem={false}
                        sortBy="none"
                        datavaluefield="id"
                        datatextfield="tanim"
                        style={
                          {
                            borderBottomWidth: 1,
                            borderColor: "rgba(155, 155, 155, 1)",
                            borderStyle: "dotted",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderLeftWidth: 0,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "left",
                            height: "36px",
                            display: "block",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "12px",
                            color: "rgba(47, 47, 47, 1)",
                            letterSpacing: "0.5px"
                          } as any
                        }
                      ></KSelectBox>
                    </Form.Item>
                  </KCol>

                  <KCol
                    id="773161"
                    xs={8}
                    sm={8}
                    md={8}
                    lg={8}
                    xl={8}
                    xxl={8}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "contain",
                        backgroundPosition: "left",
                        alignItems: "center",
                        fontWeight: 500,
                        fontSize: "14px",
                        letterSpacing: "0.5px"
                      } as any
                    }
                  >
                    <Form.Item className="kFormItem" name="fiyatlandirmanotlarlist_725438_value">
                      <TextInput
                        id="725438"
                        onChange={(e?: any) => {
                          if (e && e.stopPropagation) e.stopPropagation();
                          this.FiyatlandirmaNotlarListComponent_725438_onChange();
                        }}
                        visibility={this.state.isComp725438Visible}
                        placeholder={ReactSystemFunctions.translate(this.ml, 725438, "placeholder", this.defaultML)}
                        allowClear={false}
                        bordered={true}
                        disabled={false}
                        type="text"
                        iconColor="rgba(0, 0, 0, 1)"
                        formatter="None"
                        isCharOnly={false}
                        autoComplete={true}
                        style={
                          {
                            borderBottomWidth: 1,
                            borderColor: "rgba(155, 155, 155, 1)",
                            borderStyle: "dotted",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderLeftWidth: 0,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "left",
                            height: "36px",
                            maxHeight: "32px",
                            display: "block",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "12px",
                            color: "rgba(47, 47, 47, 1)",
                            letterSpacing: "0.5px"
                          } as any
                        }
                      ></TextInput>
                    </Form.Item>
                  </KCol>
                </KRow>

                <KRow
                  id="36573"
                  align="top"
                  justify="start"
                  horizontalGutter={0}
                  verticalGutter={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "contain",
                      backgroundPosition: "left",
                      paddingTop: 32,
                      paddingRight: 48,
                      paddingBottom: 16,
                      paddingLeft: 48,
                      fontWeight: 500,
                      fontSize: "14px",
                      letterSpacing: "0.5px"
                    } as any
                  }
                >
                  <KCol
                    id="862677"
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={24}
                    xxl={24}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderTopLeftRadius: 12,
                        borderTopRightRadius: 12,
                        borderBottomRightRadius: 12,
                        borderBottomLeftRadius: 12,
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        backgroundColor: "rgba(255, 255, 255, 1)",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "contain",
                        backgroundPosition: "left",
                        paddingTop: null,
                        paddingRight: 16,
                        paddingBottom: 16,
                        paddingLeft: 16,
                        alignItems: "center",
                        textAlign: "-webkit-right",
                        fontWeight: 500,
                        fontSize: "14px",
                        letterSpacing: "0.5px"
                      } as any
                    }
                  >
                    <KRow
                      id="997064"
                      align="top"
                      justify="start"
                      horizontalGutter={0}
                      verticalGutter={0}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 8,
                          paddingBottom: 8,
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "12px",
                          color: "rgba(155, 155, 155, 1)",
                          letterSpacing: "0.5px"
                        } as any
                      }
                    >
                      <KCol
                        id="437264"
                        xs={3}
                        sm={3}
                        md={3}
                        lg={3}
                        xl={3}
                        xxl={3}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(68, 68, 68, 1)",
                            letterSpacing: "0.5px"
                          } as any
                        }
                      >
                        <Label
                          id="953507"
                          value={ReactSystemFunctions.translate(this.ml, 953507, "value", this.defaultML)}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "12px",
                              color: "rgba(155, 155, 155, 1)",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        ></Label>
                      </KCol>

                      <KCol
                        id="771716"
                        xs={3}
                        sm={3}
                        md={3}
                        lg={3}
                        xl={3}
                        xxl={3}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(68, 68, 68, 1)",
                            letterSpacing: "0.5px"
                          } as any
                        }
                      >
                        <Label
                          id="15638"
                          value={ReactSystemFunctions.translate(this.ml, 15638, "value", this.defaultML)}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "12px",
                              color: "rgba(155, 155, 155, 1)",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        ></Label>
                      </KCol>

                      <KCol
                        id="866229"
                        xs={2}
                        sm={2}
                        md={2}
                        lg={2}
                        xl={2}
                        xxl={2}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            alignItems: "center",
                            textAlign: "-webkit-center",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(68, 68, 68, 1)",
                            letterSpacing: "0.5px"
                          } as any
                        }
                      >
                        <Label
                          id="198729"
                          value={ReactSystemFunctions.translate(this.ml, 198729, "value", this.defaultML)}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "12px",
                              color: "rgba(155, 155, 155, 1)",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        ></Label>
                      </KCol>

                      <KCol
                        id="274464"
                        xs={2}
                        sm={2}
                        md={2}
                        lg={2}
                        xl={2}
                        xxl={2}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            alignItems: "center",
                            textAlign: "-webkit-center",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(68, 68, 68, 1)",
                            letterSpacing: "0.5px"
                          } as any
                        }
                      >
                        <Label
                          id="976347"
                          value={ReactSystemFunctions.translate(this.ml, 976347, "value", this.defaultML)}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "12px",
                              color: "rgba(155, 155, 155, 1)",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        ></Label>
                      </KCol>

                      <KCol
                        id="269621"
                        xs={10}
                        sm={10}
                        md={10}
                        lg={10}
                        xl={10}
                        xxl={10}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(68, 68, 68, 1)",
                            letterSpacing: "0.5px"
                          } as any
                        }
                      >
                        <Label
                          id="742249"
                          value={ReactSystemFunctions.translate(this.ml, 742249, "value", this.defaultML)}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "12px",
                              color: "rgba(155, 155, 155, 1)",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        ></Label>
                      </KCol>

                      <KCol
                        id="139508"
                        xs={2}
                        sm={2}
                        md={2}
                        lg={2}
                        xl={2}
                        xxl={2}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            alignItems: "center",
                            textAlign: "-webkit-center",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(68, 68, 68, 1)",
                            letterSpacing: "0.5px"
                          } as any
                        }
                      >
                        <Label
                          id="693169"
                          value={ReactSystemFunctions.translate(this.ml, 693169, "value", this.defaultML)}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "12px",
                              color: "rgba(155, 155, 155, 1)",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        ></Label>
                      </KCol>

                      <KCol
                        id="399028"
                        xs={1}
                        sm={1}
                        md={1}
                        lg={1}
                        xl={1}
                        xxl={1}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(68, 68, 68, 1)",
                            letterSpacing: "0.5px"
                          } as any
                        }
                      ></KCol>

                      <KCol
                        id="796804"
                        xs={1}
                        sm={1}
                        md={1}
                        lg={1}
                        xl={1}
                        xxl={1}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            alignItems: "center",
                            textAlign: "-webkit-center",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(68, 68, 68, 1)",
                            letterSpacing: "0.5px"
                          } as any
                        }
                      ></KCol>
                    </KRow>

                    <KRow
                      id="766111"
                      align="top"
                      justify="start"
                      horizontalGutter={0}
                      verticalGutter={0}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "14px",
                          color: "rgba(68, 68, 68, 1)",
                          letterSpacing: "0.5px"
                        } as any
                      }
                    >
                      <KCol
                        id="641085"
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={24}
                        xxl={24}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(68, 68, 68, 1)",
                            letterSpacing: "0.5px"
                          } as any
                        }
                      >
                        <KTable
                          id="181000"
                          kuikaRef={this.fiyatlandirmanotlarlist_181000_value_kuikaTableRef}
                          form={this.props.form}
                          dataSource={this.state.spFiyatlandirmaNotlarList}
                          size="middle"
                          bordered={true}
                          loading={false}
                          transformedOnMobileResolution={false}
                          showHeader={false}
                          fixedHeader={false}
                          editable={false}
                          tableWidthMode="fit"
                          globalSearch={false}
                          searchable={false}
                          sorter={false}
                          filtering={false}
                          pagination={false}
                          pageSize={10}
                          showSizeChanger={false}
                          columnChooser={false}
                          resizableColumns={false}
                          striped={false}
                          stripedColor="#F5F7FA"
                          insertRowActive={false}
                          insertRowPosition="top"
                          showNoDataFound={true}
                          nodatafoundmessage={ReactSystemFunctions.translate(
                            this.ml,
                            181000,
                            "nodatafoundmessage",
                            this.defaultML
                          )}
                          multiSelect={false}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(155, 155, 155, 1)",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KTableHeader
                            id="394325"
                            hideOnMobileResolution={false}
                            textDirection="Default"
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "12px",
                                color: "rgba(155, 155, 155, 1)",
                                letterSpacing: "0.5px",
                                textTransform: "uppercase"
                              } as any
                            }
                          ></KTableHeader>

                          <KTableRow
                            id="935419"
                            hoverFontColor="red"
                            hoverBgColor="rgba(172, 38, 42, 0.08)"
                            style={
                              {
                                borderTopLeftRadius: 0,
                                borderTopRightRadius: 0,
                                borderBottomRightRadius: 0,
                                borderBottomLeftRadius: 0,
                                borderTopWidth: 1,
                                borderColor: "rgba(204, 204, 204, 1)",
                                borderStyle: "solid",
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <KTableColumn
                              id="295011"
                              title={ReactSystemFunctions.translate(this.ml, 295011, "title", this.defaultML)}
                              alwaysVisibleOnMobileResolution={false}
                              hideFiltering={false}
                              hideSorting={false}
                              hideSearch={false}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            >
                              <KRow
                                id="562842"
                                align="top"
                                justify="start"
                                horizontalGutter={0}
                                verticalGutter={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 0,
                                    paddingLeft: 0,
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <KCol
                                  id="857593"
                                  xs={3}
                                  sm={3}
                                  md={3}
                                  lg={3}
                                  xl={3}
                                  xxl={3}
                                  order={0}
                                  pull={0}
                                  push={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 8,
                                      paddingBottom: 8,
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                >
                                  <Label
                                    id="245227"
                                    value="[datafield:btTanim]"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 0,
                                        paddingLeft: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "block",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(47, 47, 47, 1)",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  ></Label>
                                </KCol>

                                <KCol
                                  id="930580"
                                  xs={3}
                                  sm={3}
                                  md={3}
                                  lg={3}
                                  xl={3}
                                  xxl={3}
                                  order={0}
                                  pull={0}
                                  push={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 8,
                                      paddingBottom: 8,
                                      alignItems: "center",
                                      textAlign: "-webkit-center",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                >
                                  <Label
                                    id="204556"
                                    value="[datafield:tanim]"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 0,
                                        paddingLeft: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "block",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(47, 47, 47, 1)",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  ></Label>
                                </KCol>

                                <KCol
                                  id="358922"
                                  xs={2}
                                  sm={2}
                                  md={2}
                                  lg={2}
                                  xl={2}
                                  xxl={2}
                                  order={0}
                                  pull={0}
                                  push={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 8,
                                      paddingBottom: 8,
                                      alignItems: "center",
                                      textAlign: "-webkit-center",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                >
                                  <Form.Item className="kFormItem" name="fiyatlandirmanotlarlist_888724_value">
                                    <Checkbox
                                      id="888724"
                                      children={ReactSystemFunctions.translate(
                                        this.ml,
                                        888724,
                                        "children",
                                        this.defaultML
                                      )}
                                      disabled={false}
                                      value="[datafield:onemli]"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 0,
                                          paddingLeft: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-center",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(208, 2, 27, 1)",
                                          letterSpacing: "0.5px"
                                        } as any
                                      }
                                    ></Checkbox>
                                  </Form.Item>
                                </KCol>

                                <KCol
                                  id="71267"
                                  xs={2}
                                  sm={2}
                                  md={2}
                                  lg={2}
                                  xl={2}
                                  xxl={2}
                                  order={0}
                                  pull={0}
                                  push={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 8,
                                      paddingBottom: 8,
                                      alignItems: "center",
                                      textAlign: "-webkit-center",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                >
                                  <Form.Item className="kFormItem" name="fiyatlandirmanotlarlist_213578_value">
                                    <Checkbox
                                      id="213578"
                                      children={ReactSystemFunctions.translate(
                                        this.ml,
                                        213578,
                                        "children",
                                        this.defaultML
                                      )}
                                      disabled={false}
                                      value="[datafield:baslik]"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 0,
                                          paddingLeft: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-center",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(68, 68, 68, 1)",
                                          letterSpacing: "0.5px"
                                        } as any
                                      }
                                    ></Checkbox>
                                  </Form.Item>
                                </KCol>

                                <KCol
                                  id="44371"
                                  xs={10}
                                  sm={10}
                                  md={10}
                                  lg={10}
                                  xl={10}
                                  xxl={10}
                                  order={0}
                                  pull={0}
                                  push={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 8,
                                      paddingBottom: 8,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                >
                                  <Label
                                    id="756239"
                                    value="[datafield:aciklama]"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 0,
                                        paddingLeft: 0,
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(47, 47, 47, 1)",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  ></Label>
                                </KCol>

                                <KCol
                                  id="246451"
                                  xs={2}
                                  sm={2}
                                  md={2}
                                  lg={2}
                                  xl={2}
                                  xxl={2}
                                  order={0}
                                  pull={0}
                                  push={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 8,
                                      paddingBottom: 8,
                                      alignItems: "center",
                                      textAlign: "-webkit-center",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                >
                                  <Label
                                    id="672320"
                                    value="[datafield:orderBy]"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 0,
                                        paddingLeft: 0,
                                        textAlign: "-webkit-center",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(47, 47, 47, 1)",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  ></Label>
                                </KCol>

                                <KCol
                                  id="255879"
                                  xs={1}
                                  sm={1}
                                  md={1}
                                  lg={1}
                                  xl={1}
                                  xxl={1}
                                  order={0}
                                  pull={0}
                                  push={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 8,
                                      paddingBottom: 8,
                                      alignItems: "center",
                                      textAlign: "-webkit-right",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                >
                                  <Icon
                                    id="605729"
                                    onClick={(e?: any) => {
                                      if (e && e.stopPropagation) e.stopPropagation();
                                      this.FiyatlandirmaNotlarListComponent_605729_onClick();
                                    }}
                                    showCursorPointer
                                    iconName="delete"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 0,
                                        paddingLeft: 0,
                                        fontSize: "24px",
                                        color: "rgba(172, 38, 42, 1)"
                                      } as any
                                    }
                                  ></Icon>
                                </KCol>

                                <KCol
                                  id="1017"
                                  xs={1}
                                  sm={1}
                                  md={1}
                                  lg={1}
                                  xl={1}
                                  xxl={1}
                                  order={0}
                                  pull={0}
                                  push={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 8,
                                      paddingRight: null,
                                      paddingBottom: 8,
                                      alignItems: "center",
                                      textAlign: "-webkit-right",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                >
                                  <Icon
                                    id="209203"
                                    onClick={(e?: any) => {
                                      if (e && e.stopPropagation) e.stopPropagation();
                                      this.FiyatlandirmaNotlarListComponent_209203_onClick();
                                    }}
                                    showCursorPointer
                                    iconName="visibility"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 0,
                                        paddingLeft: 0,
                                        fontSize: "24px",
                                        color: "rgba(172, 38, 42, 1)"
                                      } as any
                                    }
                                  ></Icon>
                                </KCol>
                              </KRow>
                            </KTableColumn>
                          </KTableRow>
                        </KTable>
                      </KCol>
                    </KRow>

                    <KPagination
                      id="775365"
                      visibility={this.state.isComp775365Visible}
                      kuikaRef={this.fiyatlandirmanotlarlist_775365_value_kuikaPaginationRef}
                      total={1000}
                      pageSize={20}
                      defaultCurrent={1}
                      current={1}
                      defaultPageSize={10}
                      disabled={false}
                      hideOnSinglePage={true}
                      showLessItems={false}
                      showQuickJumper={false}
                      showSizeChanger={true}
                      showFirstPageButton={false}
                      showLastPageButton={false}
                      showNumbers={true}
                      showText={false}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 8,
                          paddingBottom: 8,
                          alignItems: "center",
                          textAlign: "-webkit-right",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "14px",
                          color: "rgba(68, 68, 68, 1)",
                          letterSpacing: "0.5px"
                        } as any
                      }
                    ></KPagination>
                  </KCol>
                </KRow>

                <KDrawer
                  id="294787"
                  placement="left"
                  onClose={() => {
                    this.setState({ NavVisible294787: false }, () => {
                      this.callCallbackFunction(294787);
                    });
                  }}
                  visible={this.state.NavVisible294787}
                  push={true}
                  children={
                    this.state.NavVisible294787 && (
                      <LeftMenu_Screen
                        onClose={() => {
                          this.setState({ NavVisible294787: false }, () => {
                            this.callCallbackFunction(294787);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                      />
                    )
                  }
                  style={{} as any}
                ></KDrawer>

                <KDrawer
                  id="60214"
                  placement="right"
                  onClose={() => {
                    this.setState({ NavVisible60214: false }, () => {
                      this.callCallbackFunction(60214);
                    });
                  }}
                  visible={this.state.NavVisible60214}
                  width="480px"
                  height="100vh"
                  push={true}
                  children={
                    this.state.NavVisible60214 && (
                      <FiyatlandirmaNotlarKart_Screen
                        onClose={() => {
                          this.setState({ NavVisible60214: false }, () => {
                            this.callCallbackFunction(60214);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                      />
                    )
                  }
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 0,
                      paddingRight: 0,
                      paddingBottom: 0,
                      paddingLeft: 0,
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(68, 68, 68, 1)",
                      letterSpacing: "0.5px"
                    } as any
                  }
                ></KDrawer>

                <KDrawer
                  id="727043"
                  placement="right"
                  onClose={() => {
                    this.setState({ NavVisible727043: false }, () => {
                      this.callCallbackFunction(727043);
                    });
                  }}
                  visible={this.state.NavVisible727043}
                  width="480px"
                  height="100vh"
                  push={true}
                  children={
                    this.state.NavVisible727043 && (
                      <FiyatlandirmaNotlarKart_Screen
                        onClose={() => {
                          this.setState({ NavVisible727043: false }, () => {
                            this.callCallbackFunction(727043);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                      />
                    )
                  }
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 0,
                      paddingRight: 0,
                      paddingBottom: 0,
                      paddingLeft: 0,
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(68, 68, 68, 1)",
                      letterSpacing: "0.5px"
                    } as any
                  }
                ></KDrawer>
              </div>
            </div>
          </Form>
        </Spin>
      </>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  return {
    spinnerCount: state.spinnerReducer.spinnerCount,
    photoTakeVisible: state.photoTakeReducer.visible
  };
};
const mapDispatchToProps = (dispatch) => ({
  showPhotoTake: () => dispatch(showPhotoTake()),
  hidePhotoTake: () => dispatch(hidePhotoTake()),
  showStripeDrawer: (data, callback, that, future) => dispatch(showStripeDrawer(data, callback, that, future)),
  hideStripeDrawer: (data, callback, that) => dispatch(hideStripeDrawer(data, callback, that)),
  showIyzicoDrawer: (data, callback, that) => dispatch(showIyzicoDrawer(data, callback, that)),
  hideIyzicoDrawer: (data, callback, that) => dispatch(hideIyzicoDrawer(data, callback, that))
});
const tmp = withGoogleLogin(
  withContext(
    withScreenInput(withForm(withHistory(connect(mapStateToProps, mapDispatchToProps)(FiyatlandirmaNotlarList_Screen))))
  )
);
export { tmp as FiyatlandirmaNotlarList_Screen };
//export default tmp;
//export { tmp as FiyatlandirmaNotlarList_Screen };
