import { FormInstance } from "antd/lib/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IFaturaList_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IFaturaList_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  getUserInfo: any[];
  getUserInfo_dummy: any[];
  getFilterParametersByUserName: any[];
  getFilterParametersByUserName_dummy: any[];
  SetValueOf: any;
  SetValueOf_dummy: any;
  FilterParametersSaveSiparisList: number;
  FilterParametersSaveSiparisList_dummy: number;
  spFFaturaList: any[];
  spFFaturaList_dummy: any[];
  spFFaturaListCount: any[];
  spFFaturaListCount_dummy: any[];
  isComp945777Visible: "visible" | "hidden";
  isComp578618Visible: "visible" | "hidden";
}

export class FaturaList_ScreenBase extends React.PureComponent<IFaturaList_ScreenProps, any> {
  faturalist_672411_value_kuikaDatePickerRef: React.RefObject<any>;
  faturalist_166484_value_kuikaSelectBoxRef: React.RefObject<any>;
  faturalist_660947_value_kuikaTableRef: React.RefObject<any>;
  faturalist_443704_value_kuikaPaginationRef: React.RefObject<any>;
  ml = [
    {
      Id: "16c151eb-42cf-0b2b-7e4a-e8b04fc43d88",
      Name: "tr_TR",
      ShortName: "Turkish",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "fbdbce9f-3fdb-30c5-c4fe-f7e443068251", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    { Id: "8468043e-f025-01e1-93b8-c71e3215778a", Name: "de_DE", ShortName: "German", LanguagePhrases: [] },
    {
      Id: "655404af-b6b5-4285-9d18-0d9a5ce5e987",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 625521, PropertyName: "value", Value: "FATURA TAKİBİ (Farklı Tutar)" },
        { Id: 672411, PropertyName: "startDatePlaceholder", Value: "Fatura Tarihi Başlangıç" },
        { Id: 672411, PropertyName: "endDatePlaceholder", Value: "Bitiş" },
        { Id: 166484, PropertyName: "placeholder", Value: "Ödeme Bilgisi Seçiniz" },
        { Id: 720564, PropertyName: "placeholder", Value: "Aranacak kelimeyi yazınız..." },
        { Id: 660947, PropertyName: "nodatafoundmessage", Value: "Veri Bulunamadı" },
        { Id: 954267, PropertyName: "title", Value: "İş No" },
        { Id: 745916, PropertyName: "value", Value: "[datafield:ticketno]" },
        { Id: 243187, PropertyName: "title", Value: "İlgili Kişi" },
        { Id: 231859, PropertyName: "value", Value: "[datafield:nick]" },
        { Id: 798471, PropertyName: "title", Value: "Fatura Tarihi" },
        { Id: 210262, PropertyName: "value", Value: "[datafield:logofaturatarihi]" },
        { Id: 468136, PropertyName: "title", Value: "Başvuru Türü" },
        { Id: 408011, PropertyName: "value", Value: "[datafield:basvuruturu]" },
        { Id: 885016, PropertyName: "title", Value: "Proje Türü" },
        { Id: 352743, PropertyName: "value", Value: "[datafield:projeturu]" },
        { Id: 213343, PropertyName: "title", Value: "Proje Adı" },
        { Id: 25124, PropertyName: "value", Value: "[datafield:projead]" },
        { Id: 352211, PropertyName: "title", Value: "Başvuru Sahibi" },
        { Id: 726097, PropertyName: "value", Value: "[datafield:adsoyad]" },
        { Id: 238433, PropertyName: "title", Value: "Fatura Düzenlenecek Firma" },
        { Id: 535875, PropertyName: "value", Value: "[datafield:cariad]" },
        { Id: 945777, PropertyName: "title", Value: "Durum" },
        { Id: 885610, PropertyName: "value", Value: "[datafield:formstatu]" },
        { Id: 152799, PropertyName: "title", Value: "Sipariş No" },
        { Id: 469334, PropertyName: "value", Value: "[datafield:erpsiparisno]" },
        { Id: 869085, PropertyName: "title", Value: "Fatura No" },
        { Id: 993439, PropertyName: "value", Value: "[datafield:logofaturano]" },
        { Id: 783244, PropertyName: "title", Value: "Alo Muzikotek" },
        { Id: 790201, PropertyName: "value", Value: "[datafield:dipgeneltoplam]" },
        { Id: 707955, PropertyName: "title", Value: "Logo" },
        { Id: 820721, PropertyName: "value", Value: "[datafield:nettotal]" }
      ]
    }
  ];
  defaultML = "tr_TR";
  screenHash = "";

  constructor(props) {
    super(props);
    this.faturalist_672411_value_kuikaDatePickerRef = React.createRef();
    this.faturalist_166484_value_kuikaSelectBoxRef = React.createRef();
    this.faturalist_660947_value_kuikaTableRef = React.createRef();
    this.faturalist_443704_value_kuikaPaginationRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      NAVIGATE: "",
      getUserInfo: [],
      getFilterParametersByUserName: [],
      SetValueOf: "",
      FilterParametersSaveSiparisList: "",
      spFFaturaList: [],
      spFFaturaListCount: [],
      dataSource_166484: [
        { key: "ALINDI", text: "ALINDI" },
        { key: "ALINMADI", text: "ALINMADI" }
      ],
      isComp945777Visible: "hidden",
      isComp578618Visible: "hidden"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("faturalist", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.FaturaListPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("faturalist", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("faturalist", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.FaturaListPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      faturalist_720564_value: this.state.getFilterParametersByUserName?.at?.(0)?.siparisListSearch ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  FaturaListPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {};

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "FaturaList/FaturaListPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.getUserInfo = result?.data.getUserInfo;
    stateVars.getFilterParametersByUserName = result?.data.getFilterParametersByUserName;
    formVars.faturalist_720564_value = ReactSystemFunctions.toString(
      this,
      stateVars.getFilterParametersByUserName?.length > 0
        ? stateVars.getFilterParametersByUserName[0]?.siparisListSearch
        : null
    );
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.FaturaListPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  FaturaListPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "faturalist_720564_value",
      ReactSystemFunctions.toString(
        this,
        this.state.getFilterParametersByUserName?.length > 0
          ? this.state.getFilterParametersByUserName[0]?.siparisListSearch
          : null
      ),
      null
    );
    formVars.faturalist_720564_value = ReactSystemFunctions.toString(
      this,
      this.state.getFilterParametersByUserName?.length > 0
        ? this.state.getFilterParametersByUserName[0]?.siparisListSearch
        : null
    );

    stateVars.isComp578618Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "faturalist_660947_value", "isEditButtonVisibility"),
        "1"
      ) === true
        ? "visible"
        : "hidden";

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  FaturaListComponent_653137_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "FaturaList",
      "LeftMenu",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "464411",
      "",
      "left",
      null,
      "",
      "",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  FaturaListComponent_672411_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      SiparisListSearch_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "faturalist_720564_value", "value", "", "", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "FaturaList/FaturaListComponent_672411_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.FilterParametersSaveSiparisList = result?.data.filterParametersSaveSiparisList;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  FaturaListComponent_166484_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      SiparisListSearch_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "faturalist_720564_value", "value", "", "", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "FaturaList/FaturaListComponent_166484_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.FilterParametersSaveSiparisList = result?.data.filterParametersSaveSiparisList;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  FaturaListComponent_720564_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      SiparisListSearch_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "faturalist_720564_value", "value", "", "", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "FaturaList/FaturaListComponent_720564_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.FilterParametersSaveSiparisList = result?.data.filterParametersSaveSiparisList;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  FaturaListComponent_225661_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Rol_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.getUserInfo?.length > 0 ? this.state.getUserInfo[0]?.rolName : null
        ),
        "string"
      ),
      Search_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "faturalist_720564_value", "value", "", "", "")
        ),
        "string"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "faturalist_443704_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "faturalist_443704_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      OdemeBilgi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "faturalist_166484_value", "value", "", "key", "")
        ),
        "string"
      ),
      BasT_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "faturalist_672411_value", "startValue", "", "", "")
        ),
        "Date"
      ),
      BitT_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "faturalist_672411_value", "endValue", "", "", "")
        ),
        "Date"
      ),
      Rol_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.getUserInfo?.length > 0 ? this.state.getUserInfo[0]?.rolName : null
        ),
        "string"
      ),
      Search_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "faturalist_720564_value", "value", "", "", "")
        ),
        "string"
      ),
      OdemeBilgi_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "faturalist_166484_value", "value", "", "key", "")
        ),
        "string"
      ),
      BasT_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "faturalist_672411_value", "startValue", "", "", "")
        ),
        "Date"
      ),
      BitT_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "faturalist_672411_value", "endValue", "", "", "")
        ),
        "Date"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "FaturaList/FaturaListComponent_225661_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.spFFaturaList = result?.data.spFFaturaList;

    stateVars.spFFaturaListCount = result?.data.spFFaturaListCount;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.FaturaListComponent_225661_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  FaturaListComponent_225661_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "faturalist_443704_value", 1, null);

    stateVars.dataSource_660947 = this.state.spFFaturaList;
    formVars.faturalist_443704_total = ReactSystemFunctions.value(
      this,
      this.state.spFFaturaListCount?.length > 0 ? this.state.spFFaturaListCount[0]?.recordCount : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  FaturaListComponent_578618_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "IsTakipKart",
      "prmGuid",
      ReactSystemFunctions.value(this, "faturalist_660947_value", "id")
    );
    KuikaAppManager.addToPageInputVariables("IsTakipKart", "prmNerdenGeldi", "siparis");

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "FaturaList",
      "IsTakipKart",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  FaturaListComponent_443704_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Rol_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.getUserInfo?.length > 0 ? this.state.getUserInfo[0]?.rolName : null
        ),
        "string"
      ),
      Search_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "faturalist_720564_value", "value", "", "", "")
        ),
        "string"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "faturalist_443704_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "faturalist_443704_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      OdemeBilgi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "faturalist_166484_value", "value", "", "key", "")
        ),
        "string"
      ),
      BasT_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "faturalist_672411_value", "startValue", "", "", "")
        ),
        "Date"
      ),
      BitT_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "faturalist_672411_value", "endValue", "", "", "")
        ),
        "Date"
      ),
      Rol_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.getUserInfo?.length > 0 ? this.state.getUserInfo[0]?.rolName : null
        ),
        "string"
      ),
      Search_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "faturalist_720564_value", "value", "", "", "")
        ),
        "string"
      ),
      OdemeBilgi_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "faturalist_166484_value", "value", "", "key", "")
        ),
        "string"
      ),
      BasT_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "faturalist_672411_value", "startValue", "", "", "")
        ),
        "Date"
      ),
      BitT_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "faturalist_672411_value", "endValue", "", "", "")
        ),
        "Date"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "FaturaList/FaturaListComponent_443704_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.spFFaturaList = result?.data.spFFaturaList;

    stateVars.spFFaturaListCount = result?.data.spFFaturaListCount;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.FaturaListComponent_443704_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  FaturaListComponent_443704_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_660947 = this.state.spFFaturaList;
    formVars.faturalist_443704_total = ReactSystemFunctions.value(
      this,
      this.state.spFFaturaListCount?.length > 0 ? this.state.spFFaturaListCount[0]?.recordCount : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [464411] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.FaturaListPageInit();
    }
  }
}
