import { FormInstance } from "antd/lib/form";
import { Guid } from "guid-typescript";
import React from "react";
import Swal from "sweetalert2";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IAnaKatalogKart_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IAnaKatalogKart_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  GoBack: any;
  GoBack_dummy: any;
  sbCariList: any[];
  sbCariList_dummy: any[];
  getKartAnaKatalog: any[];
  getKartAnaKatalog_dummy: any[];
  getListCariSozlesmeBilgileri: any[];
  getListCariSozlesmeBilgileri_dummy: any[];
  getListBaglantiliMeslekBirligiByESId: any[];
  getListBaglantiliMeslekBirligiByESId_dummy: any[];
  getListAltKatalog: any[];
  getListAltKatalog_dummy: any[];
  getListAltKatalogCount: any[];
  getListAltKatalogCount_dummy: any[];
  spTarihceAnaKatalog: any[];
  spTarihceAnaKatalog_dummy: any[];
  TriggerEvent: string;
  TriggerEvent_dummy: string;
  AltKatalogDelete: number;
  AltKatalogDelete_dummy: number;
  AnaKatalogSave: number;
  AnaKatalogSave_dummy: number;
  StokTemsilcilikDurumuUpdateByAnaKatalogID: number;
  StokTemsilcilikDurumuUpdateByAnaKatalogID_dummy: number;
  DownloadFile: any;
  DownloadFile_dummy: any;
  setKartCariSozlesmeBilgileriDelete: number;
  setKartCariSozlesmeBilgileriDelete_dummy: number;
  setBaglantiliMeslekBirligiDelete: number;
  setBaglantiliMeslekBirligiDelete_dummy: number;
  ToggleVisibilityOf: string;
  ToggleVisibilityOf_dummy: string;
  isComp271693Visible: "visible" | "hidden";
  isComp315610Visible: "visible" | "hidden";
  isCompanakatalogkart_174347AuthorizationVisible: "visible" | "hidden";
  isCompanakatalogkart_371157AuthorizationVisible: "visible" | "hidden";
  isCompanakatalogkart_31085AuthorizationVisible: "visible" | "hidden";
  isCompanakatalogkart_861691AuthorizationVisible: "visible" | "hidden";
  isCompanakatalogkart_356553AuthorizationVisible: "visible" | "hidden";
  isCompanakatalogkart_549132AuthorizationVisible: "visible" | "hidden";
  isCompanakatalogkart_315610AuthorizationVisible: "visible" | "hidden";
  isCompanakatalogkart_602142AuthorizationVisible: "visible" | "hidden";
  isCompanakatalogkart_952382AuthorizationVisible: "visible" | "hidden";
  isCompanakatalogkart_447183AuthorizationVisible: "visible" | "hidden";
  isCompanakatalogkart_254838AuthorizationVisible: "visible" | "hidden";
  isCompanakatalogkart_826606AuthorizationVisible: "visible" | "hidden";
}

export class AnaKatalogKart_ScreenBase extends React.PureComponent<IAnaKatalogKart_ScreenProps, any> {
  anakatalogkart_887861_value_kuikaSelectBoxRef: React.RefObject<any>;
  anakatalogkart_653011_value_kuikaSelectBoxRef: React.RefObject<any>;
  anakatalogkart_173832_value_kuikaTableRef: React.RefObject<any>;
  anakatalogkart_806258_value_kuikaPaginationRef: React.RefObject<any>;
  anakatalogkart_629261_value_kuikaTableRef: React.RefObject<any>;
  anakatalogkart_456057_value_kuikaTableRef: React.RefObject<any>;
  anakatalogkart_271693_value_kuikaTableRef: React.RefObject<any>;
  ml = [
    {
      Id: "16c151eb-42cf-0b2b-7e4a-e8b04fc43d88",
      Name: "tr_TR",
      ShortName: "Turkish",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "fbdbce9f-3fdb-30c5-c4fe-f7e443068251", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    { Id: "8468043e-f025-01e1-93b8-c71e3215778a", Name: "de_DE", ShortName: "German", LanguagePhrases: [] },
    {
      Id: "5d05df13-187f-4e3f-8b4e-4ec564b2e71f",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 785916, PropertyName: "value", Value: "Ana Katalog Kartı" },
        { Id: 965590, PropertyName: "value", Value: "Müşteri Adı" },
        { Id: 40473, PropertyName: "value", Value: "Ana Katalog Adı" },
        { Id: 628958, PropertyName: "value", Value: "Muhasebe Kodu" },
        { Id: 307552, PropertyName: "value", Value: "Temsilcilik Durumu" },
        { Id: 823422, PropertyName: "value", Value: "Alt Kataloglar" },
        { Id: 173832, PropertyName: "nodatafoundmessage", Value: "Veri Bulunamadı" },
        { Id: 592827, PropertyName: "title", Value: "Adı" },
        { Id: 556561, PropertyName: "value", Value: "[datafield:altkatalogad]" },
        { Id: 407501, PropertyName: "title", Value: "Kodu" },
        { Id: 466818, PropertyName: "value", Value: "[datafield:altkatalogkodu]" },
        { Id: 673209, PropertyName: "title", Value: "Durum" },
        { Id: 256726, PropertyName: "value", Value: "[datafield:temsilcilikdurumufixed]" },
        { Id: 683138, PropertyName: "value", Value: "Alt Katalog Durumları Güncellensin" },
        { Id: 861691, PropertyName: "label", Value: "Vazgeç" },
        { Id: 356553, PropertyName: "label", Value: "Kaydet" },
        { Id: 987639, PropertyName: "value", Value: "Önemli Notlar" },
        { Id: 274180, PropertyName: "value", Value: "Bağlantılı Sözleşmeler" },
        { Id: 629261, PropertyName: "nodatafoundmessage", Value: "Veri Bulunamadı" },
        { Id: 829796, PropertyName: "title", Value: "Alan" },
        { Id: 927957, PropertyName: "value", Value: "[datafield:alan]" },
        { Id: 749513, PropertyName: "title", Value: "Tür" },
        { Id: 76468, PropertyName: "value", Value: "[datafield:sozlesmetipi]" },
        { Id: 131819, PropertyName: "title", Value: "Konu" },
        { Id: 878410, PropertyName: "value", Value: "[datafield:sozlesmekonu]" },
        { Id: 318602, PropertyName: "title", Value: "Baş.Tarihi" },
        { Id: 751295, PropertyName: "value", Value: "[datafield:bastarihi]" },
        { Id: 349520, PropertyName: "title", Value: "Bit.Tarihi" },
        { Id: 853392, PropertyName: "value", Value: "[datafield:bittarihi]" },
        { Id: 173384, PropertyName: "title", Value: "PDF" },
        { Id: 91669, PropertyName: "value", Value: "Bağlantılı Meslek Birliği (PRO)" },
        { Id: 456057, PropertyName: "nodatafoundmessage", Value: "Veri Bulunamadı" },
        { Id: 733025, PropertyName: "title", Value: "Adı" },
        { Id: 806362, PropertyName: "value", Value: "[datafield:meslekbirligiad]" },
        { Id: 19410, PropertyName: "title", Value: "Kayıtlı E.S. Adı" },
        { Id: 390233, PropertyName: "value", Value: "[datafield:kayitliesadi]" },
        { Id: 993655, PropertyName: "title", Value: "IPI(CAE / Name Number)" },
        { Id: 45984, PropertyName: "value", Value: "[datafield:ipi]" },
        { Id: 112177, PropertyName: "title", Value: "Koruma Bölgesi" },
        { Id: 177858, PropertyName: "value", Value: "[datafield:korumabolgesi]" },
        { Id: 682655, PropertyName: "value", Value: "Telif Geçmişini Görüntüle" },
        { Id: 271693, PropertyName: "nodatafoundmessage", Value: "Veri Bulunamadı" },
        { Id: 947210, PropertyName: "title", Value: "İŞ NO" },
        { Id: 731359, PropertyName: "value", Value: "[datafield:ticketno]" },
        { Id: 892141, PropertyName: "title", Value: "TARİH" },
        { Id: 701016, PropertyName: "value", Value: "[datafield:quarterdate]" },
        { Id: 438148, PropertyName: "title", Value: "ALT KATALOG" },
        { Id: 948104, PropertyName: "value", Value: "[datafield:altkatalogad]" },
        { Id: 853758, PropertyName: "title", Value: "ALBÜM KODU" },
        { Id: 242214, PropertyName: "value", Value: "[datafield:albumkodu]" },
        { Id: 863969, PropertyName: "title", Value: "TR.NO" },
        { Id: 112854, PropertyName: "value", Value: "[datafield:trackno]" },
        { Id: 352098, PropertyName: "title", Value: "ESER ADI" },
        { Id: 523230, PropertyName: "value", Value: "[datafield:stokad]" },
        { Id: 251861, PropertyName: "title", Value: "ESER SAHİBİ" },
        { Id: 344835, PropertyName: "value", Value: "[datafield:besteciler]" },
        { Id: 905160, PropertyName: "title", Value: "LİSANS TÜRÜ" },
        { Id: 935858, PropertyName: "value", Value: "[datafield:lisansturu]" },
        { Id: 600936, PropertyName: "title", Value: "PROJE ADI" },
        { Id: 615054, PropertyName: "value", Value: "[datafield:projead]" },
        { Id: 431015, PropertyName: "title", Value: "PROJE TÜRÜ" },
        { Id: 327621, PropertyName: "value", Value: "[datafield:projeturu]" },
        { Id: 599647, PropertyName: "title", Value: "KULLANIM TÜRÜ" },
        { Id: 639357, PropertyName: "value", Value: "[datafield:kullanimturu]" }
      ]
    }
  ];
  defaultML = "tr_TR";
  screenHash = "";

  constructor(props) {
    super(props);
    this.anakatalogkart_887861_value_kuikaSelectBoxRef = React.createRef();
    this.anakatalogkart_653011_value_kuikaSelectBoxRef = React.createRef();
    this.anakatalogkart_173832_value_kuikaTableRef = React.createRef();
    this.anakatalogkart_806258_value_kuikaPaginationRef = React.createRef();
    this.anakatalogkart_629261_value_kuikaTableRef = React.createRef();
    this.anakatalogkart_456057_value_kuikaTableRef = React.createRef();
    this.anakatalogkart_271693_value_kuikaTableRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      NAVIGATE: "",
      GoBack: "",
      sbCariList: [],
      getKartAnaKatalog: [],
      getListCariSozlesmeBilgileri: [],
      getListBaglantiliMeslekBirligiByESId: [],
      getListAltKatalog: [],
      getListAltKatalogCount: [],
      spTarihceAnaKatalog: [],
      TriggerEvent: "",
      AltKatalogDelete: 0,
      AnaKatalogSave: 0,
      StokTemsilcilikDurumuUpdateByAnaKatalogID: "",
      DownloadFile: "",
      setKartCariSozlesmeBilgileriDelete: "",
      setBaglantiliMeslekBirligiDelete: "",
      ToggleVisibilityOf: "",
      dataSource_653011: [
        { key: "1", text: "Aktif" },
        { key: "2", text: "Post-Term" },
        { key: "0", text: "Korumamızdan Çıktı" }
      ],
      isComp271693Visible: "hidden",
      isComp315610Visible: "hidden",
      isCompanakatalogkart_174347AuthorizationVisible: "visible",
      isCompanakatalogkart_371157AuthorizationVisible: "visible",
      isCompanakatalogkart_31085AuthorizationVisible: "visible",
      isCompanakatalogkart_861691AuthorizationVisible: "visible",
      isCompanakatalogkart_356553AuthorizationVisible: "visible",
      isCompanakatalogkart_549132AuthorizationVisible: "visible",
      isCompanakatalogkart_315610AuthorizationVisible: "visible",
      isCompanakatalogkart_602142AuthorizationVisible: "visible",
      isCompanakatalogkart_952382AuthorizationVisible: "visible",
      isCompanakatalogkart_447183AuthorizationVisible: "visible",
      isCompanakatalogkart_254838AuthorizationVisible: "visible",
      isCompanakatalogkart_826606AuthorizationVisible: "visible"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("anakatalogkart", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.AnaKatalogKartPageInit();
    }
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompanakatalogkart_174347AuthorizationVisible", [
      "7165e0ad-e354-6630-d176-a4378c7ac2b9",
      "89f0bd8c-62b8-cf33-3763-edf7319ca78f",
      "5702a90f-4edb-07c0-5096-8ee6a5c813e6",
      "Muhasebe",
      "Admin",
      "Muz Ekip A"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompanakatalogkart_371157AuthorizationVisible", [
      "7165e0ad-e354-6630-d176-a4378c7ac2b9",
      "89f0bd8c-62b8-cf33-3763-edf7319ca78f",
      "5702a90f-4edb-07c0-5096-8ee6a5c813e6",
      "Muhasebe",
      "Admin",
      "Muz Ekip A"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompanakatalogkart_31085AuthorizationVisible", [
      "5702a90f-4edb-07c0-5096-8ee6a5c813e6",
      "89f0bd8c-62b8-cf33-3763-edf7319ca78f",
      "7165e0ad-e354-6630-d176-a4378c7ac2b9",
      "Muhasebe",
      "Admin",
      "Muz Ekip A"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompanakatalogkart_861691AuthorizationVisible", [
      "7165e0ad-e354-6630-d176-a4378c7ac2b9",
      "89f0bd8c-62b8-cf33-3763-edf7319ca78f",
      "5702a90f-4edb-07c0-5096-8ee6a5c813e6",
      "Muhasebe",
      "Admin",
      "Muz Ekip A"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompanakatalogkart_356553AuthorizationVisible", [
      "7165e0ad-e354-6630-d176-a4378c7ac2b9",
      "89f0bd8c-62b8-cf33-3763-edf7319ca78f",
      "5702a90f-4edb-07c0-5096-8ee6a5c813e6",
      "Muhasebe",
      "Admin",
      "Muz Ekip A"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompanakatalogkart_549132AuthorizationVisible", [
      "7165e0ad-e354-6630-d176-a4378c7ac2b9",
      "89f0bd8c-62b8-cf33-3763-edf7319ca78f",
      "5702a90f-4edb-07c0-5096-8ee6a5c813e6",
      "Muhasebe",
      "Admin",
      "Muz Ekip A"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompanakatalogkart_315610AuthorizationVisible", [
      "7165e0ad-e354-6630-d176-a4378c7ac2b9",
      "89f0bd8c-62b8-cf33-3763-edf7319ca78f",
      "5702a90f-4edb-07c0-5096-8ee6a5c813e6",
      "Muhasebe",
      "Admin",
      "Muz Ekip A"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompanakatalogkart_602142AuthorizationVisible", [
      "7165e0ad-e354-6630-d176-a4378c7ac2b9",
      "89f0bd8c-62b8-cf33-3763-edf7319ca78f",
      "5702a90f-4edb-07c0-5096-8ee6a5c813e6",
      "Muhasebe",
      "Admin",
      "Muz Ekip A"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompanakatalogkart_952382AuthorizationVisible", [
      "7165e0ad-e354-6630-d176-a4378c7ac2b9",
      "89f0bd8c-62b8-cf33-3763-edf7319ca78f",
      "5702a90f-4edb-07c0-5096-8ee6a5c813e6",
      "Muhasebe",
      "Admin",
      "Muz Ekip A"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompanakatalogkart_447183AuthorizationVisible", [
      "7165e0ad-e354-6630-d176-a4378c7ac2b9",
      "89f0bd8c-62b8-cf33-3763-edf7319ca78f",
      "5702a90f-4edb-07c0-5096-8ee6a5c813e6",
      "Muhasebe",
      "Admin",
      "Muz Ekip A"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompanakatalogkart_254838AuthorizationVisible", [
      "7165e0ad-e354-6630-d176-a4378c7ac2b9",
      "89f0bd8c-62b8-cf33-3763-edf7319ca78f",
      "5702a90f-4edb-07c0-5096-8ee6a5c813e6",
      "Muhasebe",
      "Admin",
      "Muz Ekip A"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompanakatalogkart_826606AuthorizationVisible", [
      "7165e0ad-e354-6630-d176-a4378c7ac2b9",
      "89f0bd8c-62b8-cf33-3763-edf7319ca78f",
      "5702a90f-4edb-07c0-5096-8ee6a5c813e6",
      "Muhasebe",
      "Admin",
      "Muz Ekip A"
    ]);

    KuikaAppManager.calculateAndSetBodyHeight("anakatalogkart", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("anakatalogkart", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.AnaKatalogKartPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      anakatalogkart_887861_value: this.state.getKartAnaKatalog?.at?.(0)?.cariID ?? undefined,
      anakatalogkart_802808_value: this.state.getKartAnaKatalog?.at?.(0)?.anaKatalogAd ?? undefined,
      anakatalogkart_874267_value: this.state.getKartAnaKatalog?.at?.(0)?.muhasebeKodu ?? undefined,
      anakatalogkart_653011_value: this.state.getKartAnaKatalog?.at?.(0)?.temsilcilikDurumu ?? undefined,
      anakatalogkart_92504_value: false,
      anakatalogkart_846368_value: this.state.getKartAnaKatalog?.at?.(0)?.onemliNot ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  AnaKatalogKartPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      Id_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      ),
      CariID_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      ),
      EserSahibiID_3: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      ),
      AnaKatalogID_4: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      ),
      currentpage_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "anakatalogkart_806258_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "anakatalogkart_806258_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      AnaKatalogID_5: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      ),
      AnaKatalogId_6: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "AnaKatalogKart/AnaKatalogKartPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.sbCariList = result?.data.sbCariList;

    formVars.anakatalogkart_887861_value =
      stateVars.getKartAnaKatalog?.length > 0 ? stateVars.getKartAnaKatalog[0]?.cariID : null;
    formVars.anakatalogkart_887861_options = stateVars.sbCariList;
    stateVars.getKartAnaKatalog = result?.data.getKartAnaKatalog;
    formVars.anakatalogkart_887861_value =
      stateVars.getKartAnaKatalog?.length > 0 ? stateVars.getKartAnaKatalog[0]?.cariID : null;
    formVars.anakatalogkart_887861_options = stateVars.sbCariList;
    formVars.anakatalogkart_802808_value = ReactSystemFunctions.toString(
      this,
      stateVars.getKartAnaKatalog?.length > 0 ? stateVars.getKartAnaKatalog[0]?.anaKatalogAd : null
    );
    formVars.anakatalogkart_874267_value = ReactSystemFunctions.toString(
      this,
      stateVars.getKartAnaKatalog?.length > 0 ? stateVars.getKartAnaKatalog[0]?.muhasebeKodu : null
    );
    formVars.anakatalogkart_653011_value =
      stateVars.getKartAnaKatalog?.length > 0 ? stateVars.getKartAnaKatalog[0]?.temsilcilikDurumu : null;
    formVars.anakatalogkart_846368_value = ReactSystemFunctions.toString(
      this,
      stateVars.getKartAnaKatalog?.length > 0 ? stateVars.getKartAnaKatalog[0]?.onemliNot : null
    );
    stateVars.getListCariSozlesmeBilgileri = result?.data.getListCariSozlesmeBilgileri;

    stateVars.getListBaglantiliMeslekBirligiByESId = result?.data.getListBaglantiliMeslekBirligiByESId;

    stateVars.getListAltKatalog = result?.data.getListAltKatalog;

    stateVars.getListAltKatalogCount = result?.data.getListAltKatalogCount;
    stateVars.spTarihceAnaKatalog = result?.data.spTarihceAnaKatalog;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.AnaKatalogKartPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  AnaKatalogKartPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.anakatalogkart_887861_value = ReactSystemFunctions.value(
      this,
      this.state.getKartAnaKatalog?.length > 0 ? this.state.getKartAnaKatalog[0]?.cariID : null
    );

    stateVars.dataSource_887861 = this.state.sbCariList;
    stateVars.dataSource_887861 = this.state.sbCariList;
    formVars.anakatalogkart_802808_value = ReactSystemFunctions.toString(
      this,
      this.state.getKartAnaKatalog?.length > 0 ? this.state.getKartAnaKatalog[0]?.anaKatalogAd : null
    );

    formVars.anakatalogkart_874267_value = ReactSystemFunctions.toString(
      this,
      this.state.getKartAnaKatalog?.length > 0 ? this.state.getKartAnaKatalog[0]?.muhasebeKodu : null
    );

    formVars.anakatalogkart_653011_value = ReactSystemFunctions.toString(
      this,
      this.state.getKartAnaKatalog?.length > 0 ? this.state.getKartAnaKatalog[0]?.temsilcilikDurumu : null
    );

    stateVars.dataSource_173832 = this.state.getListAltKatalog;
    formVars.anakatalogkart_806258_total = ReactSystemFunctions.value(
      this,
      this.state.getListAltKatalogCount?.length > 0 ? this.state.getListAltKatalogCount[0]?.altKatalogCount : null
    );

    formVars.anakatalogkart_846368_value = ReactSystemFunctions.toString(
      this,
      this.state.getKartAnaKatalog?.length > 0 ? this.state.getKartAnaKatalog[0]?.onemliNot : null
    );

    stateVars.dataSource_629261 = this.state.getListCariSozlesmeBilgileri;
    stateVars.isComp315610Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(this, "anakatalogkart_629261_value", "dosya"),
        null
      ) === true
        ? "visible"
        : "hidden";

    stateVars.dataSource_456057 = this.state.getListBaglantiliMeslekBirligiByESId;

    stateVars.dataSource_271693 = this.state.spTarihceAnaKatalog;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  AnaKatalogKartComponent_83067_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "AnaKatalogKart",
      "LeftMenu",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "820946",
      "",
      "left",
      null,
      "",
      "",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  AnaKatalogKartComponent_996082_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  AnaKatalogKartComponent_174347_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    if (await this.AnaKatalogKartComponent_356553_onClick()) return true;

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables("AltKatalogKart", "prmGuid", Guid.create().toString());
    KuikaAppManager.addToPageInputVariables(
      "AltKatalogKart",
      "prmKatalogId",
      this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      this.AnaKatalogKartComponent_174347_onClick1_,
      "AnaKatalogKart",
      "AltKatalogKart",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "134160",
      null,
      "right",
      null,
      "720px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };
  AnaKatalogKartComponent_174347_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      AnaKatalogID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "anakatalogkart_806258_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "anakatalogkart_806258_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "AnaKatalogKart/AnaKatalogKartComponent_174347_onClick1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.getListAltKatalog = result?.data.getListAltKatalog;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.AnaKatalogKartComponent_174347_onClick2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  AnaKatalogKartComponent_174347_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_173832 = this.state.getListAltKatalog;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  AnaKatalogKartComponent_371157_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      AltKatalogID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "anakatalogkart_173832_value", "id"),
        "Guid"
      ),
      AnaKatalogID_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      ),
      currentpage_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "anakatalogkart_806258_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "anakatalogkart_806258_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      AnaKatalogID_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    stateVars.Confirm = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "53dc3df7_0248_f727_5bb7_3b7737e90891_confirmation",
        this.defaultML,
        "Seçilen Kayıt Silinecek; Devam edilsin mi?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel",
      customClass: {
        title: "swal2-font-poppins"
      }
    });

    if (stateVars.Confirm.isConfirmed) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "AnaKatalogKart/AnaKatalogKartComponent_371157_onClick",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.AltKatalogDelete = result?.data.altKatalogDelete;
      stateVars.getListAltKatalog = result?.data.getListAltKatalog;

      stateVars.getListAltKatalogCount = result?.data.getListAltKatalogCount;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.AnaKatalogKartComponent_371157_onClick1_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  AnaKatalogKartComponent_371157_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_173832 = this.state.getListAltKatalog;
    formVars.anakatalogkart_806258_total = ReactSystemFunctions.value(
      this,
      this.state.getListAltKatalogCount?.length > 0 ? this.state.getListAltKatalogCount[0]?.altKatalogCount : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  AnaKatalogKartComponent_31085_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    if (await this.AnaKatalogKartComponent_356553_onClick()) return true;

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "AltKatalogKart",
      "prmGuid",
      ReactSystemFunctions.value(this, "anakatalogkart_173832_value", "id")
    );
    KuikaAppManager.addToPageInputVariables(
      "AltKatalogKart",
      "prmKatalogId",
      this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      this.AnaKatalogKartComponent_31085_onClick1_,
      "AnaKatalogKart",
      "AltKatalogKart",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "377981",
      null,
      "right",
      null,
      "720px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };
  AnaKatalogKartComponent_31085_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      AnaKatalogID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "anakatalogkart_806258_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "anakatalogkart_806258_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "AnaKatalogKart/AnaKatalogKartComponent_31085_onClick1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.getListAltKatalog = result?.data.getListAltKatalog;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.AnaKatalogKartComponent_31085_onClick2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  AnaKatalogKartComponent_31085_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_173832 = this.state.getListAltKatalog;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  AnaKatalogKartComponent_806258_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      AnaKatalogID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "anakatalogkart_806258_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "anakatalogkart_806258_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "AnaKatalogKart/AnaKatalogKartComponent_806258_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.getListAltKatalog = result?.data.getListAltKatalog;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.AnaKatalogKartComponent_806258_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  AnaKatalogKartComponent_806258_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_173832 = this.state.getListAltKatalog;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  AnaKatalogKartComponent_861691_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  AnaKatalogKartComponent_356553_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      AnaKatalogAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "anakatalogkart_802808_value", "value", "", "", "")
        ),
        "string"
      ),
      MuhasebeKodu_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "anakatalogkart_874267_value", "value", "", "", "")
        ),
        "string"
      ),
      OnemliNot_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "anakatalogkart_846368_value", "value", "", "", "")
        ),
        "string"
      ),
      CariID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "anakatalogkart_887861_value", "value", "sbCariList", "id", "id")
        ),
        "Guid"
      ),
      TemsilcilikDurumu_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toInteger(
          this,
          ReactSystemFunctions.value(this, "anakatalogkart_653011_value", "value", "", "key", "")
        ),
        "number"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "AnaKatalogKart/AnaKatalogKartComponent_356553_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.AnaKatalogSave = result?.data.anaKatalogSave;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.AnaKatalogKartComponent_356553_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  AnaKatalogKartComponent_356553_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      TemsilcilikDurumu_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toInteger(
          this,
          ReactSystemFunctions.value(this, "anakatalogkart_653011_value", "value", "", "key", "")
        ),
        "number"
      ),
      AnaKatalogID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    };

    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "anakatalogkart_92504_value", "value", "", "", "")
        ),
        true
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "AnaKatalogKart/AnaKatalogKartComponent_356553_onClick1_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.StokTemsilcilikDurumuUpdateByAnaKatalogID = result?.data.stokTemsilcilikDurumuUpdateByAnaKatalogID;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.AnaKatalogKartComponent_356553_onClick2_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.AnaKatalogKartComponent_356553_onClick2_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  AnaKatalogKartComponent_356553_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      AnaKatalogID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "anakatalogkart_806258_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "anakatalogkart_806258_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "anakatalogkart_92504_value", "value", "", "", "")
        ),
        true
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "AnaKatalogKart/AnaKatalogKartComponent_356553_onClick2_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.getListAltKatalog = result?.data.getListAltKatalog;

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.AnaKatalogKartComponent_356553_onClick3_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.AnaKatalogKartComponent_356553_onClick3_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  AnaKatalogKartComponent_356553_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "anakatalogkart_92504_value", "value", "", "", "")
        ),
        true
      )
    ) {
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.AnaKatalogKartComponent_356553_onClick4_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.AnaKatalogKartComponent_356553_onClick4_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  AnaKatalogKartComponent_356553_onClick4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "anakatalogkart_92504_value", "value", "", "", "")
        ),
        true
      )
    ) {
      stateVars.dataSource_173832 = this.state.getListAltKatalog;

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  AnaKatalogKartComponent_549132_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    if (await this.AnaKatalogKartComponent_356553_onClick()) return true;

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables("CariSozlesmeler", "prmGuid", Guid.create().toString());
    KuikaAppManager.addToPageInputVariables(
      "CariSozlesmeler",
      "prmCariID",
      this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid
    );
    KuikaAppManager.addToPageInputVariables("CariSozlesmeler", "prmNerdenGeldi", "cari");
    KuikaAppManager.addToPageInputVariables("CariSozlesmeler", "prmIsKartTuruID", null);
    KuikaAppManager.addToPageInputVariables("CariSozlesmeler", "prmMusteriID", null);

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "AnaKatalogKart",
      "CariSozlesmeler",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "626262",
      null,
      "right",
      null,
      "480",
      "",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  AnaKatalogKartComponent_315610_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.DownloadFile = await ReactSystemFunctions.downloadFile(
      ReactSystemFunctions.value(this, "anakatalogkart_629261_value", "dosya"),
      ReactSystemFunctions.value(this, "anakatalogkart_629261_value", "dosyaAd")
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  AnaKatalogKartComponent_602142_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "anakatalogkart_629261_value", "id"),
        "Guid"
      ),
      CariID_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    stateVars.Confirm = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "217f3a0e_9c33_6ac6_444a_2d1a15ce1523_confirmation",
        this.defaultML,
        "Seçilen Kayıt Silinecek; Devam Edilsin mi?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel",
      customClass: {
        title: "swal2-font-poppins"
      }
    });

    if (stateVars.Confirm.isConfirmed) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "AnaKatalogKart/AnaKatalogKartComponent_602142_onClick",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.setKartCariSozlesmeBilgileriDelete = result?.data.setKartCariSozlesmeBilgileriDelete;
      stateVars.getListCariSozlesmeBilgileri = result?.data.getListCariSozlesmeBilgileri;

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.AnaKatalogKartComponent_602142_onClick1_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  AnaKatalogKartComponent_602142_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_629261 = this.state.getListCariSozlesmeBilgileri;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  AnaKatalogKartComponent_952382_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    if (await this.AnaKatalogKartComponent_356553_onClick()) return true;

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "CariSozlesmeler",
      "prmGuid",
      ReactSystemFunctions.value(this, "anakatalogkart_629261_value", "id")
    );
    KuikaAppManager.addToPageInputVariables(
      "CariSozlesmeler",
      "prmCariID",
      this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid
    );
    KuikaAppManager.addToPageInputVariables("CariSozlesmeler", "prmNerdenGeldi", "cari");
    KuikaAppManager.addToPageInputVariables("CariSozlesmeler", "prmIsKartTuruID", null);
    KuikaAppManager.addToPageInputVariables("CariSozlesmeler", "prmMusteriID", null);

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "AnaKatalogKart",
      "CariSozlesmeler",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "998364",
      null,
      "right",
      null,
      "480",
      "",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  AnaKatalogKartComponent_447183_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    if (await this.AnaKatalogKartComponent_356553_onClick()) return true;

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables("BaglantiliMeslekBirligiKart", "prmGuid", Guid.create().toString());
    KuikaAppManager.addToPageInputVariables(
      "BaglantiliMeslekBirligiKart",
      "prmEserSahibiID",
      this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "AnaKatalogKart",
      "BaglantiliMeslekBirligiKart",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "433350",
      null,
      "right",
      null,
      "480px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  AnaKatalogKartComponent_254838_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "anakatalogkart_456057_value", "id"),
        "Guid"
      ),
      EserSahibiID_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    stateVars.Confirm = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "6624671e_0595_5a7e_4849_0505de0b6aa4_confirmation",
        this.defaultML,
        "Seçilen Kayıt Silinecek; Devam Edilsin mi?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel",
      customClass: {
        title: "swal2-font-poppins"
      }
    });

    if (stateVars.Confirm.isConfirmed) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "AnaKatalogKart/AnaKatalogKartComponent_254838_onClick",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.setBaglantiliMeslekBirligiDelete = result?.data.setBaglantiliMeslekBirligiDelete;
      stateVars.getListBaglantiliMeslekBirligiByESId = result?.data.getListBaglantiliMeslekBirligiByESId;

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.AnaKatalogKartComponent_254838_onClick1_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  AnaKatalogKartComponent_254838_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_456057 = this.state.getListBaglantiliMeslekBirligiByESId;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  AnaKatalogKartComponent_826606_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    if (await this.AnaKatalogKartComponent_356553_onClick()) return true;

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "BaglantiliMeslekBirligiKart",
      "prmGuid",
      ReactSystemFunctions.value(this, "anakatalogkart_456057_value", "id")
    );
    KuikaAppManager.addToPageInputVariables(
      "BaglantiliMeslekBirligiKart",
      "prmEserSahibiID",
      this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "AnaKatalogKart",
      "BaglantiliMeslekBirligiKart",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "659105",
      null,
      "right",
      null,
      "480px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  AnaKatalogKartComponent_204438_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp271693Visible");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  AnaKatalogKartComponent_315610_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [820946, 134160, 377981, 626262, 998364, 433350, 659105] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.AnaKatalogKartPageInit();
    }
    if (diId == 134160) {
      isErrorOccurred = await this.AnaKatalogKartComponent_174347_onClick1_();
      if (isErrorOccurred) return true;
    } else if (diId == 377981) {
      isErrorOccurred = await this.AnaKatalogKartComponent_31085_onClick1_();
      if (isErrorOccurred) return true;
    }
  }
}
