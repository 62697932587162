import { FormInstance } from "antd/lib/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IDagitimMailList_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IDagitimMailList_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  spDagitimList_AltCariEXCEL: any[];
  spDagitimList_AltCariEXCEL_dummy: any[];
  ExportExcel: any;
  ExportExcel_dummy: any;
  GetExcelBase64: string;
  GetExcelBase64_dummy: string;
  PrintPdf: string;
  PrintPdf_dummy: string;
  getUserInfo: any[];
  getUserInfo_dummy: any[];
  getFilterParametersByUserName: any[];
  getFilterParametersByUserName_dummy: any[];
  sbQuarterYearList: any[];
  sbQuarterYearList_dummy: any[];
  sbQuarterList: any[];
  sbQuarterList_dummy: any[];
  sbDagitimYeriList_AltCari: any[];
  sbDagitimYeriList_AltCari_dummy: any[];
  SetValueOf: any;
  SetValueOf_dummy: any;
  FilterParametersSaveDagitimMailList: number;
  FilterParametersSaveDagitimMailList_dummy: number;
  spTELIFMAILSendList: any[];
  spTELIFMAILSendList_dummy: any[];
  spTELIFMAILSendListCount: any[];
  spTELIFMAILSendListCount_dummy: any[];
  spTELIFMAILtoTEMPINSERT: any[];
  spTELIFMAILtoTEMPINSERT_dummy: any[];
  Notify: boolean;
  Notify_dummy: boolean;
  isComp588351Visible: "visible" | "hidden";
  isComp98619Visible: "visible" | "hidden";
  isComp749484Visible: "visible" | "hidden";
  isComp871646Visible: "visible" | "hidden";
  isComp578618Visible: "visible" | "hidden";
  isComp418506Visible: "visible" | "hidden";
  isComp178646Visible: "visible" | "hidden";
}

export class DagitimMailList_ScreenBase extends React.PureComponent<IDagitimMailList_ScreenProps, any> {
  dagitimmaillist_989958_value_kuikaSelectBoxRef: React.RefObject<any>;
  dagitimmaillist_298413_value_kuikaSelectBoxRef: React.RefObject<any>;
  dagitimmaillist_967799_value_kuikaSelectBoxRef: React.RefObject<any>;
  dagitimmaillist_660947_value_kuikaTableRef: React.RefObject<any>;
  dagitimmaillist_178646_value_kuikaPaginationRef: React.RefObject<any>;
  ml = [
    {
      Id: "16c151eb-42cf-0b2b-7e4a-e8b04fc43d88",
      Name: "tr_TR",
      ShortName: "Turkish",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "fbdbce9f-3fdb-30c5-c4fe-f7e443068251", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    { Id: "8468043e-f025-01e1-93b8-c71e3215778a", Name: "de_DE", ShortName: "German", LanguagePhrases: [] },
    {
      Id: "289aa8c9-c723-408e-8ef6-f8b768e39824",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 625521, PropertyName: "value", Value: "DAĞITIM MAİL LİST (Telif)" },
        { Id: 989958, PropertyName: "placeholder", Value: "Yıl Seçiniz..!" },
        { Id: 298413, PropertyName: "placeholder", Value: "Quarter Seçiniz..!" },
        { Id: 967799, PropertyName: "placeholder", Value: "Telif Durumu Seçiniz!" },
        { Id: 598594, PropertyName: "label", Value: "Mail Gönder" },
        { Id: 660947, PropertyName: "nodatafoundmessage", Value: "Veri Bulunamadı" },
        { Id: 133595, PropertyName: "title", Value: "Yıl" },
        { Id: 431410, PropertyName: "value", Value: "[datafield:quarteryeartemp]" },
        { Id: 993136, PropertyName: "title", Value: "Q" },
        { Id: 343483, PropertyName: "value", Value: "[datafield:quarterkodtemp]" },
        { Id: 822531, PropertyName: "title", Value: "Müşteri Adı" },
        { Id: 166617, PropertyName: "value", Value: "[datafield:telifcariad]" },
        { Id: 468136, PropertyName: "title", Value: "ÖdenecekTutar" },
        { Id: 408011, PropertyName: "value", Value: "[datafield:tutar]" },
        { Id: 213343, PropertyName: "title", Value: "Telif (Var/Yok)" },
        { Id: 838567, PropertyName: "title", Value: "Mail Bilgi" },
        { Id: 6476, PropertyName: "value", Value: "[datafield:maildil]" },
        { Id: 930420, PropertyName: "value", Value: "[datafield:email]" }
      ]
    }
  ];
  defaultML = "tr_TR";
  screenHash = "";

  constructor(props) {
    super(props);
    this.dagitimmaillist_989958_value_kuikaSelectBoxRef = React.createRef();
    this.dagitimmaillist_298413_value_kuikaSelectBoxRef = React.createRef();
    this.dagitimmaillist_967799_value_kuikaSelectBoxRef = React.createRef();
    this.dagitimmaillist_660947_value_kuikaTableRef = React.createRef();
    this.dagitimmaillist_178646_value_kuikaPaginationRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      NAVIGATE: "",
      spDagitimList_AltCariEXCEL: [],
      ExportExcel: "",
      GetExcelBase64: "",
      PrintPdf: "",
      getUserInfo: [],
      getFilterParametersByUserName: [],
      sbQuarterYearList: [],
      sbQuarterList: [],
      sbDagitimYeriList_AltCari: [],
      SetValueOf: "",
      FilterParametersSaveDagitimMailList: "",
      spTELIFMAILSendList: [],
      spTELIFMAILSendListCount: [],
      spTELIFMAILtoTEMPINSERT: [],
      Notify: false,
      dataSource_967799: [
        { key: "0", text: "Telifi Olmayan" },
        { key: "1", text: "Telifi Olan" }
      ],
      isComp588351Visible: "hidden",
      isComp98619Visible: "hidden",
      isComp749484Visible: "hidden",
      isComp871646Visible: "hidden",
      isComp578618Visible: "hidden",
      isComp418506Visible: "hidden",
      isComp178646Visible: "hidden"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("dagitimmaillist", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.DagitimMailListPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("dagitimmaillist", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("dagitimmaillist", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.DagitimMailListPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      dagitimmaillist_989958_value: this.state.getFilterParametersByUserName?.at?.(0)?.dagitimMailListYil ?? undefined,
      dagitimmaillist_298413_value: this.state.getFilterParametersByUserName?.at?.(0)?.dagitimMailListQ ?? undefined,
      dagitimmaillist_894279_value:
        this.state.getFilterParametersByUserName?.at?.(0)?.dagitimMailListSearch ?? undefined,
      dagitimmaillist_967799_value: this.state.getFilterParametersByUserName?.at?.(0)?.dagitimMailListTelif ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  DagitimMailListPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {};

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "DagitimMailList/DagitimMailListPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.getUserInfo = result?.data.getUserInfo;
    stateVars.getFilterParametersByUserName = result?.data.getFilterParametersByUserName;
    formVars.dagitimmaillist_989958_value =
      stateVars.getFilterParametersByUserName?.length > 0
        ? stateVars.getFilterParametersByUserName[0]?.dagitimMailListYil
        : null;
    formVars.dagitimmaillist_989958_options = stateVars.sbQuarterYearList;
    formVars.dagitimmaillist_298413_value =
      stateVars.getFilterParametersByUserName?.length > 0
        ? stateVars.getFilterParametersByUserName[0]?.dagitimMailListQ
        : null;
    formVars.dagitimmaillist_298413_options = stateVars.sbQuarterList;
    formVars.dagitimmaillist_894279_options = stateVars.sbDagitimYeriList_AltCari;
    formVars.dagitimmaillist_894279_value =
      stateVars.getFilterParametersByUserName?.length > 0
        ? stateVars.getFilterParametersByUserName[0]?.dagitimMailListSearch
        : null;
    formVars.dagitimmaillist_967799_value =
      stateVars.getFilterParametersByUserName?.length > 0
        ? stateVars.getFilterParametersByUserName[0]?.dagitimMailListTelif
        : null;
    stateVars.sbQuarterYearList = result?.data.sbQuarterYearList;

    formVars.dagitimmaillist_989958_value =
      stateVars.getFilterParametersByUserName?.length > 0
        ? stateVars.getFilterParametersByUserName[0]?.dagitimMailListYil
        : null;
    formVars.dagitimmaillist_989958_options = stateVars.sbQuarterYearList;
    stateVars.sbQuarterList = result?.data.sbQuarterList;

    formVars.dagitimmaillist_298413_value =
      stateVars.getFilterParametersByUserName?.length > 0
        ? stateVars.getFilterParametersByUserName[0]?.dagitimMailListQ
        : null;
    formVars.dagitimmaillist_298413_options = stateVars.sbQuarterList;
    stateVars.sbDagitimYeriList_AltCari = result?.data.sbDagitimYeriList_AltCari;

    formVars.dagitimmaillist_894279_options = stateVars.sbDagitimYeriList_AltCari;
    formVars.dagitimmaillist_894279_value =
      stateVars.getFilterParametersByUserName?.length > 0
        ? stateVars.getFilterParametersByUserName[0]?.dagitimMailListSearch
        : null;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.DagitimMailListPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  DagitimMailListPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "dagitimmaillist_989958_value",
      ReactSystemFunctions.toString(
        this,
        this.state.getFilterParametersByUserName?.length > 0
          ? this.state.getFilterParametersByUserName[0]?.dagitimMailListYil
          : null
      ),
      null,
      "QuarterYear"
    );
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "dagitimmaillist_298413_value",
      ReactSystemFunctions.toString(
        this,
        this.state.getFilterParametersByUserName?.length > 0
          ? this.state.getFilterParametersByUserName[0]?.dagitimMailListQ
          : null
      ),
      null,
      "QuarterKod"
    );
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "dagitimmaillist_894279_value",
      ReactSystemFunctions.toString(
        this,
        this.state.getFilterParametersByUserName?.length > 0
          ? this.state.getFilterParametersByUserName[0]?.dagitimMailListSearch
          : null
      ),
      null
    );
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "dagitimmaillist_967799_value",
      ReactSystemFunctions.toString(
        this,
        this.state.getFilterParametersByUserName?.length > 0
          ? this.state.getFilterParametersByUserName[0]?.dagitimMailListTelif
          : null
      ),
      null,
      "Key"
    );
    formVars.dagitimmaillist_989958_value = ReactSystemFunctions.toString(
      this,
      this.state.getFilterParametersByUserName?.length > 0
        ? this.state.getFilterParametersByUserName[0]?.dagitimMailListYil
        : null
    );

    stateVars.dataSource_989958 = this.state.sbQuarterYearList;
    stateVars.dataSource_989958 = this.state.sbQuarterYearList;
    formVars.dagitimmaillist_298413_value = ReactSystemFunctions.toString(
      this,
      this.state.getFilterParametersByUserName?.length > 0
        ? this.state.getFilterParametersByUserName[0]?.dagitimMailListQ
        : null
    );

    stateVars.dataSource_298413 = this.state.sbQuarterList;
    stateVars.dataSource_298413 = this.state.sbQuarterList;

    formVars.dagitimmaillist_894279_value = ReactSystemFunctions.toString(
      this,
      this.state.getFilterParametersByUserName?.length > 0
        ? this.state.getFilterParametersByUserName[0]?.dagitimMailListSearch
        : null
    );

    stateVars.dataSource_894279 = this.state.sbDagitimYeriList_AltCari;
    stateVars.dataSource_894279 = this.state.sbDagitimYeriList_AltCari;
    formVars.dagitimmaillist_967799_value = ReactSystemFunctions.toString(
      this,
      this.state.getFilterParametersByUserName?.length > 0
        ? this.state.getFilterParametersByUserName[0]?.dagitimMailListTelif
        : null
    );

    stateVars.isComp98619Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "dagitimmaillist_660947_value", "telifVar1Yok0"),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp749484Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "dagitimmaillist_660947_value", "telifVar1Yok0"),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp871646Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "dagitimmaillist_660947_value", "mailGonderildi"),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp578618Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "dagitimmaillist_660947_value", "mailGonderildi"),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp418506Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "dagitimmaillist_660947_value", "mailGonderildi"),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp178646Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          this.state.spTELIFMAILSendListCount?.length > 0 ? this.state.spTELIFMAILSendListCount[0]?.recordCount : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  DagitimMailListComponent_653137_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "DagitimMailList",
      "LeftMenu",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "464411",
      "",
      "left",
      null,
      "",
      "",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DagitimMailListComponent_192205_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Search_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      QuarterYear_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "dagitimmaillist_989958_value",
            "value",
            "sbQuarterYearList",
            "quarterYear",
            "quarterYear"
          )
        ),
        "string"
      ),
      QuarterKod_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "dagitimmaillist_298413_value",
            "value",
            "sbQuarterList",
            "quarterKod",
            "quarterKod"
          )
        ),
        "string"
      ),
      DagitimYapilacakKodu_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "dagitimmaillist_894279_value",
            "value",
            "sbDagitimYeriList_AltCari",
            "alt_CariId",
            "alt_CariId"
          )
        ),
        "string"
      ),
      header_1: ReactSystemFunctions.convertToTypeByName("DAĞITIM LİSTESİ", "string"),
      footer_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      header_2: ReactSystemFunctions.convertToTypeByName("DAĞITIM LİSTESİ", "string"),
      footer_2: ReactSystemFunctions.convertToTypeByName("", "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "DagitimMailList/DagitimMailListComponent_192205_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.spDagitimList_AltCariEXCEL = result?.data.spDagitimList_AltCariEXCEL;
    stateVars.ExportExcel = result?.data.exportExcel;
    ReactSystemFunctions.downloadFile(result?.data?.exportExcel);
    stateVars.GetExcelBase64 = result?.data.getExcelBase64;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.DagitimMailListComponent_192205_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  DagitimMailListComponent_192205_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.PrintPdf = await ReactSystemFunctions.printPdf(
      ReactSystemFunctions.toString(this, this.state.GetExcelBase64)
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DagitimMailListComponent_179004_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      DagitimMailListPagination_0: ReactSystemFunctions.convertToTypeByName(1, "number"),
      DagitimMailListQ_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "dagitimmaillist_298413_value",
            "value",
            "sbQuarterList",
            "quarterKod",
            "quarterKod"
          )
        ),
        "string"
      ),
      DagitimMailListSearch_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "dagitimmaillist_894279_value",
            "value",
            "sbDagitimYeriList_AltCari",
            "alt_CariId",
            "alt_CariId"
          )
        ),
        "string"
      ),
      DagitimMailListTelif_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "dagitimmaillist_967799_value", "value", "", "key", "")
        ),
        "string"
      ),
      DagitimMailListYil_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "dagitimmaillist_989958_value",
            "value",
            "sbQuarterYearList",
            "quarterYear",
            "quarterYear"
          )
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "DagitimMailList/DagitimMailListComponent_179004_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.FilterParametersSaveDagitimMailList = result?.data.filterParametersSaveDagitimMailList;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.DagitimMailListComponent_179004_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  DagitimMailListComponent_179004_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "dagitimmaillist_178646_value", 1, null);

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.DagitimMailListComponent_179004_onClick2_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  DagitimMailListComponent_179004_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Search_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      QuarterYear_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "dagitimmaillist_989958_value",
            "value",
            "sbQuarterYearList",
            "quarterYear",
            "quarterYear"
          )
        ),
        "string"
      ),
      QuarterKod_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "dagitimmaillist_298413_value",
            "value",
            "sbQuarterList",
            "quarterKod",
            "quarterKod"
          )
        ),
        "string"
      ),
      DagitimYapilacakKodu_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "dagitimmaillist_894279_value",
            "value",
            "sbDagitimYeriList_AltCari",
            "alt_CariId",
            "alt_CariId"
          )
        ),
        "string"
      ),
      chkTelif_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toInteger(
          this,
          ReactSystemFunctions.value(this, "dagitimmaillist_967799_value", "value", "", "key", "")
        ),
        "number"
      ),
      PageNumber_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dagitimmaillist_178646_value", "current", "", "", "")
        ),
        "number"
      ),
      RowsOfPage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dagitimmaillist_178646_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      Search_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      QuarterYear_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "dagitimmaillist_989958_value",
            "value",
            "sbQuarterYearList",
            "quarterYear",
            "quarterYear"
          )
        ),
        "string"
      ),
      QuarterKod_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "dagitimmaillist_298413_value",
            "value",
            "sbQuarterList",
            "quarterKod",
            "quarterKod"
          )
        ),
        "string"
      ),
      DagitimYapilacakKodu_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "dagitimmaillist_894279_value",
            "value",
            "sbDagitimYeriList_AltCari",
            "alt_CariId",
            "alt_CariId"
          )
        ),
        "string"
      ),
      chkTelif_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toInteger(
          this,
          ReactSystemFunctions.value(this, "dagitimmaillist_967799_value", "value", "", "key", "")
        ),
        "number"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "DagitimMailList/DagitimMailListComponent_179004_onClick2_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.spTELIFMAILSendList = result?.data.spTELIFMAILSendList;

    stateVars.spTELIFMAILSendListCount = result?.data.spTELIFMAILSendListCount;
    stateVars.isComp178646Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.spTELIFMAILSendListCount?.length > 0 ? stateVars.spTELIFMAILSendListCount[0]?.recordCount : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.DagitimMailListComponent_179004_onClick3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  DagitimMailListComponent_179004_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_660947 = this.state.spTELIFMAILSendList;
    formVars.dagitimmaillist_178646_total = ReactSystemFunctions.value(
      this,
      this.state.spTELIFMAILSendListCount?.length > 0 ? this.state.spTELIFMAILSendListCount[0]?.recordCount : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DagitimMailListComponent_598594_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Search_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      QuarterYear_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "dagitimmaillist_989958_value",
            "value",
            "sbQuarterYearList",
            "quarterYear",
            "quarterYear"
          )
        ),
        "string"
      ),
      QuarterKod_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "dagitimmaillist_298413_value",
            "value",
            "sbQuarterList",
            "quarterKod",
            "quarterKod"
          )
        ),
        "string"
      ),
      DagitimYapilacakKodu_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "dagitimmaillist_894279_value",
            "value",
            "sbDagitimYeriList_AltCari",
            "alt_CariId",
            "alt_CariId"
          )
        ),
        "string"
      ),
      chkTelif_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toInteger(
          this,
          ReactSystemFunctions.value(this, "dagitimmaillist_967799_value", "value", "", "key", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "DagitimMailList/DagitimMailListComponent_598594_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.spTELIFMAILtoTEMPINSERT = result?.data.spTELIFMAILtoTEMPINSERT;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.DagitimMailListComponent_598594_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  DagitimMailListComponent_598594_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "b8560aad_d7a9_ba4e_f66c_6486a7d0f95c_notify",
        this.defaultML,
        "Mail Gönderildi!"
      ),
      "success",
      "bottom-right",
      1,
      null,
      null,
      null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DagitimMailListComponent_178646_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Search_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      QuarterYear_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "dagitimmaillist_989958_value",
            "value",
            "sbQuarterYearList",
            "quarterYear",
            "quarterYear"
          )
        ),
        "string"
      ),
      QuarterKod_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "dagitimmaillist_298413_value",
            "value",
            "sbQuarterList",
            "quarterKod",
            "quarterKod"
          )
        ),
        "string"
      ),
      DagitimYapilacakKodu_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "dagitimmaillist_894279_value",
            "value",
            "sbDagitimYeriList_AltCari",
            "alt_CariId",
            "alt_CariId"
          )
        ),
        "string"
      ),
      chkTelif_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toInteger(
          this,
          ReactSystemFunctions.value(this, "dagitimmaillist_967799_value", "value", "", "key", "")
        ),
        "number"
      ),
      PageNumber_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dagitimmaillist_178646_value", "current", "", "", "")
        ),
        "number"
      ),
      RowsOfPage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dagitimmaillist_178646_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "DagitimMailList/DagitimMailListComponent_178646_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.spTELIFMAILSendList = result?.data.spTELIFMAILSendList;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.DagitimMailListComponent_178646_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  DagitimMailListComponent_178646_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_660947 = this.state.spTELIFMAILSendList;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DagitimMailListComponent_418506_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [464411] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.DagitimMailListPageInit();
    }
  }
}
