import React from "react";
import { BrowserRouter, HashRouter, Switch } from "react-router-dom";
import { Redirect } from "../auth/redirect";
import { NoMatch404 } from "../components/common/no-match-404";
import { Dashboard, ForgotPassword, Login, ResetPassword } from "../components/default_screens/index";
import { PrivateRoute } from "./private-route";
import { Route } from "./route";
import { BFormuAnaSayfa_Screen } from "../screens/bformuanasayfa/bformuanasayfa";
import { MusteriKart_Screen } from "../screens/musterikart/musterikart";
import { BaglantiliMeslekBirligiKart_Screen } from "../screens/baglantilimeslekbirligikart/baglantilimeslekbirligikart";
import { UrunList_Screen } from "../screens/urunlist/urunlist";
import { IKartYapim_Screen } from "../screens/ikartyapim/ikartyapim";
import { SearchList_Screen } from "../screens/searchlist/searchlist";
import { TProjeTuru_Form_Screen } from "../screens/tprojeturu_form/tprojeturu_form";
import { TAksiyonTur_Form_Screen } from "../screens/taksiyontur_form/taksiyontur_form";
import { TSozlesmeKonu_List_Screen } from "../screens/tsozlesmekonu_list/tsozlesmekonu_list";
import { TParaBirim_Form_Screen } from "../screens/tparabirim_form/tparabirim_form";
import { Ulke_List_Screen } from "../screens/ulke_list/ulke_list";
import { ArsivList_Screen } from "../screens/arsivlist/arsivlist";
import { ParcaEkle_Screen } from "../screens/parcaekle/parcaekle";
import { IsTakipList_Screen } from "../screens/istakiplist/istakiplist";
import { KullaniciList_Screen } from "../screens/kullanicilist/kullanicilist";
import { KullaniciKart_Screen } from "../screens/kullanicikart/kullanicikart";
import { YapimList_Screen } from "../screens/yapimlist/yapimlist";
import { UrunPayDagilimKart_Screen } from "../screens/urunpaydagilimkart/urunpaydagilimkart";
import { FiyatlandirmaNotlarKart_Screen } from "../screens/fiyatlandirmanotlarkart/fiyatlandirmanotlarkart";
import { KatalogPayDagilimKart_Screen } from "../screens/katalogpaydagilimkart/katalogpaydagilimkart";
import { TStopaj_Form_Screen } from "../screens/tstopaj_form/tstopaj_form";
import { CariIlgiliKisiler_Screen } from "../screens/cariilgilikisiler/cariilgilikisiler";
import { TIsAlani_List_Screen } from "../screens/tisalani_list/tisalani_list";
import { IListMeslekBirligiTR_Screen } from "../screens/ilistmeslekbirligitr/ilistmeslekbirligitr";
import { TSozlesmeTipi_Form_Screen } from "../screens/tsozlesmetipi_form/tsozlesmetipi_form";
import { UrunPayDagilimKartelifsiz_Screen } from "../screens/urunpaydagilimkartelifsiz/urunpaydagilimkartelifsiz";
import { FiyatArttir_Screen } from "../screens/fiyatarttir/fiyatarttir";
import { IKartAltYayimci_Screen } from "../screens/ikartaltyayimci/ikartaltyayimci";
import { IListYapim_Screen } from "../screens/ilistyapim/ilistyapim";
import { TSureAralik_Form_Screen } from "../screens/tsurearalik_form/tsurearalik_form";
import { IListAltYayimci_Screen } from "../screens/ilistaltyayimci/ilistaltyayimci";
import { CariAdresler_Screen } from "../screens/cariadresler/cariadresler";
import { QuarterChange_Screen } from "../screens/quarterchange/quarterchange";
import { CariVarisler_Screen } from "../screens/carivarisler/carivarisler";
import { ProjeTuruMecraEsle_Screen } from "../screens/projeturumecraesle/projeturumecraesle";
import { EserSahibiList_Screen } from "../screens/esersahibilist/esersahibilist";
import { Ulke_Form_Screen } from "../screens/ulke_form/ulke_form";
import { DagitimAltList_Screen } from "../screens/dagitimaltlist/dagitimaltlist";
import { TSozlesmeKonu_Form_Screen } from "../screens/tsozlesmekonu_form/tsozlesmekonu_form";
import { IsTakipKart_Screen } from "../screens/istakipkart/istakipkart";
import { TSureAralik_List_Screen } from "../screens/tsurearalik_list/tsurearalik_list";
import { TFirmaTuru_List_Screen } from "../screens/tfirmaturu_list/tfirmaturu_list";
import { CariSozlesmeler_Screen } from "../screens/carisozlesmeler/carisozlesmeler";
import { DagitimDetailList_Screen } from "../screens/dagitimdetaillist/dagitimdetaillist";
import { PopupMuzikBankasiSecimi_Screen } from "../screens/popupmuzikbankasisecimi/popupmuzikbankasisecimi";
import { TMecra_List_Screen } from "../screens/tmecra_list/tmecra_list";
import { EserSahibiKart_Screen } from "../screens/esersahibikart/esersahibikart";
import { AlbumEkle_Screen } from "../screens/albumekle/albumekle";
import { TTelifTur_List_Screen } from "../screens/tteliftur_list/tteliftur_list";
import { UrunKart_Screen } from "../screens/urunkart/urunkart";
import { IKartDigital_Screen } from "../screens/ikartdigital/ikartdigital";
import { DagitimMailList_Screen } from "../screens/dagitimmaillist/dagitimmaillist";
import { TMeslekBirligiAd_Form_Screen } from "../screens/tmeslekbirligiad_form/tmeslekbirligiad_form";
import { CariIBankaBilgileri_Screen } from "../screens/cariibankabilgileri/cariibankabilgileri";
import { Dogrulama_Screen } from "../screens/dogrulama/dogrulama";
import { FiyatList_Screen } from "../screens/fiyatlist/fiyatlist";
import { IKartMeslekBirligiY_Screen } from "../screens/ikartmeslekbirligiy/ikartmeslekbirligiy";
import { FaturaList_Screen } from "../screens/faturalist/faturalist";
import { TicketListBayi_Screen } from "../screens/ticketlistbayi/ticketlistbayi";
import { HataKontrol_Screen } from "../screens/hatakontrol/hatakontrol";
import { IKartMeslekBirligiTR_Screen } from "../screens/ikartmeslekbirligitr/ikartmeslekbirligitr";
import { TSozlesmeTipi_List_Screen } from "../screens/tsozlesmetipi_list/tsozlesmetipi_list";
import { TSozlesmeKanal_Form_Screen } from "../screens/tsozlesmekanal_form/tsozlesmekanal_form";
import { AksiyonKart_Screen } from "../screens/aksiyonkart/aksiyonkart";
import { IListMeslekBirligiY_Screen } from "../screens/ilistmeslekbirligiy/ilistmeslekbirligiy";
import { FiyatlandirmaOzelKart_Screen } from "../screens/fiyatlandirmaozelkart/fiyatlandirmaozelkart";
import { TYayimci_List_Screen } from "../screens/tyayimci_list/tyayimci_list";
import { BFormuBankaV2_Screen } from "../screens/bformubankav2/bformubankav2";
import { IKartPaketAnlasma_Screen } from "../screens/ikartpaketanlasma/ikartpaketanlasma";
import { TParaBirim_List_Screen } from "../screens/tparabirim_list/tparabirim_list";
import { EserKayitKart_Screen } from "../screens/eserkayitkart/eserkayitkart";
import { TSozlesmeKanal_List_Screen } from "../screens/tsozlesmekanal_list/tsozlesmekanal_list";
import { IsTakipListNot_Screen } from "../screens/istakiplistnot/istakiplistnot";
import { KatalogKart_Screen } from "../screens/katalogkart/katalogkart";
import { BFormuTicari_Screen } from "../screens/bformuticari/bformuticari";
import { FiyatlandirmaNotlarList_Screen } from "../screens/fiyatlandirmanotlarlist/fiyatlandirmanotlarlist";
import { EserSahibiPayDagilimKart_Screen } from "../screens/esersahibipaydagilimkart/esersahibipaydagilimkart";
import { ParolamiUnuttum_Screen } from "../screens/parolamiunuttum/parolamiunuttum";
import { TTemsilBolgesi_List_Screen } from "../screens/ttemsilbolgesi_list/ttemsilbolgesi_list";
import { TicketList_Screen } from "../screens/ticketlist/ticketlist";
import { Ilce_Form_Screen } from "../screens/ilce_form/ilce_form";
import { AksiyonTur_List_Screen } from "../screens/aksiyontur_list/aksiyontur_list";
import { MusteriList_Screen } from "../screens/musterilist/musterilist";
import { Signin_Screen } from "../screens/signin/signin";
import { TYayimci_Form_Screen } from "../screens/tyayimci_form/tyayimci_form";
import { TMBirligi_List_Screen } from "../screens/tmbirligi_list/tmbirligi_list";
import { TProjeFormTuru_Form_Screen } from "../screens/tprojeformturu_form/tprojeformturu_form";
import { ParolaGuncelle_Screen } from "../screens/parolaguncelle/parolaguncelle";
import { TMecra_Form_Screen } from "../screens/tmecra_form/tmecra_form";
import { TProjeFormTuru_List_Screen } from "../screens/tprojeformturu_list/tprojeformturu_list";
import { IListPaketAnlasma_Screen } from "../screens/ilistpaketanlasma/ilistpaketanlasma";
import { AksiyonTur_Form_Screen } from "../screens/aksiyontur_form/aksiyontur_form";
import { FiyatKart_Screen } from "../screens/fiyatkart/fiyatkart";
import { TMusteriTuru_Form_Screen } from "../screens/tmusterituru_form/tmusterituru_form";
import { BFormuTesekkur_Screen } from "../screens/bformutesekkur/bformutesekkur";
import { Signup_Screen } from "../screens/signup/signup";
import { SatisList_Screen } from "../screens/satislist/satislist";
import { SiparisList_Screen } from "../screens/siparislist/siparislist";
import { AltKatalogKart_Screen } from "../screens/altkatalogkart/altkatalogkart";
import { IListDigital_Screen } from "../screens/ilistdigital/ilistdigital";
import { TurEkle_Screen } from "../screens/turekle/turekle";
import { TTelifTur_Form_Screen } from "../screens/tteliftur_form/tteliftur_form";
import { PaketAnlasmaKart_Screen } from "../screens/paketanlasmakart/paketanlasmakart";
import { Il_List_Screen } from "../screens/il_list/il_list";
import { AnaKatalogKart_Screen } from "../screens/anakatalogkart/anakatalogkart";
import { BFormuTicariPrint_Screen } from "../screens/bformuticariprint/bformuticariprint";
import { TTemsilBolgesi_Form_Screen } from "../screens/ttemsilbolgesi_form/ttemsilbolgesi_form";
import { TStopaj_List_Screen } from "../screens/tstopaj_list/tstopaj_list";
import { TIsAlani_Form_Screen } from "../screens/tisalani_form/tisalani_form";
import { DagitimList_Screen } from "../screens/dagitimlist/dagitimlist";
import { TMusteriTuru_List_Screen } from "../screens/tmusterituru_list/tmusterituru_list";
import { AltKatalogBaglantiliMeslekBirligiKart_Screen } from "../screens/altkatalogbaglantilimeslekbirligikart/altkatalogbaglantilimeslekbirligikart";
import { TMBirligi_Form_Screen } from "../screens/tmbirligi_form/tmbirligi_form";
import { Ilce_List_Screen } from "../screens/ilce_list/ilce_list";
import { Il_Form_Screen } from "../screens/il_form/il_form";
import { AnaKatalogList_Screen } from "../screens/anakataloglist/anakataloglist";
import { TFirmaTuru_Form_Screen } from "../screens/tfirmaturu_form/tfirmaturu_form";
import { TMeslekBirligiAd_List_Screen } from "../screens/tmeslekbirligiad_list/tmeslekbirligiad_list";
import { TProjeTuru_List_Screen } from "../screens/tprojeturu_list/tprojeturu_list";
import { TAksiyonTur_List_Screen } from "../screens/taksiyontur_list/taksiyontur_list";
import { LeftMenu_Screen } from "../screens/leftmenu/leftmenu";
import { DagitimListKUMULE_Screen } from "../screens/dagitimlistkumule/dagitimlistkumule";



import { IAppMode, IAuthType } from "../bootstrapper";
import { KuikaInfo } from "../components/default_screens/kuika-info";
import { MfeLogin } from "../components/mfe/mfe-login";
import { MsalLogin } from "../components/msal/msal-login";
import { MsalRedirect } from "../components/msal/msal-redirect";
import { WFMailInteraction } from "../screens/wf-mail-interaction";
import { KMainFunctions } from "../shared/utilty/main-functions";

const confJson = require("../shared/json/conf.json");

interface IRouterState {}

export type IRouterType = "HashRouter" | "BrowserRouter";

interface IRouterProps {
  authType: IAuthType;
  routerType?: IRouterType;
  external_access_token?: string;
  external_refresh_token?: string;
  external_user?: string;
  appMode?: IAppMode;
}

declare let window: any;
export class Router extends React.Component<IRouterProps, IRouterState> {
  timerId: any = null;

  componentDidMount = () => {
    window.onfocus = function () {
      if (window.kuika === undefined) {
        window.kuika = {};
      }
      window.kuika.isTabActive = true;
    };

    window.onblur = function () {
      if (window.kuika === undefined) {
        window.kuika = {};
      }
      window.kuika.isTabActive = false;
    };

    if (!window.styler_speed) {
      window.styler_speed = 20;
    } else if (window.styler_speed < 500) {
      window.styler_speed += 100;
    }

    if (window?.kuika?.activeKuikaComponent !== undefined) {
      return;
    }
    const runTimeout = () => {
      this.timerId = setTimeout(() => {
        const allFormItems: NodeListOf<HTMLElement> = document.querySelectorAll(".kFormItem");
        if (allFormItems) {
          allFormItems.forEach((formItem) => {
            if (formItem.getAttribute("data-display-affected") === "true") {
              return;
            }
            const antFormItemControlInputContent: HTMLElement = formItem.querySelector(
              ".ant-form-item-control .ant-form-item-control-input .ant-form-item-control-input-content"
            );
            const antFormItemControlInput: HTMLElement = formItem.querySelector(
              ".ant-form-item-control .ant-form-item-control-input"
            );
            const antFormItemControl: HTMLElement = formItem.querySelector(".ant-form-item-control");

            if (
              antFormItemControlInputContent &&
              antFormItemControlInputContent.children &&
              antFormItemControlInputContent.children.length > 0
            ) {
              const formChild: HTMLElement = antFormItemControlInputContent.children[0] as HTMLElement;
              let calculatedDisplay: string = formChild.style.display;
              if (
                calculatedDisplay &&
                antFormItemControl &&
                antFormItemControlInput &&
                antFormItemControlInputContent
              ) {
                if (calculatedDisplay === "inline-flex" || calculatedDisplay === "inline-block") {
                  calculatedDisplay = "";
                  formItem.style.width = formChild.style.width ?? "auto";
                  formItem.style.display = "inline-block";
                  formItem.setAttribute("data-display-affected", "true");
                }
              }
            }
          });
        }
        this.componentDidMount();
      }, window.styler_speed);
    };

    runTimeout();

    setTimeout(() => {
      clearTimeout(this.timerId);
    }, 10000);
  };

  render() {
    const KuikaRouter = this.KuikaRouter();
    return (
      <>
        <KuikaRouter>
          <Switch key="Switch">
            <PrivateRoute exact path="/" component={TicketList_Screen} />
                        <Route exact path="/bformuanasayfa" component={BFormuAnaSayfa_Screen} />
            <PrivateRoute exact path="/musterikart" component={MusteriKart_Screen} />
            <PrivateRoute exact path="/baglantilimeslekbirligikart" component={BaglantiliMeslekBirligiKart_Screen} />
            <Route exact path="/urunlist" component={UrunList_Screen} />
            <PrivateRoute exact path="/ikartyapim" component={IKartYapim_Screen} />
            <PrivateRoute exact path="/searchlist" component={SearchList_Screen} />
            <PrivateRoute exact path="/tprojeturu_form" component={TProjeTuru_Form_Screen} />
            <PrivateRoute exact path="/taksiyontur_form" component={TAksiyonTur_Form_Screen} />
            <PrivateRoute exact path="/tsozlesmekonu_list" component={TSozlesmeKonu_List_Screen} />
            <PrivateRoute exact path="/tparabirim_form" component={TParaBirim_Form_Screen} />
            <PrivateRoute exact path="/ulke_list" component={Ulke_List_Screen} />
            <PrivateRoute exact path="/arsivlist" component={ArsivList_Screen} />
            <Route exact path="/parcaekle" component={ParcaEkle_Screen} />
            <PrivateRoute exact path="/istakiplist" component={IsTakipList_Screen} />
            <PrivateRoute exact path="/kullanicilist" component={KullaniciList_Screen} />
            <PrivateRoute exact path="/kullanicikart" component={KullaniciKart_Screen} />
            <Route exact path="/yapimlist" component={YapimList_Screen} />
            <PrivateRoute exact path="/urunpaydagilimkart" component={UrunPayDagilimKart_Screen} />
            <Route exact path="/fiyatlandirmanotlarkart" component={FiyatlandirmaNotlarKart_Screen} />
            <PrivateRoute exact path="/katalogpaydagilimkart" component={KatalogPayDagilimKart_Screen} />
            <PrivateRoute exact path="/tstopaj_form" component={TStopaj_Form_Screen} />
            <PrivateRoute exact path="/cariilgilikisiler" component={CariIlgiliKisiler_Screen} />
            <PrivateRoute exact path="/tisalani_list" component={TIsAlani_List_Screen} />
            <Route exact path="/ilistmeslekbirligitr" component={IListMeslekBirligiTR_Screen} />
            <PrivateRoute exact path="/tsozlesmetipi_form" component={TSozlesmeTipi_Form_Screen} />
            <PrivateRoute exact path="/urunpaydagilimkartelifsiz" component={UrunPayDagilimKartelifsiz_Screen} />
            <PrivateRoute exact path="/fiyatarttir" component={FiyatArttir_Screen} />
            <PrivateRoute exact path="/ikartaltyayimci" component={IKartAltYayimci_Screen} />
            <Route exact path="/ilistyapim" component={IListYapim_Screen} />
            <PrivateRoute exact path="/tsurearalik_form" component={TSureAralik_Form_Screen} />
            <Route exact path="/ilistaltyayimci" component={IListAltYayimci_Screen} />
            <PrivateRoute exact path="/cariadresler" component={CariAdresler_Screen} />
            <Route exact path="/quarterchange" component={QuarterChange_Screen} />
            <PrivateRoute exact path="/carivarisler" component={CariVarisler_Screen} />
            <PrivateRoute exact path="/projeturumecraesle" component={ProjeTuruMecraEsle_Screen} />
            <Route exact path="/esersahibilist" component={EserSahibiList_Screen} />
            <PrivateRoute exact path="/ulke_form" component={Ulke_Form_Screen} />
            <PrivateRoute exact path="/dagitimaltlist" component={DagitimAltList_Screen} />
            <PrivateRoute exact path="/tsozlesmekonu_form" component={TSozlesmeKonu_Form_Screen} />
            <PrivateRoute exact path="/istakipkart" component={IsTakipKart_Screen} />
            <PrivateRoute exact path="/tsurearalik_list" component={TSureAralik_List_Screen} />
            <PrivateRoute exact path="/tfirmaturu_list" component={TFirmaTuru_List_Screen} />
            <PrivateRoute exact path="/carisozlesmeler" component={CariSozlesmeler_Screen} />
            <PrivateRoute exact path="/dagitimdetaillist" component={DagitimDetailList_Screen} />
            <PrivateRoute exact path="/popupmuzikbankasisecimi" component={PopupMuzikBankasiSecimi_Screen} />
            <PrivateRoute exact path="/tmecra_list" component={TMecra_List_Screen} />
            <PrivateRoute exact path="/esersahibikart" component={EserSahibiKart_Screen} />
            <Route exact path="/albumekle" component={AlbumEkle_Screen} />
            <PrivateRoute exact path="/tteliftur_list" component={TTelifTur_List_Screen} />
            <PrivateRoute exact path="/urunkart" component={UrunKart_Screen} />
            <PrivateRoute exact path="/ikartdigital" component={IKartDigital_Screen} />
            <PrivateRoute exact path="/dagitimmaillist" component={DagitimMailList_Screen} />
            <PrivateRoute exact path="/tmeslekbirligiad_form" component={TMeslekBirligiAd_Form_Screen} />
            <PrivateRoute exact path="/cariibankabilgileri" component={CariIBankaBilgileri_Screen} />
            <Route exact path="/dogrulama" component={Dogrulama_Screen} />
            <Route exact path="/fiyatlist" component={FiyatList_Screen} />
            <PrivateRoute exact path="/ikartmeslekbirligiy" component={IKartMeslekBirligiY_Screen} />
            <PrivateRoute exact path="/faturalist" component={FaturaList_Screen} />
            <PrivateRoute exact path="/ticketlistbayi" component={TicketListBayi_Screen} />
            <PrivateRoute exact path="/hatakontrol" component={HataKontrol_Screen} />
            <PrivateRoute exact path="/ikartmeslekbirligitr" component={IKartMeslekBirligiTR_Screen} />
            <PrivateRoute exact path="/tsozlesmetipi_list" component={TSozlesmeTipi_List_Screen} />
            <PrivateRoute exact path="/tsozlesmekanal_form" component={TSozlesmeKanal_Form_Screen} />
            <PrivateRoute exact path="/aksiyonkart" component={AksiyonKart_Screen} />
            <Route exact path="/ilistmeslekbirligiy" component={IListMeslekBirligiY_Screen} />
            <PrivateRoute exact path="/fiyatlandirmaozelkart" component={FiyatlandirmaOzelKart_Screen} />
            <PrivateRoute exact path="/tyayimci_list" component={TYayimci_List_Screen} />
            <Route exact path="/bformubankav2" component={BFormuBankaV2_Screen} />
            <PrivateRoute exact path="/ikartpaketanlasma" component={IKartPaketAnlasma_Screen} />
            <PrivateRoute exact path="/tparabirim_list" component={TParaBirim_List_Screen} />
            <PrivateRoute exact path="/eserkayitkart" component={EserKayitKart_Screen} />
            <PrivateRoute exact path="/tsozlesmekanal_list" component={TSozlesmeKanal_List_Screen} />
            <Route exact path="/istakiplistnot" component={IsTakipListNot_Screen} />
            <PrivateRoute exact path="/katalogkart" component={KatalogKart_Screen} />
            <Route exact path="/bformuticari" component={BFormuTicari_Screen} />
            <Route exact path="/fiyatlandirmanotlarlist" component={FiyatlandirmaNotlarList_Screen} />
            <PrivateRoute exact path="/esersahibipaydagilimkart" component={EserSahibiPayDagilimKart_Screen} />
            <Route exact path="/parolamiunuttum" component={ParolamiUnuttum_Screen} />
            <PrivateRoute exact path="/ttemsilbolgesi_list" component={TTemsilBolgesi_List_Screen} />
            <PrivateRoute exact path="/ticketlist" component={TicketList_Screen} />
            <PrivateRoute exact path="/ilce_form" component={Ilce_Form_Screen} />
            <PrivateRoute exact path="/aksiyontur_list" component={AksiyonTur_List_Screen} />
            <Route exact path="/musterilist" component={MusteriList_Screen} />
            <Route exact path="/signin" component={this.LoginComponent()} />
            <PrivateRoute exact path="/tyayimci_form" component={TYayimci_Form_Screen} />
            <PrivateRoute exact path="/tmbirligi_list" component={TMBirligi_List_Screen} />
            <PrivateRoute exact path="/tprojeformturu_form" component={TProjeFormTuru_Form_Screen} />
            <Route exact path="/parolaguncelle" component={ParolaGuncelle_Screen} />
            <PrivateRoute exact path="/tmecra_form" component={TMecra_Form_Screen} />
            <PrivateRoute exact path="/tprojeformturu_list" component={TProjeFormTuru_List_Screen} />
            <Route exact path="/ilistpaketanlasma" component={IListPaketAnlasma_Screen} />
            <PrivateRoute exact path="/aksiyontur_form" component={AksiyonTur_Form_Screen} />
            <PrivateRoute exact path="/fiyatkart" component={FiyatKart_Screen} />
            <PrivateRoute exact path="/tmusterituru_form" component={TMusteriTuru_Form_Screen} />
            <Route exact path="/bformutesekkur" component={BFormuTesekkur_Screen} />
            <Route exact path="/signup" component={Signup_Screen} />
            <PrivateRoute exact path="/satislist" component={SatisList_Screen} />
            <PrivateRoute exact path="/siparislist" component={SiparisList_Screen} />
            <PrivateRoute exact path="/altkatalogkart" component={AltKatalogKart_Screen} />
            <Route exact path="/ilistdigital" component={IListDigital_Screen} />
            <Route exact path="/turekle" component={TurEkle_Screen} />
            <PrivateRoute exact path="/tteliftur_form" component={TTelifTur_Form_Screen} />
            <PrivateRoute exact path="/paketanlasmakart" component={PaketAnlasmaKart_Screen} />
            <PrivateRoute exact path="/il_list" component={Il_List_Screen} />
            <PrivateRoute exact path="/anakatalogkart" component={AnaKatalogKart_Screen} />
            <Route exact path="/bformuticariprint" component={BFormuTicariPrint_Screen} />
            <PrivateRoute exact path="/ttemsilbolgesi_form" component={TTemsilBolgesi_Form_Screen} />
            <PrivateRoute exact path="/tstopaj_list" component={TStopaj_List_Screen} />
            <PrivateRoute exact path="/tisalani_form" component={TIsAlani_Form_Screen} />
            <PrivateRoute exact path="/dagitimlist" component={DagitimList_Screen} />
            <PrivateRoute exact path="/tmusterituru_list" component={TMusteriTuru_List_Screen} />
            <PrivateRoute exact path="/altkatalogbaglantilimeslekbirligikart" component={AltKatalogBaglantiliMeslekBirligiKart_Screen} />
            <PrivateRoute exact path="/tmbirligi_form" component={TMBirligi_Form_Screen} />
            <PrivateRoute exact path="/ilce_list" component={Ilce_List_Screen} />
            <PrivateRoute exact path="/il_form" component={Il_Form_Screen} />
            <Route exact path="/anakataloglist" component={AnaKatalogList_Screen} />
            <PrivateRoute exact path="/tfirmaturu_form" component={TFirmaTuru_Form_Screen} />
            <PrivateRoute exact path="/tmeslekbirligiad_list" component={TMeslekBirligiAd_List_Screen} />
            <PrivateRoute exact path="/tprojeturu_list" component={TProjeTuru_List_Screen} />
            <PrivateRoute exact path="/taksiyontur_list" component={TAksiyonTur_List_Screen} />
            <PrivateRoute exact path="/leftmenu" component={LeftMenu_Screen} />
            <PrivateRoute exact path="/dagitimlistkumule" component={DagitimListKUMULE_Screen} />

            <Route path="/redirect" component={Redirect} />
            <Route path={"/login"} component={this.LoginComponent()} />
            
            
            <Route exact path="/forgotpassword" component={ForgotPassword} />
            <Route exact path="/resetpassword" component={ResetPassword} />
            <PrivateRoute exact path="/dashboard" component={Dashboard} />
            <Route exact path="/kuikainfo" component={KuikaInfo} />
            <Route exact path="/msalredirect" component={MsalRedirect} />
            <Route exact path="/wfmailinteraction" component={WFMailInteraction} />
            <Route path="*" component={NoMatch404} />
          </Switch>
        </KuikaRouter>
      </>
    );
  }

  KuikaRouter = () => {
    // Web component case
    let isWebComponentApp: any;
    isWebComponentApp = this.props.appMode === "WebComponent";
    if (
      confJson?.domainName !== undefined &&
      confJson?.applicationName !== undefined &&
      confJson?.applicationName !== "" &&
      window.kuika !== undefined &&
      window.kuika?.apps !== undefined &&
      window.kuika?.apps.length !== undefined &&
      window.kuika?.apps.length > 0
    ) {
      window.kuika?.apps.forEach((app: any) => {
        if (app.appName === confJson.applicationName && app.workspace === confJson.domainName) {
          isWebComponentApp = true;
        }
      });
    }
    if (window.kuika === undefined) {
      window.kuika = {};
    }
    window.kuika.isWebComponentApp = isWebComponentApp;

    if (isWebComponentApp === true || this.props.routerType === "HashRouter") {
      return (props: any) => {
        return <HashRouter key="HashRouter">{props.children}</HashRouter>;
      };
    }
    return (props: any) => {
      if (KMainFunctions.virtualDirectoryURL === "auto" || window?.location?.protocol === "file:") {
        return <BrowserRouter key="BrowserRouter">{props.children}</BrowserRouter>;
      }
      return (
        <BrowserRouter basename={KMainFunctions.virtualDirectoryURL} key="BrowserRouter">
          {props.children}
        </BrowserRouter>
      );
    };
  };

  LoginComponent = () => {
    if (this.props.authType === "MSAL") {
      return MsalLogin;
    }

    const { external_access_token } = this.props;
    const { external_refresh_token } = this.props;
    const { external_user } = this.props;
    if (
      external_access_token !== null &&
      external_access_token !== undefined &&
      external_access_token !== "" &&
      external_refresh_token !== null &&
      external_refresh_token !== undefined &&
      external_refresh_token !== ""
    ) {
      return (props: any) => {
        return (
          <MfeLogin
            external_user={external_user}
            external_access_token={external_access_token}
            external_refresh_token={external_refresh_token}
          ></MfeLogin>
        );
      };
    }

    if (this.props.authType === "Kuika") {
      return Signin_Screen;
    }
    return <></>;
  };
}
