import { FormInstance } from "antd/lib/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IDagitimDetailList_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IDagitimDetailList_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  GoBack: any;
  GoBack_dummy: any;
  getUserInfo: any[];
  getUserInfo_dummy: any[];
  spDagitimDetailList: any[];
  spDagitimDetailList_dummy: any[];
  spDagitimDetailListCount: any[];
  spDagitimDetailListCount_dummy: any[];
  SetValueOf: any;
  SetValueOf_dummy: any;
  spDagitimDetailListEXCEL: any[];
  spDagitimDetailListEXCEL_dummy: any[];
  ExportExcel: any;
  ExportExcel_dummy: any;
  isComp976556Visible: "visible" | "hidden";
  isComp860498Visible: "visible" | "hidden";
  isComp885016Visible: "visible" | "hidden";
  isComp352743Visible: "visible" | "hidden";
  isComp812977Visible: "visible" | "hidden";
  isComp176244Visible: "visible" | "hidden";
}

export class DagitimDetailList_ScreenBase extends React.PureComponent<IDagitimDetailList_ScreenProps, any> {
  dagitimdetaillist_660947_value_kuikaTableRef: React.RefObject<any>;
  dagitimdetaillist_682626_value_kuikaPaginationRef: React.RefObject<any>;
  ml = [
    {
      Id: "16c151eb-42cf-0b2b-7e4a-e8b04fc43d88",
      Name: "tr_TR",
      ShortName: "Turkish",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "fbdbce9f-3fdb-30c5-c4fe-f7e443068251", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    { Id: "8468043e-f025-01e1-93b8-c71e3215778a", Name: "de_DE", ShortName: "German", LanguagePhrases: [] },
    {
      Id: "0cc9984e-e007-4166-a951-fea6c33b4490",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 625521, PropertyName: "value", Value: "Dağıtım Detail List" },
        { Id: 498299, PropertyName: "placeholder", Value: "Aranacak kelimeyi yazınız..." },
        { Id: 660947, PropertyName: "nodatafoundmessage", Value: "Veri Bulunamadı" },
        { Id: 999399, PropertyName: "title", Value: "Q" },
        { Id: 240646, PropertyName: "value", Value: "[datafield:quarteryear]" },
        { Id: 439818, PropertyName: "value", Value: "[datafield:quarterkod]" },
        { Id: 394605, PropertyName: "title", Value: "Kaynak" },
        { Id: 37501, PropertyName: "value", Value: "[datafield:sektorelad]" },
        { Id: 515169, PropertyName: "title", Value: "Telif Turu" },
        { Id: 435264, PropertyName: "value", Value: "[datafield:teliftanim]" },
        { Id: 421582, PropertyName: "title", Value: "Proje Adı" },
        { Id: 492430, PropertyName: "value", Value: "[datafield:projead]" },
        { Id: 468136, PropertyName: "title", Value: "Eser Adı" },
        { Id: 408011, PropertyName: "value", Value: "[datafield:stokadi]" },
        { Id: 885016, PropertyName: "title", Value: "Süre Sn" },
        { Id: 352743, PropertyName: "value", Value: "[datafield:sure]" },
        { Id: 812977, PropertyName: "title", Value: "B.Fiyat" },
        { Id: 176244, PropertyName: "value", Value: "[datafield:birimfiyat]" },
        { Id: 352211, PropertyName: "title", Value: "Tutar" },
        { Id: 726097, PropertyName: "value", Value: "[datafield:_tutar]" },
        { Id: 752009, PropertyName: "value", Value: "[datafield:_simge]" },
        { Id: 371130, PropertyName: "title", Value: "E.Sahibi Pay %" },
        { Id: 734099, PropertyName: "value", Value: "[datafield:hakedisoran]" },
        { Id: 750032, PropertyName: "title", Value: "MUZ Oran %" },
        { Id: 549332, PropertyName: "value", Value: "[datafield:muzoran]" },
        { Id: 501872, PropertyName: "title", Value: "Hakediş Tutar" },
        { Id: 78006, PropertyName: "value", Value: "[datafield:_hakedistutar]" },
        { Id: 717597, PropertyName: "value", Value: "[datafield:_simge]" },
        { Id: 934413, PropertyName: "title", Value: "MUZ Tutar" },
        { Id: 61894, PropertyName: "value", Value: "[datafield:_muztutar]" },
        { Id: 332483, PropertyName: "value", Value: "[datafield:_simge]" },
        { Id: 155643, PropertyName: "title", Value: "Stopaj Dahil Tutar" },
        { Id: 877493, PropertyName: "value", Value: "[datafield:_stopajdahiltutar]" },
        { Id: 218664, PropertyName: "value", Value: "[datafield:_simge]" }
      ]
    }
  ];
  defaultML = "tr_TR";
  screenHash = "";

  constructor(props) {
    super(props);
    this.dagitimdetaillist_660947_value_kuikaTableRef = React.createRef();
    this.dagitimdetaillist_682626_value_kuikaPaginationRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      NAVIGATE: "",
      GoBack: "",
      getUserInfo: [],
      spDagitimDetailList: [],
      spDagitimDetailListCount: [],
      SetValueOf: "",
      spDagitimDetailListEXCEL: [],
      ExportExcel: "",
      isComp976556Visible: "hidden",
      isComp860498Visible: "hidden",
      isComp885016Visible: "hidden",
      isComp352743Visible: "hidden",
      isComp812977Visible: "hidden",
      isComp176244Visible: "hidden"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("dagitimdetaillist", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.DagitimDetailListPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("dagitimdetaillist", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("dagitimdetaillist", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.DagitimDetailListPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  DagitimDetailListPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      KatalogKodu_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmKategoriKodu ?? this.props.screenInputs.prmkategorikodu,
        "string"
      ),
      Simge_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmSimge ?? this.props.screenInputs.prmsimge,
        "string"
      ),
      Search_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dagitimdetaillist_498299_value", "value", "", "", "")
        ),
        "string"
      ),
      QuarterYear_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmQuarterYear ?? this.props.screenInputs.prmquarteryear,
        "string"
      ),
      QuarterKod_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmQuarterKod ?? this.props.screenInputs.prmquarterkod,
        "string"
      ),
      PageNumber_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dagitimdetaillist_682626_value", "current", "", "", "")
        ),
        "number"
      ),
      RowsOfPage_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dagitimdetaillist_682626_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      KatalogKodu_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmKategoriKodu ?? this.props.screenInputs.prmkategorikodu,
        "string"
      ),
      Simge_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmSimge ?? this.props.screenInputs.prmsimge,
        "string"
      ),
      Search_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dagitimdetaillist_498299_value", "value", "", "", "")
        ),
        "string"
      ),
      QuarterYear_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmQuarterYear ?? this.props.screenInputs.prmquarteryear,
        "string"
      ),
      QuarterKod_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmQuarterKod ?? this.props.screenInputs.prmquarterkod,
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "DagitimDetailList/DagitimDetailListPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.getUserInfo = result?.data.getUserInfo;
    stateVars.spDagitimDetailList = result?.data.spDagitimDetailList;

    stateVars.spDagitimDetailListCount = result?.data.spDagitimDetailListCount;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.DagitimDetailListPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  DagitimDetailListPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_660947 = this.state.spDagitimDetailList;
    stateVars.isComp976556Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(this, "dagitimdetaillist_660947_value", "tanim"),
        "excel"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp860498Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(this, "dagitimdetaillist_660947_value", "tanim"),
        "excel"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp885016Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "dagitimdetaillist_660947_value", "tanim"),
        "excel"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp352743Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "dagitimdetaillist_660947_value", "tanim"),
        "excel"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp812977Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "dagitimdetaillist_660947_value", "tanim"),
        "excel"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp176244Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "dagitimdetaillist_660947_value", "tanim"),
        "excel"
      ) === true
        ? "visible"
        : "hidden";
    formVars.dagitimdetaillist_682626_total = ReactSystemFunctions.value(
      this,
      this.state.spDagitimDetailListCount?.length > 0 ? this.state.spDagitimDetailListCount[0]?.sumCount : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  DagitimDetailListComponent_653137_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "DagitimDetailList",
      "LeftMenu",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "464411",
      "",
      "left",
      null,
      "",
      "",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DagitimDetailListComponent_675400_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DagitimDetailListComponent_498299_onPressEnter = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "dagitimdetaillist_682626_value", 1, null);

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.DagitimDetailListComponent_498299_onPressEnter1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  DagitimDetailListComponent_498299_onPressEnter1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      KatalogKodu_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmKategoriKodu ?? this.props.screenInputs.prmkategorikodu,
        "string"
      ),
      Simge_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmSimge ?? this.props.screenInputs.prmsimge,
        "string"
      ),
      Rol_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.getUserInfo?.length > 0 ? this.state.getUserInfo[0]?.rolName : null
        ),
        "string"
      ),
      Search_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dagitimdetaillist_498299_value", "value", "", "", "")
        ),
        "string"
      ),
      QuarterYear_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmQuarterYear ?? this.props.screenInputs.prmquarteryear,
        "string"
      ),
      QuarterKod_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmQuarterKod ?? this.props.screenInputs.prmquarterkod,
        "string"
      ),
      PageNumber_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dagitimdetaillist_682626_value", "current", "", "", "")
        ),
        "number"
      ),
      RowsOfPage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dagitimdetaillist_682626_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      KatalogKodu_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmKategoriKodu ?? this.props.screenInputs.prmkategorikodu,
        "string"
      ),
      Simge_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmSimge ?? this.props.screenInputs.prmsimge,
        "string"
      ),
      Rol_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.getUserInfo?.length > 0 ? this.state.getUserInfo[0]?.rolName : null
        ),
        "string"
      ),
      Search_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dagitimdetaillist_498299_value", "value", "", "", "")
        ),
        "string"
      ),
      QuarterYear_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmQuarterYear ?? this.props.screenInputs.prmquarteryear,
        "string"
      ),
      QuarterKod_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmQuarterKod ?? this.props.screenInputs.prmquarterkod,
        "string"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "DagitimDetailList/DagitimDetailListComponent_498299_onPressEnter1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.spDagitimDetailList = result?.data.spDagitimDetailList;

    stateVars.spDagitimDetailListCount = result?.data.spDagitimDetailListCount;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.DagitimDetailListComponent_498299_onPressEnter2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  DagitimDetailListComponent_498299_onPressEnter2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_660947 = this.state.spDagitimDetailList;
    formVars.dagitimdetaillist_682626_total = ReactSystemFunctions.value(
      this,
      this.state.spDagitimDetailListCount?.length > 0 ? this.state.spDagitimDetailListCount[0]?.sumCount : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DagitimDetailListComponent_315359_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      KatalogKodu_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmKategoriKodu ?? this.props.screenInputs.prmkategorikodu,
        "string"
      ),
      Simge_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmSimge ?? this.props.screenInputs.prmsimge,
        "string"
      ),
      Rol_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.getUserInfo?.length > 0 ? this.state.getUserInfo[0]?.rolName : null
        ),
        "string"
      ),
      Search_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dagitimdetaillist_498299_value", "value", "", "", "")
        ),
        "string"
      ),
      QuarterYear_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmQuarterYear ?? this.props.screenInputs.prmquarteryear,
        "string"
      ),
      QuarterKod_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmQuarterKod ?? this.props.screenInputs.prmquarterkod,
        "string"
      ),
      header_1: ReactSystemFunctions.convertToTypeByName("DAĞITIM LİSTESİ", "string"),
      footer_1: ReactSystemFunctions.convertToTypeByName("", "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "DagitimDetailList/DagitimDetailListComponent_315359_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.spDagitimDetailListEXCEL = result?.data.spDagitimDetailListEXCEL;
    stateVars.ExportExcel = result?.data.exportExcel;
    ReactSystemFunctions.downloadFile(result?.data?.exportExcel);
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  DagitimDetailListComponent_976556_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "QuarterChange",
      "prmGuid",
      ReactSystemFunctions.value(this, "dagitimdetaillist_660947_value", "id")
    );
    KuikaAppManager.addToPageInputVariables("QuarterChange", "nereden", "dagitim");

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      this.DagitimDetailListComponent_976556_onClick1_,
      "DagitimDetailList",
      "QuarterChange",
      "",
      "9924ef5f-4b84-4200-b624-8d76348c7686",
      "113546",
      null,
      null,
      "true",
      "380px",
      "25vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };
  DagitimDetailListComponent_976556_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      KatalogKodu_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmKategoriKodu ?? this.props.screenInputs.prmkategorikodu,
        "string"
      ),
      Simge_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmSimge ?? this.props.screenInputs.prmsimge,
        "string"
      ),
      Rol_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.getUserInfo?.length > 0 ? this.state.getUserInfo[0]?.rolName : null
        ),
        "string"
      ),
      Search_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dagitimdetaillist_498299_value", "value", "", "", "")
        ),
        "string"
      ),
      QuarterYear_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmQuarterYear ?? this.props.screenInputs.prmquarteryear,
        "string"
      ),
      QuarterKod_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmQuarterKod ?? this.props.screenInputs.prmquarterkod,
        "string"
      ),
      PageNumber_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dagitimdetaillist_682626_value", "current", "", "", "")
        ),
        "number"
      ),
      RowsOfPage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dagitimdetaillist_682626_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "DagitimDetailList/DagitimDetailListComponent_976556_onClick1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.spDagitimDetailList = result?.data.spDagitimDetailList;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.DagitimDetailListComponent_976556_onClick2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  DagitimDetailListComponent_976556_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_660947 = this.state.spDagitimDetailList;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DagitimDetailListComponent_860498_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "IsTakipKart",
      "prmGuid",
      ReactSystemFunctions.value(this, "dagitimdetaillist_660947_value", "basvuruFormuID")
    );
    KuikaAppManager.addToPageInputVariables("IsTakipKart", "prmNerdenGeldi", "D");

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "DagitimDetailList",
      "IsTakipKart",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DagitimDetailListComponent_682626_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      KatalogKodu_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmKategoriKodu ?? this.props.screenInputs.prmkategorikodu,
        "string"
      ),
      Simge_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmSimge ?? this.props.screenInputs.prmsimge,
        "string"
      ),
      Rol_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.getUserInfo?.length > 0 ? this.state.getUserInfo[0]?.rolName : null
        ),
        "string"
      ),
      Search_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dagitimdetaillist_498299_value", "value", "", "", "")
        ),
        "string"
      ),
      QuarterYear_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmQuarterYear ?? this.props.screenInputs.prmquarteryear,
        "string"
      ),
      QuarterKod_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmQuarterKod ?? this.props.screenInputs.prmquarterkod,
        "string"
      ),
      PageNumber_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dagitimdetaillist_682626_value", "current", "", "", "")
        ),
        "number"
      ),
      RowsOfPage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dagitimdetaillist_682626_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "DagitimDetailList/DagitimDetailListComponent_682626_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.spDagitimDetailList = result?.data.spDagitimDetailList;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.DagitimDetailListComponent_682626_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  DagitimDetailListComponent_682626_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_660947 = this.state.spDagitimDetailList;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [464411, 113546] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.DagitimDetailListPageInit();
    }
    if (diId == 113546) {
      isErrorOccurred = await this.DagitimDetailListComponent_976556_onClick1_();
      if (isErrorOccurred) return true;
    }
  }
}
