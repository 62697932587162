import { FormInstance } from "antd/lib/form";
import { Guid } from "guid-typescript";
import React from "react";
import Swal from "sweetalert2";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IAltKatalogKart_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IAltKatalogKart_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  GoBack: any;
  GoBack_dummy: any;
  sbEserTipiList: any[];
  sbEserTipiList_dummy: any[];
  getKartAnaKatalog: any[];
  getKartAnaKatalog_dummy: any[];
  getKartAltKatalog: any[];
  getKartAltKatalog_dummy: any[];
  getAltKatalogBaglantiliMeslekBirligiListByAKId: any[];
  getAltKatalogBaglantiliMeslekBirligiListByAKId_dummy: any[];
  getListKatalogPayDagilimByKId: any[];
  getListKatalogPayDagilimByKId_dummy: any[];
  ChangeEnabledOf: any;
  ChangeEnabledOf_dummy: any;
  spTarihceAltKatalog: any[];
  spTarihceAltKatalog_dummy: any[];
  TriggerEvent: string;
  TriggerEvent_dummy: string;
  AltKatalogBaglantiliMeslekBirligiDelete: number;
  AltKatalogBaglantiliMeslekBirligiDelete_dummy: number;
  KatalogPayDagilimDelete: number;
  KatalogPayDagilimDelete_dummy: number;
  ToggleVisibilityOf: string;
  ToggleVisibilityOf_dummy: string;
  AltKatalogSave: number;
  AltKatalogSave_dummy: number;
  StokTemsilcilikDurumuUpdateByAltKatalogID: number;
  StokTemsilcilikDurumuUpdateByAltKatalogID_dummy: number;
  isComp400676Visible: "visible" | "hidden";
  isComp701333Enabled: "enabled" | "disabled";
  isComp887861Enabled: "enabled" | "disabled";
}

export class AltKatalogKart_ScreenBase extends React.PureComponent<IAltKatalogKart_ScreenProps, any> {
  altkatalogkart_887861_value_kuikaSelectBoxRef: React.RefObject<any>;
  altkatalogkart_33187_value_kuikaSelectBoxRef: React.RefObject<any>;
  altkatalogkart_930853_value_kuikaDateRef: React.RefObject<any>;
  altkatalogkart_855917_value_kuikaDateRef: React.RefObject<any>;
  altkatalogkart_383603_value_kuikaSelectBoxRef: React.RefObject<any>;
  altkatalogkart_925382_value_kuikaSelectBoxRef: React.RefObject<any>;
  altkatalogkart_14602_value_kuikaDateRef: React.RefObject<any>;
  altkatalogkart_456057_value_kuikaTableRef: React.RefObject<any>;
  altkatalogkart_965575_value_kuikaTableRef: React.RefObject<any>;
  altkatalogkart_400676_value_kuikaTableRef: React.RefObject<any>;
  ml = [
    {
      Id: "16c151eb-42cf-0b2b-7e4a-e8b04fc43d88",
      Name: "tr_TR",
      ShortName: "Turkish",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "fbdbce9f-3fdb-30c5-c4fe-f7e443068251", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    { Id: "8468043e-f025-01e1-93b8-c71e3215778a", Name: "de_DE", ShortName: "German", LanguagePhrases: [] },
    {
      Id: "7f806b56-6a5a-4bf8-8de4-730c09572a81",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 785916, PropertyName: "value", Value: "Alt Katalog Kartı" },
        { Id: 965590, PropertyName: "value", Value: "Bölüm Tipi" },
        { Id: 889611, PropertyName: "value", Value: "Temsilcilik Durumu" },
        { Id: 116000, PropertyName: "value", Value: "Ana Katalog Adı" },
        { Id: 40473, PropertyName: "value", Value: "Alt Katalog Adı" },
        { Id: 912153, PropertyName: "value", Value: "Alt Katalog Kodu" },
        { Id: 435912, PropertyName: "value", Value: "Muhasebe Kodu" },
        { Id: 628958, PropertyName: "value", Value: "Temsilcilik Başlangıç Tarihi" },
        { Id: 316291, PropertyName: "value", Value: "Temsilcilik Bitiş Tarihi" },
        { Id: 340781, PropertyName: "value", Value: "Otomatik Yenileme" },
        { Id: 597078, PropertyName: "value", Value: "Post-Term Toplama" },
        { Id: 146041, PropertyName: "value", Value: "Post-Term  Süresi" },
        { Id: 777111, PropertyName: "value", Value: "Post-Term Toplama Bitiş Tarihi" },
        { Id: 91669, PropertyName: "value", Value: "Alt Katalog Meslek Birliği Bilgisi" },
        { Id: 456057, PropertyName: "nodatafoundmessage", Value: "Veri Bulunamadı" },
        { Id: 733025, PropertyName: "title", Value: "Yayımcı" },
        { Id: 806362, PropertyName: "value", Value: "[datafield:yayimci]" },
        { Id: 19410, PropertyName: "title", Value: "Meslek Birliği" },
        { Id: 390233, PropertyName: "value", Value: "[datafield:meslekbirligiad]" },
        { Id: 993655, PropertyName: "title", Value: "IPI" },
        { Id: 45984, PropertyName: "value", Value: "[datafield:ipi]" },
        { Id: 376176, PropertyName: "value", Value: "Katalog Payları / Dağıtım" },
        { Id: 965575, PropertyName: "nodatafoundmessage", Value: "Veri Bulunamadı" },
        { Id: 665401, PropertyName: "title", Value: "Yayımcı" },
        { Id: 601641, PropertyName: "value", Value: "[datafield:cariad]" },
        { Id: 145604, PropertyName: "title", Value: "Eser Payı (Mekanik)" },
        { Id: 614424, PropertyName: "value", Value: "[datafield:hakedisoran]" },
        { Id: 925636, PropertyName: "title", Value: "Eser Payı (Temsili)" },
        { Id: 737657, PropertyName: "value", Value: "[datafield:hakedisorantemsili]" },
        { Id: 260950, PropertyName: "title", Value: "MUZ (Mekanik Payı)" },
        { Id: 785674, PropertyName: "value", Value: "[datafield:muzoran]" },
        { Id: 406203, PropertyName: "title", Value: "MUZ (Temsili Payı)" },
        { Id: 617480, PropertyName: "value", Value: "[datafield:muzorantemsili]" },
        { Id: 264381, PropertyName: "value", Value: "Telif Geçmişini Görüntüle" },
        { Id: 400676, PropertyName: "nodatafoundmessage", Value: "Veri Bulunamadı" },
        { Id: 438988, PropertyName: "title", Value: "İŞ NO" },
        { Id: 304177, PropertyName: "value", Value: "[datafield:ticketno]" },
        { Id: 178782, PropertyName: "title", Value: "TARİH" },
        { Id: 888248, PropertyName: "value", Value: "[datafield:quarterdate]" },
        { Id: 593049, PropertyName: "title", Value: "ALBÜM KODU" },
        { Id: 884741, PropertyName: "value", Value: "[datafield:albumkodu]" },
        { Id: 507394, PropertyName: "title", Value: "TR.NO" },
        { Id: 121332, PropertyName: "value", Value: "[datafield:trackno]" },
        { Id: 764655, PropertyName: "title", Value: "ESER ADI" },
        { Id: 788196, PropertyName: "value", Value: "[datafield:stokad]" },
        { Id: 975690, PropertyName: "title", Value: "ESER SAHİBİ" },
        { Id: 34477, PropertyName: "value", Value: "[datafield:besteciler]" },
        { Id: 518249, PropertyName: "title", Value: "LİSANS TÜRÜ" },
        { Id: 783271, PropertyName: "value", Value: "[datafield:lisansturu]" },
        { Id: 460425, PropertyName: "title", Value: "PROJE ADI" },
        { Id: 919258, PropertyName: "value", Value: "[datafield:projead]" },
        { Id: 927485, PropertyName: "title", Value: "PROJE TÜRÜ" },
        { Id: 131405, PropertyName: "value", Value: "[datafield:projeturu]" },
        { Id: 184328, PropertyName: "title", Value: "KULLANIM TÜRÜ" },
        { Id: 236452, PropertyName: "value", Value: "[datafield:kullanimturu]" },
        { Id: 861691, PropertyName: "label", Value: "Vazgeç" },
        { Id: 356553, PropertyName: "label", Value: "Kaydet" }
      ]
    }
  ];
  defaultML = "tr_TR";
  screenHash = "";

  constructor(props) {
    super(props);
    this.altkatalogkart_887861_value_kuikaSelectBoxRef = React.createRef();
    this.altkatalogkart_33187_value_kuikaSelectBoxRef = React.createRef();
    this.altkatalogkart_930853_value_kuikaDateRef = React.createRef();
    this.altkatalogkart_855917_value_kuikaDateRef = React.createRef();
    this.altkatalogkart_383603_value_kuikaSelectBoxRef = React.createRef();
    this.altkatalogkart_925382_value_kuikaSelectBoxRef = React.createRef();
    this.altkatalogkart_14602_value_kuikaDateRef = React.createRef();
    this.altkatalogkart_456057_value_kuikaTableRef = React.createRef();
    this.altkatalogkart_965575_value_kuikaTableRef = React.createRef();
    this.altkatalogkart_400676_value_kuikaTableRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      NAVIGATE: "",
      GoBack: "",
      sbEserTipiList: [],
      getKartAnaKatalog: [],
      getKartAltKatalog: [],
      getAltKatalogBaglantiliMeslekBirligiListByAKId: [],
      getListKatalogPayDagilimByKId: [],
      ChangeEnabledOf: "",
      spTarihceAltKatalog: [],
      TriggerEvent: "",
      AltKatalogBaglantiliMeslekBirligiDelete: 0,
      KatalogPayDagilimDelete: "",
      ToggleVisibilityOf: "",
      AltKatalogSave: "",
      StokTemsilcilikDurumuUpdateByAltKatalogID: 0,
      dataSource_33187: [
        { key: "1", text: "Aktif" },
        { key: "2", text: "Post-Term" },
        { key: "0", text: "Korumamızdan Çıktı" }
      ],
      dataSource_383603: [
        { key: "1", text: "VAR" },
        { key: "0", text: "YOK" }
      ],
      dataSource_925382: [
        { key: "1", text: "VAR" },
        { key: "0", text: "YOK" }
      ],
      isComp400676Visible: "hidden",
      isComp701333Enabled: "enabled",
      isComp887861Enabled: "enabled"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("altkatalogkart", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.AltKatalogKartPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("altkatalogkart", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("altkatalogkart", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.AltKatalogKartPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      altkatalogkart_33187_value: this.state.getKartAltKatalog?.at?.(0)?.temsilcilikDurumu ?? undefined,
      altkatalogkart_701333_value: this.state.getKartAnaKatalog?.at?.(0)?.anaKatalogAd ?? undefined,
      altkatalogkart_802808_value: this.state.getKartAltKatalog?.at?.(0)?.altKatalogAd ?? undefined,
      altkatalogkart_851651_value: this.state.getKartAltKatalog?.at?.(0)?.altKatalogKodu ?? undefined,
      altkatalogkart_164704_value: this.state.getKartAltKatalog?.at?.(0)?.muhasebeKodu ?? undefined,
      altkatalogkart_930853_value: this.state.getKartAltKatalog?.at?.(0)?.tBasTar ?? undefined,
      altkatalogkart_855917_value: this.state.getKartAltKatalog?.at?.(0)?.tBitTar ?? undefined,
      altkatalogkart_383603_value: this.state.getKartAltKatalog?.at?.(0)?.yenileme ?? undefined,
      altkatalogkart_925382_value: this.state.getKartAltKatalog?.at?.(0)?.ptt ?? undefined,
      altkatalogkart_480329_value: this.state.getKartAltKatalog?.at?.(0)?.pts ?? undefined,
      altkatalogkart_14602_value: this.state.getKartAltKatalog?.at?.(0)?.pttt ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  AltKatalogKartPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      Id_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmKatalogId ?? this.props.screenInputs.prmkatalogid,
        "Guid"
      ),
      Id_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      ),
      AltKatalogID_3: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      ),
      AltKatalogID_4: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "AltKatalogKart/AltKatalogKartPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.sbEserTipiList = result?.data.sbEserTipiList;

    formVars.altkatalogkart_887861_options = stateVars.sbEserTipiList;
    stateVars.getKartAnaKatalog = result?.data.getKartAnaKatalog;
    formVars.altkatalogkart_701333_value = ReactSystemFunctions.toString(
      this,
      stateVars.getKartAnaKatalog?.length > 0 ? stateVars.getKartAnaKatalog[0]?.anaKatalogAd : null
    );
    stateVars.getKartAltKatalog = result?.data.getKartAltKatalog;
    formVars.altkatalogkart_33187_value =
      stateVars.getKartAltKatalog?.length > 0 ? stateVars.getKartAltKatalog[0]?.temsilcilikDurumu : null;
    formVars.altkatalogkart_802808_value = ReactSystemFunctions.toString(
      this,
      stateVars.getKartAltKatalog?.length > 0 ? stateVars.getKartAltKatalog[0]?.altKatalogAd : null
    );
    formVars.altkatalogkart_851651_value = ReactSystemFunctions.toString(
      this,
      stateVars.getKartAltKatalog?.length > 0 ? stateVars.getKartAltKatalog[0]?.altKatalogKodu : null
    );
    formVars.altkatalogkart_164704_value = ReactSystemFunctions.toString(
      this,
      stateVars.getKartAltKatalog?.length > 0 ? stateVars.getKartAltKatalog[0]?.muhasebeKodu : null
    );
    formVars.altkatalogkart_930853_value = ReactSystemFunctions.value(
      this,
      stateVars.getKartAltKatalog?.length > 0 ? stateVars.getKartAltKatalog[0]?.tBasTar : null
    );
    formVars.altkatalogkart_855917_value = ReactSystemFunctions.value(
      this,
      stateVars.getKartAltKatalog?.length > 0 ? stateVars.getKartAltKatalog[0]?.tBitTar : null
    );
    formVars.altkatalogkart_383603_value =
      stateVars.getKartAltKatalog?.length > 0 ? stateVars.getKartAltKatalog[0]?.yenileme : null;
    formVars.altkatalogkart_925382_value =
      stateVars.getKartAltKatalog?.length > 0 ? stateVars.getKartAltKatalog[0]?.ptt : null;
    formVars.altkatalogkart_480329_value = ReactSystemFunctions.toString(
      this,
      stateVars.getKartAltKatalog?.length > 0 ? stateVars.getKartAltKatalog[0]?.pts : null
    );
    formVars.altkatalogkart_14602_value = ReactSystemFunctions.value(
      this,
      stateVars.getKartAltKatalog?.length > 0 ? stateVars.getKartAltKatalog[0]?.pttt : null
    );
    stateVars.getAltKatalogBaglantiliMeslekBirligiListByAKId =
      result?.data.getAltKatalogBaglantiliMeslekBirligiListByAKId;

    stateVars.getListKatalogPayDagilimByKId = result?.data.getListKatalogPayDagilimByKId;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.AltKatalogKartPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  AltKatalogKartPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp701333Enabled", "disabled");
    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp887861Enabled", "disabled");

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.AltKatalogKartPageInit2_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  AltKatalogKartPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      AltKatalogId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "AltKatalogKart/AltKatalogKartPageInit2_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.spTarihceAltKatalog = result?.data.spTarihceAltKatalog;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.AltKatalogKartPageInit3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  AltKatalogKartPageInit3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_887861 = this.state.sbEserTipiList;
    stateVars.dataSource_887861 = this.state.sbEserTipiList;
    formVars.altkatalogkart_33187_value = ReactSystemFunctions.toString(
      this,
      this.state.getKartAltKatalog?.length > 0 ? this.state.getKartAltKatalog[0]?.temsilcilikDurumu : null
    );

    formVars.altkatalogkart_701333_value = ReactSystemFunctions.toString(
      this,
      this.state.getKartAnaKatalog?.length > 0 ? this.state.getKartAnaKatalog[0]?.anaKatalogAd : null
    );

    formVars.altkatalogkart_802808_value = ReactSystemFunctions.toString(
      this,
      this.state.getKartAltKatalog?.length > 0 ? this.state.getKartAltKatalog[0]?.altKatalogAd : null
    );

    formVars.altkatalogkart_851651_value = ReactSystemFunctions.toString(
      this,
      this.state.getKartAltKatalog?.length > 0 ? this.state.getKartAltKatalog[0]?.altKatalogKodu : null
    );

    formVars.altkatalogkart_164704_value = ReactSystemFunctions.toString(
      this,
      this.state.getKartAltKatalog?.length > 0 ? this.state.getKartAltKatalog[0]?.muhasebeKodu : null
    );

    formVars.altkatalogkart_930853_value = ReactSystemFunctions.value(
      this,
      this.state.getKartAltKatalog?.length > 0 ? this.state.getKartAltKatalog[0]?.tBasTar : null
    );

    formVars.altkatalogkart_855917_value = ReactSystemFunctions.value(
      this,
      this.state.getKartAltKatalog?.length > 0 ? this.state.getKartAltKatalog[0]?.tBitTar : null
    );

    formVars.altkatalogkart_383603_value = ReactSystemFunctions.toString(
      this,
      this.state.getKartAltKatalog?.length > 0 ? this.state.getKartAltKatalog[0]?.yenileme : null
    );

    formVars.altkatalogkart_925382_value = ReactSystemFunctions.toString(
      this,
      this.state.getKartAltKatalog?.length > 0 ? this.state.getKartAltKatalog[0]?.ptt : null
    );

    formVars.altkatalogkart_480329_value = ReactSystemFunctions.toString(
      this,
      this.state.getKartAltKatalog?.length > 0 ? this.state.getKartAltKatalog[0]?.pts : null
    );

    formVars.altkatalogkart_14602_value = ReactSystemFunctions.value(
      this,
      this.state.getKartAltKatalog?.length > 0 ? this.state.getKartAltKatalog[0]?.pttt : null
    );

    stateVars.dataSource_456057 = this.state.getAltKatalogBaglantiliMeslekBirligiListByAKId;

    stateVars.dataSource_965575 = this.state.getListKatalogPayDagilimByKId;

    stateVars.dataSource_400676 = this.state.spTarihceAltKatalog;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  AltKatalogKartComponent_83067_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "AltKatalogKart",
      "LeftMenu",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "820946",
      "",
      "left",
      null,
      "",
      "",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  AltKatalogKartComponent_996082_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  AltKatalogKartComponent_447183_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    if (await this.AltKatalogKartComponent_356553_onClick()) return true;

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "AltKatalogBaglantiliMeslekBirligiKart",
      "prmGuid",
      Guid.create().toString()
    );
    KuikaAppManager.addToPageInputVariables(
      "AltKatalogBaglantiliMeslekBirligiKart",
      "prmAltKatalogiID",
      this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      this.AltKatalogKartComponent_447183_onClick1_,
      "AltKatalogKart",
      "AltKatalogBaglantiliMeslekBirligiKart",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "433350",
      null,
      "right",
      null,
      "480px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };
  AltKatalogKartComponent_447183_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      AltKatalogID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "AltKatalogKart/AltKatalogKartComponent_447183_onClick1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.getAltKatalogBaglantiliMeslekBirligiListByAKId =
      result?.data.getAltKatalogBaglantiliMeslekBirligiListByAKId;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.AltKatalogKartComponent_447183_onClick2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  AltKatalogKartComponent_447183_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_456057 = this.state.getAltKatalogBaglantiliMeslekBirligiListByAKId;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  AltKatalogKartComponent_254838_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "altkatalogkart_456057_value", "id"),
        "Guid"
      ),
      AltKatalogID_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    stateVars.Confirm = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "ca9c5b99_7ebc_b21a_1280_794ab8306d00_confirmation",
        this.defaultML,
        "Seçilen Kayıt Silinecek; Devam Edilsin mi?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel",
      customClass: {
        title: "swal2-font-poppins"
      }
    });

    if (stateVars.Confirm.isConfirmed) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "AltKatalogKart/AltKatalogKartComponent_254838_onClick",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.AltKatalogBaglantiliMeslekBirligiDelete = result?.data.altKatalogBaglantiliMeslekBirligiDelete;
      stateVars.getAltKatalogBaglantiliMeslekBirligiListByAKId =
        result?.data.getAltKatalogBaglantiliMeslekBirligiListByAKId;

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.AltKatalogKartComponent_254838_onClick1_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  AltKatalogKartComponent_254838_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_456057 = this.state.getAltKatalogBaglantiliMeslekBirligiListByAKId;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  AltKatalogKartComponent_826606_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "AltKatalogBaglantiliMeslekBirligiKart",
      "prmGuid",
      ReactSystemFunctions.value(this, "altkatalogkart_456057_value", "id")
    );
    KuikaAppManager.addToPageInputVariables(
      "AltKatalogBaglantiliMeslekBirligiKart",
      "prmAltKatalogiID",
      this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "AltKatalogKart",
      "AltKatalogBaglantiliMeslekBirligiKart",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "659105",
      null,
      "right",
      null,
      "480px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  AltKatalogKartComponent_877038_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    if (await this.AltKatalogKartComponent_356553_onClick()) return true;

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables("KatalogPayDagilimKart", "prmGuid", Guid.create().toString());
    KuikaAppManager.addToPageInputVariables(
      "KatalogPayDagilimKart",
      "prmAltKatalogID",
      this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      this.AltKatalogKartComponent_877038_onClick1_,
      "AltKatalogKart",
      "KatalogPayDagilimKart",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "725693",
      null,
      "right",
      null,
      "380px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };
  AltKatalogKartComponent_877038_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      AltKatalogID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "AltKatalogKart/AltKatalogKartComponent_877038_onClick1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.getListKatalogPayDagilimByKId = result?.data.getListKatalogPayDagilimByKId;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.AltKatalogKartComponent_877038_onClick2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  AltKatalogKartComponent_877038_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_965575 = this.state.getListKatalogPayDagilimByKId;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  AltKatalogKartComponent_601641_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "MusteriKart",
      "prmGuid",
      ReactSystemFunctions.value(this, "altkatalogkart_965575_value", "dagitimYapilacakKodu")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "AltKatalogKart",
      "MusteriKart",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  AltKatalogKartComponent_707970_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "altkatalogkart_965575_value", "id"),
        "Guid"
      ),
      AltKatalogID_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    stateVars.Confirm = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "f503fd8a_2e03_248c_11ea_8952a307ae5a_confirmation",
        this.defaultML,
        "Seçilen Kayıt Silinecek; Devam Edilsin mi?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel",
      customClass: {
        title: "swal2-font-poppins"
      }
    });

    if (stateVars.Confirm.isConfirmed) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "AltKatalogKart/AltKatalogKartComponent_707970_onClick",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.KatalogPayDagilimDelete = result?.data.katalogPayDagilimDelete;
      stateVars.getListKatalogPayDagilimByKId = result?.data.getListKatalogPayDagilimByKId;

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.AltKatalogKartComponent_707970_onClick1_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  AltKatalogKartComponent_707970_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_965575 = this.state.getListKatalogPayDagilimByKId;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  AltKatalogKartComponent_101637_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "KatalogPayDagilimKart",
      "prmGuid",
      ReactSystemFunctions.value(this, "altkatalogkart_965575_value", "id")
    );
    KuikaAppManager.addToPageInputVariables(
      "KatalogPayDagilimKart",
      "prmAltKatalogID",
      this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "AltKatalogKart",
      "KatalogPayDagilimKart",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "3551",
      null,
      "right",
      null,
      "380px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  AltKatalogKartComponent_21029_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp400676Visible");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  AltKatalogKartComponent_861691_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  AltKatalogKartComponent_356553_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      AltKatalogAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "altkatalogkart_802808_value", "value", "", "", "")
        ),
        "string"
      ),
      AltKatalogKodu_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "altkatalogkart_851651_value", "value", "", "", "")
        ),
        "string"
      ),
      AnaKatalogID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmKatalogId ?? this.props.screenInputs.prmkatalogid,
        "Guid"
      ),
      MuhasebeKodu_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "altkatalogkart_164704_value", "value", "", "", "")
        ),
        "string"
      ),
      PTS_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "altkatalogkart_480329_value", "value", "", "", "")
        ),
        "string"
      ),
      PTT_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toInteger(
          this,
          ReactSystemFunctions.value(this, "altkatalogkart_925382_value", "value", "", "key", "")
        ),
        "number"
      ),
      PTTT_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "altkatalogkart_14602_value", "value", "", "", "")
        ),
        "Date"
      ),
      TBasTar_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "altkatalogkart_930853_value", "value", "", "", "")
        ),
        "Date"
      ),
      TBitTar_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "altkatalogkart_855917_value", "value", "", "", "")
        ),
        "Date"
      ),
      TemsilcilikDurumu_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toInteger(
          this,
          ReactSystemFunctions.value(this, "altkatalogkart_33187_value", "value", "", "key", "")
        ),
        "number"
      ),
      Yenileme_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toInteger(
          this,
          ReactSystemFunctions.value(this, "altkatalogkart_383603_value", "value", "", "key", "")
        ),
        "number"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      ),
      TemsilcilikDurumu_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toInteger(
          this,
          ReactSystemFunctions.value(this, "altkatalogkart_33187_value", "value", "", "key", "")
        ),
        "number"
      ),
      AltKatalogID_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "AltKatalogKart/AltKatalogKartComponent_356553_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.AltKatalogSave = result?.data.altKatalogSave;
    stateVars.StokTemsilcilikDurumuUpdateByAltKatalogID = result?.data.stokTemsilcilikDurumuUpdateByAltKatalogID;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [820946, 433350, 659105, 725693, , 3551] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.AltKatalogKartPageInit();
    }
    if (diId == 433350) {
      isErrorOccurred = await this.AltKatalogKartComponent_447183_onClick1_();
      if (isErrorOccurred) return true;
    } else if (diId == 725693) {
      isErrorOccurred = await this.AltKatalogKartComponent_877038_onClick1_();
      if (isErrorOccurred) return true;
    }
  }
}
