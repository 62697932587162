import { FormInstance } from "antd/lib/form";
import { Guid } from "guid-typescript";
import React from "react";
import Swal from "sweetalert2";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IIListMeslekBirligiY_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IIListMeslekBirligiY_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  spIListMeslekBirligiY: any[];
  spIListMeslekBirligiY_dummy: any[];
  spIListMeslekBirligiYCount: any[];
  spIListMeslekBirligiYCount_dummy: any[];
  spIKartDelete: any[];
  spIKartDelete_dummy: any[];
  isComp852895Visible: "visible" | "hidden";
  isComp252835Visible: "visible" | "hidden";
  isCompilistmeslekbirligiy_350278AuthorizationVisible: "visible" | "hidden";
}

export class IListMeslekBirligiY_ScreenBase extends React.PureComponent<IIListMeslekBirligiY_ScreenProps, any> {
  ilistmeslekbirligiy_181000_value_kuikaTableRef: React.RefObject<any>;
  ilistmeslekbirligiy_555790_value_kuikaPaginationRef: React.RefObject<any>;
  ml = [
    {
      Id: "16c151eb-42cf-0b2b-7e4a-e8b04fc43d88",
      Name: "tr_TR",
      ShortName: "Turkish",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "fbdbce9f-3fdb-30c5-c4fe-f7e443068251", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    { Id: "8468043e-f025-01e1-93b8-c71e3215778a", Name: "de_DE", ShortName: "German", LanguagePhrases: [] },
    {
      Id: "83717667-43ef-4d09-9e30-4de0cee2fcdd",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 513636, PropertyName: "value", Value: "MESLEK BİRLİĞİ WW" },
        { Id: 241632, PropertyName: "placeholder", Value: "Aranacak kelimeyi yazınız..." },
        { Id: 181000, PropertyName: "nodatafoundmessage", Value: "Veri Bulunamadı" },
        { Id: 668335, PropertyName: "title", Value: "Müşteri Adı" },
        { Id: 889613, PropertyName: "value", Value: "[datafield:cariad]" },
        { Id: 295011, PropertyName: "title", Value: "Sektörel Adı" },
        { Id: 538790, PropertyName: "value", Value: "[datafield:sektorelad]" },
        { Id: 200214, PropertyName: "title", Value: "Temsil Bölgesi" },
        { Id: 245227, PropertyName: "value", Value: "[datafield:yapimadi]" },
        { Id: 852895, PropertyName: "title", Value: "Kanallar" },
        { Id: 627915, PropertyName: "value", Value: "[datafield:kanals]" },
        { Id: 252835, PropertyName: "title", Value: "Liste Gönderim Dönem" },
        { Id: 232850, PropertyName: "value", Value: "[datafield:sozlesmedonem]" },
        { Id: 879882, PropertyName: "title", Value: "Sözleşme Durumu" },
        { Id: 555379, PropertyName: "value", Value: "[datafield:sozlesmedurum]" }
      ]
    }
  ];
  defaultML = "tr_TR";
  screenHash = "";

  constructor(props) {
    super(props);
    this.ilistmeslekbirligiy_181000_value_kuikaTableRef = React.createRef();
    this.ilistmeslekbirligiy_555790_value_kuikaPaginationRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      NAVIGATE: "",
      spIListMeslekBirligiY: [],
      spIListMeslekBirligiYCount: [],
      spIKartDelete: [],
      isComp852895Visible: "hidden",
      isComp252835Visible: "hidden",
      isCompilistmeslekbirligiy_350278AuthorizationVisible: "visible"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("ilistmeslekbirligiy", "");
      return;
    }

    if (true) {
      await this.IListMeslekBirligiYPageInit();
    }
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompilistmeslekbirligiy_350278AuthorizationVisible", [
      "89f0bd8c-62b8-cf33-3763-edf7319ca78f",
      "Admin"
    ]);

    KuikaAppManager.calculateAndSetBodyHeight("ilistmeslekbirligiy", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("ilistmeslekbirligiy", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.IListMeslekBirligiYPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  IListMeslekBirligiYPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      Search_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ilistmeslekbirligiy_241632_value", "value", "", "", "")
        ),
        "string"
      ),
      PageNumber_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ilistmeslekbirligiy_555790_value", "current", "", "", "")
        ),
        "number"
      ),
      RowsOfPage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ilistmeslekbirligiy_555790_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      Search_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ilistmeslekbirligiy_241632_value", "value", "", "", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "IListMeslekBirligiY/IListMeslekBirligiYPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.spIListMeslekBirligiY = result?.data.spIListMeslekBirligiY;

    stateVars.spIListMeslekBirligiYCount = result?.data.spIListMeslekBirligiYCount;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.IListMeslekBirligiYPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  IListMeslekBirligiYPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_181000 = this.state.spIListMeslekBirligiY;
    formVars.ilistmeslekbirligiy_555790_total = ReactSystemFunctions.value(
      this,
      this.state.spIListMeslekBirligiYCount?.length > 0 ? this.state.spIListMeslekBirligiYCount[0]?.sumCount : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  IListMeslekBirligiYComponent_798391_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "IListMeslekBirligiY",
      "LeftMenu",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "294787",
      "",
      "left",
      null,
      "",
      "",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  IListMeslekBirligiYComponent_36053_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables("IKartMeslekBirligiY", "prmGuid", Guid.create().toString());

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "IListMeslekBirligiY",
      "IKartMeslekBirligiY",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  IListMeslekBirligiYComponent_241632_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Search_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ilistmeslekbirligiy_241632_value", "value", "", "", "")
        ),
        "string"
      ),
      PageNumber_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ilistmeslekbirligiy_555790_value", "current", "", "", "")
        ),
        "number"
      ),
      RowsOfPage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ilistmeslekbirligiy_555790_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      Search_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ilistmeslekbirligiy_241632_value", "value", "", "", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "IListMeslekBirligiY/IListMeslekBirligiYComponent_241632_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.spIListMeslekBirligiY = result?.data.spIListMeslekBirligiY;

    stateVars.spIListMeslekBirligiYCount = result?.data.spIListMeslekBirligiYCount;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.IListMeslekBirligiYComponent_241632_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  IListMeslekBirligiYComponent_241632_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_181000 = this.state.spIListMeslekBirligiY;
    formVars.ilistmeslekbirligiy_555790_total = ReactSystemFunctions.value(
      this,
      this.state.spIListMeslekBirligiYCount?.length > 0 ? this.state.spIListMeslekBirligiYCount[0]?.sumCount : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  IListMeslekBirligiYComponent_350278_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      IKartTur_0: ReactSystemFunctions.convertToTypeByName("MBY", "string"),
      IKartId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "ilistmeslekbirligiy_181000_value", "id"),
        "string"
      ),
      Search_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ilistmeslekbirligiy_241632_value", "value", "", "", "")
        ),
        "string"
      ),
      PageNumber_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ilistmeslekbirligiy_555790_value", "current", "", "", "")
        ),
        "number"
      ),
      RowsOfPage_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ilistmeslekbirligiy_555790_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      Search_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ilistmeslekbirligiy_241632_value", "value", "", "", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    stateVars.Confirm = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "827dbed9_5166_ecff_cfaf_bf424498a0d9_confirmation",
        this.defaultML,
        "Seçilen Kayıt Silinecek; Devam edilsin mi?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel",
      customClass: {
        title: "swal2-font-poppins"
      }
    });

    if (stateVars.Confirm.isConfirmed) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "IListMeslekBirligiY/IListMeslekBirligiYComponent_350278_onClick",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.spIKartDelete = result?.data.spIKartDelete;
      stateVars.spIListMeslekBirligiY = result?.data.spIListMeslekBirligiY;

      stateVars.spIListMeslekBirligiYCount = result?.data.spIListMeslekBirligiYCount;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.IListMeslekBirligiYComponent_350278_onClick1_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  IListMeslekBirligiYComponent_350278_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_181000 = this.state.spIListMeslekBirligiY;
    formVars.ilistmeslekbirligiy_555790_total = ReactSystemFunctions.value(
      this,
      this.state.spIListMeslekBirligiYCount?.length > 0 ? this.state.spIListMeslekBirligiYCount[0]?.sumCount : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  IListMeslekBirligiYComponent_209203_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "IKartMeslekBirligiY",
      "prmGuid",
      ReactSystemFunctions.value(this, "ilistmeslekbirligiy_181000_value", "id")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "IListMeslekBirligiY",
      "IKartMeslekBirligiY",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  IListMeslekBirligiYComponent_555790_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Search_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ilistmeslekbirligiy_241632_value", "value", "", "", "")
        ),
        "string"
      ),
      PageNumber_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ilistmeslekbirligiy_555790_value", "current", "", "", "")
        ),
        "number"
      ),
      RowsOfPage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ilistmeslekbirligiy_555790_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "IListMeslekBirligiY/IListMeslekBirligiYComponent_555790_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.spIListMeslekBirligiY = result?.data.spIListMeslekBirligiY;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.IListMeslekBirligiYComponent_555790_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  IListMeslekBirligiYComponent_555790_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_181000 = this.state.spIListMeslekBirligiY;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [294787] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.IListMeslekBirligiYPageInit();
    }
  }
}
