import { Form, Spin, Typography } from "antd";
import { connect } from "react-redux";
import { Button, Footer, Header, Icon, KCol, KRow, Label, TextInput } from "../../components/web-components";
import { Spacer } from "../../kuika";
import {
  hideIyzicoDrawer,
  hidePhotoTake,
  hideStripeDrawer,
  showIyzicoDrawer,
  showPhotoTake,
  showStripeDrawer
} from "../../redux/photo-take/actions";
import { AppState } from "../../redux/root-reducer";
import { withContext } from "../../shared/hoc/with-context";
import { withForm } from "../../shared/hoc/with-form";
import { withGoogleLogin } from "../../shared/hoc/with-google-login";
import { withHistory } from "../../shared/hoc/with-history";
import { withScreenInput } from "../../shared/hoc/with-screen-input";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";
import { EserKayitKart_ScreenBase } from "./eserkayitkart-base";

declare let window: any;
const { Title, Paragraph, Text, Link } = Typography;
const menuMap = [];

class EserKayitKart_Screen extends EserKayitKart_ScreenBase {
  render() {
    if (this.state.isPageVisible === false) {
      return <></>;
    }
    return (
      <>
        <Spin spinning={this.state.spinnerCount > 0}>
          <Form
            form={this.props.form}
            name="basic"
            initialValues={{
              eserkayitkart_980508_value: this.state.EserKayitlariKartById?.at?.(0)?.seslendiren ?? undefined,
              eserkayitkart_329046_value: this.state.EserKayitlariKartById?.at?.(0)?.yapimSirketi ?? undefined,
              eserkayitkart_344736_value: this.state.EserKayitlariKartById?.at?.(0)?.kayitYili ?? undefined,
              eserkayitkart_841928_value: this.state.EserKayitlariKartById?.at?.(0)?.isrc ?? undefined
            }}
          >
            <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
              <Header
                id="eserkayitkart_header"
                style={
                  {
                    borderStyle: "solid",
                    borderTopWidth: 0,
                    borderRightWidth: 0,
                    borderBottomWidth: 0,
                    borderLeftWidth: 0,
                    backgroundColor: "rgba(255, 255, 255, 1)",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                    backgroundPosition: "left",
                    paddingTop: 0,
                    paddingRight: 0,
                    paddingBottom: 0,
                    paddingLeft: 0,
                    fontFamily: "Poppins",
                    fontWeight: 500,
                    fontSize: "14px",
                    color: "rgba(68, 68, 68, 1)",
                    letterSpacing: "0.5px"
                  } as any
                }
              >
                <KRow
                  id="414222"
                  align="top"
                  justify="start"
                  horizontalGutter={0}
                  verticalGutter={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 16,
                      paddingBottom: 16,
                      fontWeight: 500,
                      fontSize: "14px",
                      letterSpacing: "0.5px"
                    } as any
                  }
                >
                  <KCol
                    id="575854"
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={24}
                    xxl={24}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "contain",
                        backgroundPosition: "left",
                        paddingRight: 16,
                        paddingLeft: 16,
                        textAlign: "-webkit-right",
                        fontWeight: 500,
                        fontSize: "14px",
                        letterSpacing: "0.5px"
                      } as any
                    }
                  >
                    <Icon
                      id="896409"
                      onClick={(e?: any) => {
                        if (e && e.stopPropagation) e.stopPropagation();
                        this.EserKayitKartComponent_896409_onClick();
                      }}
                      showCursorPointer
                      iconName="close"
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 0,
                          paddingRight: 0,
                          paddingBottom: 0,
                          paddingLeft: 0,
                          fontSize: "24px",
                          color: "rgba(172, 38, 42, 1)"
                        } as any
                      }
                    ></Icon>
                  </KCol>
                </KRow>
              </Header>

              <div
                id="eserkayitkart_body"
                style={
                  {
                    borderStyle: "solid",
                    borderTopWidth: 0,
                    borderRightWidth: 0,
                    borderBottomWidth: 0,
                    borderLeftWidth: 0,
                    backgroundColor: "rgba(255, 255, 255, 1)",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                    backgroundPosition: "left",
                    display: "block"
                  } as any
                }
              >
                <KRow
                  id="187805"
                  align="top"
                  justify="start"
                  horizontalGutter={0}
                  verticalGutter={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 16,
                      paddingBottom: 16,
                      fontWeight: 500,
                      fontSize: "14px",
                      letterSpacing: "0.5px"
                    } as any
                  }
                >
                  <KCol
                    id="606402"
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={24}
                    xxl={24}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "contain",
                        backgroundPosition: "left",
                        paddingRight: 16,
                        paddingLeft: 16,
                        fontWeight: 500,
                        fontSize: "14px",
                        letterSpacing: "0.5px"
                      } as any
                    }
                  >
                    <Label
                      id="993411"
                      value={ReactSystemFunctions.translate(this.ml, 993411, "value", this.defaultML)}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          alignItems: "center",
                          textAlign: "-webkit-left",
                          display: "inline",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "14px",
                          color: "rgba(68, 68, 68, 1)",
                          letterSpacing: "0.5px"
                        } as any
                      }
                    ></Label>

                    <Form.Item className="kFormItem" name="eserkayitkart_980508_value">
                      <TextInput
                        id="980508"
                        placeholder={ReactSystemFunctions.translate(this.ml, 980508, "placeholder", this.defaultML)}
                        allowClear={false}
                        bordered={true}
                        disabled={false}
                        type="text"
                        iconColor="rgba(0, 0, 0, 1)"
                        formatter="None"
                        isCharOnly={false}
                        style={
                          {
                            borderBottomWidth: 1,
                            borderColor: "rgba(155, 155, 155, 1)",
                            borderStyle: "dotted",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderLeftWidth: 0,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "left",
                            height: "36px",
                            display: "block",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "12px",
                            color: "rgba(47, 47, 47, 1)",
                            letterSpacing: "0.5px"
                          } as any
                        }
                      ></TextInput>
                    </Form.Item>
                  </KCol>
                </KRow>

                <KRow
                  id="675593"
                  align="top"
                  justify="start"
                  horizontalGutter={0}
                  verticalGutter={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 16,
                      paddingBottom: 16,
                      fontWeight: 500,
                      fontSize: "14px",
                      letterSpacing: "0.5px"
                    } as any
                  }
                >
                  <KCol
                    id="479358"
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={24}
                    xxl={24}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingRight: 16,
                        paddingLeft: 16,
                        fontWeight: 500,
                        fontSize: "14px",
                        letterSpacing: "0.5px"
                      } as any
                    }
                  >
                    <Label
                      id="11299"
                      value={ReactSystemFunctions.translate(this.ml, 11299, "value", this.defaultML)}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          alignItems: "center",
                          textAlign: "-webkit-left",
                          display: "inline",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "14px",
                          color: "rgba(68, 68, 68, 1)",
                          letterSpacing: "0.5px"
                        } as any
                      }
                    ></Label>

                    <Form.Item className="kFormItem" name="eserkayitkart_329046_value">
                      <TextInput
                        id="329046"
                        placeholder={ReactSystemFunctions.translate(this.ml, 329046, "placeholder", this.defaultML)}
                        allowClear={false}
                        bordered={true}
                        disabled={false}
                        type="text"
                        iconColor="rgba(0, 0, 0, 1)"
                        formatter="None"
                        isCharOnly={false}
                        style={
                          {
                            borderBottomWidth: 1,
                            borderColor: "rgba(155, 155, 155, 1)",
                            borderStyle: "dotted",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderLeftWidth: 0,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "left",
                            height: "36px",
                            display: "block",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "12px",
                            color: "rgba(47, 47, 47, 1)",
                            letterSpacing: "0.5px"
                          } as any
                        }
                      ></TextInput>
                    </Form.Item>
                  </KCol>
                </KRow>

                <KRow
                  id="784430"
                  align="top"
                  justify="start"
                  horizontalGutter={0}
                  verticalGutter={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 16,
                      paddingBottom: 16,
                      fontWeight: 500,
                      fontSize: "14px",
                      letterSpacing: "0.5px"
                    } as any
                  }
                >
                  <KCol
                    id="353054"
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={24}
                    xxl={24}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingRight: 16,
                        paddingLeft: 16,
                        fontWeight: 500,
                        fontSize: "14px",
                        letterSpacing: "0.5px"
                      } as any
                    }
                  >
                    <Label
                      id="807853"
                      value={ReactSystemFunctions.translate(this.ml, 807853, "value", this.defaultML)}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          alignItems: "center",
                          textAlign: "-webkit-left",
                          display: "inline",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "14px",
                          color: "rgba(68, 68, 68, 1)",
                          letterSpacing: "0.5px"
                        } as any
                      }
                    ></Label>

                    <Form.Item className="kFormItem" name="eserkayitkart_344736_value">
                      <TextInput
                        id="344736"
                        placeholder={ReactSystemFunctions.translate(this.ml, 344736, "placeholder", this.defaultML)}
                        allowClear={false}
                        bordered={true}
                        disabled={false}
                        type="text"
                        iconColor="rgba(0, 0, 0, 1)"
                        formatter="None"
                        isCharOnly={false}
                        style={
                          {
                            borderBottomWidth: 1,
                            borderColor: "rgba(155, 155, 155, 1)",
                            borderStyle: "dotted",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderLeftWidth: 0,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "left",
                            height: "36px",
                            display: "block",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "12px",
                            color: "rgba(47, 47, 47, 1)",
                            letterSpacing: "0.5px"
                          } as any
                        }
                      ></TextInput>
                    </Form.Item>
                  </KCol>
                </KRow>

                <KRow
                  id="535955"
                  align="top"
                  justify="start"
                  horizontalGutter={0}
                  verticalGutter={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 16,
                      paddingBottom: 16,
                      fontWeight: 500,
                      fontSize: "14px",
                      letterSpacing: "0.5px"
                    } as any
                  }
                >
                  <KCol
                    id="106458"
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={24}
                    xxl={24}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingRight: 16,
                        paddingLeft: 16,
                        fontWeight: 500,
                        fontSize: "14px",
                        letterSpacing: "0.5px"
                      } as any
                    }
                  >
                    <Label
                      id="91291"
                      value={ReactSystemFunctions.translate(this.ml, 91291, "value", this.defaultML)}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          alignItems: "center",
                          textAlign: "-webkit-left",
                          display: "inline",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "14px",
                          color: "rgba(68, 68, 68, 1)",
                          letterSpacing: "0.5px"
                        } as any
                      }
                    ></Label>

                    <Form.Item className="kFormItem" name="eserkayitkart_841928_value">
                      <TextInput
                        id="841928"
                        placeholder={ReactSystemFunctions.translate(this.ml, 841928, "placeholder", this.defaultML)}
                        allowClear={false}
                        bordered={true}
                        disabled={false}
                        type="text"
                        iconColor="rgba(0, 0, 0, 1)"
                        formatter="None"
                        isCharOnly={false}
                        style={
                          {
                            borderBottomWidth: 1,
                            borderColor: "rgba(155, 155, 155, 1)",
                            borderStyle: "dotted",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderLeftWidth: 0,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "left",
                            height: "36px",
                            display: "block",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "12px",
                            color: "rgba(47, 47, 47, 1)",
                            letterSpacing: "0.5px"
                          } as any
                        }
                      ></TextInput>
                    </Form.Item>
                  </KCol>
                </KRow>
              </div>

              <Footer
                id="eserkayitkart_footer"
                style={
                  {
                    borderStyle: "solid",
                    borderTopWidth: 0,
                    borderRightWidth: 0,
                    borderBottomWidth: 0,
                    borderLeftWidth: 0,
                    backgroundColor: "rgba(255, 255, 255, 1)",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                    backgroundPosition: "left",
                    paddingTop: 0,
                    paddingRight: 0,
                    paddingBottom: 0,
                    paddingLeft: 0,
                    fontFamily: "Poppins",
                    fontWeight: 500,
                    fontSize: "14px",
                    color: "rgba(68, 68, 68, 1)",
                    letterSpacing: "0.5px"
                  } as any
                }
              >
                <KRow
                  id="87166"
                  align="top"
                  justify="start"
                  horizontalGutter={0}
                  verticalGutter={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 16,
                      paddingBottom: 16,
                      fontWeight: 500,
                      fontSize: "14px",
                      letterSpacing: "0.5px"
                    } as any
                  }
                >
                  <KCol
                    id="602881"
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={24}
                    xxl={24}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingRight: 16,
                        paddingLeft: 16,
                        textAlign: "-webkit-right",
                        fontWeight: 500,
                        fontSize: "14px",
                        letterSpacing: "0.5px"
                      } as any
                    }
                  >
                    <Spacer id="720381" direction="horizontal" size={10} style={{} as any}>
                      <Button
                        id="604367"
                        onClick={(e?: any) => {
                          if (e && e.stopPropagation) e.stopPropagation();
                          this.EserKayitKartComponent_604367_onClick();
                        }}
                        showCursorPointer
                        label={ReactSystemFunctions.translate(this.ml, 604367, "label", this.defaultML)}
                        size="middle"
                        loading={false}
                        ghost={false}
                        block={false}
                        htmlType="button"
                        iconPosition="left"
                        danger={false}
                        style={
                          {
                            borderTopLeftRadius: 200,
                            borderTopRightRadius: 200,
                            borderBottomRightRadius: 200,
                            borderBottomLeftRadius: 200,
                            borderColor: "rgba(172, 38, 42, 1)",
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            backgroundColor: "rgba(172, 38, 42, 1)",
                            paddingTop: 8,
                            paddingRight: 16,
                            paddingBottom: 8,
                            paddingLeft: 16,
                            width: "140px",
                            alignItems: "center",
                            textAlign: "-webkit-center",
                            fontWeight: 500,
                            fontSize: "16px",
                            color: "rgba(255, 255, 255, 1)",
                            letterSpacing: "0.5px"
                          } as any
                        }
                      ></Button>
                    </Spacer>
                  </KCol>
                </KRow>
              </Footer>
            </div>
          </Form>
        </Spin>
      </>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  return {
    spinnerCount: state.spinnerReducer.spinnerCount,
    photoTakeVisible: state.photoTakeReducer.visible
  };
};
const mapDispatchToProps = (dispatch) => ({
  showPhotoTake: () => dispatch(showPhotoTake()),
  hidePhotoTake: () => dispatch(hidePhotoTake()),
  showStripeDrawer: (data, callback, that, future) => dispatch(showStripeDrawer(data, callback, that, future)),
  hideStripeDrawer: (data, callback, that) => dispatch(hideStripeDrawer(data, callback, that)),
  showIyzicoDrawer: (data, callback, that) => dispatch(showIyzicoDrawer(data, callback, that)),
  hideIyzicoDrawer: (data, callback, that) => dispatch(hideIyzicoDrawer(data, callback, that))
});
const tmp = withGoogleLogin(
  withContext(
    withScreenInput(withForm(withHistory(connect(mapStateToProps, mapDispatchToProps)(EserKayitKart_Screen))))
  )
);
export { tmp as EserKayitKart_Screen };
//export default tmp;
//export { tmp as EserKayitKart_Screen };
