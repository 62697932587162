import { FormInstance } from "antd/lib/form";
import { Guid } from "guid-typescript";
import React from "react";
import Swal from "sweetalert2";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IUrunKart_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IUrunKart_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  GoBack: any;
  GoBack_dummy: any;
  sbEserTipiListYeni: any[];
  sbEserTipiListYeni_dummy: any[];
  getStokKartbyId: any[];
  getStokKartbyId_dummy: any[];
  getEserKayitlariListByStokId: any[];
  getEserKayitlariListByStokId_dummy: any[];
  getEserPayDagilimListByStokId: any[];
  getEserPayDagilimListByStokId_dummy: any[];
  getEserPayDagilimTelifsizListByStokId: any[];
  getEserPayDagilimTelifsizListByStokId_dummy: any[];
  sbAnaKatalogList: any[];
  sbAnaKatalogList_dummy: any[];
  sbAltKatalogList: any[];
  sbAltKatalogList_dummy: any[];
  spTarihceEser: any[];
  spTarihceEser_dummy: any[];
  ChangeVisibilityOf: string;
  ChangeVisibilityOf_dummy: string;
  TriggerEvent: string;
  TriggerEvent_dummy: string;
  EserKayitlariDelete: number;
  EserKayitlariDelete_dummy: number;
  setEserPayDagilimDelete: number;
  setEserPayDagilimDelete_dummy: number;
  setEserPayDagilimTelifsizDelete: number;
  setEserPayDagilimTelifsizDelete_dummy: number;
  ToggleVisibilityOf: string;
  ToggleVisibilityOf_dummy: string;
  SaveStokM: number;
  SaveStokM_dummy: number;
  SaveStokT: number;
  SaveStokT_dummy: number;
  setUpdateTicariYayimNo: number;
  setUpdateTicariYayimNo_dummy: number;
  SaveStokB: number;
  SaveStokB_dummy: number;
  isComp868926Visible: "visible" | "hidden";
  isComp782375Visible: "visible" | "hidden";
  isComp352631Visible: "visible" | "hidden";
  isComp370656Visible: "visible" | "hidden";
  isComp884751Visible: "visible" | "hidden";
  isComp439236Visible: "visible" | "hidden";
  isComp960168Visible: "visible" | "hidden";
  isComp230593Visible: "visible" | "hidden";
  isComp707570Visible: "visible" | "hidden";
  isComp944587Visible: "visible" | "hidden";
  isComp3319Visible: "visible" | "hidden";
  isComp855521Visible: "visible" | "hidden";
  isComp737157Visible: "visible" | "hidden";
  isComp580090Visible: "visible" | "hidden";
  isComp71001Visible: "visible" | "hidden";
  isComp398542Visible: "visible" | "hidden";
  isComp336812Visible: "visible" | "hidden";
  isCompurunkart_684462AuthorizationVisible: "visible" | "hidden";
  isCompurunkart_155599AuthorizationVisible: "visible" | "hidden";
  isCompurunkart_351695AuthorizationVisible: "visible" | "hidden";
  isCompurunkart_492388AuthorizationVisible: "visible" | "hidden";
  isCompurunkart_537689AuthorizationVisible: "visible" | "hidden";
  isCompurunkart_525158AuthorizationVisible: "visible" | "hidden";
  isCompurunkart_211241AuthorizationVisible: "visible" | "hidden";
  isCompurunkart_553764AuthorizationVisible: "visible" | "hidden";
  isCompurunkart_555720AuthorizationVisible: "visible" | "hidden";
  isCompurunkart_556966AuthorizationVisible: "visible" | "hidden";
  isCompurunkart_353506AuthorizationVisible: "visible" | "hidden";
  isCompurunkart_601868AuthorizationVisible: "visible" | "hidden";
}

export class UrunKart_ScreenBase extends React.PureComponent<IUrunKart_ScreenProps, any> {
  urunkart_868633_value_kuikaSelectBoxRef: React.RefObject<any>;
  urunkart_520044_value_kuikaDateRef: React.RefObject<any>;
  urunkart_360462_value_kuikaDateRef: React.RefObject<any>;
  urunkart_159707_value_kuikaDateRef: React.RefObject<any>;
  urunkart_261881_value_kuikaTableRef: React.RefObject<any>;
  urunkart_578735_value_kuikaSelectBoxRef: React.RefObject<any>;
  urunkart_234730_value_kuikaSelectBoxRef: React.RefObject<any>;
  urunkart_761278_value_kuikaSelectBoxRef: React.RefObject<any>;
  urunkart_981337_value_kuikaTableRef: React.RefObject<any>;
  urunkart_473103_value_kuikaTableRef: React.RefObject<any>;
  urunkart_782375_value_kuikaTableRef: React.RefObject<any>;
  ml = [
    {
      Id: "16c151eb-42cf-0b2b-7e4a-e8b04fc43d88",
      Name: "tr_TR",
      ShortName: "Turkish",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "fbdbce9f-3fdb-30c5-c4fe-f7e443068251", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    { Id: "8468043e-f025-01e1-93b8-c71e3215778a", Name: "de_DE", ShortName: "German", LanguagePhrases: [] },
    {
      Id: "2ca36b47-1360-4025-8061-cb01fd9233c9",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 554046, PropertyName: "value", Value: "Eser  Bilgileri" },
        { Id: 12763, PropertyName: "value", Value: "Eser Tipi" },
        { Id: 868633, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 168968, PropertyName: "value", Value: "Eser Adı" },
        { Id: 6285, PropertyName: "placeholder", Value: "..." },
        { Id: 59872, PropertyName: "value", Value: "Alternatif Eser Adı" },
        { Id: 370468, PropertyName: "placeholder", Value: "..." },
        { Id: 8015, PropertyName: "value", Value: "Adapte Eser ise Orjinal Eser Adı" },
        { Id: 563580, PropertyName: "placeholder", Value: "..." },
        { Id: 199175, PropertyName: "value", Value: "Albüm Numarası" },
        { Id: 986980, PropertyName: "placeholder", Value: "..." },
        { Id: 207512, PropertyName: "value", Value: "Albüm Adı" },
        { Id: 519113, PropertyName: "placeholder", Value: "..." },
        { Id: 482170, PropertyName: "value", Value: "Albüm Track No" },
        { Id: 93994, PropertyName: "value", Value: "Versiyon" },
        { Id: 607171, PropertyName: "placeholder", Value: "..." },
        { Id: 864844, PropertyName: "value", Value: "Süre" },
        { Id: 391357, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 370656, PropertyName: "value", Value: "Track Ad" },
        { Id: 884751, PropertyName: "placeholder", Value: "..." },
        { Id: 254531, PropertyName: "value", Value: "Eser Yayım Sözleşme Tarihi" },
        { Id: 520044, PropertyName: "placeholder", Value: "..." },
        { Id: 560288, PropertyName: "value", Value: "ISWC (Uluslararası Standart Müzik Eser Kodu)" },
        { Id: 972982, PropertyName: "placeholder", Value: "..." },
        { Id: 778437, PropertyName: "value", Value: "Eser Yayın (Release) Tarihi" },
        { Id: 360462, PropertyName: "placeholder", Value: "..." },
        { Id: 997552, PropertyName: "value", Value: "Orjinal Yapım / Kayıt Sahibi" },
        { Id: 872732, PropertyName: "placeholder", Value: "..." },
        { Id: 665254, PropertyName: "value", Value: "ISRC (Uluslararası Standart Yapım/Kayıt Kodu)" },
        { Id: 776878, PropertyName: "placeholder", Value: "..." },
        { Id: 385167, PropertyName: "value", Value: "Eser Ticari Olarak Yayınlandıysa;" },
        { Id: 256214, PropertyName: "value", Value: "Ticari Albümü Çıkaran" },
        { Id: 869356, PropertyName: "placeholder", Value: "..." },
        { Id: 654716, PropertyName: "value", Value: "Ticari Albüm Adı" },
        { Id: 723070, PropertyName: "placeholder", Value: "..." },
        { Id: 752850, PropertyName: "value", Value: "Eserin Ticari Adı" },
        { Id: 586230, PropertyName: "placeholder", Value: "..." },
        { Id: 119094, PropertyName: "value", Value: "Ticari Yayın (Release) Tarihi" },
        { Id: 159707, PropertyName: "placeholder", Value: "..." },
        { Id: 126278, PropertyName: "value", Value: "Eser Adı" },
        { Id: 110409, PropertyName: "placeholder", Value: "..." },
        { Id: 644974, PropertyName: "value", Value: "Alternatif Eser Adı" },
        { Id: 523571, PropertyName: "placeholder", Value: "..." },
        { Id: 526195, PropertyName: "value", Value: "Adapte Eser ise Orjinal Eser Adı" },
        { Id: 824162, PropertyName: "placeholder", Value: "..." },
        { Id: 606496, PropertyName: "value", Value: "Söz Varsa, Sözlerin Dili" },
        { Id: 402655, PropertyName: "placeholder", Value: "..." },
        { Id: 912223, PropertyName: "value", Value: "Söz Varsa, Sözlerin İlk Satırı" },
        { Id: 565130, PropertyName: "placeholder", Value: "..." },
        { Id: 959899, PropertyName: "value", Value: "Eserin İlk Tescil Tarihi ve Merci" },
        { Id: 69214, PropertyName: "placeholder", Value: "..." },
        { Id: 345213, PropertyName: "value", Value: "ISWC (Uluslararası Standart Müzik Eser Kodu)" },
        { Id: 370470, PropertyName: "placeholder", Value: "..." },
        { Id: 985024, PropertyName: "value", Value: "Eserin En Popüler Kayıtları" },
        { Id: 261881, PropertyName: "nodatafoundmessage", Value: "Veri Bulunamadı" },
        { Id: 967055, PropertyName: "title", Value: "Seslendiren" },
        { Id: 563782, PropertyName: "value", Value: "[datafield:seslendiren]" },
        { Id: 382867, PropertyName: "title", Value: "Yapım Şirketi" },
        { Id: 15895, PropertyName: "value", Value: "[datafield:yapimsirketi]" },
        { Id: 799944, PropertyName: "title", Value: "Kayıt Yılı" },
        { Id: 588618, PropertyName: "value", Value: "[datafield:kayityili]" },
        { Id: 917033, PropertyName: "title", Value: "ISRC" },
        { Id: 446711, PropertyName: "value", Value: "[datafield:isrc]" },
        { Id: 574931, PropertyName: "value", Value: "Eser Adı" },
        { Id: 600359, PropertyName: "placeholder", Value: "..." },
        { Id: 410211, PropertyName: "value", Value: "Alternatif Eser Adı" },
        { Id: 251494, PropertyName: "placeholder", Value: "..." },
        { Id: 148170, PropertyName: "value", Value: "Albüm Adı" },
        { Id: 745458, PropertyName: "placeholder", Value: "..." },
        { Id: 609799, PropertyName: "value", Value: "Sanatçı Adı" },
        { Id: 285047, PropertyName: "placeholder", Value: "..." },
        { Id: 113936, PropertyName: "value", Value: "Söz Varsa, Sözlerin Dili" },
        { Id: 337728, PropertyName: "placeholder", Value: "..." },
        { Id: 541616, PropertyName: "value", Value: "Kayıt Yılı" },
        { Id: 614200, PropertyName: "placeholder", Value: "..." },
        { Id: 840446, PropertyName: "value", Value: "ISRC" },
        { Id: 298012, PropertyName: "placeholder", Value: "..." },
        { Id: 179534, PropertyName: "value", Value: "Ana Katalog Adı" },
        { Id: 578735, PropertyName: "placeholder", Value: "..." },
        { Id: 581235, PropertyName: "value", Value: "Alt Katalog Kodu" },
        { Id: 234730, PropertyName: "placeholder", Value: "..." },
        { Id: 568440, PropertyName: "value", Value: "Eser Adı" },
        { Id: 659821, PropertyName: "placeholder", Value: "..." },
        { Id: 767695, PropertyName: "value", Value: "Albüm Numarası" },
        { Id: 130710, PropertyName: "placeholder", Value: "..." },
        { Id: 295390, PropertyName: "value", Value: "Albüm Adı" },
        { Id: 590309, PropertyName: "placeholder", Value: "..." },
        { Id: 452135, PropertyName: "value", Value: "Albüm Track No" },
        { Id: 130105, PropertyName: "value", Value: "Versiyon" },
        { Id: 999749, PropertyName: "placeholder", Value: "..." },
        { Id: 731289, PropertyName: "value", Value: "Süre" },
        { Id: 932213, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 740657, PropertyName: "value", Value: "Temsilcilik Durumu" },
        { Id: 761278, PropertyName: "placeholder", Value: "..." },
        { Id: 312838, PropertyName: "value", Value: "Besteciler" },
        { Id: 971818, PropertyName: "placeholder", Value: "..." },
        { Id: 707570, PropertyName: "value", Value: "Muzikotek Eser Sahipleri" },
        { Id: 944587, PropertyName: "value", Value: "Hak Sahipleri" },
        { Id: 981337, PropertyName: "nodatafoundmessage", Value: "Veri Bulunamadı" },
        { Id: 242926, PropertyName: "title", Value: "Eser Sahibi" },
        { Id: 930735, PropertyName: "value", Value: "[datafield:adsoyad]" },
        { Id: 833397, PropertyName: "title", Value: "Görevi" },
        { Id: 870872, PropertyName: "value", Value: "[datafield:gorevi]" },
        { Id: 944008, PropertyName: "title", Value: "Eser Payı" },
        { Id: 238471, PropertyName: "value", Value: "[datafield:hakedisoran]" },
        { Id: 3319, PropertyName: "title", Value: "Yayımcı" },
        { Id: 461838, PropertyName: "value", Value: "[datafield:yayimcitanim]" },
        { Id: 855521, PropertyName: "title", Value: "M.Birliği" },
        { Id: 961374, PropertyName: "value", Value: "[datafield:mbirligitanim]" },
        { Id: 537689, PropertyName: "title", Value: "MUZ (Mekanik Payı)" },
        { Id: 878313, PropertyName: "value", Value: "[datafield:muzoran]" },
        { Id: 525158, PropertyName: "title", Value: "MUZ (Temsili Payı)" },
        { Id: 289488, PropertyName: "value", Value: "[datafield:muzorantemsili]" },
        { Id: 660197, PropertyName: "value", Value: "Diğer Bağlantılı Eser Sahipleri" },
        { Id: 473103, PropertyName: "nodatafoundmessage", Value: "Veri Bulunamadı" },
        { Id: 326858, PropertyName: "title", Value: "Eser Sahibi" },
        { Id: 328365, PropertyName: "value", Value: "[datafield:dagitimyapilacakkisi]" },
        { Id: 811023, PropertyName: "title", Value: "Görevi" },
        { Id: 325514, PropertyName: "value", Value: "[datafield:gorevi]" },
        { Id: 556966, PropertyName: "title", Value: "Eser Payı" },
        { Id: 342468, PropertyName: "value", Value: "[datafield:hakedisoran]" },
        { Id: 413960, PropertyName: "title", Value: "Yayımcı" },
        { Id: 626157, PropertyName: "value", Value: "[datafield:yayimcitanim]" },
        { Id: 902947, PropertyName: "value", Value: "Telif Geçmişini Görüntüle" },
        { Id: 782375, PropertyName: "nodatafoundmessage", Value: "Veri Bulunamadı" },
        { Id: 742021, PropertyName: "title", Value: "İŞ NO" },
        { Id: 547394, PropertyName: "value", Value: "[datafield:ticketno]" },
        { Id: 932266, PropertyName: "title", Value: "TARİH" },
        { Id: 254725, PropertyName: "value", Value: "[datafield:quarterdate]" },
        { Id: 712601, PropertyName: "title", Value: "ESER SAHİBİ" },
        { Id: 228427, PropertyName: "value", Value: "[datafield:esersahibi]" },
        { Id: 939958, PropertyName: "title", Value: "LİSANS TÜRÜ" },
        { Id: 78464, PropertyName: "value", Value: "[datafield:lisansturu]" },
        { Id: 78823, PropertyName: "title", Value: "PROJE ADI" },
        { Id: 540961, PropertyName: "value", Value: "[datafield:projead]" },
        { Id: 96496, PropertyName: "title", Value: "PROJE TÜRÜ" },
        { Id: 623043, PropertyName: "value", Value: "[datafield:projeturu]" },
        { Id: 544260, PropertyName: "title", Value: "KULLANIM TÜRÜ" },
        { Id: 980859, PropertyName: "value", Value: "[datafield:kullanimturu]" },
        { Id: 398542, PropertyName: "label", Value: "Vazgeç" },
        { Id: 336812, PropertyName: "label", Value: "Kaydet" }
      ]
    }
  ];
  defaultML = "tr_TR";
  screenHash = "";

  constructor(props) {
    super(props);
    this.urunkart_868633_value_kuikaSelectBoxRef = React.createRef();
    this.urunkart_520044_value_kuikaDateRef = React.createRef();
    this.urunkart_360462_value_kuikaDateRef = React.createRef();
    this.urunkart_159707_value_kuikaDateRef = React.createRef();
    this.urunkart_261881_value_kuikaTableRef = React.createRef();
    this.urunkart_578735_value_kuikaSelectBoxRef = React.createRef();
    this.urunkart_234730_value_kuikaSelectBoxRef = React.createRef();
    this.urunkart_761278_value_kuikaSelectBoxRef = React.createRef();
    this.urunkart_981337_value_kuikaTableRef = React.createRef();
    this.urunkart_473103_value_kuikaTableRef = React.createRef();
    this.urunkart_782375_value_kuikaTableRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      NAVIGATE: "",
      GoBack: "",
      sbEserTipiListYeni: [],
      getStokKartbyId: [],
      getEserKayitlariListByStokId: [],
      getEserPayDagilimListByStokId: [],
      getEserPayDagilimTelifsizListByStokId: [],
      sbAnaKatalogList: [],
      sbAltKatalogList: [],
      spTarihceEser: [],
      ChangeVisibilityOf: "",
      TriggerEvent: "",
      EserKayitlariDelete: "",
      setEserPayDagilimDelete: "",
      setEserPayDagilimTelifsizDelete: "",
      ToggleVisibilityOf: "",
      SaveStokM: 0,
      SaveStokT: 0,
      setUpdateTicariYayimNo: 0,
      SaveStokB: 0,
      dataSource_761278: [
        { key: "1", text: "Aktif" },
        { key: "2", text: "Post-Term" },
        { key: "0", text: "Korumamızdan Çıktı" }
      ],
      isComp868926Visible: "hidden",
      isComp782375Visible: "hidden",
      isComp352631Visible: "hidden",
      isComp370656Visible: "hidden",
      isComp884751Visible: "hidden",
      isComp439236Visible: "hidden",
      isComp960168Visible: "hidden",
      isComp230593Visible: "hidden",
      isComp707570Visible: "hidden",
      isComp944587Visible: "hidden",
      isComp3319Visible: "hidden",
      isComp855521Visible: "hidden",
      isComp737157Visible: "hidden",
      isComp580090Visible: "hidden",
      isComp71001Visible: "hidden",
      isComp398542Visible: "hidden",
      isComp336812Visible: "hidden",
      isCompurunkart_684462AuthorizationVisible: "visible",
      isCompurunkart_155599AuthorizationVisible: "visible",
      isCompurunkart_351695AuthorizationVisible: "visible",
      isCompurunkart_492388AuthorizationVisible: "visible",
      isCompurunkart_537689AuthorizationVisible: "visible",
      isCompurunkart_525158AuthorizationVisible: "visible",
      isCompurunkart_211241AuthorizationVisible: "visible",
      isCompurunkart_553764AuthorizationVisible: "visible",
      isCompurunkart_555720AuthorizationVisible: "visible",
      isCompurunkart_556966AuthorizationVisible: "visible",
      isCompurunkart_353506AuthorizationVisible: "visible",
      isCompurunkart_601868AuthorizationVisible: "visible"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("urunkart", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.UrunKartPageInit();
    }
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompurunkart_684462AuthorizationVisible", [
      "7165e0ad-e354-6630-d176-a4378c7ac2b9",
      "89f0bd8c-62b8-cf33-3763-edf7319ca78f",
      "5702a90f-4edb-07c0-5096-8ee6a5c813e6",
      "Muhasebe",
      "Admin",
      "Muz Ekip A"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompurunkart_155599AuthorizationVisible", [
      "7165e0ad-e354-6630-d176-a4378c7ac2b9",
      "89f0bd8c-62b8-cf33-3763-edf7319ca78f",
      "5702a90f-4edb-07c0-5096-8ee6a5c813e6",
      "Muhasebe",
      "Admin",
      "Muz Ekip A"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompurunkart_351695AuthorizationVisible", [
      "7165e0ad-e354-6630-d176-a4378c7ac2b9",
      "89f0bd8c-62b8-cf33-3763-edf7319ca78f",
      "5702a90f-4edb-07c0-5096-8ee6a5c813e6",
      "Muhasebe",
      "Admin",
      "Muz Ekip A"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompurunkart_492388AuthorizationVisible", [
      "7165e0ad-e354-6630-d176-a4378c7ac2b9",
      "89f0bd8c-62b8-cf33-3763-edf7319ca78f",
      "5702a90f-4edb-07c0-5096-8ee6a5c813e6",
      "Muhasebe",
      "Admin",
      "Muz Ekip A"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompurunkart_537689AuthorizationVisible", [
      "7165e0ad-e354-6630-d176-a4378c7ac2b9",
      "89f0bd8c-62b8-cf33-3763-edf7319ca78f",
      "5702a90f-4edb-07c0-5096-8ee6a5c813e6",
      "Muhasebe",
      "Admin",
      "Muz Ekip A"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompurunkart_525158AuthorizationVisible", [
      "7165e0ad-e354-6630-d176-a4378c7ac2b9",
      "89f0bd8c-62b8-cf33-3763-edf7319ca78f",
      "5702a90f-4edb-07c0-5096-8ee6a5c813e6",
      "Muhasebe",
      "Admin",
      "Muz Ekip A"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompurunkart_211241AuthorizationVisible", [
      "7165e0ad-e354-6630-d176-a4378c7ac2b9",
      "5702a90f-4edb-07c0-5096-8ee6a5c813e6",
      "89f0bd8c-62b8-cf33-3763-edf7319ca78f",
      "Muhasebe",
      "Admin",
      "Muz Ekip A"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompurunkart_553764AuthorizationVisible", [
      "7165e0ad-e354-6630-d176-a4378c7ac2b9",
      "89f0bd8c-62b8-cf33-3763-edf7319ca78f",
      "5702a90f-4edb-07c0-5096-8ee6a5c813e6",
      "Muhasebe",
      "Admin",
      "Muz Ekip A"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompurunkart_555720AuthorizationVisible", [
      "7165e0ad-e354-6630-d176-a4378c7ac2b9",
      "89f0bd8c-62b8-cf33-3763-edf7319ca78f",
      "5702a90f-4edb-07c0-5096-8ee6a5c813e6",
      "Muhasebe",
      "Admin",
      "Muz Ekip A"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompurunkart_556966AuthorizationVisible", [
      "7165e0ad-e354-6630-d176-a4378c7ac2b9",
      "89f0bd8c-62b8-cf33-3763-edf7319ca78f",
      "5702a90f-4edb-07c0-5096-8ee6a5c813e6",
      "Muhasebe",
      "Admin",
      "Muz Ekip A"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompurunkart_353506AuthorizationVisible", [
      "7165e0ad-e354-6630-d176-a4378c7ac2b9",
      "89f0bd8c-62b8-cf33-3763-edf7319ca78f",
      "5702a90f-4edb-07c0-5096-8ee6a5c813e6",
      "Muhasebe",
      "Admin",
      "Muz Ekip A"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompurunkart_601868AuthorizationVisible", [
      "7165e0ad-e354-6630-d176-a4378c7ac2b9",
      "89f0bd8c-62b8-cf33-3763-edf7319ca78f",
      "5702a90f-4edb-07c0-5096-8ee6a5c813e6",
      "Muhasebe",
      "Admin",
      "Muz Ekip A"
    ]);

    KuikaAppManager.calculateAndSetBodyHeight("urunkart", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("urunkart", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.UrunKartPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      urunkart_868633_value: this.state.getStokKartbyId?.at?.(0)?.eserTipiID ?? undefined,
      urunkart_6285_value: this.state.getStokKartbyId?.at?.(0)?.stokAd ?? undefined,
      urunkart_370468_value: this.state.getStokKartbyId?.at?.(0)?.stokAdAlternatif ?? undefined,
      urunkart_563580_value: this.state.getStokKartbyId?.at?.(0)?.stokAdAdapte ?? undefined,
      urunkart_986980_value: this.state.getStokKartbyId?.at?.(0)?.albumKodu ?? undefined,
      urunkart_519113_value: this.state.getStokKartbyId?.at?.(0)?.albumAd ?? undefined,
      urunkart_140490_value: this.state.getStokKartbyId?.at?.(0)?.trackNo ?? undefined,
      urunkart_607171_value: this.state.getStokKartbyId?.at?.(0)?.trackVersiyon ?? undefined,
      urunkart_391357_value: this.state.getStokKartbyId?.at?.(0)?.trackSure ?? undefined,
      urunkart_884751_value: this.state.getStokKartbyId?.at?.(0)?.trackAd ?? undefined,
      urunkart_520044_value: this.state.getStokKartbyId?.at?.(0)?.sozlesmeTarihi ?? undefined,
      urunkart_972982_value: this.state.getStokKartbyId?.at?.(0)?.iswc ?? undefined,
      urunkart_360462_value: this.state.getStokKartbyId?.at?.(0)?.releaseTarihi ?? undefined,
      urunkart_872732_value: this.state.getStokKartbyId?.at?.(0)?.yapimSahibi ?? undefined,
      urunkart_776878_value: this.state.getStokKartbyId?.at?.(0)?.isrc ?? undefined,
      urunkart_869356_value: this.state.getStokKartbyId?.at?.(0)?.ticariAlbumCikaran ?? undefined,
      urunkart_723070_value: this.state.getStokKartbyId?.at?.(0)?.ticariAlbumAd ?? undefined,
      urunkart_586230_value: this.state.getStokKartbyId?.at?.(0)?.ticariAd ?? undefined,
      urunkart_159707_value: this.state.getStokKartbyId?.at?.(0)?.ticariReleaseTarihi ?? undefined,
      urunkart_110409_value: this.state.getStokKartbyId?.at?.(0)?.stokAd ?? undefined,
      urunkart_523571_value: this.state.getStokKartbyId?.at?.(0)?.stokAdAlternatif ?? undefined,
      urunkart_824162_value: this.state.getStokKartbyId?.at?.(0)?.stokAdAdapte ?? undefined,
      urunkart_402655_value: this.state.getStokKartbyId?.at?.(0)?.sozDili ?? undefined,
      urunkart_565130_value: this.state.getStokKartbyId?.at?.(0)?.sozilksatir ?? undefined,
      urunkart_69214_value: this.state.getStokKartbyId?.at?.(0)?.tescilveMerci ?? undefined,
      urunkart_370470_value: this.state.getStokKartbyId?.at?.(0)?.iswc ?? undefined,
      urunkart_600359_value: this.state.getStokKartbyId?.at?.(0)?.stokAd ?? undefined,
      urunkart_251494_value: this.state.getStokKartbyId?.at?.(0)?.stokAdAlternatif ?? undefined,
      urunkart_745458_value: this.state.getStokKartbyId?.at?.(0)?.stokAdAdapte ?? undefined,
      urunkart_285047_value: this.state.getStokKartbyId?.at?.(0)?.sozDili ?? undefined,
      urunkart_337728_value: this.state.getStokKartbyId?.at?.(0)?.sozilksatir ?? undefined,
      urunkart_614200_value: this.state.getStokKartbyId?.at?.(0)?.tescilveMerci ?? undefined,
      urunkart_298012_value: this.state.getStokKartbyId?.at?.(0)?.iswc ?? undefined,
      urunkart_578735_value: this.state.getStokKartbyId?.at?.(0)?.anaKatalogID ?? undefined,
      urunkart_234730_value: this.state.getStokKartbyId?.at?.(0)?.altKatalogID ?? undefined,
      urunkart_659821_value: this.state.getStokKartbyId?.at?.(0)?.stokAd ?? undefined,
      urunkart_130710_value: this.state.getStokKartbyId?.at?.(0)?.albumKodu ?? undefined,
      urunkart_590309_value: this.state.getStokKartbyId?.at?.(0)?.albumAd ?? undefined,
      urunkart_749098_value: this.state.getStokKartbyId?.at?.(0)?.trackNo ?? undefined,
      urunkart_999749_value: this.state.getStokKartbyId?.at?.(0)?.trackVersiyon ?? undefined,
      urunkart_932213_value: this.state.getStokKartbyId?.at?.(0)?.trackSure ?? undefined,
      urunkart_761278_value: this.state.getStokKartbyId?.at?.(0)?.temsilcilikDurumu ?? undefined,
      urunkart_971818_value: this.state.getStokKartbyId?.at?.(0)?.besteciler ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  UrunKartPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      NewEdit_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.NewEdit ?? this.props.screenInputs.newedit,
        "string"
      ),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      ),
      UserName_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      StokId_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      ),
      StokId_3: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      ),
      StokID_4: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      ),
      EserId_7: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "UrunKart/UrunKartPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.sbEserTipiListYeni = result?.data.sbEserTipiListYeni;

    formVars.urunkart_868633_value =
      stateVars.getStokKartbyId?.length > 0 ? stateVars.getStokKartbyId[0]?.eserTipiID : null;
    formVars.urunkart_868633_options = stateVars.sbEserTipiListYeni;
    stateVars.getStokKartbyId = result?.data.getStokKartbyId;
    formVars.urunkart_868633_value =
      stateVars.getStokKartbyId?.length > 0 ? stateVars.getStokKartbyId[0]?.eserTipiID : null;
    formVars.urunkart_868633_options = stateVars.sbEserTipiListYeni;
    formVars.urunkart_6285_value = ReactSystemFunctions.toString(
      this,
      stateVars.getStokKartbyId?.length > 0 ? stateVars.getStokKartbyId[0]?.stokAd : null
    );
    formVars.urunkart_370468_value = ReactSystemFunctions.toString(
      this,
      stateVars.getStokKartbyId?.length > 0 ? stateVars.getStokKartbyId[0]?.stokAdAlternatif : null
    );
    formVars.urunkart_563580_value = ReactSystemFunctions.toString(
      this,
      stateVars.getStokKartbyId?.length > 0 ? stateVars.getStokKartbyId[0]?.stokAdAdapte : null
    );
    formVars.urunkart_986980_value = ReactSystemFunctions.toString(
      this,
      stateVars.getStokKartbyId?.length > 0 ? stateVars.getStokKartbyId[0]?.albumKodu : null
    );
    formVars.urunkart_519113_value = ReactSystemFunctions.toString(
      this,
      stateVars.getStokKartbyId?.length > 0 ? stateVars.getStokKartbyId[0]?.albumAd : null
    );
    formVars.urunkart_140490_value = ReactSystemFunctions.toString(
      this,
      stateVars.getStokKartbyId?.length > 0 ? stateVars.getStokKartbyId[0]?.trackNo : null
    );
    formVars.urunkart_607171_value = ReactSystemFunctions.toString(
      this,
      stateVars.getStokKartbyId?.length > 0 ? stateVars.getStokKartbyId[0]?.trackVersiyon : null
    );
    formVars.urunkart_391357_value = ReactSystemFunctions.toString(
      this,
      stateVars.getStokKartbyId?.length > 0 ? stateVars.getStokKartbyId[0]?.trackSure : null
    );
    formVars.urunkart_884751_value = ReactSystemFunctions.toString(
      this,
      stateVars.getStokKartbyId?.length > 0 ? stateVars.getStokKartbyId[0]?.trackAd : null
    );
    formVars.urunkart_520044_value = ReactSystemFunctions.value(
      this,
      stateVars.getStokKartbyId?.length > 0 ? stateVars.getStokKartbyId[0]?.sozlesmeTarihi : null
    );
    formVars.urunkart_972982_value = ReactSystemFunctions.toString(
      this,
      stateVars.getStokKartbyId?.length > 0 ? stateVars.getStokKartbyId[0]?.iswc : null
    );
    formVars.urunkart_360462_value = ReactSystemFunctions.value(
      this,
      stateVars.getStokKartbyId?.length > 0 ? stateVars.getStokKartbyId[0]?.releaseTarihi : null
    );
    formVars.urunkart_872732_value = ReactSystemFunctions.toString(
      this,
      stateVars.getStokKartbyId?.length > 0 ? stateVars.getStokKartbyId[0]?.yapimSahibi : null
    );
    formVars.urunkart_776878_value = ReactSystemFunctions.toString(
      this,
      stateVars.getStokKartbyId?.length > 0 ? stateVars.getStokKartbyId[0]?.isrc : null
    );
    formVars.urunkart_869356_value = ReactSystemFunctions.toString(
      this,
      stateVars.getStokKartbyId?.length > 0 ? stateVars.getStokKartbyId[0]?.ticariAlbumCikaran : null
    );
    formVars.urunkart_723070_value = ReactSystemFunctions.toString(
      this,
      stateVars.getStokKartbyId?.length > 0 ? stateVars.getStokKartbyId[0]?.ticariAlbumAd : null
    );
    formVars.urunkart_586230_value = ReactSystemFunctions.toString(
      this,
      stateVars.getStokKartbyId?.length > 0 ? stateVars.getStokKartbyId[0]?.ticariAd : null
    );
    formVars.urunkart_159707_value = ReactSystemFunctions.value(
      this,
      stateVars.getStokKartbyId?.length > 0 ? stateVars.getStokKartbyId[0]?.ticariReleaseTarihi : null
    );
    formVars.urunkart_110409_value = ReactSystemFunctions.toString(
      this,
      stateVars.getStokKartbyId?.length > 0 ? stateVars.getStokKartbyId[0]?.stokAd : null
    );
    formVars.urunkart_523571_value = ReactSystemFunctions.toString(
      this,
      stateVars.getStokKartbyId?.length > 0 ? stateVars.getStokKartbyId[0]?.stokAdAlternatif : null
    );
    formVars.urunkart_824162_value = ReactSystemFunctions.toString(
      this,
      stateVars.getStokKartbyId?.length > 0 ? stateVars.getStokKartbyId[0]?.stokAdAdapte : null
    );
    formVars.urunkart_402655_value = ReactSystemFunctions.toString(
      this,
      stateVars.getStokKartbyId?.length > 0 ? stateVars.getStokKartbyId[0]?.sozDili : null
    );
    formVars.urunkart_565130_value = ReactSystemFunctions.toString(
      this,
      stateVars.getStokKartbyId?.length > 0 ? stateVars.getStokKartbyId[0]?.sozilksatir : null
    );
    formVars.urunkart_69214_value = ReactSystemFunctions.toString(
      this,
      stateVars.getStokKartbyId?.length > 0 ? stateVars.getStokKartbyId[0]?.tescilveMerci : null
    );
    formVars.urunkart_370470_value = ReactSystemFunctions.toString(
      this,
      stateVars.getStokKartbyId?.length > 0 ? stateVars.getStokKartbyId[0]?.iswc : null
    );
    formVars.urunkart_600359_value = ReactSystemFunctions.toString(
      this,
      stateVars.getStokKartbyId?.length > 0 ? stateVars.getStokKartbyId[0]?.stokAd : null
    );
    formVars.urunkart_251494_value = ReactSystemFunctions.toString(
      this,
      stateVars.getStokKartbyId?.length > 0 ? stateVars.getStokKartbyId[0]?.stokAdAlternatif : null
    );
    formVars.urunkart_745458_value = ReactSystemFunctions.toString(
      this,
      stateVars.getStokKartbyId?.length > 0 ? stateVars.getStokKartbyId[0]?.stokAdAdapte : null
    );
    formVars.urunkart_285047_value = ReactSystemFunctions.toString(
      this,
      stateVars.getStokKartbyId?.length > 0 ? stateVars.getStokKartbyId[0]?.sozDili : null
    );
    formVars.urunkart_337728_value = ReactSystemFunctions.toString(
      this,
      stateVars.getStokKartbyId?.length > 0 ? stateVars.getStokKartbyId[0]?.sozilksatir : null
    );
    formVars.urunkart_614200_value = ReactSystemFunctions.toString(
      this,
      stateVars.getStokKartbyId?.length > 0 ? stateVars.getStokKartbyId[0]?.tescilveMerci : null
    );
    formVars.urunkart_298012_value = ReactSystemFunctions.toString(
      this,
      stateVars.getStokKartbyId?.length > 0 ? stateVars.getStokKartbyId[0]?.iswc : null
    );
    formVars.urunkart_578735_value =
      stateVars.getStokKartbyId?.length > 0 ? stateVars.getStokKartbyId[0]?.anaKatalogID : null;
    formVars.urunkart_578735_options = stateVars.sbAnaKatalogList;
    formVars.urunkart_234730_value =
      stateVars.getStokKartbyId?.length > 0 ? stateVars.getStokKartbyId[0]?.altKatalogID : null;
    formVars.urunkart_234730_options = stateVars.sbAltKatalogList;
    formVars.urunkart_659821_value = ReactSystemFunctions.toString(
      this,
      stateVars.getStokKartbyId?.length > 0 ? stateVars.getStokKartbyId[0]?.stokAd : null
    );
    formVars.urunkart_130710_value = ReactSystemFunctions.toString(
      this,
      stateVars.getStokKartbyId?.length > 0 ? stateVars.getStokKartbyId[0]?.albumKodu : null
    );
    formVars.urunkart_590309_value = ReactSystemFunctions.toString(
      this,
      stateVars.getStokKartbyId?.length > 0 ? stateVars.getStokKartbyId[0]?.albumAd : null
    );
    formVars.urunkart_749098_value = ReactSystemFunctions.toString(
      this,
      stateVars.getStokKartbyId?.length > 0 ? stateVars.getStokKartbyId[0]?.trackNo : null
    );
    formVars.urunkart_999749_value = ReactSystemFunctions.toString(
      this,
      stateVars.getStokKartbyId?.length > 0 ? stateVars.getStokKartbyId[0]?.trackVersiyon : null
    );
    formVars.urunkart_932213_value = ReactSystemFunctions.toString(
      this,
      stateVars.getStokKartbyId?.length > 0 ? stateVars.getStokKartbyId[0]?.trackSure : null
    );
    formVars.urunkart_761278_value =
      stateVars.getStokKartbyId?.length > 0 ? stateVars.getStokKartbyId[0]?.temsilcilikDurumu : null;
    formVars.urunkart_971818_value = ReactSystemFunctions.toString(
      this,
      stateVars.getStokKartbyId?.length > 0 ? stateVars.getStokKartbyId[0]?.besteciler : null
    );
    stateVars.isComp398542Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getStokKartbyId?.length > 0 ? stateVars.getStokKartbyId[0]?.isVazgecKaydetVisibility : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp336812Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getStokKartbyId?.length > 0 ? stateVars.getStokKartbyId[0]?.isVazgecKaydetVisibility : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.getEserKayitlariListByStokId = result?.data.getEserKayitlariListByStokId;

    stateVars.getEserPayDagilimListByStokId = result?.data.getEserPayDagilimListByStokId;

    stateVars.getEserPayDagilimTelifsizListByStokId = result?.data.getEserPayDagilimTelifsizListByStokId;

    stateVars.sbAnaKatalogList = result?.data.sbAnaKatalogList;

    formVars.urunkart_578735_value =
      stateVars.getStokKartbyId?.length > 0 ? stateVars.getStokKartbyId[0]?.anaKatalogID : null;
    formVars.urunkart_578735_options = stateVars.sbAnaKatalogList;
    stateVars.sbAltKatalogList = result?.data.sbAltKatalogList;

    formVars.urunkart_234730_value =
      stateVars.getStokKartbyId?.length > 0 ? stateVars.getStokKartbyId[0]?.altKatalogID : null;
    formVars.urunkart_234730_options = stateVars.sbAltKatalogList;
    stateVars.spTarihceEser = result?.data.spTarihceEser;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.UrunKartPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  UrunKartPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "urunkart_868633_value", "value", "sbEserTipiListYeni", "id", "id")
        ),
        null
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp868926Visible",
        "hidden"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.UrunKartPageInit2_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.UrunKartPageInit2_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  UrunKartPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "urunkart_868633_value", "value", "sbEserTipiListYeni", "id", "kod")
        ),
        "B"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp868926Visible",
        "hidden"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.UrunKartPageInit3_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.UrunKartPageInit3_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  UrunKartPageInit3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "urunkart_868633_value", "value", "sbEserTipiListYeni", "id", "kod")
        ),
        "M"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp868926Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.UrunKartPageInit4_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.UrunKartPageInit4_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  UrunKartPageInit4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "urunkart_868633_value", "value", "sbEserTipiListYeni", "id", "kod")
        ),
        "T"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp868926Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.UrunKartPageInit5_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.UrunKartPageInit5_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  UrunKartPageInit5_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "urunkart_868633_value", "value", "sbEserTipiListYeni", "id", "kod")
        ),
        "R"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp868926Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.UrunKartPageInit6_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.UrunKartPageInit6_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  UrunKartPageInit6_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.urunkart_868633_value = ReactSystemFunctions.value(
      this,
      this.state.getStokKartbyId?.length > 0 ? this.state.getStokKartbyId[0]?.eserTipiID : null
    );

    stateVars.dataSource_868633 = this.state.sbEserTipiListYeni;
    stateVars.dataSource_868633 = this.state.sbEserTipiListYeni;
    stateVars.isComp352631Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "urunkart_868633_value", "value", "sbEserTipiListYeni", "id", "kod")
        ),
        "M"
      ) === true
        ? "visible"
        : "hidden";
    formVars.urunkart_6285_value = ReactSystemFunctions.toString(
      this,
      this.state.getStokKartbyId?.length > 0 ? this.state.getStokKartbyId[0]?.stokAd : null
    );

    formVars.urunkart_370468_value = ReactSystemFunctions.toString(
      this,
      this.state.getStokKartbyId?.length > 0 ? this.state.getStokKartbyId[0]?.stokAdAlternatif : null
    );

    formVars.urunkart_563580_value = ReactSystemFunctions.toString(
      this,
      this.state.getStokKartbyId?.length > 0 ? this.state.getStokKartbyId[0]?.stokAdAdapte : null
    );

    formVars.urunkart_986980_value = ReactSystemFunctions.toString(
      this,
      this.state.getStokKartbyId?.length > 0 ? this.state.getStokKartbyId[0]?.albumKodu : null
    );

    formVars.urunkart_519113_value = ReactSystemFunctions.toString(
      this,
      this.state.getStokKartbyId?.length > 0 ? this.state.getStokKartbyId[0]?.albumAd : null
    );

    formVars.urunkart_140490_value = ReactSystemFunctions.toString(
      this,
      this.state.getStokKartbyId?.length > 0 ? this.state.getStokKartbyId[0]?.trackNo : null
    );

    formVars.urunkart_607171_value = ReactSystemFunctions.toString(
      this,
      this.state.getStokKartbyId?.length > 0 ? this.state.getStokKartbyId[0]?.trackVersiyon : null
    );

    formVars.urunkart_391357_value = ReactSystemFunctions.toString(
      this,
      this.state.getStokKartbyId?.length > 0 ? this.state.getStokKartbyId[0]?.trackSure : null
    );

    formVars.urunkart_884751_value = ReactSystemFunctions.toString(
      this,
      this.state.getStokKartbyId?.length > 0 ? this.state.getStokKartbyId[0]?.trackAd : null
    );

    formVars.urunkart_520044_value = ReactSystemFunctions.value(
      this,
      this.state.getStokKartbyId?.length > 0 ? this.state.getStokKartbyId[0]?.sozlesmeTarihi : null
    );

    formVars.urunkart_972982_value = ReactSystemFunctions.toString(
      this,
      this.state.getStokKartbyId?.length > 0 ? this.state.getStokKartbyId[0]?.iswc : null
    );

    formVars.urunkart_360462_value = ReactSystemFunctions.value(
      this,
      this.state.getStokKartbyId?.length > 0 ? this.state.getStokKartbyId[0]?.releaseTarihi : null
    );

    formVars.urunkart_872732_value = ReactSystemFunctions.toString(
      this,
      this.state.getStokKartbyId?.length > 0 ? this.state.getStokKartbyId[0]?.yapimSahibi : null
    );

    formVars.urunkart_776878_value = ReactSystemFunctions.toString(
      this,
      this.state.getStokKartbyId?.length > 0 ? this.state.getStokKartbyId[0]?.isrc : null
    );

    formVars.urunkart_869356_value = ReactSystemFunctions.toString(
      this,
      this.state.getStokKartbyId?.length > 0 ? this.state.getStokKartbyId[0]?.ticariAlbumCikaran : null
    );

    formVars.urunkart_723070_value = ReactSystemFunctions.toString(
      this,
      this.state.getStokKartbyId?.length > 0 ? this.state.getStokKartbyId[0]?.ticariAlbumAd : null
    );

    formVars.urunkart_586230_value = ReactSystemFunctions.toString(
      this,
      this.state.getStokKartbyId?.length > 0 ? this.state.getStokKartbyId[0]?.ticariAd : null
    );

    formVars.urunkart_159707_value = ReactSystemFunctions.value(
      this,
      this.state.getStokKartbyId?.length > 0 ? this.state.getStokKartbyId[0]?.ticariReleaseTarihi : null
    );

    stateVars.isComp439236Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "urunkart_868633_value", "value", "sbEserTipiListYeni", "id", "kod")
        ),
        "T"
      ) === true
        ? "visible"
        : "hidden";
    formVars.urunkart_110409_value = ReactSystemFunctions.toString(
      this,
      this.state.getStokKartbyId?.length > 0 ? this.state.getStokKartbyId[0]?.stokAd : null
    );

    formVars.urunkart_523571_value = ReactSystemFunctions.toString(
      this,
      this.state.getStokKartbyId?.length > 0 ? this.state.getStokKartbyId[0]?.stokAdAlternatif : null
    );

    formVars.urunkart_824162_value = ReactSystemFunctions.toString(
      this,
      this.state.getStokKartbyId?.length > 0 ? this.state.getStokKartbyId[0]?.stokAdAdapte : null
    );

    formVars.urunkart_402655_value = ReactSystemFunctions.toString(
      this,
      this.state.getStokKartbyId?.length > 0 ? this.state.getStokKartbyId[0]?.sozDili : null
    );

    formVars.urunkart_565130_value = ReactSystemFunctions.toString(
      this,
      this.state.getStokKartbyId?.length > 0 ? this.state.getStokKartbyId[0]?.sozilksatir : null
    );

    formVars.urunkart_69214_value = ReactSystemFunctions.toString(
      this,
      this.state.getStokKartbyId?.length > 0 ? this.state.getStokKartbyId[0]?.tescilveMerci : null
    );

    formVars.urunkart_370470_value = ReactSystemFunctions.toString(
      this,
      this.state.getStokKartbyId?.length > 0 ? this.state.getStokKartbyId[0]?.iswc : null
    );

    stateVars.dataSource_261881 = this.state.getEserKayitlariListByStokId;
    stateVars.isComp960168Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "urunkart_868633_value", "value", "sbEserTipiListYeni", "id", "kod")
        ),
        "R"
      ) === true
        ? "visible"
        : "hidden";
    formVars.urunkart_600359_value = ReactSystemFunctions.toString(
      this,
      this.state.getStokKartbyId?.length > 0 ? this.state.getStokKartbyId[0]?.stokAd : null
    );

    formVars.urunkart_251494_value = ReactSystemFunctions.toString(
      this,
      this.state.getStokKartbyId?.length > 0 ? this.state.getStokKartbyId[0]?.stokAdAlternatif : null
    );

    formVars.urunkart_745458_value = ReactSystemFunctions.toString(
      this,
      this.state.getStokKartbyId?.length > 0 ? this.state.getStokKartbyId[0]?.stokAdAdapte : null
    );

    formVars.urunkart_285047_value = ReactSystemFunctions.toString(
      this,
      this.state.getStokKartbyId?.length > 0 ? this.state.getStokKartbyId[0]?.sozDili : null
    );

    formVars.urunkart_337728_value = ReactSystemFunctions.toString(
      this,
      this.state.getStokKartbyId?.length > 0 ? this.state.getStokKartbyId[0]?.sozilksatir : null
    );

    formVars.urunkart_614200_value = ReactSystemFunctions.toString(
      this,
      this.state.getStokKartbyId?.length > 0 ? this.state.getStokKartbyId[0]?.tescilveMerci : null
    );

    formVars.urunkart_298012_value = ReactSystemFunctions.toString(
      this,
      this.state.getStokKartbyId?.length > 0 ? this.state.getStokKartbyId[0]?.iswc : null
    );

    stateVars.isComp230593Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "urunkart_868633_value", "value", "sbEserTipiListYeni", "id", "kod")
        ),
        "B"
      ) === true
        ? "visible"
        : "hidden";
    formVars.urunkart_578735_value = ReactSystemFunctions.value(
      this,
      this.state.getStokKartbyId?.length > 0 ? this.state.getStokKartbyId[0]?.anaKatalogID : null
    );

    stateVars.dataSource_578735 = this.state.sbAnaKatalogList;
    stateVars.dataSource_578735 = this.state.sbAnaKatalogList;
    formVars.urunkart_234730_value = ReactSystemFunctions.value(
      this,
      this.state.getStokKartbyId?.length > 0 ? this.state.getStokKartbyId[0]?.altKatalogID : null
    );

    stateVars.dataSource_234730 = this.state.sbAltKatalogList;
    stateVars.dataSource_234730 = this.state.sbAltKatalogList;
    formVars.urunkart_659821_value = ReactSystemFunctions.toString(
      this,
      this.state.getStokKartbyId?.length > 0 ? this.state.getStokKartbyId[0]?.stokAd : null
    );

    formVars.urunkart_130710_value = ReactSystemFunctions.toString(
      this,
      this.state.getStokKartbyId?.length > 0 ? this.state.getStokKartbyId[0]?.albumKodu : null
    );

    formVars.urunkart_590309_value = ReactSystemFunctions.toString(
      this,
      this.state.getStokKartbyId?.length > 0 ? this.state.getStokKartbyId[0]?.albumAd : null
    );

    formVars.urunkart_749098_value = ReactSystemFunctions.toString(
      this,
      this.state.getStokKartbyId?.length > 0 ? this.state.getStokKartbyId[0]?.trackNo : null
    );

    formVars.urunkart_999749_value = ReactSystemFunctions.toString(
      this,
      this.state.getStokKartbyId?.length > 0 ? this.state.getStokKartbyId[0]?.trackVersiyon : null
    );

    formVars.urunkart_932213_value = ReactSystemFunctions.toString(
      this,
      this.state.getStokKartbyId?.length > 0 ? this.state.getStokKartbyId[0]?.trackSure : null
    );

    formVars.urunkart_761278_value = ReactSystemFunctions.toString(
      this,
      this.state.getStokKartbyId?.length > 0 ? this.state.getStokKartbyId[0]?.temsilcilikDurumu : null
    );

    formVars.urunkart_971818_value = ReactSystemFunctions.toString(
      this,
      this.state.getStokKartbyId?.length > 0 ? this.state.getStokKartbyId[0]?.besteciler : null
    );

    stateVars.isComp707570Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "urunkart_868633_value", "value", "sbEserTipiListYeni", "id", "kod")
        ),
        "R"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp944587Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "urunkart_868633_value", "value", "sbEserTipiListYeni", "id", "kod")
        ),
        "R"
      ) === true
        ? "visible"
        : "hidden";

    stateVars.dataSource_981337 = this.state.getEserPayDagilimListByStokId;
    stateVars.isComp3319Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "urunkart_868633_value", "value", "sbEserTipiListYeni", "id", "kod")
        ),
        "R"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp855521Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "urunkart_868633_value", "value", "sbEserTipiListYeni", "id", "kod")
        ),
        "R"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp737157Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "urunkart_868633_value", "value", "sbEserTipiListYeni", "id", "kod")
        ),
        "R"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp580090Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "urunkart_868633_value", "value", "sbEserTipiListYeni", "id", "kod")
        ),
        "R"
      ) === true
        ? "visible"
        : "hidden";

    stateVars.dataSource_473103 = this.state.getEserPayDagilimTelifsizListByStokId;

    stateVars.dataSource_782375 = this.state.spTarihceEser;
    stateVars.isComp71001Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "urunkart_868633_value", "value", "sbEserTipiListYeni", "id", "id")
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp398542Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.getStokKartbyId?.length > 0 ? this.state.getStokKartbyId[0]?.isVazgecKaydetVisibility : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp336812Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.getStokKartbyId?.length > 0 ? this.state.getStokKartbyId[0]?.isVazgecKaydetVisibility : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  UrunKartComponent_515750_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "UrunKart",
      "LeftMenu",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "304331",
      "",
      "left",
      null,
      "",
      "",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  UrunKartComponent_622923_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  UrunKartComponent_868633_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "urunkart_868633_value", "value", "sbEserTipiListYeni", "id", "kod")
        ),
        "B"
      )
    ) {
      KuikaAppManager.showSpinner(this);

      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp868926Visible",
        "hidden"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.UrunKartComponent_868633_onChange1_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.UrunKartComponent_868633_onChange1_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  UrunKartComponent_868633_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "urunkart_868633_value", "value", "sbEserTipiListYeni", "id", "kod")
        ),
        "M"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp868926Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.UrunKartComponent_868633_onChange2_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.UrunKartComponent_868633_onChange2_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  UrunKartComponent_868633_onChange2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "urunkart_868633_value", "value", "sbEserTipiListYeni", "id", "kod")
        ),
        "T"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp868926Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.UrunKartComponent_868633_onChange3_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.UrunKartComponent_868633_onChange3_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  UrunKartComponent_868633_onChange3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "urunkart_868633_value", "value", "sbEserTipiListYeni", "id", "kod")
        ),
        "R"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp868926Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.UrunKartComponent_868633_onChange4_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.UrunKartComponent_868633_onChange4_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  UrunKartComponent_868633_onChange4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.isComp352631Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "urunkart_868633_value", "value", "sbEserTipiListYeni", "id", "kod")
        ),
        "M"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp439236Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "urunkart_868633_value", "value", "sbEserTipiListYeni", "id", "kod")
        ),
        "T"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp960168Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "urunkart_868633_value", "value", "sbEserTipiListYeni", "id", "kod")
        ),
        "R"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp230593Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "urunkart_868633_value", "value", "sbEserTipiListYeni", "id", "kod")
        ),
        "B"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp707570Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "urunkart_868633_value", "value", "sbEserTipiListYeni", "id", "kod")
        ),
        "R"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp944587Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "urunkart_868633_value", "value", "sbEserTipiListYeni", "id", "kod")
        ),
        "R"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp3319Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "urunkart_868633_value", "value", "sbEserTipiListYeni", "id", "kod")
        ),
        "R"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp855521Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "urunkart_868633_value", "value", "sbEserTipiListYeni", "id", "kod")
        ),
        "R"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp737157Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "urunkart_868633_value", "value", "sbEserTipiListYeni", "id", "kod")
        ),
        "R"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp580090Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "urunkart_868633_value", "value", "sbEserTipiListYeni", "id", "kod")
        ),
        "R"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp71001Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "urunkart_868633_value", "value", "sbEserTipiListYeni", "id", "id")
        ),
        null
      ) === true
        ? "visible"
        : "hidden";

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  UrunKartComponent_684462_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    if (await this.UrunKartComponent_336812_onClick()) return true;

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "EserKayitKart",
      "prmStokID",
      this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid
    );
    KuikaAppManager.addToPageInputVariables("EserKayitKart", "prmGuid", Guid.create().toString());

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "UrunKart",
      "EserKayitKart",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "231933",
      null,
      "right",
      null,
      "400px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  UrunKartComponent_155599_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "urunkart_261881_value", "id"),
        "Guid"
      ),
      StokId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "UrunKart/UrunKartComponent_155599_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.EserKayitlariDelete = result?.data.eserKayitlariDelete;
    stateVars.getEserKayitlariListByStokId = result?.data.getEserKayitlariListByStokId;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.UrunKartComponent_155599_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  UrunKartComponent_155599_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_261881 = this.state.getEserKayitlariListByStokId;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  UrunKartComponent_351695_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "EserKayitKart",
      "prmStokID",
      this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid
    );
    KuikaAppManager.addToPageInputVariables(
      "EserKayitKart",
      "prmGuid",
      ReactSystemFunctions.value(this, "urunkart_261881_value", "id")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "UrunKart",
      "EserKayitKart",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "514194",
      null,
      "right",
      null,
      "400px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  UrunKartComponent_578735_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      AnaKatalogID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "urunkart_578735_value", "value", "sbAnaKatalogList", "id", "id")
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "UrunKart/UrunKartComponent_578735_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.sbAltKatalogList = result?.data.sbAltKatalogList;

    formVars.urunkart_234730_options = stateVars.sbAltKatalogList;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.UrunKartComponent_578735_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  UrunKartComponent_578735_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_234730 = this.state.sbAltKatalogList;
    stateVars.dataSource_234730 = this.state.sbAltKatalogList;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  UrunKartComponent_492388_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    if (await this.UrunKartComponent_336812_onClick()) return true;

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "UrunPayDagilimKart",
      "prmStokID",
      this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid
    );
    KuikaAppManager.addToPageInputVariables("UrunPayDagilimKart", "prmGuid", Guid.create().toString());

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "UrunKart",
      "UrunPayDagilimKart",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "107654",
      null,
      "right",
      null,
      "400",
      "",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  UrunKartComponent_211241_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "urunkart_981337_value", "id"),
        "Guid"
      ),
      StokId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    stateVars.Confirm = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "9c43e15f_3a21_e984_a354_b95f143da59b_confirmation",
        this.defaultML,
        "Seçilen Kayıt Silinecek; Devam Edilsin mi?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel",
      customClass: {
        title: "swal2-font-poppins"
      }
    });

    if (stateVars.Confirm.isConfirmed) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(this, "UrunKart/UrunKartComponent_211241_onClick", localVar);
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.setEserPayDagilimDelete = result?.data.setEserPayDagilimDelete;
      stateVars.getEserPayDagilimListByStokId = result?.data.getEserPayDagilimListByStokId;

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.UrunKartComponent_211241_onClick1_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  UrunKartComponent_211241_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_981337 = this.state.getEserPayDagilimListByStokId;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  UrunKartComponent_553764_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "UrunPayDagilimKart",
      "prmStokID",
      this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid
    );
    KuikaAppManager.addToPageInputVariables(
      "UrunPayDagilimKart",
      "prmGuid",
      ReactSystemFunctions.value(this, "urunkart_981337_value", "id")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      this.UrunKartComponent_553764_onClick1_,
      "UrunKart",
      "UrunPayDagilimKart",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "374430",
      null,
      "right",
      null,
      "400",
      "",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };
  UrunKartComponent_553764_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      StokId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "UrunKart/UrunKartComponent_553764_onClick1_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.getEserPayDagilimListByStokId = result?.data.getEserPayDagilimListByStokId;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.UrunKartComponent_553764_onClick2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  UrunKartComponent_553764_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_981337 = this.state.getEserPayDagilimListByStokId;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  UrunKartComponent_555720_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    if (await this.UrunKartComponent_336812_onClick()) return true;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.UrunKartComponent_555720_onClick1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  UrunKartComponent_555720_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "urunkart_110409_value", "value", "", "", "")
        ),
        null
      )
    ) {
      KuikaAppManager.prepareForNavigation();
      KuikaAppManager.addToPageInputVariables(
        "UrunPayDagilimKartelifsiz",
        "prmStokID",
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid
      );
      KuikaAppManager.addToPageInputVariables("UrunPayDagilimKartelifsiz", "prmGuid", Guid.create().toString());

      stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
        this,
        undefined,
        "UrunKart",
        "UrunPayDagilimKartelifsiz",
        "",
        "7d969ddf-5801-4eb6-b92b-12be35bdd565",
        "739598",
        null,
        "right",
        null,
        "400",
        "",
        true
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  UrunKartComponent_353506_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "urunkart_473103_value", "id"),
        "Guid"
      ),
      StokID_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    stateVars.Confirm = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "94e79fa7_e816_f0c7_d587_f495aad538fa_confirmation",
        this.defaultML,
        "Seçilen Kayıt Silinecek; Devam Edilsin mi?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel",
      customClass: {
        title: "swal2-font-poppins"
      }
    });

    if (stateVars.Confirm.isConfirmed) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(this, "UrunKart/UrunKartComponent_353506_onClick", localVar);
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.setEserPayDagilimTelifsizDelete = result?.data.setEserPayDagilimTelifsizDelete;
      stateVars.getEserPayDagilimTelifsizListByStokId = result?.data.getEserPayDagilimTelifsizListByStokId;

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.UrunKartComponent_353506_onClick1_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  UrunKartComponent_353506_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_473103 = this.state.getEserPayDagilimTelifsizListByStokId;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  UrunKartComponent_601868_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "UrunPayDagilimKartelifsiz",
      "prmStokID",
      this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid
    );
    KuikaAppManager.addToPageInputVariables(
      "UrunPayDagilimKartelifsiz",
      "prmGuid",
      ReactSystemFunctions.value(this, "urunkart_473103_value", "id")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      this.UrunKartComponent_601868_onClick1_,
      "UrunKart",
      "UrunPayDagilimKartelifsiz",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "407514",
      null,
      "right",
      null,
      "400",
      "",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };
  UrunKartComponent_601868_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      StokID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "UrunKart/UrunKartComponent_601868_onClick1_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.getEserPayDagilimTelifsizListByStokId = result?.data.getEserPayDagilimTelifsizListByStokId;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.UrunKartComponent_601868_onClick2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  UrunKartComponent_601868_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_473103 = this.state.getEserPayDagilimTelifsizListByStokId;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  UrunKartComponent_229594_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp782375Visible");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  UrunKartComponent_398542_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  UrunKartComponent_336812_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "urunkart_6285_value", "value", "", "", "")
        ),
        null
      ),
      message: "!",
      formName: "urunkart_6285_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "urunkart_868633_value", "value", "sbEserTipiListYeni", "id", "kod")
        ),
        "M"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      AlbumAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "urunkart_519113_value", "value", "", "", "")
        ),
        "string"
      ),
      AlbumKodu_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "urunkart_986980_value", "value", "", "", "")
        ),
        "string"
      ),
      EserTipiID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "urunkart_868633_value", "value", "sbEserTipiListYeni", "id", "id")
        ),
        "Guid"
      ),
      StokAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "urunkart_6285_value", "value", "", "", "")
        ),
        "string"
      ),
      TrackAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "urunkart_884751_value", "value", "", "", "")
        ),
        "string"
      ),
      TrackNo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "urunkart_140490_value", "value", "", "", "")
        ),
        "string"
      ),
      TrackSure_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "urunkart_391357_value", "value", "", "", "")
        ),
        "string"
      ),
      TrackVersiyon_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "urunkart_607171_value", "value", "", "", "")
        ),
        "string"
      ),
      StokAdAlternatif_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "urunkart_370468_value", "value", "", "", "")
        ),
        "string"
      ),
      StokAdAdapte_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "urunkart_563580_value", "value", "", "", "")
        ),
        "string"
      ),
      SozlesmeTarihi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "urunkart_520044_value", "value", "", "", "")
        ),
        "Date"
      ),
      ISWC_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "urunkart_972982_value", "value", "", "", "")
        ),
        "string"
      ),
      ReleaseTarihi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "urunkart_360462_value", "value", "", "", "")
        ),
        "Date"
      ),
      YapimSahibi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "urunkart_872732_value", "value", "", "", "")
        ),
        "string"
      ),
      ISRC_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "urunkart_776878_value", "value", "", "", "")
        ),
        "string"
      ),
      TicariAlbumCikaran_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "urunkart_869356_value", "value", "", "", "")
        ),
        "string"
      ),
      TicariAlbumAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "urunkart_723070_value", "value", "", "", "")
        ),
        "string"
      ),
      TicariAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "urunkart_586230_value", "value", "", "", "")
        ),
        "string"
      ),
      TicariReleaseTarihi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "urunkart_159707_value", "value", "", "", "")
        ),
        "Date"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "urunkart_868633_value", "value", "sbEserTipiListYeni", "id", "kod")
        ),
        "M"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(this, "UrunKart/UrunKartComponent_336812_onClick", localVar);
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.SaveStokM = result?.data.saveStokM;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.UrunKartComponent_336812_onClick1_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.UrunKartComponent_336812_onClick1_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  UrunKartComponent_336812_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "urunkart_110409_value", "value", "", "", "")
        ),
        null
      ),
      message: "!",
      formName: "urunkart_110409_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "urunkart_868633_value", "value", "sbEserTipiListYeni", "id", "kod")
        ),
        "T"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      EserTipiID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "urunkart_868633_value", "value", "sbEserTipiListYeni", "id", "id")
        ),
        "Guid"
      ),
      StokAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "urunkart_110409_value", "value", "", "", "")
        ),
        "string"
      ),
      StokAdAlternatif_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "urunkart_523571_value", "value", "", "", "")
        ),
        "string"
      ),
      StokAdAdapte_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "urunkart_824162_value", "value", "", "", "")
        ),
        "string"
      ),
      SozDili_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "urunkart_402655_value", "value", "", "", "")
        ),
        "string"
      ),
      Sozilksatir_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "urunkart_565130_value", "value", "", "", "")
        ),
        "string"
      ),
      TescilveMerci_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "urunkart_69214_value", "value", "", "", "")
        ),
        "string"
      ),
      ISWC_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "urunkart_370470_value", "value", "", "", "")
        ),
        "string"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    };

    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "urunkart_868633_value", "value", "sbEserTipiListYeni", "id", "kod")
        ),
        "T"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(this, "UrunKart/UrunKartComponent_336812_onClick1_", localVar);
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.SaveStokT = result?.data.saveStokT;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.UrunKartComponent_336812_onClick2_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.UrunKartComponent_336812_onClick2_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  UrunKartComponent_336812_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      ),
      EserTipiID_0: ReactSystemFunctions.convertToTypeByName("00000000-0000-0000-0000-000000000002", "Guid")
    };

    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "urunkart_868633_value", "value", "sbEserTipiListYeni", "id", "kod")
        ),
        "T"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(this, "UrunKart/UrunKartComponent_336812_onClick2_", localVar);
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.setUpdateTicariYayimNo = result?.data.setUpdateTicariYayimNo;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.UrunKartComponent_336812_onClick3_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.UrunKartComponent_336812_onClick3_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  UrunKartComponent_336812_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "urunkart_6285_value", "value", "", "", "")
        ),
        null
      ),
      message: "!",
      formName: "urunkart_659821_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "urunkart_868633_value", "value", "sbEserTipiListYeni", "id", "kod")
        ),
        "M"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      AnaKatalogID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "urunkart_578735_value", "value", "sbAnaKatalogList", "id", "id")
        ),
        "Guid"
      ),
      AltKatalogID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "urunkart_234730_value", "value", "sbAltKatalogList", "id", "id")
        ),
        "Guid"
      ),
      StokAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "urunkart_659821_value", "value", "", "", "")
        ),
        "string"
      ),
      AlbumAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "urunkart_590309_value", "value", "", "", "")
        ),
        "string"
      ),
      AlbumKodu_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "urunkart_130710_value", "value", "", "", "")
        ),
        "string"
      ),
      TrackNo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "urunkart_749098_value", "value", "", "", "")
        ),
        "string"
      ),
      TrackSure_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "urunkart_932213_value", "value", "", "", "")
        ),
        "string"
      ),
      TrackVersiyon_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "urunkart_999749_value", "value", "", "", "")
        ),
        "string"
      ),
      Besteciler_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "urunkart_971818_value", "value", "", "", "")
        ),
        "string"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      ),
      EserTipiID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "urunkart_868633_value", "value", "sbEserTipiListYeni", "id", "id")
        ),
        "Guid"
      ),
      TemsilcilikDurumu_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toInteger(
          this,
          ReactSystemFunctions.value(this, "urunkart_761278_value", "value", "", "key", "")
        ),
        "number"
      )
    };

    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "urunkart_868633_value", "value", "sbEserTipiListYeni", "id", "kod")
        ),
        "B"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(this, "UrunKart/UrunKartComponent_336812_onClick3_", localVar);
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.SaveStokB = result?.data.saveStokB;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.UrunKartComponent_336812_onClick4_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.UrunKartComponent_336812_onClick4_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  UrunKartComponent_336812_onClick4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "urunkart_110409_value", "value", "", "", "")
        ),
        null
      ),
      message: "!",
      formName: "urunkart_600359_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "urunkart_868633_value", "value", "sbEserTipiListYeni", "id", "kod")
        ),
        "T"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      EserTipiID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "urunkart_868633_value", "value", "sbEserTipiListYeni", "id", "id")
        ),
        "Guid"
      ),
      StokAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "urunkart_600359_value", "value", "", "", "")
        ),
        "string"
      ),
      StokAdAlternatif_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "urunkart_251494_value", "value", "", "", "")
        ),
        "string"
      ),
      StokAdAdapte_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "urunkart_745458_value", "value", "", "", "")
        ),
        "string"
      ),
      SozDili_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "urunkart_285047_value", "value", "", "", "")
        ),
        "string"
      ),
      Sozilksatir_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "urunkart_337728_value", "value", "", "", "")
        ),
        "string"
      ),
      TescilveMerci_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "urunkart_614200_value", "value", "", "", "")
        ),
        "string"
      ),
      ISWC_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "urunkart_298012_value", "value", "", "", "")
        ),
        "string"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    };

    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "urunkart_868633_value", "value", "sbEserTipiListYeni", "id", "kod")
        ),
        "R"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(this, "UrunKart/UrunKartComponent_336812_onClick4_", localVar);
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.SaveStokT = result?.data.saveStokT;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.UrunKartComponent_336812_onClick5_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.UrunKartComponent_336812_onClick5_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  UrunKartComponent_336812_onClick5_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      ),
      EserTipiID_0: ReactSystemFunctions.convertToTypeByName("00000000-0000-0000-0000-000000000004", "Guid")
    };

    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "urunkart_868633_value", "value", "sbEserTipiListYeni", "id", "kod")
        ),
        "R"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(this, "UrunKart/UrunKartComponent_336812_onClick5_", localVar);
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.setUpdateTicariYayimNo = result?.data.setUpdateTicariYayimNo;
      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [304331, 231933, 514194, 107654, 374430, 739598, 407514] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.UrunKartPageInit();
    }
    if (diId == 374430) {
      isErrorOccurred = await this.UrunKartComponent_553764_onClick1_();
      if (isErrorOccurred) return true;
    } else if (diId == 407514) {
      isErrorOccurred = await this.UrunKartComponent_601868_onClick1_();
      if (isErrorOccurred) return true;
    }
  }
}
