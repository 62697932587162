import { FormInstance } from "antd/lib/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IIsTakipList_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IIsTakipList_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  OTOMAILTempInsert: any[];
  OTOMAILTempInsert_dummy: any[];
  Notify: boolean;
  Notify_dummy: boolean;
  getUserInfo: any[];
  getUserInfo_dummy: any[];
  getFilterParametersByUserName: any[];
  getFilterParametersByUserName_dummy: any[];
  sbSureAralikList: any[];
  sbSureAralikList_dummy: any[];
  sbsKartTuruList: any[];
  sbsKartTuruList_dummy: any[];
  getIsTakipNickList: any[];
  getIsTakipNickList_dummy: any[];
  SetValueOf: any;
  SetValueOf_dummy: any;
  spIsTakipList: any[];
  spIsTakipList_dummy: any[];
  spIsTakipListCount: any[];
  spIsTakipListCount_dummy: any[];
  FilterParametersSaveIsTakipList: number;
  FilterParametersSaveIsTakipList_dummy: number;
  getFormNo: any[];
  getFormNo_dummy: any[];
  isComp3650Visible: "visible" | "hidden";
  isComp871646Visible: "visible" | "hidden";
  isComp639961Visible: "visible" | "hidden";
  isComp664290Visible: "visible" | "hidden";
  isComp765309Visible: "visible" | "hidden";
  isCompistakiplist_801431AuthorizationVisible: "visible" | "hidden";
}

export class IsTakipList_ScreenBase extends React.PureComponent<IIsTakipList_ScreenProps, any> {
  istakiplist_583028_value_kuikaSelectBoxRef: React.RefObject<any>;
  istakiplist_799826_value_kuikaSelectBoxRef: React.RefObject<any>;
  istakiplist_234593_value_kuikaSelectBoxRef: React.RefObject<any>;
  istakiplist_660947_value_kuikaTableRef: React.RefObject<any>;
  istakiplist_958309_value_kuikaPaginationRef: React.RefObject<any>;
  ml = [
    {
      Id: "16c151eb-42cf-0b2b-7e4a-e8b04fc43d88",
      Name: "tr_TR",
      ShortName: "Turkish",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "fbdbce9f-3fdb-30c5-c4fe-f7e443068251", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    { Id: "8468043e-f025-01e1-93b8-c71e3215778a", Name: "de_DE", ShortName: "German", LanguagePhrases: [] },
    {
      Id: "7cea1bdd-54fd-4aea-abc8-f8651daa7d67",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 625521, PropertyName: "value", Value: "İŞ TAKİP LİSTEM" },
        { Id: 801431, PropertyName: "label", Value: "Otomatik Hatırlatma Maili Gönder" },
        { Id: 583028, PropertyName: "placeholder", Value: "..." },
        { Id: 799826, PropertyName: "placeholder", Value: "İlgili Kişi Seçiniz" },
        { Id: 998378, PropertyName: "placeholder", Value: "Aranacak kelimeyi yazınız..." },
        { Id: 234593, PropertyName: "placeholder", Value: "Sıralama" },
        { Id: 50806, PropertyName: "label", Value: "Başvurular Sayfasına Git" },
        { Id: 660947, PropertyName: "nodatafoundmessage", Value: "Veri Bulunamadı" },
        { Id: 789346, PropertyName: "title", Value: "İş No" },
        { Id: 854825, PropertyName: "value", Value: "[datafield:ticketno]" },
        { Id: 812977, PropertyName: "title", Value: "Başvuru Tarihi" },
        { Id: 679677, PropertyName: "value", Value: "[datafield:basvurutarihi]" },
        { Id: 152799, PropertyName: "title", Value: "Son Güncelleme" },
        { Id: 469334, PropertyName: "value", Value: "[datafield:istakipguncellemezaman]" },
        { Id: 963517, PropertyName: "title", Value: "Termin Tarihi" },
        { Id: 412788, PropertyName: "value", Value: "[datafield:termintarihi]" },
        { Id: 275633, PropertyName: "title", Value: "Müşteri Adı" },
        { Id: 491907, PropertyName: "value", Value: "[datafield:cariad]" },
        { Id: 945777, PropertyName: "title", Value: "Durum" },
        { Id: 885610, PropertyName: "value", Value: "[datafield:formstatu]" },
        { Id: 468136, PropertyName: "title", Value: "Başvuru Türü" },
        { Id: 408011, PropertyName: "value", Value: "[datafield:basvuruturu]" },
        { Id: 885016, PropertyName: "title", Value: "Proje Türü" },
        { Id: 352743, PropertyName: "value", Value: "[datafield:projeturu]" },
        { Id: 229962, PropertyName: "title", Value: "Bölüm" },
        { Id: 588234, PropertyName: "value", Value: "[datafield:dizibolumno]" },
        { Id: 243187, PropertyName: "title", Value: "Eser" },
        { Id: 231859, PropertyName: "value", Value: "[datafield:eserad]" },
        { Id: 688600, PropertyName: "title", Value: "Eser Sahibi" },
        { Id: 795938, PropertyName: "value", Value: "[datafield:esersahibi]" },
        { Id: 352211, PropertyName: "title", Value: "Başvuru Sahibi" },
        { Id: 726097, PropertyName: "value", Value: "[datafield:adsoyad]" },
        { Id: 707219, PropertyName: "title", Value: "Proje Adı" },
        { Id: 772072, PropertyName: "value", Value: "[datafield:projead]" },
        { Id: 114383, PropertyName: "title", Value: "İlgili Kişi" },
        { Id: 737796, PropertyName: "value", Value: "[datafield:nick]" }
      ]
    }
  ];
  defaultML = "tr_TR";
  screenHash = "";

  constructor(props) {
    super(props);
    this.istakiplist_583028_value_kuikaSelectBoxRef = React.createRef();
    this.istakiplist_799826_value_kuikaSelectBoxRef = React.createRef();
    this.istakiplist_234593_value_kuikaSelectBoxRef = React.createRef();
    this.istakiplist_660947_value_kuikaTableRef = React.createRef();
    this.istakiplist_958309_value_kuikaPaginationRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      NAVIGATE: "",
      OTOMAILTempInsert: [],
      Notify: false,
      getUserInfo: [],
      getFilterParametersByUserName: [],
      sbSureAralikList: [],
      sbsKartTuruList: [],
      getIsTakipNickList: [],
      SetValueOf: "",
      spIsTakipList: [],
      spIsTakipListCount: [],
      FilterParametersSaveIsTakipList: 0,
      getFormNo: [],
      dataSource_234593: [
        { key: "BT", text: "Başvuru Tarihine Göre Sırala (Z-A) - Yeni iş’ten eskiye" },
        { key: "BTAZ", text: "Başvuru Tarihine Göre Sırala (A-Z) - Eski iş’ten yeniye" },
        { key: "TT", text: "Termin Tarihine Göre Sırala (A-Z)" }
      ],
      isComp3650Visible: "hidden",
      isComp871646Visible: "hidden",
      isComp639961Visible: "hidden",
      isComp664290Visible: "hidden",
      isComp765309Visible: "hidden",
      isCompistakiplist_801431AuthorizationVisible: "visible"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("istakiplist", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.IsTakipListPageInit();
    }
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompistakiplist_801431AuthorizationVisible", [
      "7165e0ad-e354-6630-d176-a4378c7ac2b9",
      "89f0bd8c-62b8-cf33-3763-edf7319ca78f",
      "Muhasebe",
      "Admin"
    ]);

    KuikaAppManager.calculateAndSetBodyHeight("istakiplist", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("istakiplist", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.IsTakipListPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      istakiplist_583028_value: this.state.getFilterParametersByUserName?.at?.(0)?.isTakipListSBox ?? undefined,
      istakiplist_799826_value: this.state.getFilterParametersByUserName?.at?.(0)?.isTakipListIlgiliKisi ?? undefined,
      istakiplist_998378_value: this.state.getFilterParametersByUserName?.at?.(0)?.isTakipListSearch ?? undefined,
      istakiplist_234593_value: this.state.getFilterParametersByUserName?.at?.(0)?.isTakipListSiralama ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  IsTakipListPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {};

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "IsTakipList/IsTakipListPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.getUserInfo = result?.data.getUserInfo;
    stateVars.getFilterParametersByUserName = result?.data.getFilterParametersByUserName;
    formVars.istakiplist_583028_value =
      stateVars.getFilterParametersByUserName?.length > 0
        ? stateVars.getFilterParametersByUserName[0]?.isTakipListSBox
        : null;
    formVars.istakiplist_583028_options = stateVars.sbSureAralikList;
    formVars.istakiplist_799826_value =
      stateVars.getFilterParametersByUserName?.length > 0
        ? stateVars.getFilterParametersByUserName[0]?.isTakipListIlgiliKisi
        : null;
    formVars.istakiplist_799826_options = stateVars.getIsTakipNickList;
    formVars.istakiplist_998378_value = ReactSystemFunctions.toString(
      this,
      stateVars.getFilterParametersByUserName?.length > 0
        ? stateVars.getFilterParametersByUserName[0]?.isTakipListSearch
        : null
    );
    formVars.istakiplist_234593_value =
      stateVars.getFilterParametersByUserName?.length > 0
        ? stateVars.getFilterParametersByUserName[0]?.isTakipListSiralama
        : null;
    stateVars.sbSureAralikList = result?.data.sbSureAralikList;

    formVars.istakiplist_583028_value =
      stateVars.getFilterParametersByUserName?.length > 0
        ? stateVars.getFilterParametersByUserName[0]?.isTakipListSBox
        : null;
    formVars.istakiplist_583028_options = stateVars.sbSureAralikList;
    stateVars.sbsKartTuruList = result?.data.sbsKartTuruList;
    stateVars.getIsTakipNickList = result?.data.getIsTakipNickList;

    formVars.istakiplist_799826_value =
      stateVars.getFilterParametersByUserName?.length > 0
        ? stateVars.getFilterParametersByUserName[0]?.isTakipListIlgiliKisi
        : null;
    formVars.istakiplist_799826_options = stateVars.getIsTakipNickList;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.IsTakipListPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  IsTakipListPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "istakiplist_583028_value",
      ReactSystemFunctions.toString(
        this,
        this.state.getFilterParametersByUserName?.length > 0
          ? this.state.getFilterParametersByUserName[0]?.isTakipListSBox
          : null
      ),
      null,
      "Id"
    );
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "istakiplist_799826_value",
      ReactSystemFunctions.toString(
        this,
        this.state.getFilterParametersByUserName?.length > 0
          ? this.state.getFilterParametersByUserName[0]?.isTakipListIlgiliKisi
          : null
      ),
      null,
      "KUserID"
    );
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "istakiplist_998378_value",
      ReactSystemFunctions.toString(
        this,
        this.state.getFilterParametersByUserName?.length > 0
          ? this.state.getFilterParametersByUserName[0]?.isTakipListSearch
          : null
      ),
      null
    );
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "istakiplist_234593_value",
      ReactSystemFunctions.toString(
        this,
        this.state.getFilterParametersByUserName?.length > 0
          ? this.state.getFilterParametersByUserName[0]?.isTakipListSiralama
          : null
      ),
      null,
      "Key"
    );

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.IsTakipListPageInit2_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  IsTakipListPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      Rol_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.getUserInfo?.length > 0 ? this.state.getUserInfo[0]?.rolName : null
        ),
        "string"
      ),
      Search_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "istakiplist_998378_value", "value", "", "", "")
        ),
        "string"
      ),
      PageNumber_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "istakiplist_958309_value", "current", "", "", "")
        ),
        "number"
      ),
      RowsOfPage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "istakiplist_958309_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      GuncellemeGunuSon_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "istakiplist_583028_value", "value", "sbSureAralikList", "id", "maxGun")
        ),
        "number"
      ),
      Nick_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "istakiplist_799826_value", "value", "getIsTakipNickList", "kUserID", "nick")
        ),
        "string"
      ),
      Siralama_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "istakiplist_234593_value", "value", "", "key", "")
        ),
        "string"
      ),
      Rol_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.getUserInfo?.length > 0 ? this.state.getUserInfo[0]?.rolName : null
        ),
        "string"
      ),
      Search_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "istakiplist_998378_value", "value", "", "", "")
        ),
        "string"
      ),
      GuncellemeGunuSon_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "istakiplist_583028_value", "value", "sbSureAralikList", "id", "maxGun")
        ),
        "number"
      ),
      Nick_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "istakiplist_799826_value", "value", "getIsTakipNickList", "kUserID", "nick")
        ),
        "string"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "IsTakipList/IsTakipListPageInit2_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.spIsTakipList = result?.data.spIsTakipList;

    stateVars.spIsTakipListCount = result?.data.spIsTakipListCount;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.IsTakipListPageInit3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  IsTakipListPageInit3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.istakiplist_583028_value = ReactSystemFunctions.toString(
      this,
      this.state.getFilterParametersByUserName?.length > 0
        ? this.state.getFilterParametersByUserName[0]?.isTakipListSBox
        : null
    );

    stateVars.dataSource_583028 = this.state.sbSureAralikList;
    stateVars.dataSource_583028 = this.state.sbSureAralikList;
    formVars.istakiplist_799826_value = ReactSystemFunctions.toString(
      this,
      this.state.getFilterParametersByUserName?.length > 0
        ? this.state.getFilterParametersByUserName[0]?.isTakipListIlgiliKisi
        : null
    );

    stateVars.dataSource_799826 = this.state.getIsTakipNickList;
    stateVars.dataSource_799826 = this.state.getIsTakipNickList;
    formVars.istakiplist_998378_value = ReactSystemFunctions.toString(
      this,
      this.state.getFilterParametersByUserName?.length > 0
        ? this.state.getFilterParametersByUserName[0]?.isTakipListSearch
        : null
    );

    formVars.istakiplist_234593_value = ReactSystemFunctions.toString(
      this,
      this.state.getFilterParametersByUserName?.length > 0
        ? this.state.getFilterParametersByUserName[0]?.isTakipListSiralama
        : null
    );

    stateVars.dataSource_660947 = this.state.spIsTakipList;
    stateVars.isComp3650Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, "istakiplist_660947_value", "acil"), true) ===
      true
        ? "visible"
        : "hidden";
    stateVars.isComp639961Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "istakiplist_660947_value", "yeniMsjIcoGoster"),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp664290Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "istakiplist_660947_value", "yeniMsjIcoGoster"),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp765309Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "istakiplist_660947_value", "yeniMsjIcoGoster"),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    formVars.istakiplist_958309_total = ReactSystemFunctions.value(
      this,
      this.state.spIsTakipListCount?.length > 0 ? this.state.spIsTakipListCount[0]?.sumCount : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  IsTakipListComponent_653137_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "IsTakipList",
      "LeftMenu",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "464411",
      "",
      "left",
      null,
      "",
      "",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  IsTakipListComponent_801431_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {};

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "IsTakipList/IsTakipListComponent_801431_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.OTOMAILTempInsert = result?.data.otomailTempInsert;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.IsTakipListComponent_801431_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  IsTakipListComponent_801431_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "1d4dbc1b_e5c3_caa3_d9ed_3ff72b8c6a63_notify",
        this.defaultML,
        "Mailler Gönderildi!"
      ),
      "success",
      "bottom-right",
      1,
      null,
      null,
      null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  IsTakipListComponent_583028_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      IsTakipListIlgiliKisi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "istakiplist_799826_value",
            "value",
            "getIsTakipNickList",
            "kUserID",
            "kUserID"
          )
        ),
        "string"
      ),
      IsTakipListSBox_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "istakiplist_583028_value", "value", "sbSureAralikList", "id", "id")
        ),
        "string"
      ),
      IsTakipListSearch_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "istakiplist_998378_value", "value", "", "", "")
        ),
        "string"
      ),
      IsTakipListSiralama_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "istakiplist_234593_value", "value", "", "key", "")
        ),
        "string"
      ),
      Rol_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.getUserInfo?.length > 0 ? this.state.getUserInfo[0]?.rolName : null
        ),
        "string"
      ),
      Search_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "istakiplist_998378_value", "value", "", "", "")
        ),
        "string"
      ),
      PageNumber_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "istakiplist_958309_value", "current", "", "", "")
        ),
        "number"
      ),
      RowsOfPage_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "istakiplist_958309_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      GuncellemeGunuSon_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "istakiplist_583028_value", "value", "sbSureAralikList", "id", "maxGun")
        ),
        "number"
      ),
      Nick_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "istakiplist_799826_value", "value", "getIsTakipNickList", "kUserID", "nick")
        ),
        "string"
      ),
      Siralama_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "istakiplist_234593_value", "value", "", "key", "")
        ),
        "string"
      ),
      Rol_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.getUserInfo?.length > 0 ? this.state.getUserInfo[0]?.rolName : null
        ),
        "string"
      ),
      Search_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "istakiplist_998378_value", "value", "", "", "")
        ),
        "string"
      ),
      GuncellemeGunuSon_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "istakiplist_583028_value", "value", "sbSureAralikList", "id", "maxGun")
        ),
        "number"
      ),
      Nick_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "istakiplist_799826_value", "value", "getIsTakipNickList", "kUserID", "nick")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "IsTakipList/IsTakipListComponent_583028_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.FilterParametersSaveIsTakipList = result?.data.filterParametersSaveIsTakipList;
    stateVars.spIsTakipList = result?.data.spIsTakipList;

    stateVars.spIsTakipListCount = result?.data.spIsTakipListCount;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.IsTakipListComponent_583028_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  IsTakipListComponent_583028_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "istakiplist_958309_value", 1, null);

    stateVars.dataSource_660947 = this.state.spIsTakipList;
    formVars.istakiplist_958309_total = ReactSystemFunctions.value(
      this,
      this.state.spIsTakipListCount?.length > 0 ? this.state.spIsTakipListCount[0]?.sumCount : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  IsTakipListComponent_799826_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      IsTakipListIlgiliKisi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "istakiplist_799826_value",
            "value",
            "getIsTakipNickList",
            "kUserID",
            "kUserID"
          )
        ),
        "string"
      ),
      IsTakipListSBox_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "istakiplist_583028_value", "value", "sbSureAralikList", "id", "id")
        ),
        "string"
      ),
      IsTakipListSearch_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "istakiplist_998378_value", "value", "", "", "")
        ),
        "string"
      ),
      IsTakipListSiralama_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "istakiplist_234593_value", "value", "", "key", "")
        ),
        "string"
      ),
      Rol_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.getUserInfo?.length > 0 ? this.state.getUserInfo[0]?.rolName : null
        ),
        "string"
      ),
      Search_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "istakiplist_998378_value", "value", "", "", "")
        ),
        "string"
      ),
      PageNumber_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "istakiplist_958309_value", "current", "", "", "")
        ),
        "number"
      ),
      RowsOfPage_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "istakiplist_958309_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      GuncellemeGunuSon_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "istakiplist_583028_value", "value", "sbSureAralikList", "id", "maxGun")
        ),
        "number"
      ),
      Nick_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "istakiplist_799826_value", "value", "getIsTakipNickList", "kUserID", "nick")
        ),
        "string"
      ),
      Siralama_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "istakiplist_234593_value", "value", "", "key", "")
        ),
        "string"
      ),
      Rol_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.getUserInfo?.length > 0 ? this.state.getUserInfo[0]?.rolName : null
        ),
        "string"
      ),
      Search_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "istakiplist_998378_value", "value", "", "", "")
        ),
        "string"
      ),
      GuncellemeGunuSon_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "istakiplist_583028_value", "value", "sbSureAralikList", "id", "maxGun")
        ),
        "number"
      ),
      Nick_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "istakiplist_799826_value", "value", "getIsTakipNickList", "kUserID", "nick")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "IsTakipList/IsTakipListComponent_799826_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.FilterParametersSaveIsTakipList = result?.data.filterParametersSaveIsTakipList;
    stateVars.spIsTakipList = result?.data.spIsTakipList;

    stateVars.spIsTakipListCount = result?.data.spIsTakipListCount;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.IsTakipListComponent_799826_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  IsTakipListComponent_799826_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "istakiplist_958309_value", 1, null);

    stateVars.dataSource_660947 = this.state.spIsTakipList;
    formVars.istakiplist_958309_total = ReactSystemFunctions.value(
      this,
      this.state.spIsTakipListCount?.length > 0 ? this.state.spIsTakipListCount[0]?.sumCount : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  IsTakipListComponent_998378_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      IsTakipListIlgiliKisi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "istakiplist_799826_value",
            "value",
            "getIsTakipNickList",
            "kUserID",
            "kUserID"
          )
        ),
        "string"
      ),
      IsTakipListSBox_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "istakiplist_583028_value", "value", "sbSureAralikList", "id", "id")
        ),
        "string"
      ),
      IsTakipListSearch_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "istakiplist_998378_value", "value", "", "", "")
        ),
        "string"
      ),
      IsTakipListSiralama_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "istakiplist_234593_value", "value", "", "key", "")
        ),
        "string"
      ),
      Rol_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.getUserInfo?.length > 0 ? this.state.getUserInfo[0]?.rolName : null
        ),
        "string"
      ),
      Search_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "istakiplist_998378_value", "value", "", "", "")
        ),
        "string"
      ),
      PageNumber_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "istakiplist_958309_value", "current", "", "", "")
        ),
        "number"
      ),
      RowsOfPage_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "istakiplist_958309_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      GuncellemeGunuSon_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "istakiplist_583028_value", "value", "sbSureAralikList", "id", "maxGun")
        ),
        "number"
      ),
      Nick_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "istakiplist_799826_value", "value", "getIsTakipNickList", "kUserID", "nick")
        ),
        "string"
      ),
      Siralama_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "istakiplist_234593_value", "value", "", "key", "")
        ),
        "string"
      ),
      Rol_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.getUserInfo?.length > 0 ? this.state.getUserInfo[0]?.rolName : null
        ),
        "string"
      ),
      Search_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "istakiplist_998378_value", "value", "", "", "")
        ),
        "string"
      ),
      GuncellemeGunuSon_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "istakiplist_583028_value", "value", "sbSureAralikList", "id", "maxGun")
        ),
        "number"
      ),
      Nick_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "istakiplist_799826_value", "value", "getIsTakipNickList", "kUserID", "nick")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "IsTakipList/IsTakipListComponent_998378_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.FilterParametersSaveIsTakipList = result?.data.filterParametersSaveIsTakipList;
    stateVars.spIsTakipList = result?.data.spIsTakipList;

    stateVars.spIsTakipListCount = result?.data.spIsTakipListCount;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.IsTakipListComponent_998378_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  IsTakipListComponent_998378_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "istakiplist_958309_value", 1, null);

    stateVars.dataSource_660947 = this.state.spIsTakipList;
    formVars.istakiplist_958309_total = ReactSystemFunctions.value(
      this,
      this.state.spIsTakipListCount?.length > 0 ? this.state.spIsTakipListCount[0]?.sumCount : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  IsTakipListComponent_234593_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      IsTakipListIlgiliKisi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "istakiplist_799826_value",
            "value",
            "getIsTakipNickList",
            "kUserID",
            "kUserID"
          )
        ),
        "string"
      ),
      IsTakipListSBox_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "istakiplist_583028_value", "value", "sbSureAralikList", "id", "id")
        ),
        "string"
      ),
      IsTakipListSearch_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "istakiplist_998378_value", "value", "", "", "")
        ),
        "string"
      ),
      IsTakipListSiralama_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "istakiplist_234593_value", "value", "", "key", "")
        ),
        "string"
      ),
      Rol_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.getUserInfo?.length > 0 ? this.state.getUserInfo[0]?.rolName : null
        ),
        "string"
      ),
      Search_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "istakiplist_998378_value", "value", "", "", "")
        ),
        "string"
      ),
      PageNumber_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "istakiplist_958309_value", "current", "", "", "")
        ),
        "number"
      ),
      RowsOfPage_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "istakiplist_958309_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      GuncellemeGunuSon_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "istakiplist_583028_value", "value", "sbSureAralikList", "id", "maxGun")
        ),
        "number"
      ),
      Nick_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "istakiplist_799826_value", "value", "getIsTakipNickList", "kUserID", "nick")
        ),
        "string"
      ),
      Siralama_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "istakiplist_234593_value", "value", "", "key", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "IsTakipList/IsTakipListComponent_234593_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.FilterParametersSaveIsTakipList = result?.data.filterParametersSaveIsTakipList;
    stateVars.spIsTakipList = result?.data.spIsTakipList;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.IsTakipListComponent_234593_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  IsTakipListComponent_234593_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_660947 = this.state.spIsTakipList;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  IsTakipListComponent_50806_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "IsTakipList",
      "TicketList",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  IsTakipListComponent_871646_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      BTuru_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "istakiplist_660947_value", "btKod"),
        "number"
      ),
      PTuru_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "istakiplist_660947_value", "pftKod"),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "IsTakipList/IsTakipListComponent_871646_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.getFormNo = result?.data.getFormNo;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.IsTakipListComponent_871646_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  IsTakipListComponent_871646_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, this.state.getFormNo?.length > 0 ? this.state.getFormNo[0]?.formNo : null),
        "0"
      )
    ) {
      KuikaAppManager.prepareForNavigation();
      KuikaAppManager.addToPageInputVariables(
        "BFormuBankaV2",
        "prmGuid",
        ReactSystemFunctions.value(this, "istakiplist_660947_value", "id")
      );
      KuikaAppManager.addToPageInputVariables(
        "BFormuBankaV2",
        "prmBavuruTuruID",
        ReactSystemFunctions.value(this, "istakiplist_660947_value", "basvuruTuruID")
      );
      KuikaAppManager.addToPageInputVariables("BFormuBankaV2", "NewDisplay", "D");
      KuikaAppManager.addToPageInputVariables("BFormuBankaV2", "prmNerdenGeldi", "info");

      stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
        this,
        this.IsTakipListComponent_871646_onClick2_,
        "IsTakipList",
        "BFormuBankaV2",
        "",
        "currentPage",
        null,
        null,
        null,
        null,
        null,
        null,
        true
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.IsTakipListComponent_871646_onClick2_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  IsTakipListComponent_871646_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(this, this.state.getFormNo?.length > 0 ? this.state.getFormNo[0]?.formNo : null),
        "0"
      )
    ) {
      KuikaAppManager.prepareForNavigation();
      KuikaAppManager.addToPageInputVariables(
        "BFormuTicari",
        "prmGuid",
        ReactSystemFunctions.value(this, "istakiplist_660947_value", "id")
      );
      KuikaAppManager.addToPageInputVariables(
        "BFormuTicari",
        "prmBavuruTuruID",
        ReactSystemFunctions.value(this, "istakiplist_660947_value", "basvuruTuruID")
      );
      KuikaAppManager.addToPageInputVariables("BFormuTicari", "NewDisplay", "D");
      KuikaAppManager.addToPageInputVariables(
        "BFormuTicari",
        "prmProjeTuruID",
        ReactSystemFunctions.value(this, "istakiplist_660947_value", "projeTuruID")
      );
      KuikaAppManager.addToPageInputVariables("BFormuTicari", "prmNerdenGeldi", "info");

      stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
        this,
        undefined,
        "IsTakipList",
        "BFormuTicari",
        "",
        "currentPage",
        null,
        null,
        null,
        null,
        null,
        null,
        true
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  IsTakipListComponent_639961_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "IsTakipListNot",
      "BasvuruFormuID",
      ReactSystemFunctions.value(this, "istakiplist_660947_value", "id")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "IsTakipList",
      "IsTakipListNot",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "618352",
      null,
      "right",
      null,
      "480px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  IsTakipListComponent_664290_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "IsTakipListNot",
      "BasvuruFormuID",
      ReactSystemFunctions.value(this, "istakiplist_660947_value", "id")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "IsTakipList",
      "IsTakipListNot",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "713073",
      null,
      "right",
      null,
      "380px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  IsTakipListComponent_765309_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "IsTakipListNot",
      "BasvuruFormuID",
      ReactSystemFunctions.value(this, "istakiplist_660947_value", "id")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "IsTakipList",
      "IsTakipListNot",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "418519",
      null,
      "right",
      null,
      "380px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  IsTakipListComponent_578618_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "IsTakipKart",
      "prmGuid",
      ReactSystemFunctions.value(this, "istakiplist_660947_value", "id")
    );
    KuikaAppManager.addToPageInputVariables("IsTakipKart", "prmNerdenGeldi", "istakip");

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "IsTakipList",
      "IsTakipKart",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  IsTakipListComponent_958309_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Search_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "istakiplist_998378_value", "value", "", "", "")
        ),
        "string"
      ),
      PageNumber_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "istakiplist_958309_value", "current", "", "", "")
        ),
        "number"
      ),
      RowsOfPage_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "istakiplist_958309_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      GuncellemeGunuSon_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "istakiplist_583028_value", "value", "sbSureAralikList", "id", "maxGun")
        ),
        "number"
      ),
      Nick_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "istakiplist_799826_value", "value", "getIsTakipNickList", "kUserID", "nick")
        ),
        "string"
      ),
      Siralama_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "istakiplist_234593_value", "value", "", "key", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "IsTakipList/IsTakipListComponent_958309_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.getUserInfo = result?.data.getUserInfo;
    stateVars.spIsTakipList = result?.data.spIsTakipList;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.IsTakipListComponent_958309_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  IsTakipListComponent_958309_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_660947 = this.state.spIsTakipList;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [464411, 618352, 713073, 418519] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.IsTakipListPageInit();
    }
  }
}
